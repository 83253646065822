import React from "react";
import { connect } from "react-redux";
import UserCard from './UserCard';
import AddUser from './addUser';
import { Row, Col, Button,Form ,Modal, Spinner} from 'react-bootstrap';
import { sort_by_field } from "../../globals";
import { getAllAdminUsers,getAllAdminShips,getAllAdminWarehouses,filterUsersAdmin,addUser,getUser,editUser,deleteUser,
closeOpenedPart,closeError} from '../../js/actions'
import ReactLoading from 'react-loading';
import OpenUser from "./OpenUser"

import Navbar from '../Common/Navbar'

class AllUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shipping_co:'', type:'', addPartModal: false, 
            user_id: null
        };
       
    }
    componentDidMount = () => {
        this.props.getAllAdminUsers();
        this.props.getAllAdminShips();
        this.props.getAllAdminWarehouses();
    }
    search = (e) => {
        e.preventDefault();
        const { shipping_co,type } = this.state;
        this.props.filterUsersAdmin({ shipping_co: shipping_co, type:type});
    }

   
    renderUsers = () => {
        const { all_users, filter_applied,filtered_items, users_success} = this.props;
        if (filter_applied) {
            if(filtered_items.length > 0){
                return (filtered_items.map((p, i) => {
                    return (
                            <UserCard user={p} key={i} openUser={this.openUser}/>
                    )
                }))
            }
          
    

        }
        else  {
            if(users_success){
                return (
                    all_users.map((p, i) => {
                        return (
                                <UserCard user={p} key={i} openUser={this.openUser} />
                        )
                    })
                )
            }
            else{
                return ( <ReactLoading type={'spin'} height='2em' width='2em' color={'#2E5BFF'}  />)
            }
            

        }
    }

    openUser = (id) => {
        this.props.getUser({ id: `${id}` });
    }
    addUser = (data) => {
        const {type, name, email, shipping_company_name, ship_id,warehouse_name,warehouse_id } = data;
        const{all_ships,all_warehouses}=this.props;

        let tempData = data;
        if(type=="Shipping Company"){
            if(ship_id==null){
                tempData.ship_id=all_ships[0]._id;
                tempData.shipping_company_name=all_ships[0].shipping_company_name;
                
            }
            else{
                let temp_s=all_ships.find(s=>{ if(s._id==ship_id) return s.shipping_company_name});
               
                tempData.shipping_company_name=temp_s.shipping_company_name;
            }
            delete tempData.warehouse_id;
            delete tempData.warehouse_name;
        }
        else{
            if(warehouse_id==null){
                tempData.warehouse_id=all_warehouses[0]._id;
                tempData.warehouse_name=all_warehouses[0].forwarding_company;
                
            }
            else{
                let temp_s=all_warehouses.find(w=>{ if(w._id==warehouse_id) return w.forwarding_company});
                tempData.warehouse_name=temp_s.forwarding_company
            }
            delete tempData.ship_id;
            delete tempData.shipping_company_name;
        }
        this.props.addUser(tempData)
        this.setState({ addPartModal: false });
        this.props.getAllAdminUsers();
    }
    deleteUser = (id) => {
        this.props.deleteUser({ id: `${id}` });
        this.props.closeOpenedPart();
    }


    render() {
        const { all_users,all_ships,user ,all_warehouses,ships_success} = this.props;
        const { shipping_co,addPartModal,type } = this.state;

        const { error, err_msg, success_msg, closeError ,openConfirm,open_item_modal,closeOpenedPart} = this.props;
        if(localStorage.getItem("loginState")=="false" && localStorage.getItem("admin_login")=="false"){
            this.props.history.push("/admin/");
            return null;
          }
        else
            return (
                <React.Fragment>
                <Row>
                    <Navbar active="users" />
                    <Col>
                        <Row className="top-header">

                        </Row>

                        <Row className="parts-main">
                            <Col md="10">
                                <Row>
                                    <Col><h2>Users</h2></Col>
                                    <Col><p className='gray_text pt-3'>{all_users?all_users.length:''} Total</p></Col>
                                    <Col> <Button variant="primary" className="add-part-btn" onClick={() => { this.setState({ addPartModal: true }) }}>+ User</Button></Col>
                                </Row>
                               <div id='labels'>
                                <Col style={{padding:0}}><p className='gray_text text-uppercase'>Name</p></Col>
                                <Col style={{padding:0}}><p className='gray_text text-uppercase'>Company/Warehouse</p></Col>
                                <Col style={{padding:0}}><p className='gray_text text-uppercase'>Email</p></Col>
                                <Col style={{padding:0}}><p className='gray_text text-uppercase'>Password</p></Col>
                                </div>
                                <div style={{overflow:'scroll',height:'82vh',padding:10}}>
                                {
                                    this.renderUsers()
                                }
                                </div>

                            </Col>

                            <Col md="2" className="parts-filter">
                                <p>FILTER</p>
                                    <Form onSubmit={this.search}>

                                        <Form.Group >
                                            <p className='gray_text  bold text-uppercase'>Type</p>
                                            <Form.Control as="select" onChange={(e) => { this.setState({ type: `${e.target.value}` }) }}>
                                                <option key="0" value=""  >Choose...</option>
                                                <option key="Shipping Company" value="Shipping Company"  >Shipping Company</option>
                                                <option key="Warehouse Manager" value="Warehouse Manager"  >Warehouse Manager</option>

                                            </Form.Control>
                                        </Form.Group>
                                        {
                                            ships_success &&    
                                            <Form.Group >
                                            <p className='gray_text  bold text-uppercase'>Company Name</p>
                                            <Form.Control as="select" onChange={(e) => { this.setState({ shipping_co: `${e.target.value}` }) }}>
                                                <option key="0" value=""  >Choose...</option>
                                                {
                                                    sort_by_field(all_ships,'shipping_company_name').map((s, i) => {
                                                        return <option name="ship" key={s._id} value={s.shipping_company_name} >{s.shipping_company_name}</option>
                                                    })
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                        }
                                        
                                        <button className="btn btn-primary search-btn">Search</button>
                                    </Form>
                               
                            </Col>

                            <AddUser show={addPartModal} onHide={() => { this.setState({ addPartModal: false }) }}
                               all_ships={all_ships} warehouses={all_warehouses} addUser={this.addUser}  />

                            <OpenUser user={user} all_ships={all_ships} warehouses={all_warehouses} show={open_item_modal} onHide={closeOpenedPart}
                            deleteUser={this.deleteUser} />

                        </Row>
                    </Col>

                </Row>

                
                  
                </React.Fragment>





            )


    }
}
const mapDispatchToProps = dispatch => {
    return {
        getAllAdminUsers: payload => dispatch(getAllAdminUsers()),
        getAllAdminShips:payload => dispatch(getAllAdminShips()),
        addUser:payload => dispatch(addUser(payload)),
        filterUsersAdmin:payload => dispatch(filterUsersAdmin(payload)),
        getAllAdminWarehouses: payload => dispatch(getAllAdminWarehouses()),

        closeError: payload => dispatch(closeError()),
        closeOpenedPart:payload => dispatch(closeOpenedPart()),
        getUser: payload => dispatch(getUser(payload)),
        editUser: payload => dispatch(editUser(payload)),
        deleteUser: payload => dispatch(deleteUser(payload))
    }
}
const mapStateToProps = state => {
    return { all_users: state.all_users, all_ships:state.all_ships, user: state.user, all_warehouses: state.all_warehouses,
        filtered_items: state.filtered_items, filter_applied: state.filter_applied,
        error: state.error, err_msg: state.err_msg, success_msg: state.success_msg,openConfirm:state.openConfirm,
        open_item_modal: state.open_item_modal,
        loginState:state.loginState, admin_login:state.admin_login,
        ships_success:state.ships_success, users_success:state.users_success
      }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllUsers);
