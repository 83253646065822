import React from 'react'
import {Modal, OverlayTrigger,Tooltip,Button} from 'react-bootstrap'
import Row from '../Layout/Row'
import { store } from 'react-notifications-component';
import ConfirmDeleteButton from '../ConfirmDeleteButton/ConfirmDeleteButton';
export default function MyModal({show,setShow,children,title,setEditMode,editMode,handleDelete,showButtons=false}) {
    return (
        <Modal
        show={show}
        onHide={()=>setShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
         <Modal.Body style={{padding:'1em'}}>
         <div className='col-12 row' style={{justifyContent:'space-between'}}>
             <h4>{title}</h4>
             <Row>
           {showButtons&&
           (editMode ?
            <button onClick={()=>setEditMode(false)} className='btn mr-3'>Abort Edit</button>:
            <React.Fragment>
             <button onClick={()=>setEditMode(true)} className='btn mr-3'>Edit</button>
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
                >

            <ConfirmDeleteButton hideModal={()=>setShow(false)} yesFunction={()=>handleDelete()} />
            </OverlayTrigger>
           </React.Fragment>) }  
             </Row>
             </div>
         <hr/>
          {children}
         </Modal.Body>
    </Modal>
    )
}


const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        Double Click to Delete
    </Tooltip>
  );

