import React from "react";
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { api_callback } from "../../globals";


const initialState = { shipping_co: '', person_in_charge: '', address: '', city: '', country: '', 
phone_no: '', email: '', validated:false };

class AddShip extends React.Component {
    constructor(props) {
        super(props);
        this.state = { shipping_co: '',phone:'', person_in_charge: '', address: '', city: '', country: '', phone_no:'',
         email: '', validated:false };


        this.handleChange = this.handleChange.bind(this);
    }
    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    }

    addShip = (event) => {
        const form = event.currentTarget;
        
        if (form.checkValidity() === false  ) {
            event.preventDefault()
            event.stopPropagation();
        }
        event.preventDefault();
        this.setState({ validated: true })
        if (form.checkValidity() === true) {
            console.log(this.state)
            this.props.addShip(this.state);
            this.setState(initialState)
        }
    }

    onHide = () => {
        this.setState(initialState)
        this.props.onHide()
    }
    render() {
        const { show } = this.props;

        const { shipping_co, person_in_charge, address, city, country, phone_no, email,validated } = this.state;
        return (
            <Modal
                show={show}
                onHide={this.onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <h4>Add a Shipping Company</h4>
                    <Form noValidate validated={validated} onSubmit={this.addShip}>
                        <Form.Row>
                            <Form.Group as={Col} md="8" >
                               <p className="gray_text bold text-uppercase"> SHIPPING COMPANY</p>
                                <Form.Control id="shipping_co" value={shipping_co} onChange={this.handleChange} placeholder="Enter Name" required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide Shipping Company Name
                            </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md="4" >
                                <p className="gray_text bold text-uppercase">PERSON IN CHARGE</p>
                                <Form.Control id="person_in_charge" value={person_in_charge} onChange={this.handleChange} placeholder="Enter Name of Person in Charge" required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide name of person in charge
                            </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>

                            <Form.Group as={Col} md="4">
                                <p className="gray_text bold text-uppercase">ADDRESS</p>
                                <Form.Control
                                    id="address" value={address} onChange={this.handleChange} placeholder="Enter Address" required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide address.
                            </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md="4">
                                <p className="gray_text bold text-uppercase">CITY</p>
                                <Form.Control id="city" value={city} onChange={this.handleChange} placeholder="Enter City" required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide city name.
                            </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md="4">
                                <p className="gray_text bold text-uppercase">COUNTRY</p>
                                <Form.Control id="country" value={country} onChange={this.handleChange} placeholder="Enter Country" required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide country name
                            </Form.Control.Feedback>
                            </Form.Group>

                        </Form.Row>
                        <Form.Row>
                       
                        <Form.Group as={Col} md="4">
                       <p className="gray_text bold text-uppercase">PHONE NUMBER</p>
                       <ReactPhoneInput
                        containerStyle={{
                            marginLeft:0,
                            height:'2.6rem'
                        }}
                        country={'gr'}
                        buttonStyle={{
                            background:'rgba(224,231,255,0.2)',borderColor:'#E0E7FF' 
                        }}
                        inputStyle={{background:'rgba(224,231,255,0.2)',borderColor:'#E0E7FF',height:'2.6rem',width:'100%'}}
                        value={this.state.phone_no}
                        enableSearch={true}
                        onChange={(phone_no)=>this.setState({phone_no})}
                        /> 
                      
                            </Form.Group>

                            <Form.Group as={Col} md="4">
                                <p className="gray_text bold text-uppercase">EMAIL</p>
                                <Form.Control type="email" id="email" value={email} onChange={this.handleChange} placeholder="Enter Email" required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide email
                            </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Button type="submit" className="btn btn-primary col-4 mt-3">Done</Button>
                    </Form>

                </Modal.Body>
            </Modal>
        );

    }

}


export default AddShip;
