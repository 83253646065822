import { Component } from "react";
import Joi from "joi-browser";

export default class Form extends Component {
  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };
  handleChange = ({ target }) => {
    const error = { ...this.state.error };
    const errorMassage = this.validateProperty(target);
    if (errorMassage) error[target.name] = errorMassage;
    else delete error[target.name];

    const data = { ...this.state.data };
    data[target.name] = target.value;
    this.setState({ data, error,loginButtonSpinner:false,signupButtonLoading:false });
  };
  validate = () => {
    const result = Joi.validate(this.state.data, this.schema, {
      abortEarly: false,
    });
    if (!result.error) return null;
    const error = {};
    for (let item of result.error.details) error[item.path[0]] = item.message;
    return error;
  };
  //On the spinner of the button
  // spinnerButton = () => {
  //   this.setState({ loginSpinner: true });
  //   console.log("loginspinner", this.state.loginSpinner);
  // };
}
