import React from "react";
import { Card, Row, Col } from 'react-bootstrap';
import '../styles.scss';



export default function ShipCard(props) {
    const { ship,openShip } = props;
    return (
        <React.Fragment>
            <Card className="part-detail" onClick={()=>{openShip(ship._id)}}>
                    <Row>
                    <Col>
                    <p className='black_text'>{ship.shipping_company_name}</p>
                    </Col>
                    <Col><p className='black_text'>{ship.person_in_charge}</p></Col>
                    <Col>
                    <p className='black_text'>{ship.city || ''}</p>
                    </Col>
                    <Col><p className='black_text'>{ship.country || ''}</p></Col>
                    </Row>
            </Card>
        </React.Fragment>

    );
}
