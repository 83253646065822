import React from "react";
import { Row, Col, Modal, Button, Form } from 'react-bootstrap';
import '../styles.scss'
import { connect } from "react-redux";
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import {
    editWarehouse
} from '../../js/actions'
import ConfirmDeleteButton from "../../Common/ConfirmDeleteButton/ConfirmDeleteButton";

const initialState = { forwarding_company: '', address: '', email: '', city: '', country: null, phone_no: '', edit: false };
class OpenWarehouse extends React.Component {
    constructor(props) {
        super(props);
        this.state = { forwarding_company: '', address: '', email: '', city: '', country: null, phone_no: '', edit: false };
    }
    editWhById = (event) => {
        const { wh } = this.props;
        let tempData = this.state;

        tempData.id = wh._id;
        this.props.editWarehouse(tempData)
        this.setState(initialState)
        this.props.onHide();
    }

    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value })
    }
    onClose = () => {
        this.setState(initialState, () => {
            this.props.onHide();
        })

    }


    editClicked = () => {
        const wh = this.props.wh
        this.setState({
            forwarding_company: wh.forwarding_company,
            address: wh.address,
            email: wh.email,
            city: wh.city,
            country: wh.country,
            phone_no: wh.phone_no,
            edit: true
        });
    }
    render() {
        const { wh, show, deleteWarehouse } = this.props;
        const { forwarding_company, address, country, city, email, phone_no, edit } = this.state;

        if (wh != undefined && edit == false) {
            return (
                <Modal
                    show={show}
                    onHide={this.onClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Body>
                        <React.Fragment>

                            <Row>
                                <Col>
                                    <h3>{wh.forwarding_company}</h3>


                                </Col>
                                <Col>

                        <ConfirmDeleteButton yesFunction={()=>deleteWarehouse(wh._id)}  hideModal={this.onClose}/>
                                    <Button className="edit-trans" onClick={this.editClicked}>
                                        Edit
                    </Button>
                                </Col>

                            </Row>
                            <br></br>
                            <Row>
                                <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>Name</p>
                                    <p>{wh.forwarding_company}</p>
                                </Col>

                            </Row>


                            <Row>

                                <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>ADDRESS</p>
                                    <p>{wh.address}</p>
                                </Col>
                                <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>CITY</p>
                                    <p>{wh.city}</p>
                                </Col>
                                <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>COUNTRY</p>
                                    <p>{wh.country}</p>
                                </Col>

                            </Row>

                            <Row>

                                <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>PHONE NUMBER</p>
                                    <p>{wh.phone_no}</p>
                                </Col>
                                <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>EMAIL</p>
                                    <p>{wh.email}</p>
                                </Col>


                            </Row>



                        </React.Fragment>
                    </Modal.Body>


                </Modal>


            );
        }
        else if (wh != undefined && edit == true) {
            return (
                <Modal
                    show={show}
                    onHide={this.onClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Body>
                        <React.Fragment>

                            <Row>
                                <Col>
                                <h3>{wh.forwarding_company}</h3>

                                </Col>
                                <Col>
                                    <Button className="edit-trans" onClick={this.editWhById}>
                                        Save
                        </Button>
                                </Col>

                            </Row>
                            <br></br>
                            <Form >

                                <Form.Row>


                                    <Form.Group as={Col} md="8">
                                        <p className='gray_text  bold text-uppercase'>FORWARDING COMPANY</p>
                                        <Form.Control
                                            id="forwarding_company" value={forwarding_company} onChange={this.handleChange} required />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide Name.
                            </Form.Control.Feedback>
                                    </Form.Group>


                                </Form.Row>


                                <Form.Row>

                                    <Form.Group as={Col} md="4">
                                        <p className='gray_text  bold text-uppercase'>ADDRESS</p>
                                        <Form.Control
                                            id="address" value={address} onChange={this.handleChange} required />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide address.
                            </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="4">
                                        <p className='gray_text  bold text-uppercase'>CITY</p>
                                        <Form.Control
                                            id="city" value={city} onChange={this.handleChange} required />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide city.
                            </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="4">
                                        <p className='gray_text  bold text-uppercase'>COUNTRY</p>
                                        <Form.Control
                                            id="country" value={country} onChange={this.handleChange} required />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide country.
                            </Form.Control.Feedback>
                                    </Form.Group>

                                </Form.Row>

                                <Form.Row>
                                    <Form.Group as={Col} md="4">
                                        <p className='gray_text  bold text-uppercase'>PHONE NUMBER</p>
                                        <ReactPhoneInput
                                    inputExtraProps={{
                                        id:'phone_no',
                                        name: "phone_no",
                                        required: true,
                                    }}
                                    containerStyle={{
                                        marginLeft:0,
                                        height:'2.6rem'
                                    }}
                                    buttonStyle={{
                                        background:'rgba(224,231,255,0.2)',borderColor:'#E0E7FF' 
                                    }}
                                    inputStyle={{background:'rgba(224,231,255,0.2)',borderColor:'#E0E7FF',height:'2.6rem',width:'100%'}}
                                    value={phone_no}
                                    enableSearch={true}
                                    onChange={(phone_no)=>this.setState({phone_no})}
                                    /> 
                                        <Form.Control.Feedback type="invalid">
                                            Please provide phone.
                            </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        <p className='gray_text  bold text-uppercase'>EMAIL</p>
                                        <Form.Control
                                            id="email" value={email} onChange={this.handleChange} required />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide email.
                            </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>

                            </Form>



                        </React.Fragment>
                    </Modal.Body>


                </Modal>


            );
        }
        else return null;
    }
}

const mapDispatchToProps = dispatch => {
    return {
        editWarehouse: payload => dispatch(editWarehouse(payload)),

    }
}
export default connect(null, mapDispatchToProps)(OpenWarehouse);


