import React from "react";
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { connect } from "react-redux"
import { CloudWatchLogs } from "aws-sdk";
import { sort_by_field } from "../../globals";

const initialState = { reference_no: '', description: '', pieces: 1, weight: '', dimensions: '',
 vessel_id: '', supplier_id: '', warehouse_id: '', ship_id: '', status:'Pending',
validated:false };

class AddPart extends React.Component {
    constructor(props) {
        super(props);
        this.state = { reference_no: '', description: '', pieces: 1, weight: '', 
        dimensions: '', vessel_id: '', supplier_id: '', warehouse_id: '',status:'Pending',
        validated:false };


        this.handleChange = this.handleChange.bind(this);
    }
    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
        console.log(this.state);

    }
    componentWillReceiveProps=(nextProps)=>{
        
        if(nextProps.warehouses_success){
         let all_warehouses= nextProps.all_warehouses.map(({_id,forwarding_company }) => ({id:_id, value: forwarding_company, label:forwarding_company  }))
         all_warehouses=sort_by_field(all_warehouses,'label')
         this.setState({select_warehouses:all_warehouses})
         }
        if(nextProps.vessels_success){
            console.log(nextProps.all_vessels)
         let all_vessels= nextProps.all_vessels.map(({vessel,_id}) => ({id:_id, value: vessel, label:vessel}))
         all_vessels=sort_by_field(all_vessels,'label')
         this.setState({select_vessels:all_vessels})
         }
        if(nextProps.suppliers_success){
         let all_suppliers= nextProps.all_suppliers.map(({supplier_name,_id}) => ({id:_id, value: supplier_name, label:supplier_name}))
         all_suppliers=sort_by_field(all_suppliers,'label')
         this.setState({select_suppliers:all_suppliers})
         }
         console.log(nextProps)
    }
    validate_select=()=>{
        let warehouse_select = document.getElementById('warehouse_select')
        let vessel_select = document.getElementById('vessel_select')
        let supplier_select = document.getElementById('supplier_select')
        
        this.state.warehouse_id=='' ?  warehouse_select.style.border='1px solid red' :  warehouse_select.style.border='1px solid green' 
        this.state.vessel_id=='' ?  vessel_select.style.border='1px solid red' :  vessel_select.style.border='1px solid green' 
        this.state.supplier_id=='' ?  supplier_select.style.border='1px solid red' :  supplier_select.style.border='1px solid green' 
        if(this.state.warehouse_id=='' ||this.state.ship_id=='' ||this.state.vessel_id=='' ||this.state.supplier_id==''  ){
            return false; 
        }else{
            return true;
        }
    }

    addPart = (event) => {
        const form = event.currentTarget;
        if(this.validate_select()){

            event.preventDefault();
            this.setState({ validated: true })
            if (form.checkValidity() === true) {
             this.props.addPart(this.state);
             this.setState(initialState)
         }
     }
     else{
         event.preventDefault(); 
         event.stopPropagation()
     }
       
      
    }

    onHide = () => {
        this.setState(initialState)
        this.props.onHide()
    }
    render() {
        const { show } = this.props;
        //redux
        const { suppliers, warehouses, vessels } = this.props;
        const { reference_no, description, pieces, weight, dimensions, vessel, supplier, warehouse } = this.state;
        
        const{validated}=this.state;
        return (
            <Modal
                show={show}
                onHide={this.onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <h4>Add a Part</h4>
                    <Form validated={validated} onSubmit={this.addPart}>

                        <Form.Row>
                            <Form.Group as={Col} md="4" >
                            <p className="gray_text bold text-uppercase">REFERENCE NO.</p>
                             <Form.Control type="text" id="reference_no" value={reference_no} onChange={this.handleChange} placeholder="Enter Reference no." required />
                             <Form.Control.Feedback type="invalid">
                              Please provide Reference Number
                             </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md="8" >
                            <p className="gray_text bold text-uppercase">Description</p>
                                <Form.Control id="description" value={description} onChange={this.handleChange} placeholder="Enter Description" required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide Description.
                             </Form.Control.Feedback>
                            </Form.Group>

                        </Form.Row>
                        <Form.Row>

                            <Form.Group as={Col} md="4">
                            <p className="gray_text bold text-uppercase">PIECES</p>
                                <Form.Control
                                    id="pieces" type="number" min='0' value={pieces} onChange={this.handleChange} placeholder="Enter Pieces" required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide Pieces
                             </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md="4">
                            <p className="gray_text bold text-uppercase">Weight (kg)</p>
                                <Form.Control id="weight" type='number' step='0.1' min='0' value={weight} onChange={this.handleChange} placeholder="Enter Weight" required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide Weight.
                             </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md="4">
                            <p className="gray_text bold text-uppercase">Dimensions (w*d*h)</p>
                                <Form.Control id="dimensions" value={dimensions} onChange={this.handleChange} placeholder="Enter Dimensions" required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide Dimensions.
                             </Form.Control.Feedback>
                            
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          
                          {this.props.vessels_success &&
                            <Form.Group as={Col} md='4' >
                            <p className="gray_text bold text-uppercase">Vessel</p>
                              <Select
                               id='vessel_select'
                              onChange={(e) => { this.setState({ vessel_id: `${e.id}` }) }}
                              options={this.state.select_vessels}
                              /> 
                        </Form.Group>}

                         {this.props.suppliers_success &&
                            <Form.Group as={Col} md='4' >
                            <p className="gray_text bold text-uppercase">Supplier</p>
                              <Select
                              id='supplier_select'
                              onChange={(e) => { this.setState({ supplier_id: `${e.id}` }) }}
                              options={this.state.select_suppliers}
                              /> 
                            </Form.Group>}
                        </Form.Row>
                        <Form.Row>

                    {this.props.warehouses_success &&
                        <Form.Group as={Col} md="4">
                            <p className="gray_text bold text-uppercase">Warehouse</p>
                             <Select
                             id='warehouse_select'
                              onChange={(e) => { this.setState({ warehouse_id: `${e.id}` }) }}
                              options={this.state.select_warehouses}
                              />
                            </Form.Group> }
                        </Form.Row>

                        <Button type="submit" className="btn btn-primary col-4 mt-3">Done</Button>
                    </Form>
                    
                </Modal.Body>
            </Modal>
        );

    }

}

const mapStateToProps = state => {
    return {
        all_suppliers: state.all_suppliers, all_warehouses: state.all_warehouses, all_vessels: state.vesselsInShip, all_ships: state.all_ships,
        ships_success:state.ships_success, vessels_success:state.vessels_success,warehouses_success:state.warehouses_success,
        parts_success:state.parts_success, suppliers_success:state.suppliers_success
        
    }
}

export default connect(mapStateToProps, null)(AddPart);
