import React from 'react'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

export default function ConfirmDeleteButton({buttonText='Delete',className='delete-trans bold',yesFunction,hideModal=null}) {

    const submit = () => {
        if(hideModal!=null){
            hideModal()
        }
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => yesFunction()
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };
  return (
     <button className={className} onClick={submit}>{buttonText}</button>
  )
}
