import {
  USER_LOGIN_REQUEST, USER_SIGNUP_REQUEST,
  WAREHOUSE_LOGIN_REQUEST,
  USER_UPDATE_REQUEST,
  GET_USER_USER_REQUEST,

  GET_ALL_PARTS_BY_SHIP_REQUEST,
  GET_ALL_PARTS_BY_WH_REQUEST,
  GET_FILTERED_PARTS_REQUEST,

  GET_FILTERED_PARTS_FOR_TRANS,
  CLEAR_FILTERED_PARTS_FOR_TRANS,
  
  
  EDIT_PART_REQUEST,
  ADD_PART_REQUEST,
  GET_PART_TRANSACTIONS_REQUEST,
  DELETE_PART_REQUEST,
  GET_PARTNERS_REQUEST,
  GET_ALL_TRANSACTIONS_BY_SHIP_REQUEST, // for user section
  GET_ALL_TRANSACTIONS_BY_WH_REQUEST, // for warehouse section

  GET_VESSELS_BY_SHIP_REQUEST,


  
  GET_ALL_WAREHOUSE_REQUEST,
  
  
  GET_PART_REQUEST,
  CLOSE_OPENED_PART,


  // admin imports

  GET_ALL_ADMIN_PARTS_REQUEST,

  //vessels
  GET_ALL_ADMIN_VESSELS_REQUEST,
  ADD_VESSEL_REQUEST,
  GET_VESSEL_REQUEST,
  EDIT_VESSEL_REQUEST,
  DELETE_VESSEL_REQUEST,

  //ship
  GET_ALL_ADMIN_SHIP_REQUEST,
  ADD_SHIP_REQUEST,
  GET_SHIP_REQUEST,
  EDIT_SHIP_REQUEST,
  DELETE_SHIP_REQUEST,

  //suppliers
  GET_ALL_ADMIN_SUPPLIERS_REQUEST,
  GET_ALL_SUPPLIERS_REQUEST,
  ADD_SUPPLIER_REQUEST,
  GET_SUPPLIER_REQUEST,
  EDIT_SUPPLIER_REQUEST,
  DELETE_SUPPLIER_REQUEST,

  //warehouse
  GET_ALL_ADMIN_WAREHOUSE_REQUEST,
  ADD_WAREHOUSE_REQUEST,
  GET_WAREHOUSE_REQUEST,
  EDIT_WAREHOUSE_REQUEST,
  DELETE_WAREHOUSE_REQUEST,

  //transaction
  GET_ALL_TRANSACTIONS_REQUEST,
  ADD_TRANSACTION_REQUEST,
  GET_TRANSACTION_REQUEST,
  DEL_TRANSACTION_REQUEST,
  EDIT_TRANSACTION_REQUEST,

  //users
  GET_ALL_ADMIN_USER_REQUEST,
  ADD_USER_REQUEST,
  GET_USER_REQUEST,
  EDIT_USER_REQUEST,
  DELETE_USER_REQUEST,

  // settings
  GET_ALL_ADMINS_REQUEST,
  ADD_ADMIN_REQUEST,
  GET_ADMIN_REQUEST,
  EDIT_ADMIN_REQUEST,
  DELETE_ADMIN_REQUEST,


  // filteration
  GET_ADMIN_FILTERED_PARTS,
  GET_ADMIN_FILTERED_SHIPS,
  GET_ADMIN_FILTERED_VESSELS,
  GET_ADMIN_FILTERED_TRANSACTIONS,
  GET_ADMIN_FILTERED_SUPPLIERS,
  GET_ADMIN_FILTERED_WAREHOUSE,
  GET_ADMIN_FILTERED_USER,


  ADMIN_LOGIN_REQUEST,
  LOG_OUT_ADMIN,
  LOG_OUT_USER,

  // common
  CLOSE_CONFIRMATION, RESET_FILTERED_ITEMS
} from '../constants/action-types'

export function closeError(payload) {
  return { type: CLOSE_CONFIRMATION, payload };
}


// ADMIN ACTIONS

//parts
export function getAllAdminParts(payload) {
  return { type: GET_ALL_ADMIN_PARTS_REQUEST, payload };
}

export function filterPartsAdmin(payload) {
  return { type: GET_ADMIN_FILTERED_PARTS, payload };
}
export function filterPartForTrans(payload) {
  return { type: GET_FILTERED_PARTS_FOR_TRANS, payload };
}

export function clearfilterPartForTrans(payload) {
  return { type: CLEAR_FILTERED_PARTS_FOR_TRANS, payload };
}
export function editPart(payload) {
  return { type: EDIT_PART_REQUEST, payload };
}

export function getPartTransactions(payload) {
  return { type: GET_PART_TRANSACTIONS_REQUEST, payload };
}

export function deletePart(payload) {
  return { type: DELETE_PART_REQUEST, payload };
}

export function getPartners(payload) {
  return { type: GET_PARTNERS_REQUEST, payload };
}

//users

export function getAllAdminUsers(payload) {
  return { type: GET_ALL_ADMIN_USER_REQUEST, payload };
}

export function filterUsersAdmin(payload) {
  return { type: GET_ADMIN_FILTERED_USER, payload };
}

export function addUser(payload) {
  return { type: ADD_USER_REQUEST, payload };
}

export function getUser(payload) {
  return { type: GET_USER_REQUEST, payload };
}

export function editUser(payload) {
  return { type: EDIT_USER_REQUEST, payload };
}

export function deleteUser(payload) {
  return { type: DELETE_USER_REQUEST, payload };
}


//vessels
export function getAllAdminVessels(payload) {
  return { type: GET_ALL_ADMIN_VESSELS_REQUEST, payload };
}
export function filterVesselsAdmin(payload) {
  return { type: GET_ADMIN_FILTERED_VESSELS, payload };
}
export function addVessel(payload) {
  return { type: ADD_VESSEL_REQUEST, payload };
}
export function getVessel(payload) {
  return { type: GET_VESSEL_REQUEST, payload };
}

export function editVessel(payload) {
  return { type: EDIT_VESSEL_REQUEST, payload };
}

export function deleteVessel(payload) {
  return { type: DELETE_VESSEL_REQUEST, payload };
}



// ships
export function getAllAdminShips(payload) {
  return { type: GET_ALL_ADMIN_SHIP_REQUEST, payload };
}

export function filterShipsAdmin(payload) {
  return { type: GET_ADMIN_FILTERED_SHIPS, payload };
}

export function addShipAdmin(payload) {
  return { type: ADD_SHIP_REQUEST, payload };
}
export function getShip(payload) {
  return { type: GET_SHIP_REQUEST, payload };
}

export function editShip(payload) {
  return { type: EDIT_SHIP_REQUEST, payload };
}

export function deleteShip(payload) {
  return { type: DELETE_SHIP_REQUEST, payload };
}



//suppliers
export function getAllAdminSuppliers(payload) {
  return { type: GET_ALL_ADMIN_SUPPLIERS_REQUEST, payload };
}

export function addSupplier(payload) {
  return { type: ADD_SUPPLIER_REQUEST, payload };
}

export function getSupplier(payload) {
  return { type: GET_SUPPLIER_REQUEST, payload };
}

export function editSupplier(payload) {
  return { type: EDIT_SUPPLIER_REQUEST, payload };
}


export function deleteSupplier(payload) {
  return { type: DELETE_SUPPLIER_REQUEST, payload };
}

export function filterSuppliers(payload) {
  return { type: GET_ADMIN_FILTERED_SUPPLIERS, payload };
}

//WAREHOUSE

export function getAllAdminWarehouses(payload) {
  return { type: GET_ALL_ADMIN_WAREHOUSE_REQUEST, payload };
}

export function addWarehouse(payload) {
  return { type: ADD_WAREHOUSE_REQUEST, payload };
}

export function getWarehouse(payload) {
  return { type: GET_WAREHOUSE_REQUEST, payload };
}

export function editWarehouse(payload) {
  return { type: EDIT_WAREHOUSE_REQUEST, payload };
}


export function deleteWarehouse(payload) {
  return { type: DELETE_WAREHOUSE_REQUEST, payload };
}

export function filterWarehouse(payload) {
  return { type: GET_ADMIN_FILTERED_WAREHOUSE, payload };
}


// transactions

export function getAllTransactions(payload) {
  return { type: GET_ALL_TRANSACTIONS_REQUEST, payload };
}

export function addTransaction(payload,callback) {
  return { type: ADD_TRANSACTION_REQUEST, payload,callback };
}

export function getTransactionById(payload) {
  return { type: GET_TRANSACTION_REQUEST, payload };
}

export function editTransaction(payload,callback) {
  return { type: EDIT_TRANSACTION_REQUEST, payload,callback };
}


export function delTransaction(payload,callback) {
  return { type: DEL_TRANSACTION_REQUEST, payload,callback };
}

export function filterTransactions(payload) {
  return { type: GET_ADMIN_FILTERED_TRANSACTIONS, payload };
}

//settings

export function adminLogin(payload) {
  return { type: ADMIN_LOGIN_REQUEST, payload };
}

export function logoutAdmin(payload) {
  return { type: LOG_OUT_ADMIN, payload };
}
export function getAllAdmins(payload) {
  return { type: GET_ALL_ADMINS_REQUEST, payload };
}

export function addAdmin(payload) {
  return { type: ADD_ADMIN_REQUEST, payload };
}

export function getAdmin(payload) {
  return { type: GET_ADMIN_REQUEST, payload };
}

export function editAdmin(payload) {
  return { type: EDIT_ADMIN_REQUEST, payload };
}

export function deleteAdmin(payload) {
  return { type: DELETE_ADMIN_REQUEST, payload };
}





// USER ACTIONS


export function logoutUser(payload) {
  return { type: LOG_OUT_USER, payload };
}

export function userLogin(payload) {
  return { type: USER_LOGIN_REQUEST, payload };
}

export function warehouseLogin(payload) {
  return { type: WAREHOUSE_LOGIN_REQUEST, payload };
}



export function userSignup(payload) {
  return { type: USER_SIGNUP_REQUEST, payload };
}

export function getAllPartsByShip(payload) {
  return { type: GET_ALL_PARTS_BY_SHIP_REQUEST, payload };
}

export function getAllPartsByWarehouse(payload) {
  return { type: GET_ALL_PARTS_BY_WH_REQUEST, payload };
}
export function getTransactionByShip(payload) {
  return { type: GET_ALL_TRANSACTIONS_BY_SHIP_REQUEST, payload };
}

export function getTransactionByWarehouse(payload) {
  return { type: GET_ALL_TRANSACTIONS_BY_WH_REQUEST, payload };
}

export function filterParts(payload) {
  return { type: GET_FILTERED_PARTS_REQUEST, payload };
}



export function getVesselsByShip(payload) {
  return { type: GET_VESSELS_BY_SHIP_REQUEST, payload };
}
export function getAllSuppliers(payload) {
  return { type: GET_ALL_SUPPLIERS_REQUEST, payload };
}
export function getAllWarehouses(payload) {
  return { type: GET_ALL_WAREHOUSE_REQUEST, payload };
}

// close open part modal
export function closeOpenedPart(payload) {
  return { type: CLOSE_OPENED_PART, payload };
}


// COMMON actions

export function addPart(payload) {
  return { type: ADD_PART_REQUEST, payload };
}

export function getPart(payload) {
  return { type: GET_PART_REQUEST, payload };
}

export function reset_filtered_items() {
  return { type:RESET_FILTERED_ITEMS, };
}