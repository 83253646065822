import React, { Component,useEffect,useState } from 'react'
import Select from 'react-select'
import {useQuery,useQueryClient} from 'react-query'
import { getData, sort_by_field } from '../../globals'
import MultiSelect from "react-multi-select-component";

const MySelect = ({
  options,
  getUrl,
  onChange,
  label1,
  label,
  error,
  name,
  queryString,
  dbResponse,
  isMulti=false,
  useLabelForMulti=true,
  defaultValue,
  value,
  hasAllOption=true,
  sortField=''}) => {

  const queryClient = useQueryClient()
  const {data, isLoading,refetch} = useQuery(queryString,()=>getData(getUrl),{
   initialData: () =>  queryClient.getQueryData(queryString),
   enabled:false
   })
 const [multiSelected, setmultiSelected] = useState([]);
 const [fetchedData, setfetchedData] = useState([])
 const [selectDefaultValue, setselectDefaultValue] = useState(null)

//fetch on Mount
useEffect(() => {
 if(defaultValue||isMulti){
  !options && refetch()
 }
 if(options){
  console.log("OPTIONS",options)
 }
}, [])


useEffect(() => {
  refetch()
}, [getUrl])

useEffect(() => {
   if(data){
       if(dbResponse? Array.isArray(data[dbResponse]): Array.isArray(data)){
      let mydata = dbResponse ? data[dbResponse]: data
      let temp =[]
      if(Array.isArray(mydata)){
        mydata.map(data=>{
        temp.push({value:data._id,label:data[label1]})
       })
      
     if(!isMulti && hasAllOption) { 
       temp.unshift({value:'',label:'All'})
     }
     setfetchedData(temp)
    }
   } 
   
}
}, [data])

useEffect(() => {
  if(options && Array.isArray(options) && defaultValue){
   const found = options.findIndex(o=>o.value==defaultValue)
   if(found!=-1){
    setselectDefaultValue(options[found])
   }
  }
}, [options])

useEffect(() => {
  if(defaultValue && fetchedData.length>0){
  if(fetchedData && Array.isArray(fetchedData) && defaultValue){
   const found = fetchedData.findIndex(o=>o.value==defaultValue)
  
   if(found!=-1){
    console.log(fetchedData[found])
    setselectDefaultValue(fetchedData[found])
   }
  }
}
}, [fetchedData,defaultValue])


 return (
<div>
<label className='gray_text'>{label}</label>
{isMulti ?
fetchedData&&
 <MultiSelect
 options={options?options:sort_by_field(fetchedData)}
 onFocus={()=>!options && refetch()}
 labelledBy={"Select"}
 value={multiSelected}
 onChange={(val)=>{
   let selectString=''
   val.map((v,index)=>{
      selectString+= index==0 ? (useLabelForMulti ?v.label :v.value) : `,${(useLabelForMulti ?v.label :v.value)}` 
   })
   setmultiSelected(val)
   onChange(name,selectString)
 }}
 />


:defaultValue?
 selectDefaultValue &&
  <Select 
  isMulti={isMulti}
  defaultValue={selectDefaultValue}
  maxMenuHeight={300}
  name={queryString}
  isLoading={options ? false :isLoading}
  onFocus={()=>!options && refetch()}
  options={options?options:sort_by_field(fetchedData)}
  onChange={(e)=>onChange(name,e.value)}
  styles={customStyles(error)}
  />
  :
  <Select 
  isMulti={isMulti}
  maxMenuHeight={300}
  name={queryString}
  isLoading={options ? false :isLoading}
  onFocus={()=>!options && refetch()}
  options={options?options:sort_by_field(fetchedData)}
  onChange={(e)=>onChange(name,e.value)}
  styles={customStyles(error)}
  />
}
   {error && <p style={{color:'red'}}>{error}</p>}
  </div>
)
}

export default MySelect;

const customStyles =(error)=>{
  return{
    option: (provided, state) => ({
      ...provided,
    }),
    control: (provided) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      backgroundColor:`${error?'rgba(255,0,0,.1)' :'rgba(224,231,255,0.2)'}`,
      borderColor:`${error?'red':'#E0E7FF'}`,
      borderWidth:1.5,
      height:'2.5em',
      fontSize:'1em'
    }),
  }
} 
