import React,{useState,useEffect} from "react";
import { Card, Row, Col } from 'react-bootstrap';
import '../styles.scss';
import StateCard from "../../Common/StateCard";
import { notEmptyArray } from "../../globals";
var dateFormat = require('dateformat');


export default function PartCard(props) {
    const { part, openPart,isSelected,selectMode=false,handleSelectedParts,selectedParts,isUser } = props;
    const [selected, setselected] = useState(false)

    const handleSelection =()=>{
        selected?handleSelectedParts.deleteFromSelectedParts(part):handleSelectedParts.addToSelectedParts(part)
    }
    useEffect(() => {
        if(Array.isArray(selectedParts)){
        const findSelected = selectedParts.findIndex(p=> p._id===part._id)
        findSelected!=-1 ? setselected(true): setselected(false)
        }
    }, [selectedParts,part])
    useEffect(() => {
       if(!selectMode){
        setselected(false)
       } 
    }, [selectMode])
   
    if(part){
    return (
        <React.Fragment>
            <Card className={`${selected&& 'part-selected'} part-detail`} 
            onClick={()=>{
                if(isUser==true){
                  openPart(part?._id)
                }else{
                selectMode ? handleSelection():props.handleOpenRecord(part)
                }
            }}
            >

                    <Row>
                    <Col>
                    <p className='black_text'>{part.reference_no}</p>
                    <StateCard state={part.status}/>
                    </Col>
                    <Col>
                    {part.ship && <p className='black_text'>{part.ship[0].shipping_company_name}</p>}
                    {part.vessel &&  <p className='black_text'>{notEmptyArray(part.vessel)&&part.vessel[0].vessel}</p>}
                    </Col>
                    <Col>
                    {part.supplier_id &&<p className='black_text'>{notEmptyArray(part.supplier)&&part.supplier[0].supplier_name}</p>}
                    {part.warehouse_id && <p className='black_text'>{notEmptyArray(part.warehouse)&&part.warehouse[0].forwarding_company}</p>}
                    </Col>
                    <Col>
                    <div style={{display:'flex',flexDirection:'row'}}>
                    {part.pieces&&<p className='black_text mr-2'>{part.pieces} Pcs</p>} 
                    {part.weight&&<p className='black_text'>{part.weight}kg</p>}  
                    </div>
                    {part.dimensions&&<p className='black_text'>{part.dimensions}</p>} 
                    {part.first_date&&<p className='black_text'>{dateFormat(part.first_date.toLocaleString(),'dd-mm-yyyy')}</p>} 
                    </Col>
                    
                    </Row>

            </Card>
        </React.Fragment>

    );
    }else{return null}
}
