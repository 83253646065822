import React from "react";
import { connect } from "react-redux";
import WhCard from './UserCard';
import AddWarehouse from './addWarehouse';
import { Row, Col, Button,Form ,Modal, Spinner} from 'react-bootstrap';
import ReactLoading from 'react-loading';
import {sort_by_field} from '../../globals'
import { getAllAdminWarehouses,addWarehouse,getWarehouse,editWarehouse,deleteWarehouse,filterWarehouse,
closeOpenedPart,closeError,getAllAdminParts} from '../../js/actions'

import OpenWarehouse from "./openWarehouse"

import Navbar from '../Common/Navbar'

class Warehouses extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shipping_co:'', country:"", addPartModal: false, 
            wh: null, del_err:false
        };
       
    }
    componentDidMount = () => {
        this.props.getAllAdminWarehouses();
        this.props.getAllAdminParts();
    }
    search = (e) => {
        e.preventDefault();
        const { country } = this.state;
        this.props.filterWarehouse({ country: country});
    }

   
    renderWarehouse = () => {
        const { all_warehouses, filter_applied,filtered_items,warehouses_success } = this.props;
        if (filter_applied) {
            if(filtered_items.length > 0){
                return ( sort_by_field(Array.from(filtered_items),'forwarding_company').map((p, i) => {
                    return (
                            <WhCard warehouse={p} key={i} openWarehouse={this.openWarehouse}/>
                    )
                }))
            }
        }
        else {
            
            if(all_warehouses){
                return (sort_by_field(Array.from(all_warehouses),'forwarding_company').map((p, i) => {
                        return (
                                <WhCard warehouse={p} key={i} openWarehouse={this.openWarehouse} />
                        )
                    })
                )
            }
            else{
                return (<div> <ReactLoading type={'spin'} height='2em' width='2em' color={'#2E5BFF'}  /></div> )
            }
            

        }
    }

    openWarehouse = (id) => {
        this.props.getWarehouse({ id: `${id}` });
    }
    addWarehouse = (data) => {
    
        this.props.addWarehouse(data)
        this.setState({ addPartModal: false });
        this.props.getAllAdminWarehouses();
    }
    deleteWarehouseById = (id) => {
        const{parts}=this.props;
        let {del_err}=this.state;
        del_err=parts.find(p=>{
            if(p.warehouse_id===id) return true;
        })
        if(del_err != undefined){
            this.setState({del_err})
        }
        else{
            this.props.deleteWarehouse({ id: `${id}` });
        }
        console.log(del_err)
        this.props.closeOpenedPart();
    }

    render() {
        const { all_warehouses,wh } = this.props;
        const { country,addPartModal,del_err } = this.state;

        const { error, err_msg, success_msg, closeError ,openConfirm,open_item_modal,closeOpenedPart} = this.props;
        const{loginState, admin_login}=this.props;
        if(localStorage.getItem("loginState")=="false" && localStorage.getItem("admin_login")=="false"){
            this.props.history.push("/admin/");
            return null;
          }
        else
            return (
                <React.Fragment>
                <Row>
                    <Navbar active="warehouses" />
                    <Col>
                        <Row className="top-header">

                        </Row>

                        <Row className="parts-main">
                            <Col md="10">
                                <Row>
                                    <Col><h2>Warehouses</h2></Col>
                                    <Col><p className='gray_text pt-3'>{all_warehouses.length} Total</p></Col>
                                    <Col> <Button variant="primary" className="add-part-btn" onClick={() => { this.setState({ addPartModal: true }) }}>+ Warehouse</Button></Col>
                                </Row>
                               <div id='labels'>
                                    <Col style={{padding:0}}><p className='gray_text text-uppercase'>NAME and id</p></Col>
                                    <Col style={{padding:0}}><p className='gray_text text-uppercase'>Country</p></Col>
                                    <Col style={{padding:0}}><p className='gray_text text-uppercase'>City</p></Col>
                                </div>
                                <div style={{overflow:'scroll',height:'82vh',padding:10}}>
                                {
                                    this.renderWarehouse()
                                }
                                </div>

                            </Col>

                            <AddWarehouse show={addPartModal} onHide={() => { this.setState({ addPartModal: false }) }}
                               addWarehouse={this.addWarehouse}  />

                            <OpenWarehouse wh={wh} show={open_item_modal} onHide={closeOpenedPart}
                            deleteWarehouse={this.deleteWarehouseById} />

                        </Row>
                    </Col>

                </Row>
                </React.Fragment>
               





            )


    }
}
const mapDispatchToProps = dispatch => {
    return {
        getAllAdminWarehouses: payload => dispatch(getAllAdminWarehouses()),
        getAllAdminParts: payload => dispatch(getAllAdminParts()),
        addWarehouse: payload => dispatch(addWarehouse(payload)),
        getWarehouse: payload => dispatch(getWarehouse(payload)),
        editWarehouse: payload => dispatch(editWarehouse(payload)),
        deleteWarehouse: payload => dispatch(deleteWarehouse(payload)),
        filterWarehouse: payload => dispatch(filterWarehouse(payload)),

        closeError: payload => dispatch(closeError()),
        closeOpenedPart:payload => dispatch(closeOpenedPart()),
        
    }
}
const mapStateToProps = state => {
    return { all_warehouses: state.all_warehouses, wh:state.wh,
        filtered_items: state.filtered_items, filter_applied: state.filter_applied,
        error: state.error, err_msg: state.err_msg, success_msg: state.success_msg,openConfirm:state.openConfirm,
        open_item_modal: state.open_item_modal,
        parts: state.parts,
        warehouses_success:state.warehouses_success
        }
}

export default connect(mapStateToProps, mapDispatchToProps)(Warehouses);
