import React,{useEffect,useState} from 'react'
import Row from '../Layout/Row'
import Column from '../Layout/Column'
import { useQuery, useQueryClient} from 'react-query'
import { fetchPaginatedData, getData } from '../../globals'
import FilterModule from '../Filters/FilterModule'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import AddRecord from './AddRecord'
import OpenRecord from './OpenRecord'
import AddTrans from '../../Admin/Parts/AddTrans'
import { Button } from 'react-bootstrap'
import ConfirmDeleteButton from '../ConfirmDeleteButton/ConfirmDeleteButton'

export default function Crud(props) {
    const {
        dataCard,
        title,
        queryString,
        fetchUrl,
        model,
        dbResponse,
        dbResponseSingular,
        addRecordValidation,
        addRecordSchema,
        labels,
        extraButtons,
        openRecordTitle,
        hasDates,
        hasFirstDate,
        exportButton,
        addTransaction=false,
        timelinePart,
        setinvalidateTransactions,
        shouldAdd=true
    } =props

    const [filteredRecords, setFilteredRecords] = useState([])
    const [limit, setlimit] = useState(30)
    const [page, setpage] = useState(0)
    //Filters
    const [filteredData, setfilteredData] = useState({})
    const [isFiltered, setisFiltered] = useState(false)
    const [filterPage, setFilterPage] = useState(0)
    const [filterUrl, setfilterUrl] = useState('')
    const [filterHasNext,setfilterHasNext ] = useState(true)
    const [filteredRecordsCount,setfilteredRecordsCount ] = useState(0)

    const [addRecordShow, setaddRecordShow] = useState(false)
    
    const [editRecordShow, seteditRecordShow] = useState(false)
    const [editInitialValues, seteditInitialValues] = useState({})
    //Add Trans
    const [addTransModal, setaddTransModal] = useState(false)
    //Part Selections
    const [selectedParts, setselectedParts] = useState([])
    //Select Mode
    const [selectMode, setselectMode] = useState(false)
    //Open Record
    const [OpenRecordShow, setOpenRecordShow] = useState(false)
    const [openedRecord, setOpenedRecord] = useState({})
    const [editMode, seteditMode] = useState(false)       
    const handleOpenRecord=(data={},isOpen=true)=>{
        setOpenedRecord(data)
        setOpenRecordShow(isOpen)
        console.log(data)
    }

    //Fetching Data
    const queryClient = useQueryClient()
    const { isLoading, data, error, isFetching, isPreviousData } = useQuery(
        [queryString, page],
        () => fetchPaginatedData(fetchUrl,page,limit),
        {keepPreviousData: true, staleTime: 5000}
      )
    // Handling Pagination
    useEffect(() => {
        setinvalidateTransactions && setinvalidateTransactions(invalidateData)
    }, [queryString,isFiltered,filterPage,filterUrl,page])
    useEffect(() => {
        if (data?.hasNext) {
          queryClient.prefetchQuery([queryString, page + 1], () => fetchPaginatedData(fetchUrl,page+1,limit) )
        }
      }, [data, page, queryClient])

    const renderRecords =()=>{
        if(!isFiltered){
        if(isLoading){
            return  <SkeletonTheme color='white' highlightColor='#FAFEFF'> <Skeleton style={{marginBottom:12}} height='8vh' count={limit}/></SkeletonTheme>
        }else{
            if(data){
                if(Array.isArray(data[dbResponse])){
                if(data[dbResponse].length>0){
                    return data[dbResponse].map((record,i)=>{
                        return  React.cloneElement(dataCard,{[model.toLowerCase()]:record,key:i,handleOpenRecord,handleSelectedParts,selectedParts,isSelected:false,selectMode})
                    })
            }else{
                return <h3 className='mt-3'>0 Records Found</h3> 
            }
            }
            }
        }
    }else{
        console.log("FILTERED",filteredRecords, " ",filteredRecordsCount)
        if(Array.isArray(filteredRecords)){
            if(filteredRecords.length>0){
                return filteredRecords.map((record,i)=>{
                    return  React.cloneElement(dataCard,{[model.toLowerCase()]:record,key:i,handleOpenRecord,handleSelectedParts,selectedParts,selectMode})
                })
            }else{
                return <h3 className='mt-3'>0 Records Found</h3>
            }
    }
    }
    }
    const invalidateData =()=>{
     if(isFiltered){
        queryClient.invalidateQueries([`${queryString}filter`, filterPage,filterUrl]) 
        console.log([`${queryString}filter`, filterPage,filterUrl])
     }else{
        queryClient.invalidateQueries([queryString,page])
     }
    }
    const handleSelectedParts = {
        addToSelectedParts: (id)=>{
        let temp = selectedParts.slice(0)
        if(!temp.includes(id)){temp.push(id);}
        setselectedParts(temp)
        },
        deleteFromSelectedParts: (id)=>{
        let temp = selectedParts.slice(0)
        let index_=-1
        temp.forEach((p,index)=> (p._id===id._id) ? index_=index:null)
        if(index_!=-1) temp.splice(index_,1)
        console.log("Delete Selected Parts",temp)
        setselectedParts(temp)
        },
        resetSelectedParts : ()=>setselectedParts([])
        }
    return (
        <Row style={{width:'100%',justifyContent:'space-between',alignItems:'flex-start',padding:'1.5em',margin:0}}>
            <Column style={{width:'81%'}}>
                <Row style={{justifyContent:'space-between',marginBottom:'1em'}}>
                <Row style={{alignItems:'center'}}>
                 <h2 style={{margin:0}}>{title}</h2>
                 <p className='gray_text ml-3' style={{margin:0}}>Total: {isFiltered? filteredRecordsCount :data?.count||"-"}</p>
                </Row>
                <Row>
                {extraButtons}


                {title.toLowerCase()=='parts'&&
                 <button  className={`btn mr-4 ${selectMode?"btn-warning":"btn-primary"}`} 
                 onClick={() =>{
                     setselectMode(!selectMode)
                     handleSelectedParts.resetSelectedParts()
                    }}>{selectMode?'Selection Finished' :'Select'} </button>
                }
                {addTransaction && selectedParts.length>0 ? <button  className="btn btn-primary mr-4" onClick={() => setaddTransModal(true)}>+ Transaction </button> :null}
                  {/* Export Records */}
                {exportButton && React.cloneElement(exportButton,{isFiltered,filterUrl,queryString,fetchUrl})}
                  {/* Is able to add ?*/}
                {shouldAdd &&<button className='btn btn-primary' onClick={()=>setaddRecordShow(true)}>+ {model}</button>}
                </Row>
                </Row>
                {labels}
                {/* Show Records*/}
                <div style={{overflow:'scroll',height:'90vh',padding:1,paddingBottom:100,width:'100%',display:'flex',flexDirection:'column'}}>
                {renderRecords()}
                
                { ((isFiltered && filteredRecordsCount>0) ||(!isFiltered && data?.count>0)) &&
                <p className='black_text'>Current Page: {isFiltered?`${filterPage+1} of ${filteredData?.allPages||""} `:`${page+1} of ${data?.allPages||""} `}</p>
                }
                <Row>
                { ((isFiltered && filteredRecordsCount>0) ||(!isFiltered && data?.count>0)) &&
                <>
                <button className='btn btn-secondary mr-4' onClick={() =>isFiltered? setFilterPage(filterPage==0?filterPage:filterPage-1): setpage(page==0 ?page:page-1)}> Previous Page</button>
                <button className='btn btn-primary ' onClick={() =>isFiltered?setFilterPage(filterHasNext?filterPage+1:filterPage) :  setpage(data?.hasNext ?page+1:page)}> Next Page</button>
                </>
                }
               
                </Row>
                </div>
               
            </Column>
            {/* End Show Records*/}

            {/* Filters */}
            <FilterModule
            limit={limit}
            {...props}
            setFilteredRecords={setFilteredRecords}
            setisFiltered={setisFiltered}
            filterPage={filterPage}
            setFilterPage={setFilterPage}
            setfilterHasNext={setfilterHasNext}
            filterUrl={filterUrl}
            setfilterUrl={setfilterUrl}
            setfilteredRecordsCount={setfilteredRecordsCount}
            setfilteredData={setfilteredData}
            />
          
             {/* Add a Record Modal */}
            <AddRecord
            show={addRecordShow}
            setShow={setaddRecordShow}
            formSchema={addRecordSchema}
            validationSchema={addRecordValidation}
             />

            {/* Open a Record Modal */}
            <OpenRecord 
            openRecordTitle={openRecordTitle}
            dbResponseSingular={dbResponseSingular}
            queryString={queryString}
            fetchUrl={fetchUrl}
            show={OpenRecordShow}
            setShow={setOpenRecordShow}
            data={openedRecord}
            schema={addRecordSchema}
            editMode={editMode}
            setEditMode={seteditMode}
            page={page}
            filterPage={filterPage}
            isFiltered={isFiltered}
            timelinePart={timelinePart}
            />
            {addTransaction &&
            <AddTrans
        
            show={addTransModal} 
            onHide={() => setaddTransModal(false) }
            warehouses={[]} 
            parts={data? data[dbResponse] :[]} 
            ref_no={selectedParts}
            handleSelectedParts={handleSelectedParts}
            invalidateData={invalidateData}
            />
            }
          
            </Row>

    )
}