import React,{useEffect,useState}from 'react'
import { CSVLink, CSVDownload } from "react-csv";
import { useQuery, useQueryClient } from 'react-query'
import { getData } from '../../globals';
import dateFormat from 'dateformat'
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


export default function ExportParts ({isFiltered,filterUrl,queryString,fetchUrl}) {
   const queryClient = useQueryClient()
   const {data, isLoading,refetch} = useQuery(queryString,()=>getData(fetchUrl),{
     enabled:false,
     onSuccess:(res)=> setexportData(refactorDataAsObject(res.parts))
   })
   const {  data:filteredData, error, isLoading:filteredLoading, refetch:refetchFiltered } = useQuery(
    [`${queryString}filter`, filterUrl],
    () => getData(filterUrl),
    {
    enabled:!!filterUrl,
    onSuccess:(res)=> setexportFilterData(refactorDataAsObject(res.parts))
  })
    const [exportData, setexportData] = useState([])
    const [exportFilterData, setexportFilterData] = useState([])
    const [dataToRefactor, setdataToRefactor] = useState([])

      useEffect(() => {
        if(isFiltered && filteredData){
         
        }else if(!isFiltered && data){
          setdataToRefactor(data.parts)
        }
      }, [data,filteredData])

    
      useEffect(() => {
       console.log("Export Parts",exportData) 
      }, [exportData])

      const refactorData=(arr)=>{
        if(Array.isArray(arr)){
          let refactored=[]
          let header=[]

          exportSchema.map(s=>header.push(s.label))

          refactored.push(header)
          arr.map(part=>{
           let r =[]
           exportSchema.map(s=>{
             if(s.type=='input'){
               r.push(part[s.dbValue])
             }else if(s.type=='select'){
             if(Array.isArray(part[s.dbValue])){
               part[s.dbValue].length>0 ? r.push(part[s.dbValue][0][s.populate]): r.push("")
             }else{
               r.push("")
             }
           
             }
           })
           refactored.push(r)
          })
          return refactored;
         }
       
      }

      const refactorDataAsObject =(arr)=>{
        if(Array.isArray(arr)){
          let temp = [...arr]
          arr.map((data,index)=>{

            if(notEmptyArray(data.warehouse)){
              temp[index]['warehouse_p'] = data.warehouse[0].forwarding_company
            }
            if(notEmptyArray(data.ship)){
              temp[index]['ship_p'] = data.ship[0].shipping_company_name
            }
            if(notEmptyArray(data.supplier)){
              temp[index]['supplier_p'] = data.supplier[0].supplier_name
            }
            if(notEmptyArray(data.vessel)){
              temp[index]['vessel_p'] = data.vessel[0].vessel
            }
            if(data.first_date){

              temp[index]['first_date'] = dateFormat(data.first_date.toLocaleString(),'dd-mm-yyyy');
            }

          })
          console.log("objecTEMPt",temp)
          return temp
        }

      }
  

      return (
        <>
       {isFiltered?
       exportFilterData.length>0 ?
       <ExcelFile filename={`Speedair-Parts-${dateFormat(new Date(),'dd-mmm-yyyy')}`} element={<button  className="add-part-btn btn btn-info"  >Export Filtered Parts</button>}>
       <ExcelSheet data={exportFilterData} name="Parts">
         {exportSchema.map(h=> <ExcelColumn label={h.label} value={h.dbValue}/>)}
       </ExcelSheet>
       </ExcelFile>
       :  <button disabled={true} className="add-part-btn btn btn-info">Nothing to Export</button>

       :
       exportData.length>0 ?
       <ExcelFile filename={`Speedair-Parts-${dateFormat(new Date(),'dd-mmm-yyyy')}`} element={<button  className="add-part-btn btn btn-info"  >Export Parts</button>}>
        <ExcelSheet data={exportData} name="Parts">
          {exportSchema.map(h=> <ExcelColumn label={h.label} value={h.dbValue}/>)}
        </ExcelSheet>
        </ExcelFile>
       : <button disabled={true} className="add-part-btn btn btn-info">Nothing to Export</button>
       
       }

       </>
    )
}

const header = ['Reference No.',"Description","Pieces","Weight","Dimensions","Status","Warehouse","Shipping Company","Supplier","Vessel"]
const fields= ['reference_no',"description","pieces","weight","dimensions","status","warehouse_p","ship_p","supplier_p","vessel_p"]
//const fields= ['reference_no',"description","pieces","weight","dimensions","status","warehouse_id","ship_id","supplier_id","vessel_id"]
const exportSchema=[
  {label:"Reference No.",dbValue:'reference_no',type:"input"},
  {label:"Description",dbValue:'description',type:"input"},
  {label:"Pieces",dbValue:'pieces',type:"input"},
  {label:"Weight",dbValue:'weight',type:"input"},
  {label:"Dimensions",dbValue:'dimensions',type:"input"},
  {label:"Status",dbValue:'status',type:"input"},
  {label:"Warehouse",dbValue:'warehouse_p',type:"select",populate:"forwarding_company"},
  {label:"Shipping Company",dbValue:'ship_p',type:"select",populate:"shipping_company_name"},
  {label:"Supplier",dbValue:'supplier_p',type:"select",populate:"supplier_name"},
  {label:"Vessel",dbValue:'vessel_p',type:"select",populate:"vessel"},
  {label:"Remarks",dbValue:'remarks',type:"input"},
  {label:"Order Date Placement",dbValue:'first_date',type:"input"},
]

const notEmptyArray= (arr,length=0)=>{
  if(Array.isArray(arr)){
    if(arr.length>length){
      return true
    }else{
      return false
    }
  }else{
    return false
  }

}