// user login
export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";

// warehouse login
export const WAREHOUSE_LOGIN_REQUEST = "WAREHOUSE_LOGIN_REQUEST";
export const WAREHOUSE_LOGIN_SUCCESS = "WAREHOUSE_LOGIN_SUCCESS";
export const WAREHOUSE_LOGIN_FAILURE = "WAREHOUSE_LOGIN_FAILURE";

//user signup
export const USER_SIGNUP_REQUEST = "USER_SIGNUP_REQUEST";
export const USER_SIGNUP_SUCCESS = "USER_SIGNUP_SUCCESS";
export const USER_SIGNUP_FAILURE = "USER_SIGNUP_FAILURE";

//get all parts by ship
export const GET_ALL_PARTS_BY_SHIP_REQUEST = "GET_ALL_PARTS_BY_SHIP_REQUEST";
export const GET_ALL_PARTS_BY_SHIP_SUCCESS = "GET_ALL_PARTS_BY_SHIP_SUCCESS";
export const GET_ALL_PARTS_BY_SHIP_FAILURE = "GET_ALL_PARTS_BY_SHIP_FAILURE";

// get all parts by warehouse

export const GET_ALL_PARTS_BY_WH_REQUEST = "GET_ALL_PARTS_BY_WH_REQUEST";
export const GET_ALL_PARTS_BY_WH_SUCCESS = "GET_ALL_PARTS_BY_WH_SUCCESS";
export const GET_ALL_PARTS_BY_WH_FAILURE = "GET_ALL_PARTS_BY_WH_FAILURE";

// transactions by part by ship
export const GET_ALL_TRANSACTIONS_BY_SHIP_REQUEST = "GET_ALL_TRANSACTIONS_BY_SHIP_REQUEST";
export const GET_ALL_TRANSACTIONS_BY_SHIP_SUCCESS = "GET_ALL_TRANSACTIONS_BY_SHIP_SUCCESS";
export const GET_ALL_TRANSACTIONS_BY_SHIP_FAILURE = "GET_ALL_TRANSACTIONS_BY_SHIP_FAILURE";

// transactions by part by warehouse
export const GET_ALL_TRANSACTIONS_BY_WH_REQUEST = "GET_ALL_TRANSACTIONS_BY_WH_REQUEST";
export const GET_ALL_TRANSACTIONS_BY_WH_SUCCESS = "GET_ALL_TRANSACTIONS_BY_WH_SUCCESS";
export const GET_ALL_TRANSACTIONS_BY_WH_FAILURE = "GET_ALL_TRANSACTIONS_BY_WH_FAILURE";

//filter parts
export const GET_FILTERED_PARTS_REQUEST = "GET_FILTERED_PARTS_REQUEST";
export const GET_FILTERED_PARTS_SUCCESS = "GET_FILTERED_PARTS_SUCCESS";
export const GET_FILTERED_PARTS_FAILURE = "GET_FILTERED_PARTS_FAILURE";

// filter parts for trans
export const GET_FILTERED_PARTS_FOR_TRANS = "GET_FILTERED_PARTS_FOR_TRANS";
export const CLEAR_FILTERED_PARTS_FOR_TRANS="CLEAR_FILTERED_PARTS_FOR_TRANS"
//add part
export const ADD_PART_REQUEST = "ADD_PART_REQUEST";
export const ADD_PART_SUCCESS = "ADD_PART_SUCCESS";
export const ADD_PART_FAILURE = "ADD_PART_FAILURE";

//get Part
export const GET_PART_REQUEST = "GET_PART_REQUEST";
export const GET_PART_SUCCESS = "GET_PART_SUCCESS";
export const GET_PART_FAILURE = "GET_PART_FAILURE";




//get vessels by ship
export const GET_VESSELS_BY_SHIP_REQUEST = "GET_VESSELS_BY_SHIP_REQUEST";
export const GET_VESSELS_BY_SHIP_SUCCESS = "GET_VESSELS_BY_SHIP_SUCCESS";
export const GET_VESSELS_BY_SHIP_FAILURE = "GET_VESSELS_BY_SHIP_FAILURE";


//get all suppliers
export const GET_ALL_SUPPLIERS_REQUEST = "GET_ALL_SUPPLIERS_REQUEST";
export const GET_ALL_SUPPLIERS_SUCCESS = "GET_ALL_SUPPLIERS_SUCCESS";
export const GET_ALL_SUPPLIERS_FAILURE = "GET_ALL_SUPPLIERS_FAILURE";


//get all warehouse
export const GET_ALL_WAREHOUSE_REQUEST = "GET_ALL_WAREHOUSE_REQUEST";
export const GET_ALL_WAREHOUSE_SUCCESS = "GET_ALL_WAREHOUSE_SUCCESS";
export const GET_ALL_WAREHOUSE_FAILURE = "GET_ALL_WAREHOUSE_FAILURE";

export const CLOSE_OPENED_PART = "CLOSE_OPENED_PART";


// All admin action types


// Parts section
export const GET_ALL_ADMIN_PARTS_REQUEST = "GET_ALL_ADMIN_PARTS_REQUEST";
export const GET_ALL_ADMIN_PARTS_SUCCESS = "GET_ALL_ADMIN_PARTS_SUCCESS";
export const GET_ALL_ADMIN_PARTS_FAILURE = "GET_ALL_ADMIN_PARTS_FAILURE";

export const GET_ADMIN_FILTERED_PARTS = "GET_ADMIN_FILTERED_PARTS";

export const EDIT_PART_REQUEST = "EDIT_PART_REQUEST";
export const EDIT_PART_SUCCESS = "EDIT_PART_SUCCESS";
export const EDIT_PART_FAILURE = "EDIT_PART_FAILURE";

export const DELETE_PART_REQUEST = "DELETE_PART_REQUEST";
export const DELETE_PART_SUCCESS = "DELETE_PART_SUCCESS";
export const DELETE_PART_FAILURE = "DELETE_PART_FAILURE";

export const GET_PART_TRANSACTIONS_REQUEST = "GET_PART_TRANSACTIONS_REQUEST";
export const GET_PART_TRANSACTIONS_SUCCESS = "GET_PART_TRANSACTIONS_SUCCESS";
export const GET_PART_TRANSACTIONS_FAILURE = "GET_PART_TRANSACTIONS_FAILURE";

export const GET_PARTNERS_REQUEST = "GET_PARTNERS_REQUEST";
export const GET_PARTNERS_SUCCESS = "GET_PARTNERS_SUCCESS";
export const GET_PARTNERS_FAILURE = "GET_PARTNERS_FAILURE";

// add part is defined above


//users
export const GET_ALL_ADMIN_USER_REQUEST = "GET_ALL_ADMIN_USER_REQUEST";
export const GET_ALL_ADMIN_USER_SUCCESS = "GET_ALL_ADMIN_USER_SUCCESS";
export const GET_ALL_ADMIN_USER_FAILURE = "GET_ALL_ADMIN_USER_FAILURE";

export const GET_ADMIN_FILTERED_USER="GET_ADMIN_FILTERED_USER"

export const ADD_USER_REQUEST = "ADD_USER_REQUEST";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAILURE = "ADD_USER_FAILURE";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

export const EDIT_USER_REQUEST = "EDIT_USER_REQUEST";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_FAILURE = "EDIT_USER_FAILURE";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

//get aLL vessels
export const GET_ALL_ADMIN_VESSELS_REQUEST = "GET_ALL_ADMIN_VESSELS_REQUEST";
export const GET_ALL_ADMIN_VESSELS_SUCCESS = "GET_ALL_ADMIN_VESSELS_SUCCESS";
export const GET_ALL_ADMIN_VESSELS_FAILURE = "GET_ALL_ADMIN_VESSELS_FAILURE";

export const GET_ADMIN_FILTERED_VESSELS="GET_ADMIN_FILTERED_VESSELS"

export const ADD_VESSEL_REQUEST = "ADD_VESSEL_REQUEST";
export const ADD_VESSEL_SUCCESS = "ADD_VESSEL_SUCCESS";
export const ADD_VESSEL_FAILURE = "ADD_VESSEL_FAILURE";

export const GET_VESSEL_REQUEST = "GET_VESSEL_REQUEST";
export const GET_VESSEL_SUCCESS = "GET_VESSEL_SUCCESS";
export const GET_VESSEL_FAILURE = "GET_VESSEL_FAILURE";

export const EDIT_VESSEL_REQUEST = "EDIT_VESSEL_REQUEST";
export const EDIT_VESSEL_SUCCESS = "EDIT_VESSEL_SUCCESS";
export const EDIT_VESSEL_FAILURE = "EDIT_VESSEL_FAILURE";

export const DELETE_VESSEL_REQUEST = "DELETE_VESSEL_REQUEST";
export const DELETE_VESSEL_SUCCESS = "DELETE_VESSEL_SUCCESS";
export const DELETE_VESSEL_FAILURE = "DELETE_VESSEL_FAILURE";

//get all ship companies
export const GET_ALL_ADMIN_SHIP_REQUEST = "GET_ALL_ADMIN_SHIP_REQUEST";
export const GET_ALL_ADMIN_SHIP_SUCCESS = "GET_ALL_ADMIN_SHIP_SUCCESS";
export const GET_ALL_ADMIN_SHIP_FAILURE = "GET_ALL_ADMIN_SHIP_FAILURE";

export const GET_ADMIN_FILTERED_SHIPS="GET_ADMIN_FILTERED_SHIPS"

export const ADD_SHIP_REQUEST = "ADD_SHIP_REQUEST";
export const ADD_SHIP_SUCCESS = "ADD_SHIP_SUCCESS";
export const ADD_SHIP_FAILURE = "ADD_SHIP_FAILURE";

export const GET_SHIP_REQUEST = "GET_SHIP_REQUEST";
export const GET_SHIP_SUCCESS = "GET_SHIP_SUCCESS";
export const GET_SHIP_FAILURE = "GET_SHIP_FAILURE";

export const EDIT_SHIP_REQUEST = "EDIT_SHIP_REQUEST";
export const EDIT_SHIP_SUCCESS = "EDIT_SHIP_SUCCESS";
export const EDIT_SHIP_FAILURE = "EDIT_SHIP_FAILURE";

export const DELETE_SHIP_REQUEST = "DELETE_SHIP_REQUEST";
export const DELETE_SHIP_SUCCESS = "DELETE_SHIP_SUCCESS";
export const DELETE_SHIP_FAILURE = "DELETE_SHIP_FAILURE";

// we will use user actions for getting all suppliers and warehouses


// get all transactions
export const GET_ALL_TRANSACTIONS_REQUEST = "GET_ALL_TRANSACTIONS_REQUEST";
export const GET_ALL_TRANSACTIONS_SUCCESS = "GET_ALL_TRANSACTIONS_SUCCESS";
export const GET_ALL_TRANSACTIONS_FAILURE = "GET_ALL_TRANSACTIONS_FAILURE";

export const GET_ADMIN_FILTERED_TRANSACTIONS="GET_ADMIN_FILTERED_TRANSACTIONS"

export const ADD_TRANSACTION_REQUEST = "ADD_TRANSACTION_REQUEST";
export const ADD_TRANSACTION_SUCCESS = "ADD_TRANSACTION_SUCCESS";
export const ADD_TRANSACTION_FAILURE = "ADD_TRANSACTION_FAILURE";

export const GET_TRANSACTION_REQUEST = "GET_TRANSACTION_REQUEST";
export const GET_TRANSACTION_SUCCESS = "GET_TRANSACTION_SUCCESS";
export const GET_TRANSACTION_FAILURE = "GET_TRANSACTION_FAILURE";

export const EDIT_TRANSACTION_REQUEST = "EDIT_TRANSACTION_REQUEST";
export const EDIT_TRANSACTION_SUCCESS = "EDIT_TRANSACTION_SUCCESS";
export const EDIT_TRANSACTION_FAILURE = "EDIT_TRANSACTION_FAILURE";


export const DEL_TRANSACTION_REQUEST = "DEL_TRANSACTION_REQUEST";
export const DEL_TRANSACTION_SUCCESS = "DEL_TRANSACTION_SUCCESS";
export const DEL_TRANSACTION_FAILURE = "DEL_TRANSACTION_FAILURE";


//get all suppliers
export const GET_ALL_ADMIN_SUPPLIERS_REQUEST = "GET_ALL_ADMIN_SUPPLIERS_REQUEST";
export const GET_ALL_ADMIN_SUPPLIERS_SUCCESS = "GET_ALL_ADMIN_SUPPLIERS_SUCCESS";
export const GET_ALL_ADMIN_SUPPLIERS_FAILURE = "GET_ALL_ADMIN_SUPPLIERS_FAILURE";

export const GET_ADMIN_FILTERED_SUPPLIERS="GET_ADMIN_FILTERED_SUPPLIERS"

export const ADD_SUPPLIER_REQUEST = "ADD_SUPPLIER_REQUEST";
export const ADD_SUPPLIER_SUCCESS = "ADD_SUPPLIER_SUCCESS";
export const ADD_SUPPLIER_FAILURE = "ADD_SUPPLIER_FAILURE";

export const GET_SUPPLIER_REQUEST = "GET_SUPPLIER_REQUEST";
export const GET_SUPPLIER_SUCCESS = "GET_SUPPLIER_SUCCESS";
export const GET_SUPPLIER_FAILURE = "GET_SUPPLIER_FAILURE";

export const EDIT_SUPPLIER_REQUEST = "EDIT_SUPPLIER_REQUEST";
export const EDIT_SUPPLIER_SUCCESS = "EDIT_SUPPLIER_SUCCESS";
export const EDIT_SUPPLIER_FAILURE = "EDIT_SUPPLIER_FAILURE";

export const DELETE_SUPPLIER_REQUEST = "DELETE_SUPPLIER_REQUEST";
export const DELETE_SUPPLIER_SUCCESS = "DELETE_SUPPLIER_SUCCESS";
export const DELETE_SUPPLIER_FAILURE = "DELETE_SUPPLIER_FAILURE";

//get all warehouse
export const GET_ALL_ADMIN_WAREHOUSE_REQUEST = "GET_ALL_ADMIN_WAREHOUSE_REQUEST";
export const GET_ALL_ADMIN_WAREHOUSE_SUCCESS = "GET_ALL_ADMIN_WAREHOUSE_SUCCESS";
export const GET_ALL_ADMIN_WAREHOUSE_FAILURE = "GET_ALL_ADMIN_WAREHOUSE_FAILURE";

export const GET_ADMIN_FILTERED_WAREHOUSE="GET_ADMIN_FILTERED_WAREHOUSE"

export const ADD_WAREHOUSE_REQUEST = "ADD_WAREHOUSE_REQUEST";
export const ADD_WAREHOUSE_SUCCESS = "ADD_WAREHOUSE_SUCCESS";
export const ADD_WAREHOUSE_FAILURE = "ADD_WAREHOUSE_FAILURE";

export const GET_WAREHOUSE_REQUEST = "GET_WAREHOUSE_REQUEST";
export const GET_WAREHOUSE_SUCCESS = "GET_WAREHOUSE_SUCCESS";
export const GET_WAREHOUSE_FAILURE = "GET_WAREHOUSE_FAILURE";

export const EDIT_WAREHOUSE_REQUEST = "EDIT_WAREHOUSE_REQUEST";
export const EDIT_WAREHOUSE_SUCCESS = "EDIT_WAREHOUSE_SUCCESS";
export const EDIT_WAREHOUSE_FAILURE = "EDIT_WAREHOUSE_FAILURE";

export const DELETE_WAREHOUSE_REQUEST = "DELETE_WAREHOUSE_REQUEST";
export const DELETE_WAREHOUSE_SUCCESS = "DELETE_WAREHOUSE_SUCCESS";
export const DELETE_WAREHOUSE_FAILURE = "DELETE_WAREHOUSE_FAILURE";

// admin

export const GET_ALL_ADMINS_REQUEST = "GET_ALL_ADMINS_REQUEST";
export const GET_ALL_ADMINS_SUCCESS = "GET_ALL_ADMINS_SUCCESS";
export const GET_ALL_ADMINS_FAILURE = "GET_ALL_ADMINS_FAILURE";


export const ADD_ADMIN_REQUEST = "ADD_ADMIN_REQUEST";
export const ADD_ADMIN_SUCCESS = "ADD_ADMIN_SUCCESS";
export const ADD_ADMIN_FAILURE = "ADD_ADMIN_FAILURE";

export const GET_ADMIN_REQUEST = "GET_ADMIN_REQUEST";
export const GET_ADMIN_SUCCESS = "GET_ADMIN_SUCCESS";
export const GET_ADMIN_FAILURE = "GET_ADMIN_FAILURE";

export const EDIT_ADMIN_REQUEST = "EDIT_ADMIN_REQUEST";
export const EDIT_ADMIN_SUCCESS = "EDIT_ADMIN_SUCCESS";
export const EDIT_ADMIN_FAILURE = "EDIT_ADMIN_FAILURE";

export const DELETE_ADMIN_REQUEST = "DELETE_ADMIN_REQUEST";
export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCCESS";
export const DELETE_ADMIN_FAILURE = "DELETE_ADMIN_FAILURE";

export const ADMIN_LOGIN_REQUEST = "ADMIN_LOGIN_REQUEST";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMIN_LOGIN_FAILURE = "ADMIN_LOGIN_FAILURE";

export const LOG_OUT_ADMIN = "LOG_OUT_ADMIN";
export const LOG_OUT_USER = "LOG_OUT_USER";
export const LOG_OUT_WAREHOUSE = "LOG_OUT_WAREHOUSE";


// common
export const CLOSE_CONFIRMATION = "CLOSE_CONFIRMATION";

export const RESET_FILTERED_ITEMS = 'RESET_FILTERED_ITEMS';