import React from "react";
import logo from "../../logo.svg";
import Joi from "joi-browser";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "../LogInForm/styles.scss";

import InputField from "../../Common/InputField";
import Form from "../../Common/Form";

import {userSignup} from '../../js/actions'


class SignUp extends Form {
  state = {
    data: {
        name: "",
        shipping_company_name: "",
        email: "",
        password: "",
        confirm_password:""
    },
    error: {},
    invalid_signup: false,
    signupButtonLoading: false,
  };

  // schema for valiation
  schema = {
    name: Joi.string().required().label("Name"),
    shipping_company_name: Joi.string().required().label("Shipping Company"),
    email: Joi.string()
      .email({ minDomainAtoms: 2 })
      .required()
      .label("Email"),
      password: Joi.string().required().min(6).label("Password"),
      confirm_password: Joi.any().valid(Joi.ref('password')).required().options({ language: { any: { allowOnly: 'must match password' } } })
  };
  
  spinnerButton = () => {
    this.setState({ signupButtonLoading: true });
  };
  // After clicking the submit button of signup
  customerSignup = (e) => {
    e.preventDefault();
    
    const{ name,
    shipping_company_name,
    email,
    password}=this.state.data

    this.spinnerButton();
    // validation of input fields
    const error = this.validate();
    this.setState({ error: error || {} });
    //if error occur don't call api
    if (error) return null;
    this.props.userSignup({shipping_company_name,email,password,name})
    
  };
  

  render() {
    const{signupState,inValidSignup}=this.props;
    if(signupState){
    this.props.history.push("/");
    return null;
    }
    else 
    return (
        <div className="container-fluid" style={{ background: "#ffffff" }}>
          <div className="row">
            <div className="logo--position">
              <img src={logo} className="img-fluid" alt="logo"></img>
            </div>
  
            <div className="col-md-4 offset-md-1 top__signup--margin form__padding--smallscreen">
            <h2 className="fontchange d-block">
                     Get started 
                    </h2>
                    <p className="text--color">
                      Please enter your credentials to proceed.
                    </p>
              
              {/* --------Duplicate Email Error---------- */}
              {inValidSignup && 
                <div className="alert alert-danger">
                  This Email Address already has been taken.
                </div>
              }
         
              {/* ------------===form start------------------- */}
              <form onSubmit={this.customerSignup} style={{marginTop:"20%"}}>
                <div className="form-group">
                  <InputField
                    labeltitle="FULL NAME"
                    typename="text"
                    fieldname="name"
                    changeHandler={this.handleChange}
                    value={this.state.data.name}
                    error={this.state.error.name}
                  />
                </div>
                <div className="form-group">
                  <InputField
                    labeltitle="SHIPPING COMPANY"
                    typename="text"
                    fieldname="shipping_company_name"
                    changeHandler={this.handleChange}
                    value={this.state.data.shipping_company_name}
                    error={this.state.error.shipping_company_name}
                  />
                </div>
                <div className="form-group">
                  <InputField
                    labeltitle="EMAIL ADDRESS"
                    typename="email"
                    fieldname="email"
                    changeHandler={this.handleChange}
                    value={this.state.data.email}
                    error={this.state.error.email}
                  />
                </div>
  
                <div className="form-group">
                  <InputField
                    labeltitle="PASSWORD"
                    typename="password"
                    fieldname="password"
                    changeHandler={this.handleChange}
                    value={this.state.data.password}
                    error={this.state.error.password}
                  />
                </div>
                <div className="form-group">
                  <InputField
                    labeltitle="CONFIRM PASSWORD"
                    typename="password"
                    fieldname="confirm_password"
                    changeHandler={this.handleChange}
                    value={this.state.data.confirm_password}
                    error={this.state.error.confirm_password}
                  />
                </div>
                <button
                  disabled={this.validate() || this.state.signupButtonLoading}
                  type="submit"
                  className="btn btn-primary btncolor btn-block btn-lg"
                >
                  {this.state.signupButtonLoading ? (
                    <span>Loading ...</span>
                  ) : (
                    <span>Create Account</span>
                  )}
                </button>
                <div className="text--color text-center mt-2 my-4">
                  Already have an account &nbsp;
                  <Link to="/">
                    <span className="font-weight-bold">Sign in</span>
                  </Link>
                </div>
              </form>
              {/*----------------Form end-------------- */}
            </div>
            <div className="col-md-6 offset-md-1 pr-0">
              <div className="bgpic"></div>
            </div>
          </div>
        </div>
      );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {userSignup: payload => dispatch(userSignup(payload)) }  
};

const mapStateToProps = state => {
  return {  signupState:state.signupState, inValidSignup:state.inValidSignup}
}
export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
