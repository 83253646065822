import React from "react";
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { connect } from "react-redux";
import MultiSelect from "react-multi-select-component";
import DatePicker from "react-datepicker";
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import {
    getAllAdminVessels, getAllAdminWarehouses, getAllAdminShips,
    filterPartForTrans, 
    getAllAdminParts,clearfilterPartForTrans,addTransaction
} from '../../js/actions'
import { useQueryClient} from 'react-query'

import { config, api_callback, shipmentTypes, sort_by_field } from "../../globals";
import S3FileUpload from 'react-s3';
import MySelect from "../../Common/Forms/MySelect";
var dateFormat = require('dateformat');

const initialState = {
    reference_no: [], date: '', type: '', attachment: '', warehouse_id: null, partner_id: null,
    validated: false, file: null,destination:'',
    ref_no: [], shipping_co: '', vessel: '', warehouse: '', status: '',code:''
};
const states = [
    { value: 'Pending', label: 'Pending' },
    { value: 'In Stock', label: 'In Stock' },
    { value: 'Send', label: 'Send' },
    { value: 'Delivered', label: 'Delivered' },
  ];
class AddTrans extends React.Component {

    
    constructor(props) {
        super(props);
        this.state = {
            reference_no: [], date: '', type: '', attachment: '', warehouse_id: null, partner_id: null,
            validated: false, file: null,show_filter:false,destination:'',shipment_type:'',
            // filtration
            ref_no: [], shipping_co: '', vessel: '', warehouse: '', status: '',date2: '',code:''
        };


        this.handleChange = this.handleChange.bind(this);  
    }
   
    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    }
    handleSelectChange =(name,value)=>this.setState({[name]:value})
    getImage = e => {
        const files = e.target.files;

        if (files && files.length > 0) {
            const file = files[0];
            this.setState({ file });
        }
    };

    upload= async (file)=>{
       return  S3FileUpload.
        uploadFile(file, config)
    }
    
    addTransaction = async (event) =>{

        const form = event.currentTarget;
        const { date, file } = this.state;
        const {ref_no} =this.props
        
        if (form.checkValidity() === false ||   date === '' || ref_no.length === 0 ||this.state.type=='') {
            api_callback(false,'Validation Fail!','Please fill all fields')
            event.preventDefault();
            event.stopPropagation();
            return;
        }
        this.setState({ validated: true })
        //Validated 
        console.log(this.props)
        console.log(this.state)
        event.preventDefault();
        if(file!= null){
            //File Uploading API Call
           this.upload(file).then(data=>{
             api_callback(true,'Success','File Uploaded')
               const tempState = this.state;
    
               const { warehouses,partners } = this.props;
   
                   if (this.state.type === "In Stock") { 
                       delete tempState.partner_id
                       if (tempState.warehouse_id === null)
                       tempState.warehouse_id = warehouses[0]._id              
                           
                   }
                   else if (this.state.type === "Send") {
                       delete tempState.warehouse_id
                       if (tempState.partner_id === null)
                       tempState.partner= this.state.partner
                   } 
             
                   var promises  =  ref_no.map(async r => {
                       let temp = {...tempState};
                       temp.attachment =data.location
                       temp.reference_no = r.reference_no;
                       temp.part_id = r._id;
                       return this.props.addTransaction(temp,this.addTransCallback);
                   })
                   Promise.all(promises).then(()=>window.location.reload());

            }).catch(err=>{
                api_callback(false,'Error','Uploading Failed')
            })

          
        }
        else{
            const tempState = this.state;
            const { warehouses,partners } = this.props;

                if (this.state.type === "In Stock") { 
                    delete tempState.partner_id
                    if (tempState.warehouse_id === null)
                    tempState.warehouse_id = warehouses[0]._id              
                }
                else if (this.state.type === "Send") {
                    delete tempState.warehouse_id
                    tempState.partner= this.state.partner
                } 


                var promises = ref_no.map(async r=> {
                    let temp = tempState;
                    temp.reference_no = r.reference_no;
                    temp.part_id = r._id;
                    return this.props.addTransaction(temp,this.addTransCallback);
                });

                this.props.handleSelectedParts.resetSelectedParts(); 
            
        }


       

    }

    addTransCallback = (err,response) => {
        if(err) return console.log("err")
        //this.setState(initialState)
        //this.props.clearfilterPartForTrans();
        //window.location.reload();
        this.props.invalidateData() 
        this.props.onHide()
    }
   


    renderWarehouse = (e) => {
        const { type } = this.state;
        if (type === "In Stock") {
            return ( 
            <div className='col-6'>
            <MySelect
               onChange={this.handleSelectChange}
               name='warehouse_id'
               getUrl='warehouse'
               queryString='adminwarehouses'
               label='Warehouses'
               label1='forwarding_company'
               hasAllOption={false}
               />
             </div>
            )
        }
    }

    renderPartners = (e) => {
        const { partners } = this.props;
        const { type } = this.state;
        if (type === "Send") {
            return (
                <React.Fragment>
                    <Form.Group as={Col} md="6">
                        <p className="gray_text bold text-uppercase">AWB NO</p>
                        <input className='form-control' required onChange={(e)=>this.setState({partner:e.target.value})}></input> 
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                        <p className="gray_text bold text-uppercase">DESTINATION</p>
                        <input className='form-control' onChange={(e)=>this.setState({destination:e.target.value})}></input> 
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                        <p className="gray_text bold text-uppercase">Type</p>
                        <Select options={shipmentTypes} onChange={(e)=>this.setState({shipment_type:e.value})} />
                    </Form.Group>
                </React.Fragment>
            )
        }

    }
    
    render() {
        const { show } = this.props;
        //redux
        const { reference_no, date, type, attachment, warehouse_id, validated,code } = this.state;
        const {ships_success, vessels_success, warehouses_success, parts_success}=this.props;
        const { all_warehouses, all_vessels, all_ships } = this.props;
        
        return (
            <Modal
                show={show}
                onHide={this.onHide}
                size='lg'
                centered
            >
                <Modal.Body>
                    <Row>
                    <Col md="12" id='add_transaction_section'>
                    <h4>Add a Transaction</h4>
                    <Form noValidate validated={validated} onSubmit={this.addTransaction}>

                        <Form.Row>
                            <Form.Group as={Col} md="6" >
                            <p className="gray_text bold text-uppercase">Date</p>
                                <DatePicker style={{ display: "block" }} required className='form-control' dateFormat='d-MM-yyyy' selected={date} onChange={(date) =>  this.setState({date}) } required />
                                <Form.Control.Feedback type="invalid">
                                    Please select date.
                             </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>

                            <Form.Group as={Col} md="6">
                            <p className="gray_text bold text-uppercase">Type</p>
                                <Select
                                onChange={(e) => { this.setState({ type: `${e.value}` }) }}
                                options={states}
                                isSearchable={false}
                                required
                                />
                                 </Form.Group>
                            <Form.Group as={Col} md="6" >
                                <p className="gray_text bold text-uppercase">Remarks</p>
                                <input className='form-control' name='remarks' onChange={(e) =>this.setState({ [e.target.name]: e.target.value})} ></input>
                            </Form.Group>
                        {this.renderWarehouse()}
                        {this.renderPartners()}
                        </Form.Row>
                        <Row>
                            <Col md="6">
                            <p className="gray_text bold text-uppercase">Attachments</p>
                            <input type="file" id="file" onChange={this.getImage}/>
                            <label for="file" >Choose File</label>
                            </Col>
                        </Row>
                        <br></br>
                        <Button type="submit" className="btn btn-primary search-btn col-4">Done</Button>
                    </Form>
                    </Col>
                    
                  </Row>

                </Modal.Body>
            </Modal>
        );

    }

}
const mapDispatchToProps = dispatch => {
    return {
        getAllAdminShips: payload => dispatch(getAllAdminShips()),
        getAllAdminVessels: payload => dispatch(getAllAdminVessels()),
        getAllAdminWarehouses: payload => dispatch(getAllAdminWarehouses()),
        filterPartForTrans: payload => dispatch(filterPartForTrans(payload)),
        getAllAdminParts: payload => dispatch(getAllAdminParts(payload)),
        clearfilterPartForTrans: payload => dispatch(clearfilterPartForTrans(payload)),
        addTransaction: (payload,callback)=> dispatch(addTransaction(payload,callback)),
       

    }
}
const mapStateToProps = state => {
    return { 
        tempPartsTrans: state.tempPartsTrans, trans_filter: state.trans_filter,

        all_warehouses: state.all_warehouses, all_vessels: state.all_vessels, all_ships: state.all_ships,
        parts: state.parts,
        
        ships_success:state.ships_success, vessels_success:state.vessels_success,warehouses_success:state.warehouses_success,
        parts_success:state.parts_success


    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddTrans);

