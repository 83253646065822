import axios from 'axios';
import { api_callback } from '../globals';
var dateFormat = require('dateformat')
    let BASE_URL;
    if(process.env.NODE_ENV=='development'){
       
        BASE_URL = "http://localhost:8080/api/";
    }else{
        BASE_URL = window.location.protocol + "//" + window.location.host+"/api/";
    }

const API = {
    // admin api

    // parts

    adminGetAllParts: () => {
        let config = {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .get(BASE_URL + `part/`, config)
            .then(response => {
                console.log("fefefe") 
                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },
    adminPartTransactions: (reference_no) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .get(BASE_URL + `transaction/part/${reference_no}`, config)
            .then(response => {

                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },

    adminDeletePart: (id) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .delete(BASE_URL + `part/${id}`, config)
            .then(response => {
                api_callback(true,'Success','Part Deleted Successfully')
                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },

    adminAllPartners: () => {
       
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .get(BASE_URL + `partner/`, config)
            .then(response => {

                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },

    // ship companies

    adminGetAllShips: () => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .get(BASE_URL + `ship/`, config)
            .then(response => {

                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },

    adminAddShip: (payload) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .post(BASE_URL + 'ship', {
                shipping_company_name: payload.shipping_co,
                email: payload.email,
                password: 'test123',
                person_in_charge: payload.person_in_charge,
                address: payload.address,
                city: payload.city,
                country: payload.country,
                phone_no: payload.phone_no,
            }, config)
            .then(response => {
                api_callback(true,'Success','Shipping Company Added')
                return Promise.resolve(response.data);
            })
            .catch(error => {
               
                return Promise.reject(error);
            });
    },
    adminGetShip: (id) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .get(BASE_URL + `ship/${id}`, config)
            .then(response => {

                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },
    adminEditShip: (payload) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .put(BASE_URL + `ship/${payload.id}`, {
                shipping_company_name: payload.shipping_co,
                email: payload.email,
                person_in_charge: payload.person_in_charge,
                address: payload.address,
                city: payload.city,
                country: payload.country,
                phone_no: payload.phone_no
            }, config)
            .then(response => {
                api_callback(true,'Success','Shipping Company Data Changed')
                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },
    adminDeleteShip: (id) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .delete(BASE_URL + `ship/${id}`, config)
            .then(response => {

                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },

    adminEditPart: (payload) => {
        console.log(payload)
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .put(BASE_URL + `part/${payload.part_id}`, {
                reference_no:payload.reference_no,
                dimensions: payload.dimensions,
                pieces: payload.pieces,
                ship_id: payload.ship_id,
                supplier_id: payload.supplier_id,
                vessel_id: payload.vessel_id,
                warehouse_id: payload.warehouse_id,
                weight: payload.weight,
                description: payload.description,
                old_reference_no:payload.old_reference_no,
                remarks:payload.remarks
            }, config)
            .then(response => {
                api_callback(true,'Success','Part Data Changed')
                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },


// vessels

    adminGetAllVessels: () => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .get(BASE_URL + `vessel/`, config)
            .then(response => {

                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },
    adminAddVessel: (payload) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        
        return axios
            .post(BASE_URL + 'vessel', {
                vessel: payload.vessel,
                ship_id: payload.shipping_co,
                flag: payload.flag,
                phone_no: payload.phone_no,
                email: payload.email,

            }, config)
            .then(response => {
                api_callback(true,'Success','Vessel Added')
                return Promise.resolve(response.data);
            })
            .catch(error => {
                if (error.response) {
                    // Request made and server responded
                    console.log(error.response.data);
                    if(error.response.data.message){
                    api_callback(false,'Error',error.response.data.message)
                    }
                    console.log(error.response.status);
                    console.log(error.response.headers);
                  } else if (error.request) {
                    // The request was made but no response was received
                    console.log(error.request);
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                  }
                api_callback(false,'Error','Error Occured')
                console.log(error)
                return Promise.reject(error);
            });
    },
    adminGetVessel: (id) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .get(BASE_URL + `vessel/${id}`, config)
            .then(response => {

                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },
    adminEditVessel: (payload) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .put(BASE_URL + `vessel/${payload.id}`, {
                vessel: payload.vessel,
                ship_id: payload.ship_id,
                flag: payload.flag,
                phone_no: payload.phone_no,
                email: payload.email,

            }, config)
            .then(response => {
                api_callback(true,'Success','Vessel Data Changed')
                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },
    adminDeleteVessel: (id) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .delete(BASE_URL + `vessel/${id}`, config)
            .then(response => {

                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },


    // users
    adminAllUser: () => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .get(BASE_URL + `user/`, config)
            .then(response => {

                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },
    adminAddUser: (payload) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .post(BASE_URL + 'user', {
                type: payload.type,
                name: payload.name,
                email: payload.email,
                shipping_company_name: payload.shipping_company_name,
                ship_id: payload.ship_id,
                warehouse_name: payload.warehouse_name,
                warehouse_id: payload.warehouse_id,
                password:payload.password
            }, config)
            .then(response => {
                api_callback(true,'Success','User Added')
                return Promise.resolve(response.data);
            })
            .catch(error => {
                api_callback(false,'Failure','Duplicate Email Found')
                return Promise.reject(error);
            });
    },
    adminGetUser: (id) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .get(BASE_URL + `user/${id}`, config)
            .then(response => {

                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },
    adminEditUser: (payload) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .put(BASE_URL + `user/${payload.id}`, {
                type: payload.type,
                name: payload.name,
                email: payload.email,
                shipping_company_name: payload.shipping_company_name,
                ship_id: payload.ship_id,
                warehouse_name: payload.warehouse_name,
                warehouse_id: payload.warehouse_id,
            }, config)
            .then(response => {
                api_callback(true,'Success','User Data Changed')
                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },
    adminDeleteUser: (id) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .delete(BASE_URL + `user/${id}`, config)
            .then(response => {

                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },

     // admin - settings
     getAllAdmins: () => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .get(BASE_URL + `admin/`, config)
            .then(response => {

                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },
    addAdmin: (payload) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .post(BASE_URL + 'admin/add', {
                email: payload.email,
                fullname: payload.fullname,
                password:payload.password
            }, config)
            .then(response => {
                api_callback(true,'Success','Admin Added')
                return Promise.resolve(response.data);
            })
            .catch(error => {
                api_callback(false,'Error','Error Occured')
                return Promise.reject(error);
            });
    },
    getAdmin: (id) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .get(BASE_URL + `admin/${id}`, config)
            .then(response => {

                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },
    editAdmin: (payload) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .put(BASE_URL + `admin/${payload.id}`, {
                email: payload.email,
                fullname:payload.fullname
            }, config)
            .then(response => {
                api_callback(true,'Success','Admin Data Changed!')
                return Promise.resolve(response.data);
            })
            .catch(error => {
                api_callback(false,'Error','Error Occured')
                return Promise.reject(error);
            });
    },
    deleteAdmin: (id) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .delete(BASE_URL + `admin/${id}`, config)
            .then(response => {

                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },
    adminLogin: (payload) => {
       
        return axios
            .post(BASE_URL + `admin/login`,
            {
                email: payload.email,
                password:payload.password
            })
            .then(response => {
             
                localStorage.setItem('loginState', JSON.stringify(true))
                localStorage.setItem('admin_login', JSON.stringify(true))
                localStorage.setItem('token', response.data.token)
                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },


    // transactions

    adminAllTransactions: () => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .get(BASE_URL + `transaction/`, config)
            .then(response => {
                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },
    getTransactionById: (id) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .get(BASE_URL + `transaction/${id}`, config)
            .then(response => {

                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },
    editTransaction: (payload,callback) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .put(BASE_URL + `transaction/${payload.id}`, {
                date: payload.date,
                type: payload.type,
                part_id: payload.part_id,
                attachment:payload.attachment,
                remarks:payload.remarks,
                partner:payload.partner,
                destination:payload.destination,
                shipment_type:payload.shipment_type
            }, config)
            .then(response => {
                callback(null,response)
                api_callback(true,'Success','Transaction Edited Successfully')
                return Promise.resolve(response.data);
            })
            .catch(error => {
                callback(error,null)
                return Promise.reject(error);
            });
    },
    deleteTransaction: (id,callback) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .delete(BASE_URL + `transaction/${id}`, config)
            .then(response => {
                api_callback(true,'Success','Transaction Deleted Successfully')
                callback(null,response)
                return Promise.resolve(response.data);
            })
            .catch(error => {
                callback(error,null)
                return Promise.reject(error);
            });
    },
    adminAddTransaction: (payload,callback) => {

        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
          console.log(payload)
        return axios
            .post(BASE_URL + 'transaction/', {
                reference_no: payload.reference_no,
                date: payload.date,
                type: payload.type,
                warehouse_id: payload.warehouse_id,
                attachment: payload.attachment,
                part_id: payload.part_id,
                partner: payload.partner,
                code:payload.code,
                remarks:payload.remarks,
                destination:payload.destination,
                shipment_type:payload.shipment_type
            }, config)
            .then(response => {
                api_callback(true,'Success','Transaction Added')
                callback(null,response)
                return Promise.resolve(response.data);
            })
            .catch(error => {
                api_callback(false,'Failure','Transaction Adding Failed')
                callback(error,null)
                return Promise.reject(error);
            });
    },

    // warehouses

    adminGetAllWarehouses: () => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .get(BASE_URL + `warehouse/`, config)
            .then(response => {
       
                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },
    adminAddWarehouse: (payload) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
       
        return axios
            .post(BASE_URL + 'warehouse/', {
                forwarding_company: payload.forwarding_company,
                address: payload.address,
                email: payload.email,
                city: payload.city,
                country: payload.country,
                phone_no: payload.phone_no
            }, config)
            .then(response => {
                api_callback(true,'Success','Warehouse Added')
                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },
    adminGetWarehouse: (id) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .get(BASE_URL + `warehouse/${id}`, config)
            .then(response => {

                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },
    adminEditWarehouse: (payload) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .put(BASE_URL + `warehouse/${payload.id}`, {
                forwarding_company: payload.forwarding_company,
                address: payload.address,
                email: payload.email,
                city: payload.city,
                country: payload.country,
                phone_no: payload.phone_no
            }, config)
            .then(response => {
                api_callback(true,'Success','Warehouse Data Changed')
                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },
    adminDeleteWarehouse: (id) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .delete(BASE_URL + `warehouse/${id}`, config)
            .then(response => {

                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },


    // suppliers

    adminGetAllSuppliers: () => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .get(BASE_URL + `supplier/`, config)
            .then(response => {

                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },
    adminAddSupplier: (payload) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .post(BASE_URL + 'supplier/', {
                supplier_name: payload.supplier_name,
                address: payload.address,
                email: payload.email,
                city: payload.city,
                country: payload.country,
                phone_no: payload.phone_no
            }, config)
            .then(response => {
                api_callback(true,'Success','Supplier Added')
                return Promise.resolve(response.data);
            })
            .catch(error => {
                api_callback(true,'Error','Error Occured')
                return Promise.reject(error);
            });
    },
    adminGetSupplier: (id) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .get(BASE_URL + `supplier/${id}`, config)
            .then(response => {

                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },
    adminEditSupplier: (payload) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .put(BASE_URL + `supplier/${payload.id}`, {
                supplier_name: payload.supplier_name,
                address: payload.address,
                email: payload.email,
                city: payload.city,
                country: payload.country,
                phone_no: payload.phone_no
            }, config)
            .then(response => {
                api_callback(true,'Success','Supplier Data Changed')
                return Promise.resolve(response.data);
            })
            .catch(error => {
                api_callback(false,'Fail','Error Occured');
                return Promise.reject(error);
            });
    },
    adminDeleteSupplier: (id) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .delete(BASE_URL + `supplier/${id}`,config)
            .then(response => {
                console.log(response)
                api_callback(true,'Success','Supplier Deleted Succesfully')
                return Promise.resolve(response.data);
            } )
            .catch(error => {
                api_callback(false,'Fail','Error Occured');
                return Promise.reject(error);
            });
    },




    ///// warehouse apis
    warehouseLogin: post => {
        return axios
            .post(BASE_URL + 'user/login', {
                email: post.email,
                password: post.password,
                is_warehouse:true

            })
            .then(response => {
                localStorage.setItem('loginState', true)
                localStorage.setItem('warehouse_login', true)
                localStorage.setItem('token', response.data.token)
                localStorage.setItem('warehouse', JSON.stringify(response.data.user))
                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },


    ////////////////////////* *** */
    // user api
    // login api
    loginUser: post => {
        return axios
            .post(BASE_URL + 'user/login', {
                email: post.email,
                password: post.password,
                is_warehouse:false
            })
            .then(response => {
                localStorage.setItem('loginState', true)
                localStorage.setItem('user_login', true)
                localStorage.setItem('token', response.data.token)
                localStorage.setItem('user', JSON.stringify(response.data.user))
                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },

    // sign up api
    signupUser: post => {
        return axios
            .post(BASE_URL + 'user', {
                email: post.email,
                password: post.password,
                shipping_company_name:post.shipping_company_name,
                name: post.name,
                type:"Shipping Company"
            })
            .then(response => {
                api_callback(true,'Success','User Added Successfully')
                return Promise.resolve(response.data);
            })
            .catch(error => {
                api_callback(false,'Failure','Duplicate Email Found')
                return Promise.reject(error);
            });
    },

    transByShip: (ship_id) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .get(BASE_URL + `transaction/ship/${ship_id}`, config)
            .then(response => {

                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },

    transByWarehouse: (id) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .get(BASE_URL + `transaction/warehouse/${id}`, config)
            .then(response => {

                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },


    // get all parts
    getPartsByShip: (id) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .get(BASE_URL + `part/ship/${id}`, config)
            .then(response => {

                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },

    getPartsByWh: (id) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .get(BASE_URL + `part/warehouse/${id}`, config)
            .then(response => {

                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },
    // add part
    addPart: (payload) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
         console.log(payload)
        return axios
            .post(BASE_URL + 'part', {
                reference_no: payload.reference_no,
                description: payload.description,
                pieces: payload.pieces,
                weight: payload.weight,
                dimensions: payload.dimensions,
                ship_id: payload.ship_id,
                vessel_id: payload.vessel_id,
                supplier_id: payload.supplier_id.length>5? payload.supplier_id: null,
               warehouse_id:payload.warehouse_id.length>5? payload.warehouse_id:null,
                status: payload.status,
                remarks:payload.remarks
            }, config)
            .then(response => {
                api_callback(true,'Success','Part Added')
                return Promise.resolve(response.data);
            })
            .catch(error=> {
                if (error.response) {
                    // Request made and server responded
                    console.log(error.response.data);
                    if(error.response.data.message){
                        api_callback(false,'Error',error.response.data.message)
                    }else{
                        api_callback(false,'Error','Error Occured')
                    }
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    
                    return Promise.reject(error);
                  } else if (error.request) {
                    // The request was made but no response was received
                    console.log(error.request);
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                  }
               
                
            });
    },
    getPartById: (id) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .get(BASE_URL + `part/${id}`, config)
            .then(response => {

                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },
    getVesselsByShip: (id) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .get(BASE_URL + `vessel/ship/${id}`,config)
            .then(response => {

                return Promise.resolve(response.data);
            })
            .catch(error => {
                console.log(error)
                return Promise.reject(error);
            });
    },
    getAllSuppliers: () => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .get(BASE_URL + `supplier`, config)
            .then(response => {

                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },
    getAllWarehouse: () => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        return axios
            .get(BASE_URL + `warehouse`, config)
            .then(response => {

                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },



}


export default API;