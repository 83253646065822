import React,{useEffect,useState,useRef}from 'react'
import { CSVLink, CSVDownload } from "react-csv";
import { useQuery, useQueryClient } from 'react-query'
import { getData } from '../../globals';
import dateFormat from 'dateformat'

export default function ExportTransactions ({isFiltered,filterUrl,queryString,fetchUrl}) {
   const queryClient = useQueryClient()
   const {data, isLoading,refetch} = useQuery(queryString,()=>getData(fetchUrl),{
     enabled:false,
     onSuccess:(data)=> setexportData(handleExport(data.transactions))
   })
   const {  data:filteredData, error,isLoading:filterLoading, isFetching, refetch:refetchFiltered } = useQuery(
    [`${queryString}filter`, filterUrl],
    () => getData(filterUrl),
    {
    enabled:!!filterUrl,
    onSuccess:(data)=> setexportData(handleExport(data.transactions))
  })
    const [exportData, setexportData] = useState([])
    const [exportFilterData, setexportFilterData] = useState(null)
    const [dataToRefactor, setdataToRefactor] = useState(null)
    
   

      const handleExport=(dataToRefactor)=>{
        let codes=[]
        let found_delivered;
        if(Array.isArray(dataToRefactor)){
          dataToRefactor.map( transaction => {
            if(transaction.type.toLowerCase()==='send' && transaction.part.length>0){
                if(transaction.partner!=undefined && transaction.partner !=''){
                  found_delivered = dataToRefactor.find(element =>{
                  if(element.reference_no==transaction.reference_no&&element.type.toString().toLowerCase()=='delivered'){
                      return element;
                  }
                  });
                  

                  if(codes.length==0){ //if codes empty
                    codes.push({ 
                        merida: transaction.reference_no,
                        date:dateFormat(transaction.date.toLocaleString(),'d-m-yyyy'),
                        pickup_country:transaction.warehouse.length>0? transaction.warehouse[0].country:"",
                        pickup_city:transaction.warehouse.length>0? transaction.warehouse[0].city:"",
                        fortotiki: transaction.partner,
                        weight:transaction.part.length>0 ? transaction.part[0].weight:"",
                        quantity:transaction.part.length>0?parseInt(transaction.part[0].pieces):"",
                        destination:transaction.destination? transaction.destination:"",
                        date2:found_delivered?dateFormat(found_delivered.date.toLocaleString(),'d-m-yyyy'):"",
                        client:transaction.ship? transaction.ship[0].shipping_company_name:"",
                        vessel:transaction.vessel?transaction.vessel[0].vessel:"",
                        type:transaction.shipment_type
                    })
                    }else{ // if codes not empty
                    //check if we already have this fortotiki
                    const found = codes.findIndex(element =>element.fortotiki.toString().toLowerCase()==transaction.partner.toString().toLowerCase());
                    if(found!=-1){ //Found a matching fortotiki
                        codes.map(code=>{
                            if(code.fortotiki==codes[found].fortotiki){
                                code.weight =parseInt(code.weight) + parseInt(transaction.part[0].weight)
                                code.merida =code.merida+ `|${transaction.reference_no}` 
                                code.quantity = parseInt(code.quantity) +  parseInt(transaction.part[0].pieces)
                            }
                        })
                    }else{ //Not found matching fortotiki
                        codes.push({
                            merida: transaction.reference_no,
                            date:dateFormat(transaction.date.toLocaleString(),'d-m-yyyy') ,
                            pickup_country:transaction.warehouse.length>0? transaction.warehouse[0].country:"",
                            pickup_city:transaction.warehouse.length>0 ?transaction?.warehouse[0].city:"",
                            fortotiki: transaction?.partner,
                            weight:transaction.part? transaction.part[0].weight:"",
                            quantity: parseInt(transaction.part[0].pieces) ,
                            destination:transaction.destination? transaction.destination:'',
                            date2:found_delivered?dateFormat(found_delivered.date.toLocaleString(),'d-m-yyyy'):'',
                            client:transaction.ship?transaction.ship[0].shipping_company_name:"",
                            vessel:transaction.vessel?transaction.vessel[0].vessel:"",
                            type:transaction.shipment_type
                    }) 
                    }
                }
            }
        }
       found_delivered=null;
       
      });
  }
      console.log("Final Export", codes)
      return codes
   }      
   

      return (
        <>
        <button onClick={()=>isFiltered ?refetchFiltered() :refetch()} className="add-part-btn btn btn-info">
           {(filterLoading || isLoading) ?"Loading...": "Load Export" }
        </button>
        {exportData.length>0 &&
       <CSVLink 
        data={exportData} 
        className="add-part-btn btn btn-info"  
        filename={`AWB No. Export - ${dateFormat(new Date(),'d-m-yyyy')}.csv`} 
        headers={headers} 
        onClick={()=>setexportData([])}
        >
          Export Transactions
        </CSVLink>
       
        }

       </>
    )
}

const exportSchema=[
  {label:"Reference No.",dbValue:'reference_no',type:"input"},
  {label:"Description",dbValue:'description',type:"input"},
  {label:"Pieces",dbValue:'pieces',type:"input"},
  {label:"Weight",dbValue:'weight',type:"input"},
  {label:"Dimensions",dbValue:'dimensions',type:"input"},
  {label:"Status",dbValue:'status',type:"input"},
  {label:"Warehouse",dbValue:'warehouse',type:"select",populate:"forwarding_company"},
  {label:"Shipping Company",dbValue:'ship',type:"select",populate:"shipping_company_name"},
  {label:"Supplier",dbValue:'supplier',type:"select",populate:"supplier_name"},
  {label:"Vessel",dbValue:'vessel',type:"select",populate:"vessel"},
  {label:"Remarks",dbValue:'remarks',type:"input"},
]

const headers = [
  { label: "Αριθμός Παραγγελιών", key: "merida" },
  { label: "Τύπος", key: "type" },
  { label: "Πλοίο", key: "vessel" },
  { label: "Πελάτης", key: "client" },
  { label: "Ημερομηνία", key: "date" },
  { label: "Χώρα Φόρτωσης", key: "pickup_country" },
  { label: "Φορτωτική", key: "fortotiki" },
  { label: "Πόλη Φόρτωσης", key: "pickup_city" },
  { label: "Μικτό Βάρος", key: "weight" },
  { label: "Ποσότητα", key: "quantity" },
  { label: "Προορισμός", key: "destination" },
  { label: "Ημερομηνία Άφιξης", key: "date2" },

];