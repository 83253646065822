import React from "react";
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { connect } from "react-redux";
import MultiSelect from "react-multi-select-component";
import DatePicker from "react-datepicker";
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import {
    getAllAdminVessels, getAllAdminWarehouses, getAllAdminShips,
    filterPartForTrans, 
    getAllAdminParts,clearfilterPartForTrans,addTransaction
} from '../../js/actions'
import { config, api_callback } from "../../globals";
import S3FileUpload from 'react-s3';
var dateFormat = require('dateformat');

const initialState = {
    reference_no: [], date: '', type: '', attachment: '', warehouse_id: null, partner_id: null,
    validated: false, file: null,destination:'',
    ref_no: [], shipping_co: '', vessel: '', warehouse: '', status: '',code:''
};
const states = [
    { value: 'Pending', label: 'Pending' },
    { value: 'In Stock', label: 'In Stock' },
    { value: 'Send', label: 'Send' },
    { value: 'Delivered', label: 'Delivered' },
  ];
class AddTrans extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reference_no: [], date: '', type: '', attachment: '', warehouse_id: null, partner_id: null,
            validated: false, file: null,show_filter:false,destination:'',
            // filtration
            ref_no: [], shipping_co: '', vessel: '', warehouse: '', status: '',date2: '',code:''
        };


        this.handleChange = this.handleChange.bind(this);  
        this.filterInput = this.filterInput.bind(this);  
    }
    componentDidMount = () => {
        this.props.getAllAdminWarehouses();
        this.props.getAllAdminVessels();
        this.props.getAllAdminShips();
        this.props.getAllAdminParts();
    }

    componentWillReceiveProps=(nextProps)=>{
        if(nextProps.ships_success){
            let all_ships=[]
           all_ships= nextProps.all_ships.map(({shipping_company_name }) => ({ value: shipping_company_name, label:shipping_company_name  }))
           all_ships.unshift({value:'',label:'All'})
           this.setState({select_ships:all_ships})
           }
        if(nextProps.warehouses_success){
         let all_warehouses= this.props.all_warehouses.map(({_id,forwarding_company }) => ({ value: forwarding_company, label:forwarding_company  }))
         all_warehouses.unshift({value:'',label:'All'})
         this.setState({select_warehouses:all_warehouses})
         }
        if(nextProps.vessels_success){
         let all_vessels= this.props.all_vessels.map(({vessel}) => ({ value: vessel, label:vessel}))
         all_vessels.unshift({value:'',label:'All'})
         this.setState({select_vessels:all_vessels})
         }
     
    }

    search = (e) => {
        e.preventDefault();
        const {  shipping_co, vessel, warehouse, status,date } = this.state;
        this.props.filterPartForTrans({
            shipping_co: shipping_co, vessel: vessel, warehouse: warehouse,
        status: status, supplier: '',date:date
        });
    }

    filterInput = (e) => {
        this.setState({ [e.target.id]: e.target.value });
      
    }
    multi = (e) => {

        this.setState({ reference_no: e, ref_no:e })
     
    }

    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });


    }
    getImage = e => {
        const files = e.target.files;

        if (files && files.length > 0) {
            const file = files[0];
            this.setState({ file });
        }
    };

    upload= async (file)=>{
       return  S3FileUpload.
        uploadFile(file, config).catch(error=>console.log(error))
    }
    
    addTransaction = async (event) =>{

        const form = event.currentTarget;
        const { date, ref_no,file } = this.state;
        
        if (form.checkValidity() === false ||   date === '' || ref_no.length === 0 ||this.state.type=='') {
            api_callback(false,'Validation Fail!','Please fill all fields')
            event.preventDefault();
            event.stopPropagation();
            return;
        }
        this.setState({ validated: true })
        //Validated 

        event.preventDefault();
        if(file!= null){
            //Destination Code Validation
       /*     if(this.state.code == ''){
            api_callback(false,'Validation Fail!','Please insert a code for file')
                event.preventDefault();
                event.stopPropagation();
                return;
            }*/
            //File Uploading API Call
           this.upload(file).then(data=>{
             api_callback(true,'Success','File Uploaded')
               const tempState = this.state;
    
               const { warehouses,partners } = this.props;
   
                   if (this.state.type === "In Stock") { 
                       delete tempState.partner_id
                       if (tempState.warehouse_id === null)
                       tempState.warehouse_id = warehouses[0]._id              
                           
                   }
                   else if (this.state.type === "Send") {
                       delete tempState.warehouse_id
                       if (tempState.partner_id === null)
                       tempState.partner= this.state.partner
                   } 
             
                   ref_no.map(r => {
                       let temp = tempState;
                       temp.attachment =data.location
                       temp.reference_no = r.label;
                       temp.part_id = r.value;
                       this.props.addTransaction(temp);
                       this.onHide()
                   })
            }).catch(err=>{
                api_callback(false,'Error','Uploading Failed')
            })

          
        }
        else{
            const tempState = this.state;
            const { warehouses,partners } = this.props;

                if (this.state.type === "In Stock") { 
                    delete tempState.partner_id
                    if (tempState.warehouse_id === null)
                    tempState.warehouse_id = warehouses[0]._id              
                }
                else if (this.state.type === "Send") {
                    delete tempState.warehouse_id
                    tempState.partner= this.state.partner
                } 
                ref_no.map(r => {
                    let temp = tempState;
                    temp.reference_no = r.label;
                    temp.part_id = r.value;
                    this.props.addTransaction(temp);
                    this.onHide()
                }) 
            
        }


       

    }

    onHide = () => {
        this.setState(initialState)
        this.props.clearfilterPartForTrans();
        this.props.onHide()
    }
   


    renderWarehouse = (e) => {
        const { warehouses } = this.props;
        const { type } = this.state;
        if (type === "In Stock") {
            return (
                <React.Fragment>
                    <Form.Group as={Col} md="6">
                      <p className="gray_text bold text-uppercase">Warehouse</p>
                        <Form.Control as="select" onChange={(e) => { this.setState({ warehouse_id: `${e.target.value}` }) }}>
                            {
                                warehouses.map((w, i) => {
                                    return <option key={w._id} value={w._id} required >{w.forwarding_company}</option>
                                })
                            }
                        </Form.Control>
                    </Form.Group>
                </React.Fragment>
            )
        }
    }

    renderPartners = (e) => {
        const { partners } = this.props;
        const { type } = this.state;
        if (type === "Send") {
            return (
                <React.Fragment>
                    <Form.Group as={Col} md="6">
                        <p className="gray_text bold text-uppercase">AWB NO</p>
                        <input className='form-control' required onChange={(e)=>this.setState({partner:e.target.value})}></input> 
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                        <p className="gray_text bold text-uppercase">DESTINATION</p>
                        <input className='form-control' onChange={(e)=>this.setState({destination:e.target.value})}></input> 
                    </Form.Group>
                </React.Fragment>
            )
        }

    }
    

    renderMulti=()=>{
        const {  trans_filter, tempPartsTrans,parts} = this.props;
        const {ref_no}=this.state;
        let options=[];
        if(trans_filter){
            options= tempPartsTrans.map(p => {
                return { label: p.reference_no, value: p._id }
            })
        }else{
            if(parts.length>0){
                options= parts.map(p => {
                    return { label: p.reference_no, value: p._id }
                })
            }
              
        }
        if (options.length>0)
        return(<MultiSelect
            options={options}
            value={ref_no}
            onChange={this.multi}
            labelledBy={"Select"}
            required
        />)
        
    }

    render() {
        const { show } = this.props;
        //redux
        const { reference_no, date, type, attachment, warehouse_id, validated,code } = this.state;
        const {ships_success, vessels_success, warehouses_success, parts_success}=this.props;
        const { all_warehouses, all_vessels, all_ships } = this.props;
        
        return (
            <Modal
                show={show}
                onHide={this.onHide}
                size='lg'
                centered
            >
                <Modal.Body>
                    <Row>
                    <Col md="7" id='add_transaction_section'>
                    <h4>Add a Transaction</h4>
                    <Form noValidate validated={validated} onSubmit={this.addTransaction}>

                        <Form.Row>

                            <Form.Group as={Col} md="6" >
                            <p className="gray_text bold text-uppercase">Reference No.</p>
                                {
                                 this.renderMulti()
                                }
                             

                                <Form.Control.Feedback type="invalid">
                                    Please provide Reference Number
                             </Form.Control.Feedback>


                            </Form.Group>

                            <Form.Group as={Col} md="6" >
                            <p className="gray_text bold text-uppercase">Date</p>
                                <DatePicker style={{ display: "block" }} required className='form-control' dateFormat='d-MM-yyyy' selected={date} onChange={(date) =>  this.setState({date}) } required />
                                <Form.Control.Feedback type="invalid">
                                    Please select date.
                             </Form.Control.Feedback>
                            </Form.Group>

                        </Form.Row>

                        <Form.Row>

                            <Form.Group as={Col} md="6">
                            <p className="gray_text bold text-uppercase">Type</p>
                                <Select
                                onChange={(e) => { this.setState({ type: `${e.value}` }) }}
                                options={states}
                                isSearchable={false}
                                required
                                />
                                 </Form.Group>
                            <Form.Group as={Col} md="6" >
                                <p className="gray_text bold text-uppercase">Remarks</p>
                                <input className='form-control' name='remarks' onChange={(e) =>this.setState({ [e.target.name]: e.target.value})} ></input>
                            </Form.Group>
                        {this.renderWarehouse()}
                        {this.renderPartners()}
                        </Form.Row>
                        <Row>
                            <Col md="6">
                            <p className="gray_text bold text-uppercase">Attachments</p>
                            <input type="file" id="file" onChange={this.getImage}/>
                            <label for="file" >Choose File</label>
                            </Col>
                        </Row>
                        <br></br>
                        <Button type="submit" className="btn btn-primary search-btn">Done</Button>
                    </Form>
                    </Col>
                    <Col md="5" className="trans-filter-part">
                        <p>FILTER</p>

                            <Form onSubmit={this.search}>
                               
                                
                                {
                                    ships_success &&
                                    <Form.Group >
                                    <p className="gray_text bold text-uppercase">Shipping Company</p>
                                    <Select
                                     onChange={(e) => { this.setState({ shipping_co: `${e.value}` }) }}
                                     options={this.state.select_ships}
                                    />
                                </Form.Group>

                                }
                        
                                {
                                    vessels_success &&
                                    <Form.Group>
                                    <p className="gray_text bold text-uppercase">Vessels</p>
                                    <Select
                                        onChange={(e) => { this.setState({ vessel: `${e.value}` }) }}
                                        options={this.state.select_vessels}
                                        />
                                </Form.Group>

                                }
                               
                               {
                                   warehouses_success &&
                                   <Form.Group >
                                    <p className="gray_text bold text-uppercase">Warehouse</p>
                                     <Select
                                        onChange={(e) => { this.setState({ warehouse: `${e.value}` }) }}
                                        options={this.state.select_warehouses}
                                        />
                                </Form.Group>

                               }

                                

                                <Form.Group>
                                    <p className="gray_text bold text-uppercase">Status</p>
                                    <Select
                                        onChange={(e) => { this.setState({ status: `${e.value}` }) }}
                                        options={states}
                                        isSearchable={false}
                                        />
                                </Form.Group>

                                <Button type="submit" className="btn btn-primary search-btn">Search</Button>
                            </Form>

                        </Col> 
                        </Row>

                </Modal.Body>
            </Modal>
        );

    }

}
const mapDispatchToProps = dispatch => {
    return {
        getAllAdminShips: payload => dispatch(getAllAdminShips()),
        getAllAdminVessels: payload => dispatch(getAllAdminVessels()),
        getAllAdminWarehouses: payload => dispatch(getAllAdminWarehouses()),
        filterPartForTrans: payload => dispatch(filterPartForTrans(payload)),
        getAllAdminParts: payload => dispatch(getAllAdminParts(payload)),
        clearfilterPartForTrans: payload => dispatch(clearfilterPartForTrans(payload)),
        addTransaction: payload => dispatch(addTransaction(payload)),
       

    }
}
const mapStateToProps = state => {
    return { 
        tempPartsTrans: state.tempPartsTrans, trans_filter: state.trans_filter,

        all_warehouses: state.all_warehouses, all_vessels: state.all_vessels, all_ships: state.all_ships,
        parts: state.parts,
        
        ships_success:state.ships_success, vessels_success:state.vessels_success,warehouses_success:state.warehouses_success,
        parts_success:state.parts_success


    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddTrans);

