import React, { Component } from 'react'

export default class Card extends Component {
   

    render() {
        return (
            <div className='d-flex flex-column'>
               <div className='d-flex flex-row justify-content-between'>
                 <span>{this.props.p.reference_no}</span>
                 {this.props.p.warehouse.length>0 && <span>{this.props.p.warehouse[0].forwarding_company}</span>}
                  </div> 
                  <div className='d-flex flex-row  justify-content-between'>
                  <span className='gray_text'>{this.props.p.vessel[0].vessel}</span>
                  <span className='gray_text'>{this.props.p.description.slice(0,10)}</span>  
                  </div> 
            </div>
        )
    }
}
