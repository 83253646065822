import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
//Notification ---------------------------------------------------
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
//----------------------------------------------------------------
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
const queryClient = new QueryClient()

ReactDOM.render(
  <React.StrictMode>
     <div className="app-container" style={{height:'100vh'}}>
      <ReactNotification />
      <QueryClientProvider client={queryClient}>
          <App />
         <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </div>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
