import React from "react";
import { Row, Col, Modal,Button, Form} from 'react-bootstrap';
import { connect } from "react-redux";
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import {
    editShip
} from '../../js/actions'
import ConfirmDeleteButton from "../../Common/ConfirmDeleteButton/ConfirmDeleteButton";
const initialState = {
        shipping_co:'',
        email:'',
        person_in_charge:'',
        address:'',
        city:'',
        country:'',
        phone_no:'',
        edit:false
};

class OpenShip extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
                shipping_co:'',
                email:'',
                person_in_charge:'',
                address:'',
                city:'',
                country:'',
                phone_no:'',
                edit:false
        };
          
    }
    onClose = () => {
        this.setState(initialState)
        this.props.onHide();
    }
    editShip = () => {
        const{ship}=this.props;
        const temp=this.state;
        temp.id=ship._id;

        this.props.editShip(temp);
        this.setState(initialState)
        this.props.onHide();
    }


    edit=(ship)=>{
        this.setState({edit:true});
        this.setState({
            shipping_co:ship.shipping_company_name,
            email:ship.email,
            person_in_charge:ship.person_in_charge,
            address:ship.address,
            city:ship.city,
            country:ship.country,
            phone_no:ship.phone_no
        });

    }

    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value })
    }
    render(){
  
        const { ship,show,deleteShip } = this.props;
        const{shipping_co,person_in_charge,address,city,country,phone_no,email,edit}=this.state;

        if(ship!=undefined && edit == false){
        return (
            <Modal
                    show={show}
                    onHide={this.onClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Body>
                        <React.Fragment>

                            <Row>
                                <Col>
                                    <h3>{ship.shipping_company_name}</h3>
                                  
                                </Col>
                                <Col>

                                 
                            <ConfirmDeleteButton hideModal={this.onClose} yesFunction={()=>deleteShip(ship._id)} />
                                    <Button className="edit-trans" onClick={()=>this.edit(ship)}>
                                        Edit
                            </Button>
                                </Col>

                            </Row>
                            <br></br>
                            <Row>
                                <Col md="4">
                                     <p className="gray_text bold text-uppercase">SHIPPING COMPANY</p>
                                    <p>{ship.shipping_company_name}</p>
                                </Col>
                                <Col md="4">
                                     <p className="gray_text bold text-uppercase">PERSON IN CHARGE</p>
                                    <p>{ship.person_in_charge}</p>
                                </Col>
                            </Row>


                            <Row>
                                <Col md="4">
                                     <p className="gray_text bold text-uppercase">ADDRESS</p>
                                    <p>{ship.address}</p>
                                </Col>
                                <Col md="4">
                                     <p className="gray_text bold text-uppercase">CITY</p>
                                    <p>{ship.city}</p>
                                </Col>
                                <Col md="4">
                                     <p className="gray_text bold text-uppercase">COUNTRY</p>
                                    <p>{ship.country}</p>
                                </Col>
                            </Row>

                            <Row>
                                <Col md="4">
                                     <p className="gray_text bold text-uppercase">PHONE NUMBER</p>
                                    <p>{ship.phone_no}</p>
                                </Col>
                                <Col md="4">
                                     <p className="gray_text bold text-uppercase">EMAIL</p>
                                    <p>{ship.email}</p>
                                </Col>
                               
                            </Row>


                        </React.Fragment>
                    </Modal.Body>


                </Modal>
    
        );}
        else  if(ship!=undefined && edit == true){
            return (
                <Modal
                        show={show}
                        onHide={this.onClose}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Body>
                            <React.Fragment>
    
                                <Row>
                                    <Col>
                                        <h3>{ship.shipping_company_name}</h3>
                                      
                                    </Col>
                                    <Col>
                                        <Button className="edit-trans" onClick={this.editShip}>
                                            Save
                                </Button>
                                    </Col>
    
                                </Row>
                                <br></br>
                                <Row>
                                    <Col md="4">
                                         <p className="gray_text bold text-uppercase">SHIPPING COMPANY</p>
                                       <Form.Group >

                                            <Form.Control
                                                id="shipping_co"  value={shipping_co} onChange={this.handleChange} placeholder="Enter Name" required />
                                        </Form.Group>
                                    </Col>
                                    <Col md="4">
                                         <p className="gray_text bold text-uppercase">PERSON IN CHARGE</p>
                                        <Form.Group >

                                            <Form.Control
                                                id="person_in_charge" value={person_in_charge} onChange={this.handleChange} placeholder="Enter Person name" required />


                                        </Form.Group>
                                    </Col>
                                </Row>
    
    
                                <Row>
                                    <Col md="4">
                                         <p className="gray_text bold text-uppercase">ADDRESS</p>
                                       <Form.Group >

                                            <Form.Control
                                                id="address"  value={address} onChange={this.handleChange} placeholder="Enter Address" required />


                                        </Form.Group>
                                    </Col>
                                    <Col md="4">
                                         <p className="gray_text bold text-uppercase">CITY</p>
                                        <Form.Group >

                                            <Form.Control
                                                id="city" value={city} onChange={this.handleChange} placeholder="Enter City" required />


                                        </Form.Group>
                                    </Col>
                                    <Col md="4">
                                         <p className="gray_text bold text-uppercase">COUNTRY</p>
                                        <Form.Group >

                                            <Form.Control
                                                id="country"  value={country} onChange={this.handleChange} placeholder="Enter Country" required />


                                        </Form.Group>
                                    </Col>
                                </Row>
    
                                <Row>
                                    <Col md="4">
                                        <p className='gray_text  bold text-uppercase'>PHONE NUMBER</p>
                                        <Form.Group >

                                        <ReactPhoneInput
                                           country={'gr'}
                                        inputExtraProps={{
                                            id:'phone_no',
                                            name: "phone_no",
                                            required: true,
                                        }}
                                        containerStyle={{
                                            marginLeft:0,
                                            height:'2.6rem'
                                        }}
                                        buttonStyle={{
                                            background:'rgba(224,231,255,0.2)',borderColor:'#E0E7FF' 
                                        }}
                                        inputStyle={{background:'rgba(224,231,255,0.2)',borderColor:'#E0E7FF',height:'2.6rem',width:'100%'}}
                                        value={phone_no}
                                        enableSearch={true}
                                        onChange={(phone_no)=>this.setState({phone_no})}
                                        /> 


                                        </Form.Group>
                                    </Col>
                                    <Col md="4">
                                         <p className="gray_text bold text-uppercase">EMAIL</p>
                                        <Form.Group >

                                            <Form.Control
                                                id="email" value={email} onChange={this.handleChange} placeholder="Enter Email" required />


                                        </Form.Group>
                                    </Col>
                                   
                                </Row>
    
    
                            </React.Fragment>
                        </Modal.Body>
    
    
                    </Modal>
        
            );}
    
        else return null;
    
    }

}

   

const mapDispatchToProps = dispatch => {
    return {
        editShip: payload => dispatch(editShip(payload)),
    
    }
}
export default connect(null, mapDispatchToProps)(OpenShip);
