import React from "react";
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

const initialState = { forwarding_company: '', address: '', email: '', city: '', country:null ,phone_no: '', validated: false };

class AddWarehouse extends React.Component {
    constructor(props) {
        super(props);
        this.state = { forwarding_company: '', address: '', email: '', city: '', country:null ,phone_no: '', validated: false };


        this.handleChange = this.handleChange.bind(this);
    }
    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    }

    addWarehouse = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        event.preventDefault();
        this.setState({ validated: true })
        if (form.checkValidity() === true) {
            this.props.addWarehouse(this.state);
            this.setState(initialState)
        }

    }

    onHide = () => {
        this.setState(initialState)
        this.props.onHide()
    }

    
    render() {
        const { show } = this.props;


        const { forwarding_company, email, address,city,country,phone_no, validated } = this.state;
        return (
            <Modal
                show={show}
                onHide={this.onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <h4>Add a Warehouse</h4>
                    <Form noValidate validated={validated} onSubmit={this.addWarehouse}>

                        <Form.Row>
                            
                            <Form.Group as={Col} md="8">
                                <p className='gray_text  bold text-uppercase'>WAREHOUSE</p>
                                <Form.Control
                                    id="forwarding_company" value={forwarding_company} placeholder='Enter Name'   onChange={this.handleChange}  required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide Name.
                            </Form.Control.Feedback>
                            </Form.Group>

                           
                        </Form.Row>
                        
                        <Form.Row>

                        <Form.Group as={Col} md="4">
                                <p className='gray_text  bold text-uppercase'>ADDRESS</p>
                                <Form.Control
                                    id="address" value={address}  onChange={this.handleChange} placeholder='Enter Address'   required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide address.
                            </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md="4">
                                <p className='gray_text  bold text-uppercase'>CITY</p>
                                <Form.Control
                                    id="city" value={city} onChange={this.handleChange}placeholder='Enter City'    required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide city.
                            </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md="4">
                                <p className='gray_text  bold text-uppercase'>COUNTRY</p>
                                <Form.Control
                                    id="country" value={country} onChange={this.handleChange}placeholder='Enter Country'    required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide Country.
                            </Form.Control.Feedback>
                            </Form.Group>

                        </Form.Row>

                        <Form.Row>

                        <Form.Group as={Col} md="4">
                                <p className='gray_text  bold text-uppercase'>PHONE NUMBER</p>
                                <ReactPhoneInput
                                country={'gr'}
                                    inputExtraProps={{
                                        id:'phone_no',
                                        name: "phone_no",
                                        required: true,
                                    }}
                                    containerStyle={{
                                        marginLeft:0,
                                        height:'2.6rem'
                                    }}
                                    buttonStyle={{
                                        background:'rgba(224,231,255,0.2)',borderColor:'#E0E7FF' 
                                    }}
                                    inputStyle={{background:'rgba(224,231,255,0.2)',borderColor:'#E0E7FF',height:'2.6rem',width:'100%'}}
                                    value={phone_no}
                                    enableSearch={true}
                                    onChange={(phone_no)=>this.setState({phone_no})}
                                    /> 
                                <Form.Control.Feedback type="invalid">
                                    Please provide phone number.
                            </Form.Control.Feedback>
                        </Form.Group>

                            <Form.Group as={Col} md="4">
                                <p className='gray_text  bold text-uppercase'>EMAIL</p>
                                <Form.Control
                                    id="email" value={email} type="email" onChange={this.handleChange} placeholder='Enter Email'   required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide Email.
                            </Form.Control.Feedback>
                            </Form.Group>

                        </Form.Row>




                        <Button type="submit" className="btn btn-primary col-4 mt-3">Done</Button>
                    </Form>
                </Modal.Body>
            </Modal>
        );

    }

}


export default AddWarehouse;
