import React ,{useState,useEffect}from 'react'
import { Row, Col, Button ,Form} from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import MultiSelect from "react-multi-select-component";
import {sort_by_field} from '../../globals'
const states = [
    { value: '', label: 'All' },
    { value: 'Pending', label: 'Pending' },
    { value: 'In Stock', label: 'In Stock' },
    { value: 'Send', label: 'Send' },
    { value: 'Delivered', label: 'Delivered' },
];
  

export default function Filters({
    ships_success=false,
    parts_success=false,
    vessels_success=false,
    warehouses_success=false,
    suppliers_success=false,
    reference_no,
    search,
    suppliers,
    warehouses,
    vessels,
    ships,
    changeHandler,
    selectChangeHandler,
    fromDate,
    toDate,
    hasDates,
    selectParts=false,
    partOptions,
    hasCountry=false,
    hasStatus=false,
    country=''
}) {
    const [shippingCompany, setshippingCompany] = useState('')
    const [filteredVessels, setFilteredessels] = useState([])
    const [allVessels, setAllVessels] = useState([])

    useEffect(() => {
        setFilteredessels(vessels)
        setAllVessels(vessels)
    },[vessels])

    useEffect(() => {
        console.log("Ship",shippingCompany)
       if(String(shippingCompany?.label).toLowerCase()=='all'){
        setFilteredessels(allVessels)
       }else{
       if(filteredVessels){
        if(filteredVessels.length >0){
            let ves = allVessels.
            filter(v=>v.ship_id == shippingCompany._id)
            ves.unshift({value:'',label:'All',id:''})
            console.log(allVessels)
            setFilteredessels(ves)
        }}
    }
    
    },[shippingCompany])

   

    return (
        <Col md="2" style={{alignSelf:'self-end'}} className="parts-filter" >
        <p>Filter</p>

        <Form onSubmit={search}>
        {!selectParts ?
            <Form.Group>
                <p className='gray_text  '>Reference No.</p>
                <Form.Control type="text" id="reference_no" value={reference_no} onChange={(e)=>changeHandler(e)} placeholder="Enter Reference no." />
            </Form.Group>
         :
        parts_success &&
        <Form.Group >
            <p className="gray_text">Reference No.</p>
            <MultiSelect
                options={partOptions}
                value={reference_no}
                onChange={(value=>selectChangeHandler('reference_no',value))}
                labelledBy={"Select"}
                required
            />
        </Form.Group>
         }
            




            {ships_success &&
                <Form.Group >
                <p className='gray_text'>Shipping Company</p>
                <Select
                indicatorsContainer={{backgroundColor:'red'}}
                onChange={(e)=>{
                    console.log("Change Hnadler", e)
                    selectChangeHandler('shipping_co',e.value)
                    setshippingCompany(e)
                }}
                options={sort_by_field(ships)}
                />
                
            </Form.Group>
            }
         
            {vessels_success && vessels!=undefined  &&
                <Form.Group>
                <p className='gray_text  '>Vessels</p>
                <Select
                id='vessel'
                onChange={(e)=>selectChangeHandler('vessel',e.value)}
                options={sort_by_field(filteredVessels)}
                />
            </Form.Group>

            }
           
           {
               warehouses_success  && warehouses!=undefined &&
               <Form.Group >
               <p className='gray_text  '>Warehouse</p>
               <Select
                id='warehouse'
                onChange={(e)=>selectChangeHandler('warehouse',e.value)}
                options={sort_by_field(warehouses)}
                />
           </Form.Group>
           }
      
      { hasStatus&&
      <Form.Group>
            <p className='gray_text  '>Status</p>
            <Select
                onChange={(e)=>selectChangeHandler('status',e.value)}
                options={states}
                isSearchable={false}
                />
        </Form.Group>
        }

            {suppliers_success && suppliers!=undefined && 
            <Form.Group>
                <p className='gray_text  '>Supplier</p>
                <Select
                 onChange={(e)=>selectChangeHandler('supplier',e.value)}
                options={suppliers}
                />
            </Form.Group>
           }
        {hasCountry &&
         <Form.Group>
         <p className='gray_text'>Country</p>
         <Form.Control id="country" value={country} onChange={changeHandler} placeholder="Enter Country" />
        </Form.Group>
        }

        {hasDates && 
        <React.Fragment>
         <Form.Group>
            <p className="gray_text">From Date</p>
            <DatePicker className='form-control' dateFormat='d-MM-yyyy' style={{ display: "block" }} selected={fromDate}  onChange={(date) => selectChangeHandler('fromDate',date)} />
         </Form.Group>

         <Form.Group>
           <p className="gray_text">To Date</p>
           <DatePicker  className='form-control' dateFormat='d-MM-yyyy' style={{ display: "block" }} selected={toDate} onChange={(date) => selectChangeHandler('toDate',date)} />
         </Form.Group>
         </React.Fragment>
          }

         <Button type="submit" className="btn btn-primary search-btn">Search</Button>
        </Form>
    </Col>
    )
}
