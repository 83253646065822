import React from "react";
import { Row, Col, Modal, Button, Form } from 'react-bootstrap';
import '../styles.scss'
import { connect } from "react-redux";
import {
    editUser
} from '../../js/actions'
import ConfirmDeleteButton from "../../Common/ConfirmDeleteButton/ConfirmDeleteButton";


const initialState = {
    type:'',
    name:'',
    email:'',
    shipping_company_name:'',
    ship_id:null,
    warehouse_name:'',
    warehouse_id:null,
    password:'',
    edit:false
};


class OpenUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type:'',
            name:'',
            email:'',
            shipping_company_name:'',
            ship_id:null,
            warehouse_name:'',
            warehouse_id:null,
            password:'',
            edit:false
           
        };
          
    }

   
    editUserById = (event) => {
        const{user,all_ships,warehouses}=this.props;
        const{ type,ship_id, warehouse_id}=this.state;
        
        let tempData = this.state;
        if(type=="Shipping Company"){
            if(ship_id==null){
                tempData.ship_id=all_ships[0]._id;
                tempData.shipping_company_name=all_ships[0].shipping_company_name;
                
            }
            else{
                let temp_s=all_ships.find(s=>{ if(s._id==ship_id) return s.shipping_company_name});
               
                tempData.shipping_company_name=temp_s.shipping_company_name;
            }
            delete tempData.warehouse_id;
            delete tempData.warehouse_name;
        }
        else{
            if(warehouse_id==null){
                tempData.warehouse_id=warehouses[0]._id;
                tempData.warehouse_name=warehouses[0].forwarding_company;
                
            }
            else{
                let temp_s=warehouses.find(w=>{ if(w._id==warehouse_id) return w.forwarding_company});
                tempData.warehouse_name=temp_s.forwarding_company
            }
            delete tempData.ship_id;
            delete tempData.shipping_company_name;
        }
       
        tempData.id=user._id;
        this.props.editUser(tempData)
        this.setState(initialState)
        this.props.onHide();
    }
    
    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value })
        
    }
    onClose = () => {
        this.setState(initialState,()=>{
            this.props.onHide();
        })
       
    }

    Ship_Warehouse=()=>{
        const{user}=this.props;
        if(user.type=="Shipping Company"){
            if(user.ship.length>0){
                return(
                    <React.Fragment>
                         <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>Ship Company</p>
                                    <p>{user.ship[0].shipping_company_name}</p>
                                </Col>
                    </React.Fragment>
                )
            }
            else{
                return(
                    <React.Fragment>
                         <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>Ship Company</p>
                                    <p>{user.shipping_company_name}</p>
                                </Col>
                    </React.Fragment>
                )
            }
           
        }
        else{
            if(user.warehouse.length>0){
                return(
                    <React.Fragment>
                         <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>Warehouse</p>
                                    <p>{user.warehouse[0].forwarding_company}</p>
                                </Col>
                    </React.Fragment>
                )
            }
            else{
                return(
                    <React.Fragment>
                         <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>Warehouse</p>
                                    <p>{user.warehouse_name}</p>
                                </Col>
                    </React.Fragment>
                )
            }
          
        }
    }
    renderType=()=>{
        const{warehouses,all_ships,user}=this.props;
        const{type}=this.state;
        if(type==="Shipping Company"){
            return(
                <React.Fragment>
                     <Form.Group as={Col} md="4">
                                <p className='gray_text  bold text-uppercase'>Ship Company</p>
                                <Form.Control as="select" onChange={(e) => {this.setState({ ship_id:`${e.target.value}`, warehouse_id:null, warehouse_name:'' })}}>

                                    {
                                        all_ships.map((s, i) => {
                                            return <option name="ship" key={s._id} value={s._id} >{s.shipping_company_name}</option>
                                        })
                                    }
                                </Form.Control>
                            </Form.Group>
                </React.Fragment>
            )
        }
        else{
            return(
                <React.Fragment>
                     <Form.Group as={Col} md="4">
                                <p className='gray_text  bold text-uppercase'>Warehouse</p>
                                <Form.Control as="select" onChange={(e) => { this.setState({ warehouse_id:`${e.target.value}`, ship_id:null, shipping_company_name:'' }) }}>

                                    {
                                        warehouses.map((s, i) => {
                                            return <option name="warehouse" key={s._id} value={s._id} >{s.forwarding_company}</option>
                                        })
                                    }
                                </Form.Control>
                            </Form.Group>
                </React.Fragment>
            )
        }
    }

    editClicked=()=>{
        const user=this.props.user
        this.setState({
                type:user.type,
                name:user.name,
                email:user.email,
                password:user.password,
                edit:true
            });
    }
    render() {
        const { user,show,deleteUser,all_ships,warehouses } = this.props;
        const{ type,name,email,shipping_company_name,ship_id,
            warehouse_name,warehouse_id, edit, password}=this.state;
        
        if(user!=undefined && edit == false){
        return (
            <Modal
            show={show}
            onHide={this.onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <React.Fragment>

                    <Row>
                        <Col>
                            <h3>{user.name}</h3>
                            <p></p>
                          
                        </Col>
                        <Col>

 <ConfirmDeleteButton yesFunction={()=>deleteUser(user._id)}  hideModal={this.onClose}/>
                            <Button className="edit-trans" onClick={this.editClicked}>
                                Edit
                    </Button>
                        </Col>

                    </Row>
                    <br></br>
                    <Row>
                        <Col md="4">
                            <p className='gray_text  bold text-uppercase'>TYPE</p>
                            <p>{user.type}</p>
                        </Col>
                       {
                            this.Ship_Warehouse()
                       }
                    </Row>


                    <Row>
                        <Col md="4">
                            <p className='gray_text  bold text-uppercase'>EMAIL</p>
                            <p>{user.email}</p>
                        </Col>

                    </Row>
                </React.Fragment>
            </Modal.Body>


        </Modal>

    
        );}
        else if(user!=undefined && edit == true){
            return (
                <Modal
                show={show}
                onHide={this.onClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <React.Fragment>
    
                        <Row>
                            <Col>
                            <h3>{user.name}</h3>
                              
                            </Col>
                            <Col>  
                                <Button className="edit-trans"onClick={ this.editUserById}>
                                    Save
                        </Button>
                            </Col>
    
                        </Row>
                        <br></br>

                       <Form > 

                        <Form.Row>

                            <Form.Group as={Col} md="4">
                                <p className='gray_text  bold text-uppercase'>Type</p>
                                <Form.Control as="select" value={type}onChange={(e) => { this.setState({ type: `${e.target.value}` }) }}>
                                    <option key="Shipping Company" value="Shipping Company"  >Shipping Company</option>
                                    <option key="Warehouse Manager" value="Warehouse Manager"  >Warehouse Manager</option>
                                </Form.Control>
                            </Form.Group>
                            
                            <Form.Group as={Col} md="8">
                                <p className='gray_text  bold text-uppercase'>Full Name</p>
                                <Form.Control
                                    id="name" value={name} onChange={this.handleChange} placeholder="Enter Name" required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide Name.
                            </Form.Control.Feedback>
                            </Form.Group>

                           
                        </Form.Row>


                        <Form.Row>

                            <Form.Group as={Col} md="4">
                                <p className='gray_text  bold text-uppercase'>Email</p>
                                <Form.Control
                                    id="email" value={email} type="email" onChange={this.handleChange} placeholder="Enter Email" required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide Email.
                            </Form.Control.Feedback>
                            </Form.Group>

                            {
                                this.renderType()
                            }

                        </Form.Row>
  
                    </Form>
                
    
    
                    </React.Fragment>
                </Modal.Body>
    
    
            </Modal>
    
        
            );}
        else return null;
    }
}

const mapDispatchToProps = dispatch => {
    return {
        editUser: payload => dispatch(editUser(payload)),
    
    }
}
export default connect(null, mapDispatchToProps)(OpenUser);


