import React from "react";
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';


const initialState = { reference_no: '', description: '', pieces: 1, weight: '', dimensions: '',
 vessel_id: '', supplier_id: '', warehouse_id: '', ship_id: '', status:'Pending',
validated:false };

class AddPart extends React.Component {
    constructor(props) {
        super(props);
        this.state = { reference_no: '', description: '', pieces: 1, weight: '', 
        dimensions: '', vessel_id: '', supplier_id: '', warehouse_id: '',status:'Pending',
        validated:false };


        this.handleChange = this.handleChange.bind(this);
    }
    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
        console.log(this.state);

    }

    addPart = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        event.preventDefault();
        this.setState({validated:true})
        if (form.checkValidity() === true){
            this.props.addPart(this.state);
        }
       
      
    }

    onHide = () => {
        this.setState(initialState)
        this.props.onHide()
    }
    render() {
        const { show } = this.props;
        //redux
        const { suppliers, warehouses, vessels } = this.props;
        const { reference_no, description, pieces, weight, dimensions, vessel, supplier, warehouse } = this.state;
        
        const{validated}=this.state;
        return (
            <Modal
                show={show}
                onHide={this.onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <h4>Add a Part</h4>
                    <Form noValidate validated={validated} onSubmit={this.addPart}>

                        <Form.Row>

                            <Form.Group as={Col} md="4" >
                                <p className='gray_text  bold text-uppercase'>REFERENCE NO.</p>
                                <Form.Control type="text" id="reference_no" value={reference_no} onChange={this.handleChange} placeholder="Enter Reference no." required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide Reference Number
                             </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md="8" >
                                <p className='gray_text  bold text-uppercase'>DESCRIPTION</p>
                                <Form.Control id="description" value={description} onChange={this.handleChange} placeholder="Enter Description" required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide Description.
                             </Form.Control.Feedback>
                            </Form.Group>

                        </Form.Row>

                        <Form.Row>

                            <Form.Group as={Col} md="4">
                                <p className='gray_text  bold text-uppercase'>PIECES</p>
                                <Form.Control
                                    id="pieces" type="number" value={pieces} onChange={this.handleChange} placeholder="Enter Pieces" required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide Pieces
                             </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md="4">
                                <p className='gray_text  bold text-uppercase'>WEIGHT</p>
                                <Form.Control id="weight" value={weight} onChange={this.handleChange} placeholder="Enter Weight" required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide Weight.
                             </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md="4">
                                <p className='gray_text  bold text-uppercase'>DIMENSIONS</p>
                                <Form.Control id="dimensions" value={dimensions} onChange={this.handleChange} placeholder="Enter Dimensions" required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide Dimensions.
                             </Form.Control.Feedback>
                            </Form.Group>

                        </Form.Row>

                        <Form.Row>
                           

                            <Form.Group as={Col}>
                                <p className='gray_text  bold text-uppercase'>Vessels</p>
                                <Form.Control as="select" onChange={(e) => { this.setState({ vessel_id: `${e.target.value}` }) }}>
                                {
                                            vessels.map((v, i) => {
                                                return <option name="vessel" key={v._id} value={v._id} required>{v.vessel}</option>
                                            })
                                        }
                                </Form.Control>
                            </Form.Group>

                            <Form.Group as={Col} >
                                <p className='gray_text  bold text-uppercase'>Suppliers</p>
                                <Form.Control as="select" onChange={(e) => { this.setState({ supplier_id: `${e.target.value}` }) }}>
                                    {
                                        suppliers.map((s, i) => {
                                            return <option name="ship" key={s._id} value={s._id} required>{s.supplier_name}</option>
                                        })
                                    }
                                </Form.Control>
                            </Form.Group>
                            
                            <Form.Group as={Col} md="4">
                                <p className='gray_text  bold text-uppercase'>Warehouse</p>
                                <Form.Control as="select" onChange={(e) => { this.setState({ warehouse_id: `${e.target.value}` }) }}>
                                {
                                            warehouses.map((w, i) => {
                                                return <option key={w._id} value={w._id} required >{w.forwarding_company}</option>
                                            })
                                        }
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>

                    


                        <Button type="submit" className="btn btn-primary search-btn">Done</Button>
                    </Form>

                </Modal.Body>
            </Modal>
        );

    }

}


export default AddPart;
