import React from "react";
import { Card, Row, Col } from 'react-bootstrap';
import '../styles.scss';
import StateCard from "../../Common/StateCard";
import { notEmptyArray } from "../../globals";
var dateFormat = require('dateformat');


export default function TransCard(props) {

const renderWarehouse=()=>{
        const{transaction}=props;
     
        if(transaction.new_warehouse){
        if(transaction.new_warehouse.length===0){
            return(
                <React.Fragment>
                     {transaction.warehouse.length>0 &&   <p className='black_text'>{transaction.warehouse[0].forwarding_company}</p>}
                </React.Fragment>
            )
        }else{
            return(
                <React.Fragment>
                    <p className='black_text'>{transaction.new_warehouse[0].forwarding_company}</p>
                </React.Fragment>
            )
        }
    }
}
    

    const {openTrans ,transaction} = props;
    if(transaction){
    return (
        <React.Fragment>
            <Card className="part-detail" onClick={()=>{openTrans(transaction._id)}}>

                    <Row>
                    <Col>
                    <p className='black_text '>{transaction?.part?.length>0 ? transaction.part[0].reference_no:""}</p>
                    <StateCard state={transaction.type}/> 
                    </Col>
                    <Col>
                    {transaction?.ship?.length>0 && <p className='black_text'>{transaction.ship[0].shipping_company_name}</p>}
                    {transaction?.vessel?.length>0 && <p className='black_text'>{transaction.vessel[0].vessel}</p>}
                     </Col>
                    <Col>
                    {transaction?.supplier.length>0 &&<p className='black_text'>{notEmptyArray(transaction.supplier)? transaction.supplier[0].supplier_name:""}</p> } 
                    { renderWarehouse()}
                    </Col>
                    <Col>
                    <div style={{display:'flex',flexDirection:'row'}}>
                    {transaction?.part?.length>0 &&transaction.part[0].pieces &&   <p className='black_text mr-2'>{transaction.part[0].pieces} Pcs</p>} 
                    {transaction?.part?.length>0 &&transaction.part[0].weight &&  <p className='black_text mr-2'>{transaction.part[0].weight}kg</p>}  
                    {transaction?.part?.length>0 &&transaction.part[0].dimensions &&<p className='black_text'>{transaction.part[0].dimensions}</p>}  
                    </div>
                    {transaction.date &&<p className='black_text'>{dateFormat(transaction.date.toLocaleString(),"d-m-yyyy")}</p>}
                    </Col>
                    </Row>
            </Card>
        </React.Fragment>

    );
    }
    else{
        return null
    }

}
