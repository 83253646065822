import React, { useState } from "react";
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet';
import DefaultTheme from 'react-dates/lib/theme/DefaultTheme';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



const MyDatePicker= ({label='label',onChange,error,name}) => {
  const [startDate, setStartDate] = useState(null);

  const [show, setshow] = useState(null)


  return (
      <div style={{ position:'relative'}}>
    <label className='gray_text'>{label}</label>
    <DatePicker 
    className='form-control'
    dateFormat='d-MM-yyyy'
    selected={startDate} 
    onChange={date =>{ 
      onChange(name,date)
      setStartDate(date)
      }} /> 
      {error && <p style={{color:'red'}}>{error}</p>}
     </div>
  );
};

export default MyDatePicker
const selectionRange = {
  startDate: new Date(),
  endDate: new Date(),
  key: 'selection',
  showDateDisplay:false
}