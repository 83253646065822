import React, { Component } from "react";
//import "../LogInForm/styles.scss";
import Images from "../Pics/Images";
import adminImage from "../Pics/adminIcon.png"
import whImage from "../Pics/warehouseManagerIcon.png"
export default class LoginBox extends Component {
  link = (data) => {
    this.props.link(data);
  };
  handle_click = () => {
    if (this.props.title === "Administrator") {
      this.link("/admin/home");
    } else {
      this.link("/warehouse/home");
    }
  };
  render() {
    return (
      <div
        onClick={this.handle_click}
        className={
          this.props.box_clicked
            ? "box1 box_select text-center p-3 mr-1"
            : "box1 text-center p-3 mr-1"
        }
      >
        <img src={this.props.title === "Administrator"?adminImage:whImage} alt="icon" className="box_icon"></img>
        <br />
        <p className="font-weight-bold">{this.props.title}</p>
        <p className="text--color">Full access to all setting</p>
      </div>
    );
  }
}
