import React from 'react'
import { Col } from 'react-bootstrap';
import  ReactLoading  from 'react-loading';

export default function UpperCard({title,length,isLoading=false}) {
  return (
    <Col>
    <div className="top-box">
        <p className='text-uppercase'>{title}</p>
        {isLoading? 
      
      <ReactLoading type={'spin'} height='2em' width='2em' color={'#2E5BFF'}  />
        :
        <div className='d-flex flex-row'>
            <h2 style={{ margin: 0 }}>{length}</h2>
        </div>
        }
    </div>
    </Col>
  )
}
