import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import '../styles.scss'
import {  Col } from 'react-bootstrap';
import {
    logoutUser
 } from '../../js/actions'
 import logo from '../../Pics/Logo.png'
import homeIcon from  '../../Pics/icon-home.png'
import transIcon from '../../Pics/icon-transactions.png' 
import vesselIcon from '../../Pics/icon-vessel.png' 
import warehouseIcon from '../../Pics/icon-warehouse.png' 
import supplierIcon from '../../Pics/icon-suppliers.png' 
import usersIcon from '../../Pics/icon-users.png' 
import partsIcon from '../../Pics/icon-parts.png' 
import shipIcon from '../../Pics/icon-shipping_company.png' 
import logoutIcon from '../../Pics/icon-logout.png' 
import settingsIcon from '../../Pics/icon-settings.png' 
class Navbar extends Component {

    constructor(props) {
        super(props);
        this.state = { home:true, transactions:false, parts:false, settings:false,logout:false, current:"home" };
    }

    componentDidMount = () => {

    }

    handleNav=(e)=>{
        const id=e.target.id;
        const {current} = this.state;       
        this.setState({[id]:true, current:id, [current]:false });
        
    }
    logout=()=>{
        this.props.logoutUser();
        localStorage.setItem('loginState', false);
        localStorage.setItem('warehouse_login', false);
        localStorage.setItem('warehouse', false);
        localStorage.setItem('token', undefined);
        
    }
    render() {

        const {active} = this.props;

        return (
            <React.Fragment>
                <Col md="2" className="sidebar justify-content-center">
                    <div className='logo_area'>
                        <img width={150} src={logo}></img>
                    </div>
                    <ul className="nav flex-column   align-items-center">
                    
                    <li className={`nav-item  d-flex flex-row align-items-center  ${active==='home' ? "active" : ""}`}>
                        <div  className='d-flex flex-row align-items-center justify-content-center'>
                        <img src={homeIcon} width={23} height={23}/>
                            <Link to="/manager/home"> <p className="nav-link" id="home" >Home</p></Link>
                            </div>
                        </li>
           
                        <li className={`nav-item  d-flex flex-row align-items-center  ${active==='transactions' ? "active" : ""}`}>
                        <div  className='d-flex flex-row align-items-center '>
                        <img src={transIcon} width={23} height={23}/>
                            <Link to="/manager/transactions"> <p className="nav-link" id="home" >Transactions</p></Link>
                            </div>
                        </li>
                        <li className={`nav-item ${active==='parts' ? "active" : ""}`}>
                        <div  className='d-flex flex-row align-items-center '>
                        <img src={partsIcon} width={23} height={23}/>
                        <Link to="/manager/parts">  <p className="nav-link"  id="parts">Parts</p></Link>
                            </div>
                        </li>
                        <li className={`nav-item ${active==='settings' ? "active" : ""}`}>
                        <div  className='d-flex flex-row align-items-center '>
                        <img src={settingsIcon} width={23} height={23}/>
                        <Link to="/manager/settings">  <p className="nav-link" id="settings" >Settings</p></Link>
                            </div>
                        </li>

                        <li className={`nav-item ${active==='logout' ? "active" : ""}`}>
                        <div  className='d-flex flex-row align-items-center '>
                        <img src={logoutIcon} width={23} height={23}/>
                        <Link to="/admin"><p className="nav-link" id="logout" onClick={this.logout}>Logout</p></Link>
                            </div>
                        </li>
                    </ul>
                </Col>
            </React.Fragment>
        );
    }
}
const mapDispatchToProps = dispatch => {
    return {
        logoutUser: payload => dispatch(logoutUser(payload)),
  
    }
  }
export default connect(null, mapDispatchToProps)(Navbar);

