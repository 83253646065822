import React from "react";
import { connect } from "react-redux";
import PartCard from '../../Admin/Parts/PartCard';
import AddPart from './addPart';
import Select from 'react-select';
import { Row, Col, Button ,Form, Spinner} from 'react-bootstrap';
import ReactLoading from 'react-loading';
import {
    getAllPartsByWarehouse, getPart, closeOpenedPart,
    getAllAdminVessels,
    filterPartsAdmin,getAllAdminSuppliers
   
} from '../../js/actions'
import OpenPart from "../../Admin/Parts/openPart";

import Navbar from '../Common/Navbar'
const states = [
    { value: '', label: 'All' },
    { value: 'Pending', label: 'Pending' },
    { value: 'In Stock', label: 'In Stock' },
    { value: 'Send', label: 'Send' },
    { value: 'Delivered', label: 'Delivered' },
];
class PartsWh extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reference_no: '', status: '',vessel:'', supplier: '', addPartModal: false,
            part_id: null,
        };
        this.filterInput = this.filterInput.bind(this);
    }
    componentDidMount = () => {
        let warehouse = JSON.parse(localStorage.getItem("warehouse"))
        if(warehouse){
            this.props.getAllPartsByWarehouse({id:warehouse.warehouse_id});
            this.props.getAllAdminSuppliers();
            this.props.getAllAdminVessels();
        }
       
     
    }

    componentWillReceiveProps=(nextProps)=>{
       
        if(nextProps.warehouses_success){
         let all_warehouses= nextProps.all_warehouses.map(({_id,forwarding_company }) => ({id:_id, value: forwarding_company, label:forwarding_company  }))
         all_warehouses.unshift({value:'',label:'All',id:''})
         this.setState({select_warehouses:all_warehouses})
         }
        if(nextProps.vessels_success){
         let all_vessels= nextProps.all_vessels.map(({vessel,_id}) => ({id:_id, value: vessel, label:vessel}))
         all_vessels.unshift({value:'',label:'All',id:''})
         this.setState({select_vessels:all_vessels})
         }
        if(nextProps.suppliers_success){
         let all_suppliers= nextProps.all_suppliers.map(({supplier_name,_id}) => ({id:_id, value: supplier_name, label:supplier_name}))
         all_suppliers.unshift({value:'',label:'All',id:''})
         this.setState({select_suppliers:all_suppliers})
         }
    }

    // deletePart = (id) => {
    //     this.props.deletePart({ id: `${id}` });
    //     this.props.closeOpenedPart();
    // }
    search = (e) => {
        e.preventDefault();
        const { reference_no, shipping_co, vessel, warehouse, status, supplier } = this.state;
        this.setState({is_filtered:true})
        this.props.filterPartsAdmin({
            reference_no: reference_no,shipping_co:'', vessel: vessel, warehouse: '',
            status: status, supplier: supplier
        });
    }

    filterInput = (e) => {
        this.setState({ [e.target.id]: e.target.value });
        
    }
    renderParts = () => {

        const { parts, filtered_items, filter_applied, parts_success } = this.props;
        if (filter_applied) {
            if(filtered_items.length>0){
                return parts.map((p, i) => <PartCard part={p} key={i} openPart={this.openPart} />)
            }

        }
        else {
            if (parts_success) {
            return parts.map((p, i) => <PartCard part={p} key={i} openPart={this.openPart} />)
            }
            else{
                return ( <ReactLoading type={'spin'} height='2em' width='2em' color={'#2E5BFF'}  />)
            }
        }
    }


    openPart = (id) => {
        this.props.getPart({ id: `${id}` });
    }
  


    render() {
        const { open_item_modal, closeOpenedPart, parts, part } = this.props;
        //add Part
        const { all_suppliers, all_vessels } = this.props;
        const { reference_no,  addPartModal } = this.state;

         // error handling
         const { vessels_success,  suppliers_success}=this.props;

        if(localStorage.getItem("loginState")=="false" && localStorage.getItem("warehouse_login")=="false"){
            this.props.history.push("/admin");
            return null;
          }
        else
            return (
                <Row>
                    <Navbar active="parts" />
                    <Col>
                        <Row className="top-header">

                        </Row>

                        <Row className="parts-main">
                            <Col md="10">
                                <Row>
                                    <Col><h2>Parts</h2></Col>
                                <Col><p className='gray_text pt-3'>{this.state.is_filtered? this.props.filtered_items.length: parts? parts.length: '' } Total</p></Col> 
                                    <Col> </Col>
                                </Row>
                                <Row>
                                <Col><p className='gray_text text-uppercase'>Ref No./Status</p></Col>
                                <Col><p className='gray_text text-uppercase'>Ship company/vessel</p></Col>
                                <Col><p className='gray_text text-uppercase'>Supplier/Warehouse</p></Col>
                                <Col><p className='gray_text text-uppercase'>Specs</p></Col>
                                </Row>
                                <div style={{overflow:'scroll',height:'70vh',padding:10}}>
                                {
                                    this.renderParts()
                                }
                                </div>

                            </Col>

                            <Col md="2" className="parts-filter">
                                <p>FILTER</p>

                                <Form onSubmit={this.search}>
                                    <Form.Group>
                                        <p className='gray_text  bold text-uppercase'>REFERENCE NO.</p>
                                        <Form.Control type="text" id="reference_no" value={reference_no} onChange={this.filterInput} placeholder="Enter Reference no." />
                                    </Form.Group>

                                   
                                    {vessels_success && this.state.select_vessels!=undefined  &&
                                        <Form.Group>
                                        <p className='gray_text  bold text-uppercase'>Vessels</p>
                                        <Select
                                        onChange={(e) => { this.setState({ vessel: `${e.value}` }) }}
                                        options={this.state.select_vessels}
                                        />
                                    </Form.Group>

                                    }
                                   
                            
                              <Form.Group>
                                    <p className='gray_text  bold text-uppercase'>Status</p>
                                    <Select
                                        onChange={(e) => { this.setState({ status: `${e.value}` }) }}
                                        options={states}
                                        isSearchable={false}
                                        />
                                </Form.Group>

                                    {suppliers_success && this.state.select_suppliers!=undefined && 
                                    <Form.Group>
                                        <p className='gray_text  bold text-uppercase'>Supplier</p>
                                        <Select
                                        onChange={(e) => { this.setState({ supplier: `${e.value}` }) }}
                                        options={this.state.select_suppliers}
                                        />
                                    </Form.Group>
                                   }
                                    
                                    <Button type="submit" className="btn btn-primary search-btn">Search</Button>
                                </Form>
                                
                            </Col>

                            {/* <AddPart show={addPartModal} onHide={() => { this.setState({ addPartModal: false }) }}
                                suppliers={all_suppliers} warehouses={all_warehouses} vessels={all_vessels} addPart={this.addPart} /> */}

                            <OpenPart part={part} show={open_item_modal} onHide={closeOpenedPart} />

                        </Row>
                    </Col>

                </Row>





            )
        

    }
}
const mapDispatchToProps = dispatch => {
    return {
        getAllPartsByWarehouse: payload => dispatch(getAllPartsByWarehouse(payload)),
        //addPart: payload => dispatch(addPart(payload)),
        getPart: payload => dispatch(getPart(payload)),
        closeOpenedPart: payload => dispatch(closeOpenedPart(payload)),
        getAllAdminVessels: payload => dispatch(getAllAdminVessels()),
        //deletePart: payload => dispatch(deletePart(payload)),

        //getVesselsByShip: payload => dispatch(getVesselsByShip(payload)),
        getAllAdminSuppliers: payload => dispatch(getAllAdminSuppliers()),
        //getAllAdminWarehouses: payload => dispatch(getAllAdminWarehouses()),

        filterPartsAdmin: payload => dispatch(filterPartsAdmin(payload)),
      
    }
}
const mapStateToProps = state => {
    return {
        parts: state.parts, part: state.part,
        filtered_items: state.filtered_items, filter_applied: state.filter_applied,
        open_item_modal: state.open_item_modal,
        all_suppliers: state.all_suppliers,all_vessels:state.all_vessels, 
        // all_warehouses: state.all_warehouses, all_vessels: state.all_vessels,
        vessels_success:state.vessels_success,
        //warehouses_success:state.warehouses_success,
        parts_success:state.parts_success, 
        suppliers_success:state.suppliers_success
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PartsWh);
