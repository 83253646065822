import React,{useEffect,useState} from 'react'
import MyInput from '../Forms/MyInput'
import MySelect from '../Forms/MySelect'
import Row from '../Layout/Row'
import MyModal from '../Modal/MyModal'
import {useFormik} from 'formik'
import {useMutation,useQueryClient} from 'react-query'
import Button from '../Forms/Button'
import { api_callback, deleteData, putData } from '../../globals'
import MyDatePicker from '../Forms/MyDatePicker'
var dateFormat = require('dateformat');
export default function OpenRecord({
    show,
    setShow,
    schema=[],
    data={},
    editMode,
    setEditMode,
    page,
    isFiltered,
    filterPage,
    filterUrl,
    queryString,
    fetchUrl,
    openRecordTitle,
    dbResponseSingular,
    timelinePart
}) {
    const [record, setRecord] = useState({})
//================================= EDIT RECORD ============================================== 
      const queryClient =  useQueryClient()
      const editRecordMutation = useMutation(queryString,(body,url)=>putData(body,`${fetchUrl}/${data?._id}`), {
        onSuccess: (data) => {
          setRecord(data?.data[dbResponseSingular][0])
          if(isFiltered){
            queryClient.invalidateQueries([`${queryString}filter`, filterPage,filterUrl])
        }else{
            queryClient.invalidateQueries([queryString,page])
          }
          api_callback(true,"Success",`${fetchUrl} Data Updated.`)
        },
      })
    useEffect(() =>setRecord(data), [data])

    const formik = useFormik({
        enableReinitialize:true,
        onSubmit: (body,{ resetForm }) => {
            let newbody = {...body}
            if(body["first_date"]!=null){
                let isoDate = new Date(body["first_date"])
                //isoDate.setUTCHours(0,0,0,0)
                newbody["first_date"]= isoDate.toISOString()
            }
            editRecordMutation.mutate(newbody)
        },
        initialValues: {...delKey(schema,data)},
    }
      );
 //================================= END EDIT RECORD ================================= 
    useEffect(() =>console.log(formik.values), [formik.values])
    useEffect(() =>formik.resetForm(), [editMode,show])

 //================================= DELETE RECORD ============================================== 
 const deleteRecordMutation = useMutation(queryString,(body,url)=>deleteData(body,`${fetchUrl}/${data?._id}`), {
    onSuccess: () => {
      queryClient.invalidateQueries([queryString,page])
      api_callback(true,"Success",`${fetchUrl} Deleted Succesfully`)
    },
    onError:(err)=>{
      queryClient.invalidateQueries([queryString,page])
    }
  })
  

 //================================= END DELETE RECORD ============================================== 


    return (
       <MyModal
       show={show}
       setShow={setShow}
       title={openRecordTitle}
       setEditMode={setEditMode}
       editMode={editMode}
       handleDelete={()=>{
        deleteRecordMutation.mutate()
    }}
       showButtons={true}
       >
        {editMode?
         <form onSubmit={formik.handleSubmit}>
         <Row style={{flexWrap:'wrap'}}>

         {schema.map(element=>{
             if(element.type=='select'){
                return  <div className='col-4'> 
                <MySelect 
                name={element.name}
                options={element.metadata?.options}
                label1={element?.metadata?.label1} 
                getUrl={element?.metadata?.getUrl} 
                dbResponse={element?.metadata?.dbResponse} 
                queryString={element?.metadata?.queryString} 
                label={element.label} 
                onChange={formik.setFieldValue} 
                error={formik.errors[element.name]}
                defaultValue={formik.initialValues[element.name]}
                hasAllOption={false}
                />
                </div>
             }else if(element.type=='input'){
                 return<div className='col-4'>
                     <MyInput
                     label={element.label} 
                     value={formik.values[element.name]}  
                     defaultValue={formik.initialValues[element.name]}
                     onChange={formik.handleChange} 
                     id={element.name} 
                     name={element.name} 
                     error={formik.errors[element.name]}
                     /> </div> 
             } else if(element.type=='date'){
                        return<div className='col-4'>
                            <MyDatePicker
                            label={element.label}
                            onChange={formik.setFieldValue}
                            name={element.name}
                            error={formik.errors[element.name]}
                            /> 
                            </div> 
                    }
         })}
       
        <div className='col-12 pt-4'>
         <Button text='Submit' isLoading={editRecordMutation.isLoading} type='submit' />
        </div>
        </Row>
        </form>
        :
        <div className='row col-12'>

        <div className={`row col-${timelinePart?'8':'12'}`}>
            {Array.isArray(schema) && schema.map(schemaRecord=>{
                return showValue(schemaRecord.label,schemaRecord.populate?record[schemaRecord.populate]:record[schemaRecord.name],schemaRecord.type,schemaRecord?.metadata?.label1)
            })}
        </div>
        {timelinePart?React.cloneElement(timelinePart,{_id:record.reference_no}) :null}
        </div>

        }
       </MyModal> 
    )
}


const showValue=(label,value,type='input',populateLabel='')=>{
    let val;
    if(type=='input'){
        val= <p className='black_text'>{value|| "-"}</p>
    }else if(type=='select'){
        val = <p className='black_text'>{Array.isArray(value) && value?.length>0 ? value[0][populateLabel] : value||"-"}</p>
    }else if(type=='date'){
        console.log("RFFF " +value)
        if(value!=undefined){
            val = <p className='black_text'>{dateFormat(value.toLocaleString(),'dd-mm-yyyy')}</p>
        }
    }
   return( <div className='col-6'>
        <p className='gray_text bold'>{label}</p>
        {val}
    </div>)
}

function delKey(arr,obj) {
    let myobj={}
    arr.map(item=>{
        if(item.name == getKeyByValue(obj,item.name) ) { myobj[item.name]=obj[item.name]}
    })
    return myobj
  }

  function getKeyByValue(object, value) {
    return Object.keys(object).find(key => key === value);
}