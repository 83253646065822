import React ,{useState,useEffect}from 'react'
import { Row, Col, Button ,Form} from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import MultiSelect from "react-multi-select-component";
import {fetchPaginatedData, getData, sort_by_field} from '../../globals'
import {useFormik} from 'formik'
import {useQuery, useQueryClient} from 'react-query'
import MySelect  from '../Forms/MySelect'
import MyDatePicker from '../Forms/MyDatePicker';
const states = [
    { value: 'Pending', label: 'Pending' },
    { value: 'In Stock', label: 'In Stock' },
    { value: 'Send', label: 'Send' },
    { value: 'Delivered', label: 'Delivered' },
];
var dateFormat = require('dateformat');

export default function FilterModule({
    reference_no,
    search,
    suppliers,
    warehouses,
    vessels,
    ships,
    changeHandler,
    selectChangeHandler,
    fromDate,
    toDate,
    selectParts=false,
    partOptions,
    hasCountry=false,
    hasStatus=false,
    hasFirstDate=false,
    country='',
    setFilteredRecords,
    setisFiltered,
    limit,
    filterPage,
    setfilterHasNext,
    hasDates=false,
    filterUrl,
    setfilterUrl,
    queryString,
    dbResponse,
    fetchUrl,
    setfilteredRecordsCount,
    setfilteredData,
    setFilterPage
}) {
    const [shippingCompany, setshippingCompany] = useState('')
    const [filteredVessels, setFilteredessels] = useState([])
    const [allVessels, setAllVessels] = useState([])
    const [filterObject, setfilterObject] = useState({})
    //Filtering Query
    const queryClient = useQueryClient()
    const { isLoading, data, error, isFetching, isPreviousData } = useQuery(
        [`${queryString}filter`, filterPage,filterUrl],
        () => fetchPaginatedData(filterUrl,filterPage,limit),
        {
        enabled:!!filterUrl,
        keepPreviousData: true, 
        onSuccess:(data)=>{
            setisFiltered(true)
            setFilteredRecords(data[dbResponse])
            setfilterHasNext(data.hasNext)
            setfilteredRecordsCount(data.count)
            setfilteredData(data)
        },
    })
    
   useEffect(() => {
    setFilteredessels(vessels)
    setAllVessels(vessels)
    },[vessels])

    useEffect(() => {
       if(String(shippingCompany?.label).toLowerCase()=='all'){
        setFilteredessels(allVessels)
       }else{
       if(filteredVessels){
        if(filteredVessels.length >0){
            let ves = allVessels.
            filter(v=>v.ship_id == shippingCompany._id)
            ves.unshift({value:'',label:'All',id:''})
            setFilteredessels(ves)
        }}
    }
    
    },[shippingCompany])

    const formik = useFormik({
        initialValues: {},
        onSubmit: (body) =>{
        let url = fetchUrl 
        let index=0
        Object.keys(body).map((key)=>{
            if(body[key]!=''){
            if(key=='startDate'||key=='endDate'|| key=="first_date"){
                let isoDate = new Date(body[key])
                isoDate.setUTCHours(0,0,0,0)
                body[key]= isoDate.toISOString()
            }
            
            url+=`${index==0? '?':'&'}${key}=${body[key]}`
            index+=1
            }
        })
        setfilterUrl(url)
        setFilterPage(0)
      }})
   
   

    return (
        <Col md="2" style={{alignSelf:'self-start'}} className="parts-filter" >
            <p>Filter</p>
        <Form onSubmit={formik.handleSubmit}>

                <MySelect
                 isMulti={true}
                 useLabelForMulti={true}
                 onChange={formik.setFieldValue}
                 name='reference_no'
                 getUrl='part'
                 queryString='adminparts'
                 label='Reference No.'
                 dbResponse='parts'
                 label1='reference_no'
                 onChange={formik.setFieldValue}
                 sortField='reference_no'
                 value={formik.values.reference_no}
                />
               
             
                <MySelect
                onChange={formik.setFieldValue}
                name='ship_id'
                getUrl='ship'
                queryString='adminships'
                label='Shipping Company'
                sortField='shipping_company_name'
                label1='shipping_company_name'
                />
   
                <MySelect
                isMulti={true}
                useLabelForMulti={false}
                onChange={formik.setFieldValue}
                name='vessel_id'
                getUrl={formik.values?.ship_id? `vessel/ship/${formik.values?.ship_id}`:`vessel`}
                queryString='adminvessels'
                label='Vessels'
                dbResponse='vessels'
                sortField='vessel'
                label1='vessel'
                />

                <MySelect
                isMulti={true}
                useLabelForMulti={false}
                onChange={formik.setFieldValue}
                name='warehouse_id'
                getUrl='warehouse'
                queryString='adminwarehouses'
                label='Warehouses'
                sortField='forwarding_company'
                label1='forwarding_company'
                />

                <MySelect
                isMulti={true}
                useLabelForMulti={true}
                onChange={formik.setFieldValue}
                name='status'
                label='Status'
                options={states}
                />

                <MySelect
                getUrl='supplier'
                onChange={formik.setFieldValue}
                name='supplier_id'
                queryString='adminsuppliers'
                label='Suppliers'
                sortField='supplier_name'
                label1='supplier_name'
                />

                {hasCountry &&
                <Form.Group>
                <p className='gray_text'>Country</p>
                <Form.Control id="country" value={country} onChange={changeHandler} placeholder="Enter Country" />
                </Form.Group>
                }
               
                {hasFirstDate &&
                <MyDatePicker
                label='Order Date Placement'
                onChange={formik.setFieldValue}
                name='first_date'
                /> 
                }
                {hasDates && 
                <>
                <MyDatePicker
                label='Start Date'
                onChange={formik.setFieldValue}
                name='startDate'
                /> 
                <MyDatePicker
                label='End Date'
                onChange={formik.setFieldValue}
                name='endDate'
                /> 

                </>
                }

         <Button  type="submit" className="btn btn-primary search-btn">{isLoading?"Loading...":"Search"}</Button>
        </Form>
    </Col>
    )
}
