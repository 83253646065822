import React,{useEffect} from 'react'
import { Row, Col } from 'react-bootstrap';
import UpperCard from './UpperCard';
import Navbar from '../Common/Navbar';
import { useQuery } from 'react-query';
import { getData } from '../../globals';
import TransList from './TransList';

export default function HomeAdmin() {

    const { isLoading, data, error, isFetching, isPreviousData } = useQuery(
        ['adminhome'],
        () => getData('admin/home'),
      )

    useEffect(()=>{
        if(localStorage.getItem("loginState")=="false" && localStorage.getItem("admin_login")=="false"){
            this.props.history.push("/admin/");
          }
    },[])

  return (
    <Row>
        <Navbar active="home" />
        <Col>
        <Row className="top-header">
        </Row>
            <Row className="main-top">
                <UpperCard isLoading={isLoading} title={'Pending Parts'} length={data?.pendingLength} />
                <UpperCard isLoading={isLoading} title={'In Stock Parts'} length={data?.inStockLength} />
                <UpperCard isLoading={isLoading} title={'Send Parts'} length={data?.sendLength} />
                <UpperCard isLoading={isLoading} title={'Delivered Parts'} length={data?.deliveredLength} />
            </Row>
        
            <Row className="main-top">
                <TransList 
                    title='Pending'
                    data={data?.pending}
                    cssClass={'bottom-box'}
                />
                 <TransList 
                    title='In Stock'
                    data={data?.instock}
                    cssClass={'bottom-box-instock'}
                />
                 <TransList 
                    title='Send'
                    data={data?.send}
                    cssClass={'bottom-box-send'}
                />
                 <TransList 
                    title='Delivered'
                    data={data?.delivered}
                    cssClass={'bottom-box-delivered'}
                />
                
            </Row>

        </Col>

    </Row>
  )
}
