import React from "react";
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';


const initialState = { email:'',password:'',fullname:'',validated: false };

class AddAdmin extends React.Component {
    constructor(props) {
        super(props);
        this.state = { email:'',password:'',fullname:'',validated: false };


        this.handleChange = this.handleChange.bind(this);
    }
    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    }

    addAdmin = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        event.preventDefault();
        this.setState({ validated: true })
        if (form.checkValidity() === true) {
            this.props.addAdmin(this.state);
            this.setState(initialState)
        }

    }

    onHide = () => {
        this.setState(initialState)
        this.props.onHide()
    }

   
    render() {
        const { show } = this.props;


        const { password, email,fullname, validated } = this.state;
        return (
            <Modal
                show={show}
                onHide={this.onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <h4>Add an Admin</h4>
                    <Form noValidate validated={validated} onSubmit={this.addAdmin}>

                        <Form.Row>

                             <Form.Group className='col-6' >
                                <p className='gray_text  bold text-uppercase'>Full Name</p>
                                <Form.Control
                                    id="fullname" value={fullname} onChange={this.handleChange} placeholder="Enter Full Name" required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide Name.
                            </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className='col-6' >
                                <p className='gray_text  bold text-uppercase'>EMAIL</p>
                                <Form.Control
                                    id="email" value={email} onChange={this.handleChange} placeholder="Enter Email" required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide email.
                            </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className='col-6' >
                                <p className='gray_text  bold text-uppercase'>PASSWORD</p>
                                <Form.Control
                                    id="password" placeholder='Enter Password' value={password} onChange={this.handleChange} placeholder="Enter password" required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide password.
                            </Form.Control.Feedback>
                            </Form.Group>

                           
                        </Form.Row>




                        <Button type="submit" className="btn btn-primary col-3 mt-3">Done</Button>
                    </Form>
                </Modal.Body>
            </Modal>
        );

    }

}


export default AddAdmin;
