import { call, takeEvery, put,select } from 'redux-saga/effects';
import {USER_LOGIN_REQUEST,USER_LOGIN_SUCCESS,USER_LOGIN_FAILURE,
    
    USER_SIGNUP_REQUEST,USER_SIGNUP_SUCCESS,USER_SIGNUP_FAILURE,

    GET_ALL_PARTS_BY_SHIP_REQUEST, GET_ALL_PARTS_BY_SHIP_SUCCESS, GET_ALL_PARTS_BY_SHIP_FAILURE,
    GET_PART_TRANSACTIONS_REQUEST, GET_PART_TRANSACTIONS_SUCCESS, GET_PART_TRANSACTIONS_FAILURE,

    // warehouse actions
    WAREHOUSE_LOGIN_REQUEST,WAREHOUSE_LOGIN_SUCCESS,WAREHOUSE_LOGIN_FAILURE,
    GET_ALL_PARTS_BY_WH_REQUEST,GET_ALL_PARTS_BY_WH_SUCCESS,GET_ALL_PARTS_BY_WH_FAILURE,
    GET_ALL_TRANSACTIONS_BY_WH_REQUEST,GET_ALL_TRANSACTIONS_BY_WH_SUCCESS,GET_ALL_TRANSACTIONS_BY_WH_FAILURE,

    // user accomulative transactions of all parts
    GET_ALL_TRANSACTIONS_BY_SHIP_REQUEST,GET_ALL_TRANSACTIONS_BY_SHIP_SUCCESS,GET_ALL_TRANSACTIONS_BY_SHIP_FAILURE,
    DELETE_PART_REQUEST,DELETE_PART_SUCCESS,DELETE_PART_FAILURE,
    EDIT_PART_REQUEST,EDIT_PART_SUCCESS,EDIT_PART_FAILURE,

    GET_PARTNERS_REQUEST,GET_PARTNERS_SUCCESS,GET_PARTNERS_FAILURE,

    


    GET_ALL_SUPPLIERS_REQUEST,GET_ALL_SUPPLIERS_SUCCESS,GET_ALL_SUPPLIERS_FAILURE,
    GET_ALL_WAREHOUSE_REQUEST,GET_ALL_WAREHOUSE_SUCCESS,GET_ALL_WAREHOUSE_FAILURE,

    ADD_PART_REQUEST, ADD_PART_SUCCESS, ADD_PART_FAILURE,
    GET_PART_REQUEST, GET_PART_SUCCESS, GET_PART_FAILURE,

    GET_VESSELS_BY_SHIP_REQUEST,GET_VESSELS_BY_SHIP_SUCCESS,GET_VESSELS_BY_SHIP_FAILURE,

// admin parts

// parts
GET_ALL_ADMIN_PARTS_REQUEST,GET_ALL_ADMIN_PARTS_SUCCESS,GET_ALL_ADMIN_PARTS_FAILURE,



// ships
GET_ALL_ADMIN_SHIP_REQUEST,GET_ALL_ADMIN_SHIP_SUCCESS,GET_ALL_ADMIN_SHIP_FAILURE,
ADD_SHIP_REQUEST,ADD_SHIP_SUCCESS,ADD_SHIP_FAILURE,
GET_SHIP_REQUEST, GET_SHIP_SUCCESS, GET_SHIP_FAILURE,
EDIT_SHIP_REQUEST, EDIT_SHIP_SUCCESS, EDIT_SHIP_FAILURE,
DELETE_SHIP_REQUEST, DELETE_SHIP_SUCCESS, DELETE_SHIP_FAILURE,

// vessels
GET_ALL_ADMIN_VESSELS_REQUEST,GET_ALL_ADMIN_VESSELS_SUCCESS,GET_ALL_ADMIN_VESSELS_FAILURE,
ADD_VESSEL_REQUEST,ADD_VESSEL_SUCCESS,ADD_VESSEL_FAILURE,
GET_VESSEL_REQUEST, GET_VESSEL_SUCCESS, GET_VESSEL_FAILURE,
EDIT_VESSEL_REQUEST, EDIT_VESSEL_SUCCESS, EDIT_VESSEL_FAILURE,
DELETE_VESSEL_REQUEST, DELETE_VESSEL_SUCCESS, DELETE_VESSEL_FAILURE,

// transactions
GET_ALL_TRANSACTIONS_REQUEST,GET_ALL_TRANSACTIONS_SUCCESS,GET_ALL_TRANSACTIONS_FAILURE,
ADD_TRANSACTION_REQUEST,ADD_TRANSACTION_SUCCESS,ADD_TRANSACTION_FAILURE,
GET_TRANSACTION_REQUEST,GET_TRANSACTION_SUCCESS,GET_TRANSACTION_FAILURE,
DEL_TRANSACTION_REQUEST,DEL_TRANSACTION_SUCCESS,DEL_TRANSACTION_FAILURE,
EDIT_TRANSACTION_REQUEST,EDIT_TRANSACTION_SUCCESS,EDIT_TRANSACTION_FAILURE,

//user
GET_ALL_ADMIN_USER_REQUEST,GET_ALL_ADMIN_USER_SUCCESS,GET_ALL_ADMIN_USER_FAILURE,GET_ADMIN_FILTERED_USER,
ADD_USER_REQUEST,ADD_USER_SUCCESS,ADD_USER_FAILURE,
GET_USER_REQUEST,GET_USER_SUCCESS,GET_USER_FAILURE,
EDIT_USER_REQUEST,EDIT_USER_SUCCESS,EDIT_USER_FAILURE,
DELETE_USER_REQUEST,DELETE_USER_SUCCESS,DELETE_USER_FAILURE,

// suppliers
GET_ALL_ADMIN_SUPPLIERS_REQUEST,GET_ALL_ADMIN_SUPPLIERS_SUCCESS,GET_ALL_ADMIN_SUPPLIERS_FAILURE,
ADD_SUPPLIER_REQUEST,ADD_SUPPLIER_SUCCESS,ADD_SUPPLIER_FAILURE,
GET_SUPPLIER_REQUEST,GET_SUPPLIER_SUCCESS,GET_SUPPLIER_FAILURE,
EDIT_SUPPLIER_REQUEST,EDIT_SUPPLIER_SUCCESS,EDIT_SUPPLIER_FAILURE,
DELETE_SUPPLIER_REQUEST,DELETE_SUPPLIER_SUCCESS,DELETE_SUPPLIER_FAILURE,


// warehouses
GET_ALL_ADMIN_WAREHOUSE_REQUEST,GET_ALL_ADMIN_WAREHOUSE_SUCCESS,GET_ALL_ADMIN_WAREHOUSE_FAILURE,
ADD_WAREHOUSE_REQUEST,ADD_WAREHOUSE_SUCCESS,ADD_WAREHOUSE_FAILURE,
GET_WAREHOUSE_REQUEST,GET_WAREHOUSE_SUCCESS,GET_WAREHOUSE_FAILURE,
EDIT_WAREHOUSE_REQUEST,EDIT_WAREHOUSE_SUCCESS,EDIT_WAREHOUSE_FAILURE,
DELETE_WAREHOUSE_REQUEST,DELETE_WAREHOUSE_SUCCESS,DELETE_WAREHOUSE_FAILURE,


// admins - settings section
GET_ALL_ADMINS_REQUEST,GET_ALL_ADMINS_SUCCESS,GET_ALL_ADMINS_FAILURE,
ADD_ADMIN_REQUEST,ADD_ADMIN_SUCCESS,ADD_ADMIN_FAILURE,
GET_ADMIN_REQUEST,GET_ADMIN_SUCCESS,GET_ADMIN_FAILURE,
EDIT_ADMIN_REQUEST,EDIT_ADMIN_SUCCESS,EDIT_ADMIN_FAILURE,
DELETE_ADMIN_REQUEST,DELETE_ADMIN_SUCCESS,DELETE_ADMIN_FAILURE,

ADMIN_LOGIN_REQUEST,ADMIN_LOGIN_SUCCESS,ADMIN_LOGIN_FAILURE,





} from '../constants/action-types'
import API from '../../services/index'




function* handleUserLogin(action) {
    
    try {
        const login = yield call(API.loginUser, action.payload);
        yield put({type: USER_LOGIN_SUCCESS, payload: login})
    }
    catch (error) {
        yield put({type: USER_LOGIN_FAILURE})
    }
}

function* handleUserSignup(action) {

    try {
        const signup = yield call(API.signupUser, action.payload);
        yield put({type: USER_SIGNUP_SUCCESS, payload: signup})
    }
    catch (error) {
        yield put({type: USER_SIGNUP_FAILURE})
    }
}

function* handleGetAllPartsByShip(action) {
    try {
        const parts = yield call(API.getPartsByShip, action.payload.id);
        yield put({type: GET_ALL_ADMIN_PARTS_SUCCESS, payload: parts})
    }
    catch (error) {
        yield put({type: GET_ALL_ADMIN_PARTS_FAILURE})
    }
}

function* handleGetAllPartsByWh(action) {
    try {
        const parts = yield call(API.getPartsByWh, action.payload.id);
        yield put({type: GET_ALL_ADMIN_PARTS_SUCCESS, payload: parts})
    }
    catch (error) {
        yield put({type: GET_ALL_ADMIN_PARTS_FAILURE})
    }
}


function* handleAllTransactionByShip(action) {
    try {
        const trans = yield call(API.transByShip, action.payload.ship_id);
        yield put({type: GET_ALL_TRANSACTIONS_SUCCESS, payload: trans})
  
    }
    catch (error) {
        yield put({type: GET_ALL_TRANSACTIONS_FAILURE})
    }
}
function* handleAllTransactionByWh(action) {
    try {
        const trans = yield call(API.transByWarehouse, action.payload.warehouse_id);
        yield put({type: GET_ALL_TRANSACTIONS_SUCCESS, payload: trans})
  
    }
    catch (error) {
        yield put({type: GET_ALL_TRANSACTIONS_FAILURE})
    }
}

// add part section
function* handleGetAllVesselsByShip(action) {
    try {
        const vessels = yield call(API.getVesselsByShip, action.payload.id);
        yield put({type: GET_VESSELS_BY_SHIP_SUCCESS, payload: vessels})
    }
    catch (error) {
        yield put({type: GET_VESSELS_BY_SHIP_FAILURE})
    }
}


function* handleGetAllSuppliers(action) {
    try {
        const suppliers = yield call(API.getAllSuppliers);
        yield put({type: GET_ALL_SUPPLIERS_SUCCESS, payload: suppliers})
    }
    catch (error) {
        yield put({type: GET_ALL_SUPPLIERS_FAILURE})
    }
}


function* handleGetAllWarehouse(action) {
    try {
        const warehouse = yield call(API.getAllWarehouse);
        yield put({type: GET_ALL_WAREHOUSE_SUCCESS, payload: warehouse})
    }
    catch (error) {
        yield put({type: GET_ALL_WAREHOUSE_FAILURE})
    }
}



// admin sagas

// get all parts
function* adminAllParts(action) {
  
    try {
        const parts = yield call(API.adminGetAllParts);
        yield put({type: GET_ALL_ADMIN_PARTS_SUCCESS, payload: parts})
  
    }
    catch (error) {
        yield put({type: GET_ALL_ADMIN_PARTS_FAILURE})
    }
}

function* adminPartTransactions(action) {
    try {
        const trans = yield call(API.adminPartTransactions, action.payload.reference_no);
        yield put({type: GET_PART_TRANSACTIONS_SUCCESS, payload: trans})
  
    }
    catch (error) {
        yield put({type: GET_PART_TRANSACTIONS_FAILURE})
    }
}

function* adminDeletePart(action) {
    try {
        const part = yield call(API.adminDeletePart, action.payload.id);
        yield put({type: DELETE_PART_SUCCESS, payload:part})
      
    
        let user = JSON.parse(localStorage.getItem("user"))
        let warehouse = JSON.parse(localStorage.getItem("warehouse"))

        if(user && user !="false"){
            yield put({type: GET_ALL_PARTS_BY_SHIP_REQUEST, payload:{id:user.ship_id}})
            
        }
        else if(warehouse && warehouse!="false"){
            yield put({type: GET_ALL_PARTS_BY_WH_REQUEST, payload:{id:warehouse.warehouse_id}})
        }
        else{
            yield put({type: GET_ALL_ADMIN_PARTS_REQUEST})
        }
     
    }
    
    catch (error) {
        yield put({type: DELETE_PART_FAILURE})
    }
}

function* adminEditPart(action) {
    try {
        const part = yield call(API.adminEditPart,action.payload);
        yield put({type: EDIT_PART_SUCCESS, payload: part})
        let user = JSON.parse(localStorage.getItem("user"))
        let warehouse = JSON.parse(localStorage.getItem("warehouse"))
        if(user && user !="false"){
            yield put({type: GET_ALL_PARTS_BY_SHIP_REQUEST, payload:{id:user.ship_id}})
            
        }
        else if(warehouse && warehouse!="false"){
            yield put({type: GET_ALL_PARTS_BY_WH_REQUEST, payload:{id:warehouse.warehouse_id}})
        }
        else{
            yield put({type: GET_ALL_ADMIN_PARTS_REQUEST})
        }
   
  
    }
    catch (error) {
        yield put({type: EDIT_PART_FAILURE})
    }
}

function* adminAllPartners(action) {
    try {
        const partners = yield call(API.adminAllPartners);
        yield put({type: GET_PARTNERS_SUCCESS, payload: partners})
  
    }
    catch (error) {
        yield put({type: GET_PARTNERS_FAILURE})
    }
}

function* handleAddPart(action) {
    try {
        const part = yield call(API.addPart,action.payload);
        yield put({type: ADD_PART_SUCCESS, payload: part})
        let user = JSON.parse(localStorage.getItem("user"))
        let warehouse = JSON.parse(localStorage.getItem("warehouse"))
        if(user && user !="false"){
            yield put({type: GET_ALL_PARTS_BY_SHIP_REQUEST, payload:{id:user.ship_id}})
            
        }
        else if(warehouse && warehouse!="false"){
            yield put({type: GET_ALL_PARTS_BY_WH_REQUEST, payload:{id:warehouse.warehouse_id}})
        }
        else{
            yield put({type: GET_ALL_ADMIN_PARTS_REQUEST})
        }
       
    }
    catch (error) {
        yield put({type: ADD_PART_FAILURE})
    }
}

function* handleGetPart(action) {
    try {
        const part = yield call(API.getPartById , action.payload.id);
        yield put({type: GET_PART_TRANSACTIONS_REQUEST, payload: {reference_no: part.part.reference_no}})
        yield put({type: GET_PART_SUCCESS, payload: part})
  
    }
    catch (error) {
        yield put({type: GET_PART_FAILURE})
    }
}


// get all ships 
function* adminAllShips(action) {
    try {
        const ships = yield call(API.adminGetAllShips);
        yield put({type: GET_ALL_ADMIN_SHIP_SUCCESS, payload: ships})
  
    }
    catch (error) {
        yield put({type: GET_ALL_ADMIN_SHIP_FAILURE})
    }
}

function* adminAddShip(action) {
    try {
        const ship = yield call(API.adminAddShip,action.payload);
        yield put({type: ADD_SHIP_SUCCESS, payload: ship})
        yield put({type: GET_ALL_ADMIN_SHIP_REQUEST})
  
    }
    catch (error) {
        yield put({type: ADD_SHIP_FAILURE})
       
    }
}

function* adminGetShip(action) {
    try { 
        const transaction = yield call(API.adminGetShip,action.payload.id);
        yield put({type: GET_SHIP_SUCCESS, payload: transaction})
  
    }
    catch (error) {
        yield put({type: GET_SHIP_FAILURE})
    }
}

function* adminEditShip(action) {
    try {
        const ship = yield call(API.adminEditShip,action.payload);
        yield put({type: EDIT_SHIP_SUCCESS, payload: ship});
        yield put({type: GET_ALL_ADMIN_SHIP_REQUEST});
  
    }
    catch (error) {
        yield put({type: EDIT_SHIP_FAILURE})
    }
}

function* adminDeleteShip(action) {
    try {
        const ship = yield call(API.adminDeleteShip,action.payload.id);
        yield put({type: DELETE_SHIP_SUCCESS, payload: ship})
        yield put({type: GET_ALL_ADMIN_SHIP_REQUEST})
       
  
    }
    catch (error) {
        yield put({type: DELETE_SHIP_FAILURE})
    }
}



// get all vessels 
function* adminAllVessels(action) {
    try {
        const vessels = yield call(API.adminGetAllVessels);
        yield put({type: GET_ALL_ADMIN_VESSELS_SUCCESS, payload: vessels})
  
    }
    catch (error) {
        yield put({type: GET_ALL_ADMIN_VESSELS_FAILURE})
    }
}

function* adminAddVessel(action) {
    try {
        const vessel = yield call(API.adminAddVessel,action.payload);
        yield put({type: ADD_VESSEL_SUCCESS, payload: vessel})
        yield put({type: GET_ALL_ADMIN_VESSELS_REQUEST})
        
    }
    catch (error) {
        yield put({type: ADD_VESSEL_FAILURE})
    }
}

function* adminGetVessel(action) {
    try { 
        const vessel = yield call(API.adminGetVessel,action.payload.id);
        yield put({type: GET_VESSEL_SUCCESS, payload: vessel})
  
    }
    catch (error) {
        yield put({type: GET_VESSEL_FAILURE})
    }
}


function* adminDeleteVessel(action) {
    try {
        const vessel = yield call(API.adminDeleteVessel,action.payload.id);
        yield put({type: DELETE_VESSEL_SUCCESS, payload: vessel});
        yield put({type: GET_ALL_ADMIN_VESSELS_REQUEST});
  
    }
    catch (error) {
        yield put({type: DELETE_VESSEL_FAILURE})
    }
}

function* adminEditVessel(action) {
    try {
        const vessel = yield call(API.adminEditVessel,action.payload);
        yield put({type: EDIT_VESSEL_SUCCESS, payload: vessel});
        yield put({type: GET_ALL_ADMIN_VESSELS_REQUEST});
  
    }
    catch (error) {
        yield put({type: EDIT_VESSEL_FAILURE})
    }
}

// users


function* adminAllUser(action) {
    try {
        const user = yield call(API.adminAllUser);
        yield put({type: GET_ALL_ADMIN_USER_SUCCESS, payload: user})
  
    }
    catch (error) {
        yield put({type: GET_ALL_ADMIN_USER_FAILURE})
    }
}

function* adminAddUser(action) {
    try {
        const user = yield call(API.adminAddUser,action.payload);
        yield put({type: ADD_USER_SUCCESS, payload: user})
        yield put({type: GET_ALL_ADMIN_USER_REQUEST})
        
    }
    catch (error) {
        yield put({type: ADD_USER_FAILURE})
        yield put({type: GET_ALL_ADMIN_USER_REQUEST})
    }
}

function* adminGetUser(action) {
    try { 
        const user = yield call(API.adminGetUser,action.payload.id);
        yield put({type: GET_USER_SUCCESS, payload: user})
  
    }
    catch (error) {
        yield put({type: GET_USER_FAILURE})
    }
}


function* adminDeleteUser(action) {
    try {
        const user = yield call(API.adminDeleteUser,action.payload.id);
        yield put({type: DELETE_USER_SUCCESS, payload: user});
        yield put({type: GET_ALL_ADMIN_USER_REQUEST});
  
    }
    catch (error) {
        yield put({type: DELETE_USER_FAILURE})
    }
}

function* adminEditUser(action) {
    try {
        let userSt = JSON.parse(localStorage.getItem("user"))
        let warehouse = JSON.parse(localStorage.getItem("warehouse"))

        const user = yield call(API.adminEditUser,action.payload);
        yield put({type: EDIT_USER_SUCCESS, payload: user});


        if(userSt && userSt !="false"){
            yield put({type: GET_USER_REQUEST, payload: {id:userSt._id}});
            
        }
        else if(warehouse && warehouse !="false"){
            yield put({type: GET_USER_REQUEST, payload: {id:warehouse._id}});
            
        }
        else{
            yield put({type: GET_ALL_ADMIN_USER_REQUEST});
        }
       
    }
    catch (error) {
        yield put({type: EDIT_USER_FAILURE})
    }
}

// get all transactions

function* adminAllTransactions(action) {
    try {
        const transactions = yield call(API.adminAllTransactions);
        yield put({type: GET_ALL_TRANSACTIONS_SUCCESS, payload: transactions})
  
    }
    catch (error) {
        yield put({type: GET_ALL_TRANSACTIONS_FAILURE})
    }
}

function* adminGetTransactionById(action) {
    try { 
        const transaction = yield call(API.getTransactionById,action.payload.id);
        yield put({type: GET_TRANSACTION_SUCCESS, payload: transaction})
  
    }
    catch (error) {
        yield put({type: GET_TRANSACTION_FAILURE})
    }
}

function* adminAddTransaction(action) {
    try {
        const transaction = yield call(API.adminAddTransaction,action.payload,action.callback);
        yield put({type: ADD_TRANSACTION_SUCCESS, payload: transaction})
        /*
        let user = JSON.parse(localStorage.getItem("user"))
        let warehouse = JSON.parse(localStorage.getItem("warehouse"))
        let admin = JSON.parse(localStorage.getItem("admin_login"))
        
        if(user && user !="false"){
            yield put({type: GET_ALL_TRANSACTIONS_BY_SHIP_REQUEST, payload:{ship_id:user.ship_id}})
            yield put({type: GET_ALL_PARTS_BY_SHIP_REQUEST, payload:{id:user.ship_id}})
            
        }
        else if(warehouse && warehouse !="false"){
            yield put({type: GET_ALL_TRANSACTIONS_BY_WH_REQUEST, payload:{warehouse_id:warehouse.warehouse_id}})
            yield put({type: GET_ALL_PARTS_BY_WH_REQUEST, payload:{id:warehouse.warehouse_id}})
            
        }
        if(admin && admin !='false'){
            //yield put({type: GET_ALL_TRANSACTIONS_REQUEST})
            //syield put({type: GET_ALL_ADMIN_PARTS_REQUEST})
        }
        */
        
  
    }
    catch (error) {
        yield put({type: ADD_TRANSACTION_FAILURE})
    }
}
function* adminDeleteTransaction(action) {
    try {
        const transaction = yield call(API.deleteTransaction,action.payload.id,action.callback);
        yield put({type: DEL_TRANSACTION_SUCCESS, payload: transaction});
        let user = JSON.parse(localStorage.getItem("user"))
        let warehouse = JSON.parse(localStorage.getItem("warehouse"))
        let admin = JSON.parse(localStorage.getItem("admin_login"))

        if(user && user !="false"){
            yield put({type: GET_ALL_TRANSACTIONS_BY_SHIP_REQUEST, payload:{ship_id:user.ship_id}})
           
            
        }
        else if(warehouse && warehouse !="false"){
            yield put({type: GET_ALL_TRANSACTIONS_BY_WH_REQUEST, payload:{warehouse_id:warehouse.warehouse_id}})
           
            
        }
        if(admin && admin !='false'){
            //yield put({type: GET_ALL_TRANSACTIONS_REQUEST});
        }
  
    }
    catch (error) {
        yield put({type: DEL_TRANSACTION_FAILURE})
    }
}

function* adminEditTransaction(action) {
    try {
        const transaction = yield call(API.editTransaction,action.payload,action.callback);
        yield put({type: EDIT_TRANSACTION_SUCCESS, payload: transaction});

        let user = JSON.parse(localStorage.getItem("user"))
        let warehouse = JSON.parse(localStorage.getItem("warehouse"))
        
        if(user && user !="false"){
            yield put({type: GET_ALL_TRANSACTIONS_BY_SHIP_REQUEST, payload:{ship_id:user.ship_id}})
           
            
        }
        else if(warehouse && warehouse !="false"){
            yield put({type: GET_ALL_TRANSACTIONS_BY_WH_REQUEST, payload:{warehouse_id:warehouse.warehouse_id}})
           
            
        }
        else{
            //yield put({type: GET_ALL_TRANSACTIONS_REQUEST});
        }
      
  
    }
    catch (error) {
        yield put({type: EDIT_TRANSACTION_FAILURE})
    }
}

// suppliers
function* adminAllSuppliers(action) {
    try {
        const suppliers = yield call(API.adminGetAllSuppliers);
        yield put({type: GET_ALL_ADMIN_SUPPLIERS_SUCCESS, payload: suppliers})
  
    }
    catch (error) {
        yield put({type: GET_ALL_ADMIN_SUPPLIERS_FAILURE})
    }
}

function* adminAddSupplier(action) {
    try {
        const sp = yield call(API.adminAddSupplier,action.payload);
        yield put({type: ADD_SUPPLIER_SUCCESS, payload: sp})
        yield put({type: GET_ALL_ADMIN_SUPPLIERS_REQUEST})
        
    }
    catch (error) {
        yield put({type: ADD_SUPPLIER_FAILURE})
    }
}

function* adminGetSupplier(action) {
    try { 
        const sp = yield call(API.adminGetSupplier,action.payload.id);
        yield put({type: GET_SUPPLIER_SUCCESS, payload: sp})
  
    }
    catch (error) {
        yield put({type: GET_SUPPLIER_FAILURE})
    }
}


function* adminDeleteSupplier(action) {
    try {
        const sp = yield call(API.adminDeleteSupplier,action.payload.id);
        yield put({type: DELETE_SUPPLIER_SUCCESS, payload: sp});
        yield put({type: GET_ALL_ADMIN_SUPPLIERS_REQUEST});
  
    }
    catch (error) {
        yield put({type: DELETE_SUPPLIER_FAILURE})
    }
}

function* adminEditSupplier(action) {
    try {
        const sp = yield call(API.adminEditSupplier,action.payload);
        yield put({type: EDIT_SUPPLIER_SUCCESS, payload: sp});
        yield put({type: GET_ALL_ADMIN_SUPPLIERS_REQUEST});
  
    }
    catch (error) {
        yield put({type: EDIT_SUPPLIER_FAILURE})
    }
}

// warehouses
function* adminAllWarehouses(action) {
    try {
        const parts = yield call(API.adminGetAllWarehouses);
        yield put({type: GET_ALL_ADMIN_WAREHOUSE_SUCCESS, payload: parts})
  
    }
    catch (error) {
        yield put({type: GET_ALL_ADMIN_WAREHOUSE_FAILURE})
    }
}

function* adminAddWarehouse(action) {
    try {
        const wh = yield call(API.adminAddWarehouse,action.payload);
        yield put({type: ADD_WAREHOUSE_SUCCESS, payload: wh})
        yield put({type: GET_ALL_ADMIN_WAREHOUSE_REQUEST})
        
    }
    catch (error) {
        yield put({type: ADD_WAREHOUSE_FAILURE})
    }
}

function* adminGetWarehouse(action) {
    try { 
        const wh = yield call(API.adminGetWarehouse,action.payload.id);
        yield put({type: GET_WAREHOUSE_SUCCESS, payload: wh})
  
    }
    catch (error) {
        yield put({type: GET_WAREHOUSE_FAILURE})
    }
}


function* adminDeleteWarehouse(action) {
    try {
        const wh = yield call(API.adminDeleteWarehouse,action.payload.id);
        yield put({type: DELETE_WAREHOUSE_SUCCESS, payload: wh});
        yield put({type: GET_ALL_ADMIN_WAREHOUSE_REQUEST});
  
    }
    catch (error) {
        yield put({type: DELETE_WAREHOUSE_FAILURE})
    }
}

function* adminEditWarehouse(action) {
    try {
        const wh = yield call(API.adminEditWarehouse,action.payload);
        yield put({type: EDIT_WAREHOUSE_SUCCESS, payload: wh});
        yield put({type: GET_ALL_ADMIN_WAREHOUSE_REQUEST});
  
    }
    catch (error) {
        yield put({type: EDIT_WAREHOUSE_FAILURE})
    }
}

// settings - admin

function* getAllAdmins(action) {
    try {
        const admins = yield call(API.getAllAdmins);
        yield put({type: GET_ALL_ADMINS_SUCCESS, payload: admins})
  
    }
    catch (error) {
        yield put({type: GET_ALL_ADMINS_FAILURE})
    }
}

function* addAdmin(action) {
    try {
        const admin = yield call(API.addAdmin,action.payload);
        yield put({type: ADD_ADMIN_SUCCESS, payload: admin})
        yield put({type: GET_ALL_ADMINS_REQUEST})
        
    }
    catch (error) {
        yield put({type: ADD_ADMIN_FAILURE})
    }
}

function* getAdmin(action) {
    try { 
        const admin = yield call(API.getAdmin,action.payload.id);
        yield put({type: GET_ADMIN_SUCCESS, payload: admin})
  
    }
    catch (error) {
        yield put({type: GET_ADMIN_FAILURE})
    }
}


function* deleteAdmin(action) {
    try {
        const admin = yield call(API.deleteAdmin,action.payload.id);
        yield put({type: DELETE_ADMIN_SUCCESS, payload: admin});
        yield put({type: GET_ALL_ADMINS_REQUEST});
  
    }
    catch (error) {
        yield put({type: DELETE_ADMIN_FAILURE})
    }
}

function* editAdmin(action) {
    try {
        const admin = yield call(API.editAdmin,action.payload);
        yield put({type: EDIT_ADMIN_SUCCESS, payload: admin});
        yield put({type: GET_ALL_ADMINS_REQUEST});
  
    }
    catch (error) {
        yield put({type: EDIT_ADMIN_FAILURE})
    }
}

function* adminLogin(action) {
   
    try {
        const admin = yield call(API.adminLogin,action.payload);
        yield put({type: ADMIN_LOGIN_SUCCESS, payload: admin});
    }
    catch (error) {
        yield put({type: ADMIN_LOGIN_FAILURE})
    }
}

// warehouse sagas

// warehouse sagas

function* warehouseLogin(action) {
    
    try {
        const login = yield call(API.warehouseLogin, action.payload);
        yield put({type: WAREHOUSE_LOGIN_SUCCESS, payload: login})
    }
    catch (error) {
        yield put({type: WAREHOUSE_LOGIN_FAILURE})
    }
}
function* rootSaga() {
    yield takeEvery(USER_LOGIN_REQUEST, handleUserLogin);
    yield takeEvery(USER_SIGNUP_REQUEST, handleUserSignup);
    yield takeEvery(GET_ALL_PARTS_BY_SHIP_REQUEST, handleGetAllPartsByShip);
    yield takeEvery(GET_ALL_PARTS_BY_WH_REQUEST, handleGetAllPartsByWh);

    yield takeEvery(GET_ALL_TRANSACTIONS_BY_SHIP_REQUEST, handleAllTransactionByShip);
    yield takeEvery(GET_ALL_TRANSACTIONS_BY_WH_REQUEST, handleAllTransactionByWh);


    yield takeEvery(GET_VESSELS_BY_SHIP_REQUEST, handleGetAllVesselsByShip);
    yield takeEvery(GET_ALL_SUPPLIERS_REQUEST, handleGetAllSuppliers);
    yield takeEvery(GET_ALL_WAREHOUSE_REQUEST, handleGetAllWarehouse);

    yield takeEvery(ADD_PART_REQUEST, handleAddPart);
    
    yield takeEvery(GET_PART_REQUEST, handleGetPart);


    // warehouse sagas

    yield takeEvery(WAREHOUSE_LOGIN_REQUEST, warehouseLogin);
    // admin sagas
   
    // parts
    yield takeEvery(GET_ALL_ADMIN_PARTS_REQUEST, adminAllParts);
    yield takeEvery(GET_PART_TRANSACTIONS_REQUEST, adminPartTransactions);
    yield takeEvery(DELETE_PART_REQUEST, adminDeletePart);
    yield takeEvery(EDIT_PART_REQUEST, adminEditPart);
    
    //partners
    yield takeEvery(GET_PARTNERS_REQUEST, adminAllPartners);

    //vessels
    yield takeEvery(GET_ALL_ADMIN_VESSELS_REQUEST, adminAllVessels);
    yield takeEvery( ADD_VESSEL_REQUEST, adminAddVessel);
    yield takeEvery(GET_VESSEL_REQUEST, adminGetVessel);
    yield takeEvery(EDIT_VESSEL_REQUEST, adminEditVessel);
    yield takeEvery(DELETE_VESSEL_REQUEST, adminDeleteVessel);
    
    //ship companies
    yield takeEvery(GET_ALL_ADMIN_SHIP_REQUEST, adminAllShips);
    yield takeEvery(ADD_SHIP_REQUEST, adminAddShip);
    yield takeEvery(GET_SHIP_REQUEST, adminGetShip);
    yield takeEvery(EDIT_SHIP_REQUEST, adminEditShip);
    yield takeEvery(DELETE_SHIP_REQUEST, adminDeleteShip);

   
    //transactions
    yield takeEvery(GET_ALL_TRANSACTIONS_REQUEST, adminAllTransactions);
    yield takeEvery(ADD_TRANSACTION_REQUEST, adminAddTransaction);
    yield takeEvery(GET_TRANSACTION_REQUEST, adminGetTransactionById);
    yield takeEvery(DEL_TRANSACTION_REQUEST, adminDeleteTransaction);
    yield takeEvery(EDIT_TRANSACTION_REQUEST, adminEditTransaction);

    //users - admin

    yield takeEvery(GET_ALL_ADMIN_USER_REQUEST, adminAllUser);
    yield takeEvery( ADD_USER_REQUEST, adminAddUser);
    yield takeEvery(GET_USER_REQUEST, adminGetUser);
    yield takeEvery(EDIT_USER_REQUEST, adminEditUser);
    yield takeEvery(DELETE_USER_REQUEST, adminDeleteUser);

     //suppliers
     yield takeEvery(GET_ALL_ADMIN_SUPPLIERS_REQUEST, adminAllSuppliers);
     yield takeEvery( ADD_SUPPLIER_REQUEST, adminAddSupplier);
     yield takeEvery(GET_SUPPLIER_REQUEST, adminGetSupplier);
     yield takeEvery(EDIT_SUPPLIER_REQUEST, adminEditSupplier);
     yield takeEvery(DELETE_SUPPLIER_REQUEST, adminDeleteSupplier);
  
 
     //warehouses
     yield takeEvery(GET_ALL_ADMIN_WAREHOUSE_REQUEST, adminAllWarehouses);
     yield takeEvery( ADD_WAREHOUSE_REQUEST, adminAddWarehouse);
     yield takeEvery(GET_WAREHOUSE_REQUEST, adminGetWarehouse);
     yield takeEvery(EDIT_WAREHOUSE_REQUEST, adminEditWarehouse);
     yield takeEvery(DELETE_WAREHOUSE_REQUEST, adminDeleteWarehouse);


    //admins - settings

    yield takeEvery(GET_ALL_ADMINS_REQUEST, getAllAdmins);
    yield takeEvery( ADD_ADMIN_REQUEST, addAdmin);
    yield takeEvery(GET_ADMIN_REQUEST, getAdmin);
    yield takeEvery(EDIT_ADMIN_REQUEST, editAdmin);
    yield takeEvery(DELETE_ADMIN_REQUEST, deleteAdmin);


    yield takeEvery(ADMIN_LOGIN_REQUEST, adminLogin);

  
}

export default rootSaga;