import React, { Component } from "react";

class InputField extends Component {
  render() {
    return (
      <React.Fragment>
        <label
          className="font-weight-bolder"
          style={{ color: "#B0BAC9"}}
        >
          {this.props.labeltitle}
        </label>
        <input
          type={this.props.typename}
          name={this.props.fieldname}
          className="form-control"
          value={this.props.value}
          onChange={this.props.changeHandler}
        />
        {this.props.error && (
          <div className="alert alert-danger">
            {this.props.error}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default InputField;
