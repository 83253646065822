import React from "react";
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import MultiSelect from "react-multi-select-component";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Files from 'react-files';

const initialState = {
    reference_no: [], date: '', type: '', attachment: '', warehouse_id: '',partner_id:null,
    validated: false
};

class AddTrans extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reference_no: [], date: '', type: 'Pending', attachment: '', warehouse_id: null, partner_id:null,
            validated: false
        };


        this.handleChange = this.handleChange.bind(this);
    }
    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
        console.log(this.state);

    }

    addTrans = (event) => {
        const form = event.currentTarget;
        const {date,reference_no}=this.state;
        if (form.checkValidity() === false || date==='' || reference_no.length===0) {
            event.preventDefault();
            event.stopPropagation();
        }
        event.preventDefault();
        this.setState({ validated: true })
        if (form.checkValidity() === true) {
            if(this.state.type!=="In Stock"){
                const tempState=this.state;
                tempState.warehouse_id=null;
                this.props.addTrans(tempState);
                
            }
            else  if(this.state.type!=="Send"){
                const tempState=this.state;
                tempState.partner_id=null;
                this.props.addTrans(tempState);
                
            }else{
                this.props.addTrans(this.state);
            }
           
        }
        this.setState(initialState)


    }

    onHide = () => {
        this.setState(initialState)
        this.props.onHide()
    }
    multi = (e) => {

        this.setState({ reference_no: e })
    }


    renderWarehouse = (e) => {
        const { warehouses } = this.props;
        const { type } = this.state;
        if (type === "In Stock") {
            return (
                <React.Fragment>
                    <Form.Group as={Col} md="6">
                        <p className='gray_text  bold text-uppercase'>Warehouse</p>
                        <Form.Control as="select" onChange={(e) => { this.setState({ warehouse_id: `${e.target.value}` }) }}>
                            {
                                warehouses.map((w, i) => {
                                    return <option key={w._id} value={w._id} required >{w.forwarding_company}</option>
                                })
                            }
                        </Form.Control>
                    </Form.Group>
                </React.Fragment>
            )
        }

    }

    renderPartners = (e) => {
        const { partners } = this.props;
        const { type } = this.state;
        if (type === "Send") {
            return (
                <React.Fragment>
                    <Form.Group as={Col} md="6">
                        <p className='gray_text  bold text-uppercase'>Partner</p>
                        <Form.Control as="select" onChange={(e) => { this.setState({ partner_id: `${e.target.value}` }) }}>
                                            {
                                                partners.map((w, i) => {
                                                    return <option key={w._id} value={w._id} required >{w.partner_name}</option>
                                                })
                                            }
                        </Form.Control>
                    </Form.Group>
                </React.Fragment>
            )
        }

    }
    render() {
        const { show, parts } = this.props;
        //redux

        const { reference_no, date, type, attachment, warehouse_id, validated } = this.state;

        let options = parts.map(p => {
            return { label: p.reference_no, value: p._id }
        })
        return (
            <Modal
                show={show}
                onHide={this.onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <h4>Add a Transaction</h4>
                    <Form noValidate validated={validated} onSubmit={this.addTrans}>
                        <Form.Row>

                            <Form.Group as={Col} md="6" >
                                <p className='gray_text  bold text-uppercase'>REFERENCE NO.</p>

                                <MultiSelect
                                    options={options}
                                    value={reference_no}
                                    onChange={this.multi}
                                    labelledBy={"Select"}
                                    required
                                />

                                <Form.Control.Feedback type="invalid">
                                    Please provide Reference Number
                             </Form.Control.Feedback>


                            </Form.Group>

                            <Form.Group as={Col} md="6" >
                                <p className='gray_text  bold text-uppercase'>Date</p>
                                <DatePicker style={{ display: "block" }} selected={date} onChange={(date) => { this.setState({ date: date }) }} />
                                <Form.Control.Feedback type="invalid">
                                    Please select date.
                             </Form.Control.Feedback>
                            </Form.Group>

                        </Form.Row>

                        <Form.Row>

                            <Form.Group as={Col} md="6">
                                <p className='gray_text  bold text-uppercase'>Type of Transaction</p>
                                <Form.Control as="select" onChange={(e) => { this.setState({ type: `${e.target.value}` }) }}>
                                    <option key="Pending" value="Pending"  >Pending</option>
                                    <option key="In Stock" value="In Stock"  >In Stock</option>
                                    <option key="Send" value="Send"  >Send</option>
                                    <option key="Delivered" value="Delivered"  >Delivered</option>
                                </Form.Control>
                            </Form.Group>
                            {this.renderWarehouse() }

                            {this.renderPartners()}

                        </Form.Row>

                        <Row>
                        <Col md="6">
                                    <p className='gray_text  bold text-uppercase'>Attachments</p>
                                    <div className="files">
                                        <Files
                                            className='files-dropzone'
                                            onChange={(f)=>{console.log(f)}}
                                            onError={this.onFilesError}
                                            accepts={['.pdf']}
                                            multiple
                                            maxFiles={3}
                                            maxFileSize={10000000}
                                            minFileSize={0}
                                            clickable
                                        >
                                            Drop files here or click to upload
                                     </Files>
                                   </div>
                                </Col>

                                </Row>
                            <br></br>

                        <Button type="submit" className="btn btn-primary search-btn">Done</Button>
                    </Form>

                </Modal.Body>
            </Modal>
        );

    }

}


export default AddTrans;
