import React from "react";
import { connect } from "react-redux";
import TransCard from '../../Admin/Transactions/TransCard';
import AddTrans from './AddTrans';
import { Row, Col, Button, Form, Modal, Spinner } from 'react-bootstrap';
import MultiSelect from "react-multi-select-component";
import {
    getAllAdminVessels, getAllPartsByWarehouse,getPartners,
    filterTransactions, getTransactionByWarehouse, addTransaction, delTransaction,
    getTransactionById, closeOpenedPart, closeError
} from '../../js/actions'
import OpenTrans from "../../Admin/Transactions/openTrans";
import Navbar from '../Common/Navbar'
import DatePicker from "react-datepicker";
import Select from 'react-select'
import ReactLoading from 'react-loading';
var dateFormat = require('dateformat')
const states = [
    { value: '', label: 'All' },
    { value: 'Pending', label: 'Pending' },
    { value: 'In Stock', label: 'In Stock' },
    { value: 'Send', label: 'Send' },
    { value: 'Delivered', label: 'Delivered' },
  ];
class Transactions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reference_no: [], vessel: '', type: '', date: '', addPartModal: false,fromDate: null, toDate:null,

        };
        this.filterInput = this.filterInput.bind(this);
    }
    componentDidMount = () => {
        let warehouse = JSON.parse(localStorage.getItem("warehouse"))
        
        if(warehouse){
        this.props.getTransactionByWarehouse({warehouse_id:warehouse.warehouse_id})
        this.props.getAllAdminVessels();
        this.props.getAllPartsByWarehouse({id:warehouse.warehouse_id});
        this.props.getPartners();
    }
       
    }

    componentWillReceiveProps=(nextProps)=>{
        if(nextProps.ships_success){
            let all_ships=[]
           all_ships= nextProps.all_ships.map(({shipping_company_name }) => ({ value: shipping_company_name, label:shipping_company_name  }))
           all_ships.unshift({value:'',label:'All'})
           this.setState({select_ships:all_ships})
           }
        if(nextProps.warehouses_success){
         let all_warehouses= this.props.all_warehouses.map(({_id,forwarding_company }) => ({ value: forwarding_company, label:forwarding_company  }))
         all_warehouses.unshift({value:'',label:'All'})
         this.setState({select_warehouses:all_warehouses})
         }
        if(nextProps.vessels_success){
         let all_vessels= this.props.all_vessels.map(({vessel}) => ({ value: vessel, label:vessel}))
         all_vessels.unshift({value:'',label:'All'})
         this.setState({select_vessels:all_vessels})
         }
     
    }


    search = (e) => {
        e.preventDefault();
        const { reference_no,  vessel,  type, fromDate,toDate} = this.state;
        let from_date = fromDate;
        let to_date = toDate;
        if(fromDate!=null && toDate!=null){
        from_date = dateFormat(fromDate,'yyyy-m-d');
        to_date = dateFormat(toDate,'yyyy-m-d');
        }
        this.setState({is_filtered:true})
        this.props.filterTransactions({
            reference_no: reference_no, shipping_co: '', vessel, warehouse: '',
            type,from_date,to_date
        });
    }

    filterInput = (e) => {
        this.setState({ [e.target.id]: e.target.value });
        console.log(this.state)
    }
    multi = (e) => {

        this.setState({ reference_no: e })
    }
    renderTransactions = () => {

        const { all_transactions, filtered_items, filter_applied, transactions_success} = this.props;
        if (filter_applied) {
            if (filtered_items.length > 0) {
                return filtered_items.map((p, i) => <TransCard transaction={p} key={i} openTrans={this.openTrans} />)
            } 


        }
        else {
            if (transactions_success) {
                return all_transactions.map((p, i) => <TransCard transaction={p} key={i} openTrans={this.openTrans} />)
            }
            else {
                return ( <ReactLoading type={'spin'} height='2em' width='2em' color={'#2E5BFF'}  />)
            }
        }
    }

    openTrans = (id) => {
        this.props.getTransactionById({ id: `${id}` });
    }
    addTrans = (data) => {

        const { warehouse_id, reference_no, type } = data;
        const { all_warehouses } = this.props;
        let tempData = data;

        if (type === 'In Stock') {
            if (warehouse_id === null)
                tempData.warehouse_id = all_warehouses[0]._id
        } else {
            if (warehouse_id === null) {
                delete tempData.warehouse_id
            }
        }


        reference_no.map(r => {
            let temp = tempData;
            temp.reference_no = r.label;
            temp.part_id = r.value;
            temp.partner_id=null
            this.props.addTransaction(temp);
        })

        this.setState({ addPartModal: false });

    }
    deleteTrans = (id) => {
        this.props.delTransaction({ id: `${id}` });
        this.props.closeOpenedPart();
    }

    

    render() {
        const { all_transactions, transaction, parts, open_item_modal, closeOpenedPart } = this.props;

        const { all_warehouses, all_vessels, all_ships,partners } = this.props;
        const { reference_no,fromDate,toDate ,addPartModal} = this.state;

        const{openConfirm, error,err_msg,success_msg,closeError}=this.props;

        // error handling
        const { vessels_success, parts_success}=this.props;
        
        let options = parts.map(p => {
                return { label: p.reference_no, value: p._id }
            })
        
        

        if(localStorage.getItem("loginState")=="false" && localStorage.getItem("user_login")=="false"){
            this.props.history.push("/admin/");
            return null;
          }
        else
        return (
            <React.Fragment>
            <Row>
               
                <Navbar active="transactions" />
                <Col>
                    <Row className="top-header">

                    </Row>

                    <Row className="parts-main">
                  
                        <Col md="10">
                            <Row>
                                <Col><h2>Transactions</h2></Col>
                                <Col><p className='gray_text pt-3'>{this.state.is_filtered? this.props.filtered_items.length: all_transactions ? all_transactions.length: '' } Total</p></Col> 

                                <Col> </Col>
                                <Col> <Button variant="primary" className="add-part-btn" onClick={() => { this.setState({ addPartModal: true }) }}>+ Transaction</Button></Col>
                            </Row>
                            <Row>
                            <Col><p className='gray_text text-uppercase'>Ref No./Status</p></Col>
                            <Col><p className='gray_text text-uppercase'>Ship company/vessel</p></Col>
                            <Col><p className='gray_text text-uppercase'>Supplier/Warehouse</p></Col>
                            <Col><p className='gray_text text-uppercase'>Specs/Date In</p></Col>
                            </Row>
                            <div style={{overflow:'scroll',height:'70vh',padding:10}}>
                            {
                                this.renderTransactions()
                            }
                            </div>


                        </Col>

                        <Col md="2" className="parts-filter">
                            <p>FILTER</p>

                            <Form onSubmit={this.search}>
                                {
                                    parts_success &&
                                <Form.Group >
                                    <p className='gray_text  bold text-uppercase'>REFERENCE NO.</p>

                                    <MultiSelect
                                        options={options}
                                        value={reference_no}
                                        onChange={this.multi}
                                        labelledBy={"Select"}
                                        required
                                    />

                                </Form.Group>
                                }
                              
                              {
                                        vessels_success  &&
                                        <Form.Group>
                                        <p className="gray_text bold text-uppercase">Vessel</p>
                                        <Select
                                        onChange={(e) => { this.setState({ vessel: `${e.value}` }) }}
                                        options={this.state.select_vessels}
                                        />
                                    </Form.Group>

                                    }
                            
                                

                                  <Form.Group>
                                  <p className="gray_text bold text-uppercase">Status</p>
                                  <Select
                                    onChange={(e) => { this.setState({ type: `${e.value}` }) }}
                                    options={states}
                                    isSearchable={false}
                                  />
                                </Form.Group>

                                <Form.Group>
                                    <p className="gray_text bold text-uppercase">From Date</p>
                                    <DatePicker className='form-control' dateFormat='d-MM-yyyy' style={{ display: "block" }} selected={fromDate}  onChange={(date) => { this.setState({ fromDate: date }) }} />
                                </Form.Group>

                                <Form.Group>
                                    <p className="gray_text bold text-uppercase">To Date</p>
                                    <DatePicker  className='form-control' dateFormat='d-MM-yyyy' style={{ display: "block" }} selected={toDate} onChange={(date) => { this.setState({ toDate: date }) }} />
                                </Form.Group>

                               
                                <Button type="submit" className="btn btn-primary search-btn">Search</Button>
                            </Form>

                        </Col>
                         <AddTrans show={addPartModal} onHide={() => { this.setState({ addPartModal: false }) }}
                            addTrans={this.addTrans} parts={parts} partners={partners} /> 
                        <OpenTrans transaction={transaction} show={open_item_modal} onHide={closeOpenedPart} 
                        deleteTrans={this.deleteTrans}/>
                    </Row>
                </Col>
            </Row>
            </React.Fragment>
        )


    }
}
const mapDispatchToProps = dispatch => {
    return {

        getTransactionByWarehouse: payload => dispatch(getTransactionByWarehouse(payload)),
        addTransaction: payload => dispatch(addTransaction(payload)),
        getTransactionById: payload => dispatch(getTransactionById(payload)),
        delTransaction: payload => dispatch(delTransaction(payload)),
        getAllPartsByWarehouse: payload => dispatch(getAllPartsByWarehouse(payload)),
        getAllAdminVessels: payload => dispatch(getAllAdminVessels(payload)),
        filterTransactions: payload => dispatch(filterTransactions(payload)),
        closeOpenedPart: payload => dispatch(closeOpenedPart(payload)),
        closeError: payload => dispatch(closeError()),
        getPartners:payload => dispatch(getPartners()),

    }
}
const mapStateToProps = state => {
    return {
        all_transactions: state.all_transactions,
        transaction: state.transaction,
        filtered_items: state.filtered_items,
        filter_applied: state.filter_applied,
        open_item_modal: state.open_item_modal,
        all_warehouses: state.all_warehouses,
        all_vessels: state.all_vessels,
        parts: state.parts,
        partners:state.partners,
        warehouse:state.warehouse,
        error: state.error,
        err_msg: state.err_msg,
        success_msg: state.success_msg,
        openConfirm: state.openConfirm,
        vessels_success:state.vessels_success,
        warehouses_success:state.warehouses_success,
        parts_success:state.parts_success,
        transactions_success:state.transactions_success
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);
