import React from "react";
import { connect } from "react-redux";
import AdminCard from './AdminCard';
import AddAdmin from './addAdmin';
import { Row, Col, Button,Form ,Modal} from 'react-bootstrap';

import { getAllAdmins,getAdmin,editAdmin,deleteAdmin,addAdmin,closeOpenedPart,closeError} from '../../js/actions'

import OpenAdmin from './OpenAdmin';
import Navbar from '../Common/Navbar'

class AllUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           addPartModal: false,
           change_password_modal:false
        };
       
    }
    componentDidMount = () => {
        this.props.getAllAdmins();
  
    }

   
  
    openAdmin = (id) => {
        this.props.getAdmin({ id: `${id}` });
    }
    addAdmin = (data) => {
       
        this.props.addAdmin(data)
        this.setState({ addPartModal: false });
        this.props.getAllAdmins();
    }
    deleteAdmin = (id) => {
        this.props.deleteAdmin({ id: `${id}` });
        this.props.closeOpenedPart();
    }

    renderAdmins = () => {
        const { all_admins, admins_success} = this.props;
                if(admins_success)
                return (
                    all_admins.map((p, i) => {
                        return (
                                <AdminCard admin={p} key={i} openAdmin={this.openAdmin} />
                        )
                    })
                )  
    }
    render() {
        const { all_admins,admin} = this.props;
        const { addPartModal,change_password_modal } = this.state;

        const { error, err_msg, success_msg, closeError ,openConfirm,open_item_modal,closeOpenedPart} = this.props;
      
        if(localStorage.getItem("loginState")=="false" && localStorage.getItem("admin_login")=="false"){
            this.props.history.push("/admin/");
            return null;
          }
        else
            return (
                <React.Fragment>
                <Row>
                    <Navbar active="settings" />
                    <Col>
                        <Row className="top-header">

                        </Row>

                        <Row className="parts-main">
                            <Col md="8">
                                <Row>
                                    <Col><h2>Admins</h2></Col>
                                    <Col><p className='gray_text pt-3'>{all_admins.length} Total</p></Col>
                                    <Col> <Button variant="primary" className="add-part-btn" onClick={() => { this.setState({ addPartModal: true }) }}>+ Admin</Button></Col>
                                </Row>
                               <div id='labels'>
                                <Col style={{padding:0}}><p className='gray_text text-uppercase'>Full Name</p></Col>
                                <Col style={{padding:0}}><p className='gray_text text-uppercase'>Email</p></Col>
                                <Col></Col>
                                </div>
                                <div style={{overflow:'scroll',height:'80vh',paddingBottom:'20vh',width:'100%',display:'flex',flexDirection:'column'}}>
                                {this.renderAdmins()}
                                </div>

                            </Col>


                            <AddAdmin show={addPartModal} onHide={() => { this.setState({ addPartModal: false }) }}
                                addAdmin={this.addAdmin}  />

                            <OpenAdmin admin={admin} show={open_item_modal} onHide={closeOpenedPart}
                            deleteAdmin={this.deleteAdmin} />

                        </Row>
                    </Col>

                </Row>

                
                </React.Fragment>





            )


    }
}
const mapDispatchToProps = dispatch => {
    return {

        getAllAdmins: payload => dispatch(getAllAdmins()),
        getAdmin: payload => dispatch(getAdmin(payload)),
        editAdmin: payload => dispatch(editAdmin(payload)),
        deleteAdmin: payload => dispatch(deleteAdmin(payload)),
        addAdmin: payload => dispatch(addAdmin(payload)),
       
        closeError: payload => dispatch(closeError()),
        closeOpenedPart:payload => dispatch(closeOpenedPart()),
        
    }
}
const mapStateToProps = state => {
    return { all_admins: state.all_admins, admin:state.admin,
        error: state.error, err_msg: state.err_msg, success_msg: state.success_msg,openConfirm:state.openConfirm,
        open_item_modal: state.open_item_modal,
        admins_success:state.admins_success
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllUsers);
