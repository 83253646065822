import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import '../styles.scss'
import {  Col } from 'react-bootstrap';
import {
   logoutAdmin
} from '../../js/actions'
import logo from '../../Pics/Logo.png'
import homeIcon from  '../../Pics/icon-home.png'
import transIcon from '../../Pics/icon-transactions.png' 
import vesselIcon from '../../Pics/icon-vessel.png' 
import warehouseIcon from '../../Pics/icon-warehouse.png' 
import supplierIcon from '../../Pics/icon-suppliers.png' 
import usersIcon from '../../Pics/icon-users.png' 
import partsIcon from '../../Pics/icon-parts.png' 
import shipIcon from '../../Pics/icon-shipping_company.png' 
import logout from '../../Pics/icon-logout.png' 
import settingsIcon from '../../Pics/icon-settings.png' 
class Navbar extends Component {

    constructor(props) {
        super(props);
        this.state = {  };
    }


  logout=()=>{
      this.props.logoutAdmin();
      localStorage.setItem('loginState', false);
      localStorage.setItem('admin_login', false);
      localStorage.setItem('token', undefined);
      
  }

    render() {

        const {active} = this.props;

        return (
                <Col md="2" className="sidebar justify-content-center">
                    <div className='logo_area'>
                        <img width={100} src={logo}></img>
                    </div>
                    <ul style={{overflow:'scroll'}} className="flex-column   align-items-center">
                         
                    
                    <li className={` d-flex flex-row align-items-center  ${active==='home' ? "active" : ""}`}>
                        <div  className='d-flex flex-row align-items-center justify-content-center'>
                        <img src={homeIcon} width={23} height={23}/>
                            <Link to="/admin/home"> <p className="nav-link" id="home" >Home</p></Link>
                            </div>
                        </li>
           
                        <li className={` d-flex flex-row align-items-center  ${active==='transactions' ? "active" : ""}`}>
                        <div  className='d-flex flex-row align-items-center '>
                        <img src={transIcon} width={23} height={23}/>
                            <Link to="/admin/transactions"> <p className="nav-link" id="home" >Transactions</p></Link>
                            </div>
                        </li>
                        <li className={`  d-flex flex-row align-items-center  ${active==='shipping' ? "active" : ""}`}>
                        <div  className='d-flex flex-row align-items-center '>
                        <img src={shipIcon} width={23} height={23}/>
                            <Link to="/admin/shipping"> <p className="nav-link" id="shipping" >Shipping Co.</p></Link>
                            </div>
                        </li>
                        
                        
                        <li className={` ${active==='vessels' ? "active" : ""}`}>
                        <div  className='d-flex flex-row align-items-center '>
                        <img src={vesselIcon} width={23} height={23}/>
                        <Link to="/admin/vessels">  <p className="nav-link"  id="vessels">Vessels</p></Link>
                            </div>
                        </li>

                        <li className={` ${active==='warehouses' ? "active" : ""}`}>
                        <div  className='d-flex flex-row align-items-center '>
                        <img src={warehouseIcon} width={23} height={23}/>
                        <Link to="/admin/warehouses">  <p className="nav-link"  id="warehouses">Warehouses</p></Link>
                            </div>
                        </li>

                        <li className={` ${active==='parts' ? "active" : ""}`}>
                        <div  className='d-flex flex-row align-items-center '>
                        <img src={partsIcon} width={23} height={23}/>
                        <Link to="/admin/parts">  <p className="nav-link"  id="parts">Parts</p></Link>
                            </div>
                        </li>

                        <li className={` ${active==='suppliers' ? "active" : ""}`}>
                        <div  className='d-flex flex-row align-items-center '>
                        <img src={supplierIcon} width={23} height={23}/>
                        <Link to="/admin/suppliers">  <p className="nav-link"  id="suppliers">Suppliers</p></Link>
                            </div>
                        </li>

                        <li className={` ${active==='users' ? "active" : ""}`}>
                        <div  className='d-flex flex-row align-items-center '>
                        <img src={usersIcon} width={23} height={23}/>
                        <Link to="/admin/users">  <p className="nav-link"  id="users">Users</p></Link>
                            </div>
                        </li>
                        
                        <li className={` ${active==='settings' ? "active" : ""}`}>
                        <div  className='d-flex flex-row align-items-center '>
                        <img src={settingsIcon} width={23} height={23}/>
                        <Link to="/admin/settings">  <p className="nav-link" id="settings" >Admins</p></Link>
                            </div>
                        </li>

                        <li className={` ${active==='logout' ? "active" : ""}`}>
                        <div  className='d-flex flex-row align-items-center '>
                        <img src={logout} width={23} height={23}/>
                        <Link to="/admin/"><p className="nav-link" id="logout" onClick={this.logout}>Logout</p></Link>
                            </div>
                        </li>
                    </ul>
                </Col>
        );
    }
}
const mapDispatchToProps = dispatch => {
    return {
        logoutAdmin: payload => dispatch(logoutAdmin(payload)),
  
    }
  }
export default connect(null, mapDispatchToProps)(Navbar);
