import React from "react";
import { Link } from "react-router-dom";
import Joi from "joi-browser";
import Form from "../../Common/Form";
import LoginBox from "../../Common/LoginBox";
import InputField from "../../Common/InputField";
import logo from "../../logo.svg";
import { connect } from "react-redux";

import './styles.scss'

import {
  adminLogin,warehouseLogin
} from '../../js/actions'


class LogInFormAdmin extends Form{
    constructor(props){
        super(props);
        this.state={
            data: { email: "", password: "" },
            error: {},
            link: "",
            box_clicked_admin: false,
            box_clicked_warehouse: false,
            box_error: false,
           
            loading: false,
            loginButtonSpinner: false
        };
    }

      // schema for valiation
  schema = {
    email: Joi.string().email({ minDomainAtoms: 2 }).required().label("Email"),
    password: Joi.string().required().label("Password"),
  };

  box_callback_link = (link) => {
    if (link === "/admin/home")
      return this.setState({
        link: link,
        box_error: false,
        box_clicked_admin: true,
        box_clicked_warehouse: false,
      });
    return this.setState({
      link: link,
      box_error: false,
      box_clicked_admin: false,
      box_clicked_warehouse: true,
    });
  };

  
  loginManage = (e) => {
    e.preventDefault();
  
    // either user select one of the box or not
    if (!this.state.link) return this.setState({ box_error: true });
    // validation of input fields
    const error = this.validate();
    this.setState({ error: error || {} });
    //if error occur don't call api
    if (error) return null;

    //call the api
    this.callServerApi();
  };

  callServerApi = () => {
    
    const requestBody = {
      email: this.state.data.email,
      password: this.state.data.password,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (this.state.link === "/admin/home")
      this.props.adminLogin(
        requestBody
      );
    else
     {
       this.props.warehouseLogin(requestBody)
     }
  };
render(){
  const {loginState,admin_login,warehouse_login}=this.props;
  if(loginState==true && admin_login==true){
    this.props.history.push("/admin/home");
    return null;
  }
  else if(loginState==true && warehouse_login==true){
    this.props.history.push("/manager/home");
    return null;
  }
  else 
    return(
        <React.Fragment>
          <div
            className="container-fluid h-100"
            style={{ background: "#ffffff" }}
          >
            <div className="row h-100">
              <div className="logo--position d-inline">
                <img src={logo} className="img-fluid" alt="logo"></img>
              </div>
              <div className="col-md-4 offset-md-1">
                <div className="top__login--margin">
                  <div className="form__padding--smallscreen">
                  <h2 className="fontchange d-block">
                      Sign in to Speedair
                    </h2>
                    <p className="text--color">
                      Please enter your credentials to proceed.
                    </p>
                    {/*---------BOXES-----------  */}
                    <div className="boxes my-3 ">
                      <div className="d-flex">
                        <LoginBox
                          link={this.box_callback_link}
                          title="Administrator"
                          box_clicked={this.state.box_clicked_admin}
                        />
                        <LoginBox
                          link={this.box_callback_link}
                          title="Warehouse Manager"
                          box_clicked={this.state.box_clicked_warehouse}
                        />
                      </div>
                    </div>
                    {/*-------------BOXES Errors---------- */}
                    {this.state.box_error && (
                      <div
                        className="alert alert-danger"
                        style={{ fontSize: "14px" }}
                      >
                        Select Either Customer or Carrier from above
                      </div>
                    )}
                    {/*--------------Invalid Credentials--------  */}
                    {this.props.inValidLogin && (
                      <div
                        className="alert alert-danger"
                        style={{ fontSize: "14px" }}
                      >
                        The email and password you entered did not match our
                        records. Please double-check and try again.
                      </div>
                    )}
                    {/*-----------------Form begins--------------*/}
                    <form onSubmit={this.loginManage}>
                      <div className="form-group">
                        <InputField
                          labeltitle="EMAIL"
                          typename="email"
                          fieldname="email"
                          changeHandler={this.handleChange}
                          value={this.state.data.email}
                          error={this.state.error.email}
                        />
                      </div>
                      <div className="form-group">
                        <InputField
                          labeltitle="PASSWORD"
                          typename="password"
                          fieldname="password"
                          changeHandler={this.handleChange}
                          value={this.state.data.password}
                          error={this.state.error.password}
                        />
                      </div>
                      <button
                        disabled={
                          this.validate() || this.state.loginButtonSpinner
                        }
                        type="submit"
                        className="btn btncolor btn-block btn-lg"
                        
                      >
                        {this.state.loginButtonSpinner ? (
                          <span>Loading ...</span>
                        ) : (
                          <span>Sign in</span>
                        )}
                      </button>
                      
                    </form>
                    {/*-----------form End-----------  */}
                  </div>
                </div>
              </div>
              <div className="col-md-6 offset-md-1 pr-0">
                <div className="bgpic"></div>
              </div>
            </div>
          </div>
        </React.Fragment>
    )
}
}
const mapDispatchToProps = dispatch => {
  return {
    adminLogin: payload => dispatch(adminLogin(payload)),
    warehouseLogin:payload => dispatch(warehouseLogin(payload)),

  }
}
const mapStateToProps = state => {
  return {
      loginState: state.loginState,
       admin_login: state.admin_login,
       warehouse_login:state.warehouse_login,
      inValidLogin:state.inValidLogin
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(LogInFormAdmin)
