import React from "react";
import { connect } from "react-redux";
import "../styles.scss";
import { Link } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';

import { getAllPartsByShip } from '../../js/actions'

import Navbar from '../Common/Navbar'
import Card from "../../Admin/Home/Card";



class HomeUser extends React.Component {
    constructor(props) {
        super(props);

    }

    componentDidMount = () => {
        let user = JSON.parse(localStorage.getItem("user"))
        if(user){
            this.props.getAllPartsByShip({id:user.ship_id});
        }
        
    }

    render() {
        const { pending_parts, stock_parts, send_parts, delivered_parts } = this.props;

        if(localStorage.getItem("loginState")=="false" && localStorage.getItem("user_login")=="false"){
            this.props.history.push("/");
            return null;
          }

        else
        return (
            <Row>
            <Navbar active="home" />
            <Col>
                <Row className="top-header">

                </Row>
                <Row className="main-top">
                    <Col>
                        <div className="top-box">
                            <p className='text-uppercase'>Parts on Pending</p>
                            <div className='d-flex flex-row'>
                                <h2 style={{ margin: 0 }}>{pending_parts.length}</h2>
                            </div>


                        </div>
                    </Col>
                    <Col>
                        <div className="top-box">
                            <p className='text-uppercase'>Parts in Stock</p>
                            <div className='d-flex flex-row'>
                                <h2 style={{ margin: 0 }}>{stock_parts.length}</h2>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="top-box">
                            <p className='text-uppercase'>Send Parts</p>
                            <div className='d-flex flex-row'>
                                <h2 style={{ margin: 0 }}>{send_parts.length} </h2>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="top-box">
                            <p className='text-uppercase'>Delivered Parts</p>
                            <div className='d-flex flex-row'>
                                <h2 style={{ margin: 0 }}>{delivered_parts.length} </h2>
                            </div>
                        </div>
                    </Col>

                </Row>
                <Row className="main-top">
                <Col style={{overflow:'scroll',height:'60vh'}}>
                        <p>Pending</p>
                        {
                            pending_parts.reverse().map(p => {
                                return (
                                    <div className="bottom-box">
                                       <Card p={p}/>
                                    </div>
                                )
                            })
                        }
                 </Col>
                    <Col style={{overflow:'scroll',height:'60vh'}}>
                        <p>In Stock</p>
                        {
                            stock_parts.reverse().map(p => {
                                return (
                                    <div className="bottom-box-instock">
                                     <Card p={p}/>
                                    </div>
                                )
                            })
                        }
                    </Col>
                    <Col style={{overflow:'scroll',height:'60vh'}}>
                        <p>Send</p>
                        {
                            send_parts.reverse().map(p => {
                                return (
                                    <div className="bottom-box-send">
                                          <Card p={p}/>
                                    </div>
                                )
                            })
                        }
                    </Col>
                    <Col style={{overflow:'scroll',height:'60vh'}}>
                        <p>Delivered</p>
                        {
                            delivered_parts.reverse().map(p => {
                                return (
                                    <div className="bottom-box-delivered">
                                          <Card p={p}/>
                                    </div>
                                )
                            })
                        }
                    </Col>
                </Row>

            </Col>
        </Row>

        )
    }


}
const mapDispatchToProps = dispatch => {
    return {
        getAllPartsByShip: payload => dispatch(getAllPartsByShip(payload)),

    }
}
const mapStateToProps = state => {
    return {
        pending_parts: state.pending_parts, stock_parts: state.stock_parts, send_parts: state.send_parts,
        delivered_parts: state.delivered_parts, user:state.user
        
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(HomeUser);