import React from "react";
import { Row, Col, Modal, Button, Form } from 'react-bootstrap';
import '../styles.scss'
import { connect } from "react-redux";
import {
    editPart, addTransaction,getPartners
} from '../../js/actions'
import axios from 'axios';
import DatePicker from "react-datepicker";
import timelineIcon from '../../Pics/pickupicon.png'
import "react-datepicker/dist/react-datepicker.css";
import Files from 'react-files'
import { api_url, notEmptyArray } from "../../globals";
import ConfirmDeleteButton from "../../Common/ConfirmDeleteButton/ConfirmDeleteButton";

const initialState = {
    reference_no:'',shipping_co: '', vessel_id: '', pieces: '', weight: '', dimensions: '', supplier_id: '',
    warehouse_id: '', edit: false,
    ship_id: '', shipping_company_name: '', vessel: '', supplier: '', warehouse: '',
    send_modal: false, 
    wh_modal: false, new_date:new Date(), new_warehouse_id:'',code:'',
    partner_id:'', file: null, message: ''
};
var dateFormat = require('dateformat')
class OpenPart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reference_no:'',shipping_co: '', vessel_id: '', pieces: '', weight: '', dimensions: '', supplier_id: '',
            warehouse_id: '', edit: false,description:'',
            ship_id: '', shipping_company_name: '', vessel: '', supplier: '', warehouse: '',
            send_modal: false, 
            wh_modal: false, new_date:new Date(), new_warehouse_id:'',code:'',
            partner_id:'', file: null, message: ''
        };
        this.props.getPartners();
    }
    getImage = e => {
        const files = e.target.files;
       
        if (files && files.length > 0) {
            const file = files[0];
            this.setState({ file });
        }
    };
    onClose = () => {
        this.setState(initialState)
        this.props.onHide();
    }
    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
       

    }
    componentWillReceiveProps(prevProps) {
        const { shipping_company_name } = this.state;

        if (shipping_company_name === '' && this.props.part) {
            const { part } = this.props;
            this.setState({
                reference_no:part.reference_no,
                shipping_company_name: part.ship[0].shipping_company_name,
                vessel: part.vessel[0].vessel,
                supplier:part.supplier_id? notEmptyArray(part.supplier)? part.supplier[0].supplier_name:"":null,
                warehouse:part.warehouse_id ?  part.warehouse[0].forwarding_company:null,
                ship_id: part.ship[0]._id,
                vessel_id: part.vessel[0]._id,
                supplier_id:part.supplier_id ? notEmptyArray(part.supplier)? part.supplier[0]._id:"" : null,
                warehouse_id:part.warehouse_id? part.warehouse[0]._id:null,
                pieces: part.pieces,
                weight: part.weight,
                dimensions: part.dimensions,
                description:part.description,
                remarks:part.remarks
            })

        }
    }

    editPart = (event) => {
        const { part } = this.props;
        const { ship_id, supplier_id, vessel_id, warehouse_id, dimensions, pieces, weight,reference_no,description,remarks } = this.state;
        const temp = { reference_no,ship_id, supplier_id, vessel_id, warehouse_id, dimensions, pieces, weight,description,remarks };
     

        temp.part_id = part._id;
     
        temp.old_reference_no=part.reference_no;
        this.props.editPartInfo(temp);
        this.setState(initialState)
        this.props.onHide();
    }

    changeWarehouse=(e)=>{

        e.preventDefault();
        const { file } = this.state;
        if(file!= null){
            if(this.state.code == ''){
                e.preventDefault();
                e.stopPropagation();
                return;
            }
        this.setState({ message: 'Uploading...' })
        const contentType = file.type; // eg. image/jpeg or image/svg+xml

        const generatePutUrl = api_url()+ 'generate-put-url';
        const options = {
            params: {
                Key: file.name,
                ContentType: contentType,
                Bucket: process.env.S3_BUCKET,
            },
            headers: {
                'Content-Type': contentType
            }
        };

        axios.get(generatePutUrl, options).then(res => {
            const {
                data: { putURL }
            } = res;
            axios
                .put(putURL, file, options)
                .then(res => {
                    this.setState({ message: 'Upload Successful' })
                    const{new_date,new_warehouse_id}=this.state;

                    const{warehouses,part}=this.props;

                    const temp={}
                    temp.reference_no=part.reference_no;
                    if(new_date===''){
                        temp.date=new Date();
                    }
                    else{
                        temp.date=new_date;
                    }
                    temp.type="In Stock";

                    if(new_warehouse_id===''){
                        temp.warehouse_id=warehouses[0]._id;
                    }
                    else{
                        temp.warehouse_id=new_warehouse_id;
                    }
                    temp.code=this.state.code
                    temp.part_id=part._id;
                    temp.partner_id=null;
                    temp.attachment = res.request.responseURL.split("?")[0];
                    this.props.addTransaction(temp);
                   
                    this.closeWhModal();
                    this.setState(initialState)
                    this.props.onHide();
                    
                })
                .catch(err => {
                    this.setState(initialState)
                    this.props.onHide();
                });
        });
    }
    else{
        const{new_date,new_warehouse_id}=this.state;
        const{warehouses,part}=this.props;

        const temp={}
        temp.reference_no=part.reference_no;
        if(new_date===''){
            temp.date=new Date();
        }
        else{
            temp.date=new_date;
        }
        temp.type="In Stock";

        if(new_warehouse_id===''){
            temp.warehouse_id=warehouses[0]._id;
        }
        else{
            temp.warehouse_id=new_warehouse_id;
        }
        
      
        temp.part_id=part._id;
        temp.partner_id=null;
        this.props.addTransaction(temp);
        this.closeWhModal();
        this.setState(initialState)
        this.props.onHide();
    }
        

    }

    send=(e)=>{

        const { file } = this.state;
        if(file!= null){
            if(this.state.code == ''){
                e.preventDefault();
                e.stopPropagation();
                return;
            }
        this.setState({ message: 'Uploading...' })
        const contentType = file.type; // eg. image/jpeg or image/svg+xml
        const generatePutUrl = api_url()+ 'generate-put-url';
        const options = {
            params: {
                Key: file.name,
                ContentType: contentType,
                Bucket: process.env.S3_BUCKET,
            },
            headers: {
                'Content-Type': contentType
            }
        };

        axios.get(generatePutUrl, options).then(res => {
            const {
                data: { putURL }
            } = res;
            axios
                .put(putURL, file, options)
                .then(res => {
                    const{new_date,partner_id}=this.state;
                    const{partners,part}=this.props;
            
                    const temp={}
                    temp.reference_no=part.reference_no;
                    if(new_date===''){
                        temp.date=new Date();
                    }
                    else{
                        temp.date=new_date;
                    }
                    temp.type="Send";
            
                    if(partner_id===''){
                        temp.partner_id=partners[0]._id;
                    }
                    else{
                        temp.partner_id=partner_id;
                    }

                    temp.code=this.state.code
                    temp.part_id=part._id;
                    temp.attachment = res.request.responseURL.split("?")[0];
                    this.props.addTransaction(temp);
                    this.closeWhModal();
                    this.setState(initialState)
                    this.props.onHide();
            
                })
                .catch(err => {
                    this.setState(initialState)
                    this.props.onHide();
                });
        });
    }
    else{
        const{new_date,partner_id}=this.state;
        const{partners,part}=this.props;

        const temp={}
        temp.reference_no=part.reference_no;
        if(new_date===''){
            temp.date=new Date();
        }
        else{
            temp.date=new_date;
        }
        temp.type="Send";

        if(partner_id===''){
            temp.partner_id=partners[0]._id;
        }
        else{
            temp.partner_id=partner_id;
        }
        
        temp.part_id=part._id;
       
        this.props.addTransaction(temp);
        this.closeWhModal();
        this.setState(initialState)
        this.props.onHide();

    }
        
    }
    closeWhModal=()=>{
        this.setState({wh_modal:false})
    }
    closeSendModal=()=>{
        this.setState({send_modal:false})
    }

    edit=()=>{
        if (this.props.part) {
            const { part } = this.props;
            this.setState({
                reference_no:part.reference_no,
                shipping_company_name: part.ship[0]._id,
                vessel: part.vessel[0]._id,
                supplier:part.supplier_id ? part.supplier[0]._id:null,
                warehouse:part.warehouse_id ? part.warehouse[0]._id:null,
                ship_id: part.ship[0]._id,
                vessel_id: part.vessel[0]._id,
                supplier_id: part.supplier_id ? part.supplier[0]._id: null,
                warehouse_id:part.warehouse_id? part.warehouse[0]._id:null,
                pieces: part.pieces,
                weight: part.weight,
                dimensions: part.dimensions,
                description:part.description,
                remarks:part.remarks
            })

        }
        this.setState({edit:true})

    }
    render() {
        const { part, show, onHide, part_transactions, deletePart, partners } = this.props;
        const { suppliers, warehouses, vessels, ships } = this.props;
        const { shipping_company_name, vessel, supplier, warehouse, pieces, weight, dimensions, edit,
            wh_modal,new_warehouse_id, new_date,
            send_modal,reference_no, code,description,remarks } = this.state;

        if (part != undefined && edit === false) {

            return (
                <React.Fragment>
                    <Modal
                        show={show}
                        onHide={this.onClose}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Body>

                            <Row>
                                <Col md="6">
                                    <h4 >Ref No: {part.reference_no}</h4>
                                </Col>
                                <Col md="6">
                                {this.props.userType=='admin'&& 
                                    <React.Fragment>
                                   <ConfirmDeleteButton yesFunction={() => { deletePart(part._id) }}  />
                                   <Button className="edit-trans bold" onClick={this.edit}>Edit</Button>
                                   </React.Fragment>
                                }       
                                    
                                </Col>

                            </Row>
                                <hr/>
                            <Row>
                                <Col>
                                    <Row>
                                    <Col md="6">
                                            <p className="gray_text bold">DESCRIPTION</p>
                                            <p className="black_text-slim">{part.description}</p>
                                        </Col>
                                        <Col md="6">
                                            <p className="gray_text bold">SHIP COMPANY</p>
                                          {part.ship_id &&  <p className="black_text-slim">{part.ship[0].shipping_company_name}</p>}
                                        </Col>
                                      
                                        
                                    </Row>
                                    <Row>
                                    <Col md="6">
                                            <p className="gray_text bold">VESSEL</p>
                                            {part.ship_id &&  <p className="black_text-slim">{part.vessel[0].vessel}</p>}
                                        </Col>
                                        <Col md="6">
                                            <p className="gray_text bold">WAREHOUSE</p>
                                            {part.warehouse_id &&   <p className="black_text-slim">{part.warehouse[0].forwarding_company}</p>}
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <p className="gray_text bold">PIECES</p>
                                            <p className="black_text-slim">{part.pieces}</p>
                                        </Col>
                                        <Col md="6">
                                            <p className="gray_text bold">WEIGHT</p>
                                            <p className="black_text-slim">{part.weight}</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md="6">
                                            <p className="gray_text bold">DIMENSIONS</p>
                                            <p className="black_text-slim">{part.dimensions}</p>
                                        </Col>
                                        <Col md="6">
                                            <p className="gray_text bold">SUPPLIER</p>
                                         {part.supplier_id &&   <p className="black_text-slim">{part.supplier[0].supplier_name}</p>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <p className="gray_text bold">REMARKS</p>
                                           {part.remarks && <p className="black_text-slim">{part.remarks}</p>}
                                        </Col>
                                       
                                    </Row>

                                   
                                </Col>
                                <Col className='timeline'>
                                    <p className="gray_text bold">TIMELINE</p>
                                    {
                                    part_transactions &&   part_transactions.reverse().map(trans => {
                                            return (
                                                <React.Fragment>
                                                    <img width={20} src={timelineIcon}></img>
                                                    <span>{dateFormat(trans.date.toLocaleString(),'d-m-yyyy')} {trans.type}  {trans.type=='In Stock'? trans.warehouse[0] ? trans.warehouse[0].forwarding_company : '': trans.type=='Send'?trans.partner?trans.partner:'' :''}</span>
                                                    <br></br><br></br>
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </Col>
                            </Row>
                        </Modal.Body>


                    </Modal>

                    <Modal
                        show={send_modal}
                        onHide={this.closeSendModal}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Body>
                            <h2 className="gray_text bold">SEND</h2>
                            <Row>
                                <Col>
                                    <p className="gray_text bold">PARTNER</p>
                                    <Form.Group >

                                        <Form.Control as="select" onChange={(e) => { this.setState({ partner_id: `${e.target.value}` }) }}>
                                        <option key={0} value=''></option> 
                                            {
                                                partners.map((w, i) => {
                                                    return <option key={w._id} value={w._id} required >{w.partner_name}</option>
                                                })
                                            }
                                        </Form.Control>
                                    </Form.Group>

                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <p className="gray_text bold">DATE</p>
                                    <Form.Group >

                                        <DatePicker style={{ display: "block" }} selected={new_date} onChange={(date) => { this.setState({ new_date: date }) }} />

                                    </Form.Group>

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p className="gray_text bold">Attachments</p>
                                    <input id='upload-image' type='file' onChange={this.getImage} />
                                    <p>{this.state.message}</p>
                                </Col>
                                {
                                this.state.file !== null &&
                                <Col>
                                <p className='gray_text  bold text-uppercase'>Code</p>
                                <Form.Control id="code" value={code} onChange={this.handleChange} placeholder="Enter code" required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide Code.
                                 </Form.Control.Feedback>
                                </Col>
                            }
                            </Row>
                            <br></br>
                            <Row>
                                <Col>
                                <Button className="save-btn" onClick={this.send}>Save</Button>
                                </Col>
                                </Row>



                        </Modal.Body>
                    </Modal>

                    <Modal
                        show={wh_modal}
                        onHide={this.closeWhModal}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Body>
                            <h2 className="gray_text bold">CHANGE WAREHOUSE</h2>
                            <Row>
                                {warehouses &&
                                <Col>
                                    <p className="gray_text bold">NEW WAREHOUSE</p>
                                    <Form.Group >

                                        <Form.Control as="select" onChange={(e) => { this.setState({ new_warehouse_id: `${e.target.value}` }) }}>
                                            <option key={0} value=''></option>
                                            {
                                                warehouses.map((w, i) => {
                                                    return <option key={w._id} value={w._id} required >{w.forwarding_company}</option>
                                                })
                                            }
                                        </Form.Control>
                                    </Form.Group>

                                </Col>
                            }
                            </Row>

                            <Row>
                                <Col>
                                    <p className="gray_text bold">DATE</p>
                                    <Form.Group >

                                        <DatePicker style={{ display: "block" }} selected={new_date} onChange={(date) => { this.setState({ new_date: date }) }} />

                                    </Form.Group>

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p className="gray_text bold">Attachments</p>
                                    <input id='upload-image' type='file' onChange={this.getImage} />
                                    <p>{this.state.message}</p>
                                </Col>
                                {
                                this.state.file !== null &&
                                <Col>
                                <p className='gray_text  bold text-uppercase'>Code</p>
                                <Form.Control id="code" value={code} onChange={this.handleChange} placeholder="Enter code" required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide Code.
                                 </Form.Control.Feedback>
                                </Col>
                            }
                            </Row>
                            <br></br>
                            <Row>
                                <Col>
                                <Button className="save-btn" onClick={this.changeWarehouse}>Save</Button>
                                </Col>
                                </Row>



                        </Modal.Body>
                    </Modal>


                </React.Fragment>

            )
        }
//----------------------------------------------------EDIT PART --------------------------------------------------
        else if (part != undefined && edit === true) {
            return (
                <Modal
                    show={show}
                    onHide={this.onClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Body>

                        <Row>
                            <Col md="6"><h4>Ref No: {reference_no}</h4></Col>
                            <Col md="6"><Button className="edit-trans" onClick={this.editPart}>Save</Button></Col>
                        </Row>
                        <br></br>
                        <Row>
                        <Col md="6">
                            <p className="gray_text bold">Description</p>
                            <Form.Group >
                             <Form.Control id="description" value={description} onChange={this.handleChange} placeholder="Enter Description" required />
                            </Form.Group>
                            </Col> 
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                <Col md="6">
                                        <p className="gray_text bold">REFERENCE NO</p>
                                        <Form.Group >
                                            <Form.Control id="reference_no" value={reference_no} onChange={this.handleChange} placeholder="Enter Reference no" required />

                                        </Form.Group>
                                    </Col>
                                    <Col md="6">
                                        <p className="gray_text bold">SHIP COMPANY</p>
                                        <Form.Group >

                                            <Form.Control as="select" value={shipping_company_name} onChange={(e) => { this.setState({ ship_id: `${e.target.value}`, shipping_company_name: e.target.value }) }}>
                                            <option key={0} value=''></option>
                                                {
                                                   
                                                    ships.map((s, i) => {
                                                        return <option name="ship" key={s._id} value={s._id} >{s.shipping_company_name}</option>
                                                    })
                                                }
                                            </Form.Control>
                                        </Form.Group>

                                    </Col>
                                   
                                </Row>

                                <Row>
                                <Col md="6">
                                        <p className="gray_text bold">VESSEL</p>
                                        <Form.Group>

                                            <Form.Control as="select" value={vessel} onChange={(e) => { this.setState({ vessel_id: `${e.target.value}`, vessel: e.target.value }) }}>
                                            <option key={0} value=''></option>
                                                {
                                                    vessels.map((v, i) => {
                                                        return <option name="vessel" key={v._id} value={v._id} >{v.vessel}</option>
                                                    })
                                                }
                                            </Form.Control>
                                        </Form.Group>

                                    </Col>
                                    <Col md="6">
                                        <p className="gray_text bold">PIECES</p>
                                        <Form.Group >
                                            <Form.Control id="pieces" type="number" value={pieces} onChange={this.handleChange} placeholder="Enter Pieces" required />
                                        </Form.Group>
                                    </Col>

                                   
                                </Row>

                                <Row>
                                <Col md="6">
                                        <p className="gray_text bold">WEIGHT</p>
                                        <Form.Group >
                                            <Form.Control id="weight" value={weight} onChange={this.handleChange} placeholder="Enter Weight" required />

                                        </Form.Group>
                                    </Col>
                                    <Col md="6">
                                        <p className="gray_text bold">DIMENSIONS</p>
                                        <Form.Group >

                                            <Form.Control id="dimensions" value={dimensions} onChange={this.handleChange} placeholder="Enter Dimensions" required />

                                        </Form.Group>
                                    </Col>
                                   
                                </Row>

                                <Row>
                                <Col md="6">
                                        <p className="gray_text bold">SUPPLIER</p>
                                        <Form.Group>

                                            <Form.Control as="select" value={supplier} onChange={(e) => { this.setState({ supplier_id: `${e.target.value}`, supplier: e.target.value }) }}>
                                            <option key={0} value=''></option>
                                                {
                                                    suppliers.map((s, i) => {
                                                        return <option name="ship" key={s._id} value={s._id} required>{s.supplier_name}</option>
                                                    })
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    {warehouses &&
                                    <Col md="6">
                                        <p className="gray_text bold">WAREHOUSE</p>
                                        <Form.Group >

                                            <Form.Control as="select" value={warehouse} onChange={(e) => { this.setState({ warehouse_id: `${e.target.value}`, warehouse: e.target.value }) }}>
                                            <option key={0} value=''></option>
                                                {
                                                warehouses?.map((w, i) => {
                                                 return <option key={w._id} value={w._id} required >{w.forwarding_company}</option>
                                                })
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    }

                                    <Col md="6">
                                        <p className="gray_text bold">REMARKS</p>
                                        <Form.Group >
                                            <Form.Control id="remarks" type="text" value={remarks} onChange={this.handleChange} placeholder="Enter Remarks" required />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                           
                        </Row>
                    </Modal.Body>


                </Modal>
            )
        }
        else return null;
    }

}

const mapDispatchToProps = dispatch => {
    return {
        editPartInfo: payload => dispatch(editPart(payload)),
        addTransaction: payload => dispatch(addTransaction(payload)),
        getPartners:payload => dispatch(getPartners()),


    }
}
const mapStateToProps = state => {
    return {
        part_transactions: state.part_transactions,
        partners:state.partners
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(OpenPart);


