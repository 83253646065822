import React from "react";
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';


const initialState = { type: "Shipping Company", password:'', name: '', email: '', shipping_company_name: '', ship_id:null ,warehouse_name: '',warehouse_id:null, validated: false };

class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = { type: "Shipping Company", name: '',password:'', email: '', shipping_company_name: '', ship_id:null ,warehouse_name: '',warehouse_id:null, validated: false };


        this.handleChange = this.handleChange.bind(this);
    }
    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    }

    addUser = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        event.preventDefault();
        this.setState({ validated: true })
        if (form.checkValidity() === true) {
            this.props.addUser(this.state);
            this.setState(initialState)
        }

    }

    onHide = () => {
        this.setState(initialState)
        this.props.onHide()
    }

    renderType=()=>{
        const{warehouses,all_ships}=this.props;
        const{type}=this.state;
        if(type==="Shipping Company"){
            return(
                <React.Fragment>
                     <Form.Group as={Col} md="4">
                                <p className='gray_text  bold text-uppercase'>Ship Company</p>
                                <Form.Control as="select" onChange={(e) => {this.setState({ ship_id:`${e.target.value}`, warehouse_id:null, warehouse_name:'' })}}>

                                    {
                                        all_ships.map((s, i) => {
                                            return <option name="ship" key={s._id} value={s._id} >{s.shipping_company_name}</option>
                                        })
                                    }
                                </Form.Control>
                            </Form.Group>
                </React.Fragment>
            )
        }
        else{
            return(
                <React.Fragment>
                     <Form.Group as={Col} md="4">
                                <p className='gray_text  bold text-uppercase'>Warehouse</p>
                                <Form.Control as="select" onChange={(e) => { this.setState({ warehouse_id:`${e.target.value}`, ship_id:null, shipping_company_name:'' }) }}>

                                    {
                                        warehouses.map((s, i) => {
                                            return <option name="warehouse" key={s._id} value={s._id} >{s.forwarding_company}</option>
                                        })
                                    }
                                </Form.Control>
                            </Form.Group>
                </React.Fragment>
            )
        }
    }
    render() {
        const { show } = this.props;


        const { password, name, email, validated } = this.state;
        return (
            <Modal
                show={show}
                onHide={this.onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <h4>Add a User</h4>
                    <Form noValidate validated={validated} onSubmit={this.addUser}>

                        <Form.Row>

                            <Form.Group as={Col} md="4">
                                <p className='gray_text  bold text-uppercase'>Type</p>
                                <Form.Control as="select" onChange={(e) => { this.setState({ type: `${e.target.value}` }) }}>
                                    <option key="Shipping Company" value="Shipping Company"  >Shipping Company</option>
                                    <option key="Warehouse Manager" value="Warehouse Manager"  >Warehouse Manager</option>

                                </Form.Control>
                            </Form.Group>
                            
                            <Form.Group as={Col} md="8">
                                <p className='gray_text  bold text-uppercase'>Full Name</p>
                                <Form.Control
                                    id="name" value={name} onChange={this.handleChange} placeholder="Enter Name" required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide Name.
                            </Form.Control.Feedback>
                            </Form.Group>

                           
                        </Form.Row>


                        <Form.Row>

                            <Form.Group as={Col} md="4">
                                <p className='gray_text  bold text-uppercase'>Email</p>
                                <Form.Control
                                    id="email" value={email} type="email" onChange={this.handleChange} placeholder="Enter Email" required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide Email.
                            </Form.Control.Feedback>
                            </Form.Group>

                            {
                                this.renderType()
                            }
                            <Form.Group as={Col} md="4">
                                <p className='gray_text  bold text-uppercase'>Password</p>
                                <Form.Control
                                    id="password" type='password' value={password}  onChange={this.handleChange} placeholder="Enter Password" required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide Password.
                            </Form.Control.Feedback>
                            </Form.Group>

                        </Form.Row>




                        <Button type="submit" className="btn btn-primary mt-2 col-4">Done</Button>
                    </Form>
                </Modal.Body>
            </Modal>
        );

    }

}


export default AddUser;
