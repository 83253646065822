import React from "react";
import { Card, Row, Col } from 'react-bootstrap';
import '../styles.scss';



export default function VesselCard(props) {
    const { vessel, openVessel } = props;
    if(vessel){
    if(vessel.ship){
    if(vessel.ship.length >0)
    return (
        <React.Fragment>
            <Card className="part-detail" onClick={()=>{openVessel(vessel._id)}}>
                    <Row>
                    <Col>
                    <p className='black_text'>{vessel.vessel}</p>
                    </Col>
                    <Col><p className='black_text'>{vessel.ship[0].shipping_company_name}</p></Col>
                    <Col>
                    <p className='black_text'>{vessel.flag}</p>
                    </Col>
                    </Row>
            </Card>
        </React.Fragment>

    );
    }
    }else{
        return null;
    }
}
