import React from 'react'
import style from '../../'
export default function Button({isLoading,type,onClick,text,bgColor='#2E5BFF',fontSize='1em',style}) {
    return (
       <button
       type={type}
       onClick={onClick}
       style={{...style,
       fontSize,
       paddingLeft:'3em',
       paddingRight:'3em',
       paddingTop:'0.5em',
       paddingBottom:'0.5em',
       borderRadius:5,
       borderWidth:0,
       backgroundColor:bgColor,
       display:'flex',
       flexDirection:'column',
       justifyContent:'center',
       alignItems:'center'
       }}>
        <p style={{color:'white',margin:0}}> {isLoading ? "Loading...":text} </p> 
       </button>
    )
}
