import React from 'react'
import Card from './Card';
import { Col } from 'react-bootstrap';

function TransList({data,cssClass,title}) {
    return (
        <Col style={{overflow:'scroll',height:'60vh'}}>
        <p className='text-uppercase'>{title}</p>
        {data &&
            data.map(p => {
                return (
                    <div className={cssClass} >
                        <Card p={p}/>
                    </div>
                )
            })
        }
    </Col>
    )
}

export default TransList
