import React from "react";
import { connect } from "react-redux";
import PartCard from './PartCard';
import { Row, Col, Button ,Form} from 'react-bootstrap';
import Navbar from '../Common/Navbar'
import AddTrans from "./AddTrans";
import Crud from "../../Common/Crud/Crud";
import { partSchema, partValidation } from "./config";
import ExportParts from './ExportParts'
import TimelinePart from './TimelinePart'

class PartsAdmin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reference_no: '', shipping_co: '', vessel: '', warehouse: '', status: '', supplier: '', addPartModal: false,
            part_id: null,
            selectMode:false,
            addTransModal:false,
            selectedParts:[]
        };
    }
 ////=========================== Handle Select =========================== 
    handleSelectedParts = {
        addToSelectedParts: (id)=>{
        const {selectedParts} = this.state;
        if(!selectedParts.includes(id)){selectedParts.push(id);}
        this.setState({selectedParts})
        },
        deleteFromSelectedParts: (id)=>{
        let {selectedParts} = this.state;
        let index_=-1
        selectedParts.forEach((p,index)=> (p._id===id._id) ? index_=index:null)
        if(index_!=-1) selectedParts.splice(index_,1)

        console.log("Delete Selected Parts",selectedParts)
        this.setState({selectedParts})
        },
        resetSelectedParts : ()=>this.setState({selectedParts:[]})

        }
//=========================== End Handle Select ===========================


        changeHandler =(e)=>{
            this.setState({[e.target.id]:e.target.value})
        }
        selectChangeHandler =(name,value)=>{
            console.log("NAME =>",value)
            this.setState({[name]:value})
        }
    
        handleShippingCompanySelect=(e) =>{
            if(e.label=='All'){
            let all_vessels= this.props.all_vessels.map(({vessel,ship_id}) => ({ value: vessel, label:vessel,ship_id}))
            all_vessels.unshift({value:'',label:'All'})
            this.setState({ shipping_co: `${e.value}`,select_vessels:all_vessels})    
            }else{
            let all_vessels= this.props.all_vessels.map(({vessel,ship_id}) => ({ value: vessel, label:vessel,ship_id}))
            let vessels=all_vessels.filter(vessel=>{
                if(vessel.ship_id===e._id){return 1;}
            })
            vessels.unshift({value:'',label:'All'})
            this.setState({ shipping_co: `${e.value}`,select_vessels:vessels }) 
            }
    }
 
    render() {
        const { parts } = this.props;
        //add Part
        const { all_suppliers, all_warehouses, all_vessels, all_ships } = this.props;
        const {selectMode,addTransModal,selectedParts } = this.state;


        if(localStorage.getItem("loginState")=="false" && localStorage.getItem("admin_login")=="false"){
            this.props.history.push("/admin/");
            return null;
          }
        else
            return (
                <Row>
                    <Navbar active="parts" />
                    <Col>
                    <Crud 
                    title='Parts'
                    openRecordTitle='Open Part'
                    dbResponseSingular='part'
                    queryString='adminparts'
                    fetchUrl='part'
                    dbResponse='parts'
                    model='Part'
                    labels={
                    <div id='labels'>
                    <Col><p className='gray_text text-uppercase'>Ref No./Status</p></Col>
                    <Col><p className='gray_text text-uppercase'>Ship company/vessel</p></Col>
                    <Col><p className='gray_text text-uppercase'>Supplier/Warehouse</p></Col>
                    <Col><p className='gray_text text-uppercase'>Specs/Date In</p></Col>
                    </div>
                            }
                    dataCard={<PartCard   part={{}} />}
                    addRecordValidation={partValidation}
                    addRecordSchema={partSchema}
                    exportButton={<ExportParts />}
                    addTransaction={true}
                    hasFirstDate={true}
                    timelinePart={<TimelinePart /> }
                    />

                   

                    </Col>
                </Row>

)
    }
}

const mapStateToProps = state => {
    return {
        parts: state.parts, part: state.part,
        filtered_items: state.filtered_items, filter_applied: state.filter_applied,
        open_item_modal: state.open_item_modal,
        all_suppliers: state.all_suppliers, all_warehouses: state.all_warehouses, all_vessels: state.all_vessels, all_ships: state.all_ships,
        ships_success:state.ships_success, vessels_success:state.vessels_success,warehouses_success:state.warehouses_success,
        parts_success:state.parts_success, suppliers_success:state.suppliers_success
    }
}

export default connect(mapStateToProps, null)(PartsAdmin);

