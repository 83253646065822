import React from "react";
import { Row, Col, Modal, Button, Form } from 'react-bootstrap';
import '../styles.scss'
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import S3FileUpload from 'react-s3';
import {api_callback, shipmentTypes } from "../../globals";
import {
    editTransaction
} from '../../js/actions'
import axios from 'axios';
import { api_url ,config} from "../../globals";
import Select from 'react-select';
import ConfirmDeleteButton from "../../Common/ConfirmDeleteButton/ConfirmDeleteButton";
const initialState = {
    type: '', date: '',edit: false,  file:null
};

var dateFormat = require('dateformat')
class OpenTrans extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: '', 
            date: '',
            remarks:'few',
            edit: false,
            file:null
        };
          
    }

    getImage = e => {
        const files = e.target.files;
       
        if (files && files.length > 0) {
            const file = files[0];
            this.setState({ file });
        }
    };
    onClose = () => {
        this.setState(initialState)
        this.props.onHide();
    }
    upload= async (file)=>{
        return  S3FileUpload.
         uploadFile(file, config)
     }
  
     edit=(transaction)=>{
         this.setState({edit:true,
            type:transaction.type,
            date:transaction.date,
            partner:transaction.partner,
            remarks:transaction.remarks,
            destination:transaction.destination,partner:transaction.partner
        })
         
         
     }
     
    editTrans = (e) => {
        e.preventDefault();
        const { file } = this.state;
        if(file!= null){
            
           this.upload(file).then(data=>{
             api_callback(true,'Success','File Uploaded')
             const {transaction}=this.props;
             const {type, date}=this.state;
             const temp=this.state;
             if(date===''){
                 temp.date=transaction.date;
             }
             if(type===''){
                 temp.type=transaction.type;
             }
             temp.id=transaction._id;
             temp.part_id=transaction.part._id
             temp.attachment = data.location;
             this.props.editTransaction(temp);
             this.setState(initialState)
             this.props.onHide();
            }).catch(err=>{
                api_callback(false,'Error','Uploading File Failed')
            })
            .catch(err=>{
                api_callback(false,'Error','Uploading Failed')
            })

         return 
        }
        if(file!= null){
        this.setState({ message: 'Uploading...' })
        const contentType = file.type; // eg. image/jpeg or image/svg+xml

        const generatePutUrl = api_url()+ 'generate-put-url';
        const options = {
            params: {
                Key: file.name,
                ContentType: contentType,
                Bucket: process.env.S3_BUCKET,
            },
            headers: {
                'Content-Type': contentType
            }
        };

        axios.get(generatePutUrl, options).then(res => {
            const {
                data: { putURL }
            } = res;
            axios
                .put(putURL, file, options)

                .then(res => {
                    const {transaction}=this.props;
                    const {type, date}=this.state;
                    const temp=this.state;
                    if(date===''){
                        temp.date=transaction.date;
                      
                    }
                  
                    if(type===''){
                        temp.type=transaction.type;
                    }
                    temp.id=transaction._id;
                    temp.part_id=transaction.part._id
                    temp.attachment = res.request.responseURL.split("?")[0];
                    this.props.editTransaction(temp);
                    this.setState(initialState)
                    this.props.onHide();
                })
                .catch(err => {
                    this.setState(initialState)
                    this.props.onHide();
                });
        });
    }
    else{
        const {transaction}=this.props;
        const {type, date,shipment_type}=this.state;
        const temp=this.state;
        if(date===''){
            temp.date=transaction.date;
          
        }
        if(type===''){
            temp.type=transaction.type;
        }
        temp.id=transaction._id;
        temp.part_id=transaction.part._id
        if(shipment_type){
            temp.shipment_type =shipment_type
        }
        console.log("EDIT TRANs",temp)

        this.props.editTransaction(temp,this.editTransCallback);
        this.setState(initialState)
      
    }
       
    }

    editTransCallback=(error,res)=>{
        if(error)return console.log(error) 
        this.props.invalidateTransactions()
        this.props.onHide()
    }
    componentDidUpdate(prevProps){
        const{type, date}=this.state;
        if( type === '' && date === '' && this.props.transaction){
            this.setState({type:this.props.transaction.type, date:this.props.transaction.date});
        }
      

    }
 
    
    render() {
        const { transaction, show, deleteTrans } = this.props;
        const {  type, date, edit} = this.state;
        console.log(transaction)
        if (transaction != undefined && edit === false && transaction.part!=undefined) {

            return (
                <Modal
                    show={show}
                    onHide={this.onClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Body>
                        <React.Fragment>

                            <Row>
                                <Col>
                                <h4>Ref No: {transaction.part? transaction.part.reference_no:""}</h4>
                                </Col>
                                <Col>
                                     <ConfirmDeleteButton yesFunction={()=>deleteTrans(transaction._id)}  hideModal={this.onClose}/>
                                    <Button className="edit-trans" onClick={()=>this.edit(transaction)}>Edit</Button>
                                </Col>

                            </Row>
                            <br></br>
                            <Row>
                                <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>SHIP COMPANY</p>
                                    <p>{transaction.ship.length>0 && transaction.ship[0].shipping_company_name}</p>
                                </Col>
                                <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>VESSEL</p>
                                    <p>{transaction.vessel.length>0 && transaction.vessel[0].vessel}</p>
                                </Col>
                            </Row>


                            <Row>
                                <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>PIECES</p>
                                    <p>{transaction?.part.length>0 ? transaction.part[0].pieces :"-"}</p>
                                </Col>
                                <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>WEIGHT</p>
                                    <p>{transaction?.part.weight||"-"}</p>
                                </Col>
                                <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>DIMENSIONS</p>
                                    <p>{transaction?.part.dimensions||"-"}</p>
                                </Col>
                            </Row>

                            <Row>
                                <Col md="4">
                                    <p className="gray_text  bold text-uppercase" >TYPE OF TRANSACTION</p>
                                    <p>{transaction.type||"-"}</p>
                                </Col>
                                <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>DATE</p>
                                    {
                                        transaction.date &&
                                        <p>{dateFormat(transaction.date.toLocaleString(),'d-m-yyyy')}</p>
                                    }
                                   
                                </Col>
                                <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>WAREHOUSE</p>
                                   {transaction.part.warehouse_id && <p>{transaction.warehouse.length>0 && transaction.warehouse[0].forwarding_company}</p>}
                                </Col>
                                {transaction.type.toLowerCase()=='send'?
                                <React.Fragment>
                                    {transaction.partner &&transaction.partner!=''?
                                <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>AWB NO</p>
                                        <p>{transaction.partner||"-"}</p>
                                </Col>
                                :null}
                               {transaction.destination &&transaction.destination!=''?
                                <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>DESTINATION</p>
                                        <p>{transaction.destination||"-"}</p>
                                </Col>
                                :null}
                                {transaction.shipment_type ?
                                <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>Type</p>
                                        <p>{transaction.shipment_type||"-"}</p>
                                </Col>
                                :null}
                                </React.Fragment>:null}
                            </Row>

                            <Row>
                                <Col md="4">
                                <p className='gray_text  bold text-uppercase'>ATTACHMENTS {transaction.code?transaction.code:""}</p>
                                    {transaction.attachment &&  <a class="nobg_btn_blue" href={transaction.attachment} target="_blank">Open File</a>}
                                </Col>
                                {transaction.remarks &&
                                <Col md="8">
                                <p className='gray_text  bold text-uppercase'>Remarks</p>
                                <p className='black_text'>{transaction.remarks||"-"}</p>
                                 </Col> }
                            </Row>
                           


                        </React.Fragment>
                    </Modal.Body>


                </Modal>

            );
        }
        else if (transaction != undefined && edit == true) {
           
            return (
                <Modal
                    show={show}
                    onHide={this.onClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Body>
                        <React.Fragment>

                            <Row>
                                <Col>
                                    <h4>Ref No: {transaction.reference_no}</h4>
                                </Col>
                                <Col>
                                    <Button className="edit-trans" onClick={this.editTrans}>Save</Button>
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>SHIP COMPANY</p>
                                    <p>{transaction.ship.length>0 && transaction.ship[0].shipping_company_name}</p>
                                </Col>
                                <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>VESSEL</p>
                                    <p>{transaction.vessel.length>0 && transaction.vessel[0].vessel}</p>
                                </Col>
                            </Row>


                            <Row>
                                <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>PIECES</p>
                                    <p>{transaction.part.pieces||"-"}</p>
                                </Col>
                                <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>WEIGHT</p>
                                    <p>{transaction.part.weight||"-"}</p>
                                </Col>
                                <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>DIMENSIONS</p>
                                    <p>{transaction.part.dimensions||"-"}</p>
                                </Col>
                            </Row>

                            <Row>
                                <Col md="4">
                                    <p className="gray_text-slim" >TYPE OF TRANSACTION</p>
                                    <Form.Group>

                                        <Form.Control as="select" value={type} onChange={(e) => { this.setState({ type: `${e.target.value}` }) }}>
                                            <option key="Pending" value="Pending"  >Pending</option>
                                            <option key="In Stock" value="In Stock"  >In Stock</option>
                                            <option key="Send" value="Send"  >Send</option>
                                            <option key="Delivered" value="Delivered"  >Delivered</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>DATE</p>
                                    <Form.Group >
                                   
                                        <DatePicker style={{ display: "block" }} className='form-control' dateFormat='d-MM-yyyy' selected={ new Date(`${date}`)} onChange={(date) => { this.setState({ date: date }) }} />
                                       
                                    </Form.Group>
                                    
                                </Col>
                                <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>WAREHOUSE</p>
                                   {transaction.warehouse.length>0 && <p>{transaction.warehouse.length>0 && transaction.warehouse[0].forwarding_company}</p>}
                                </Col>
                              
                            </Row>

                            <Row>
                                <Col md="4">
                                <p className='gray_text  bold text-uppercase'>Attachments</p>
                                <input type="file" id="file" onChange={this.getImage}/>
                                <label for="file" >Choose File</label>
                                 </Col>
                                <Form.Group as={Col} md="4" >
                                <p className="gray_text bold text-uppercase">Remarks</p>
                                <input className='form-control' value={this.state.remarks}  name='remarks' onChange={(e) =>this.setState({ [e.target.name]: e.target.value})} ></input>
                            </Form.Group>
                            {transaction.type.toLowerCase()=='send'?
                            <React.Fragment>
                          {this.state.partner!=null &&  <Form.Group as={Col} md="4" >
                                <p className="gray_text bold text-uppercase">AWB NO</p>
                                <input className='form-control' value={this.state.partner}  name='partner' onChange={(e) =>this.setState({ [e.target.name]: e.target.value})} ></input>
                            </Form.Group>}

                            {this.state.destination!=null &&  <Form.Group as={Col} md="4" >
                                <p className="gray_text bold text-uppercase">Destination</p>
                                <input className='form-control' value={this.state.destination}  name='destination' onChange={(e) =>this.setState({ [e.target.name]: e.target.value})} ></input>
                            </Form.Group>}

                            {transaction.shipment_type!=null &&  <Form.Group as={Col} md="4" >
                                <p className="gray_text bold text-uppercase">Type</p>
                                <Select options={shipmentTypes} defaultValue={shipmentTypes.findIndex(s=>s.value==transaction.shipment_type)!=-1?shipmentTypes[shipmentTypes.findIndex(s=>s.value==transaction.shipment_type)]:shipmentTypes[0]}  
                                onChange={e=>this.setState({shipment_type:e.value})} />
                            </Form.Group>}
                            </React.Fragment>
                            :null}
                        </Row>
                          
                        </React.Fragment>
                    </Modal.Body>


                </Modal>
            )
        }
        else return null;
    }
}
const mapDispatchToProps = dispatch => {
    return {
        editTransaction: (payload,callback) => dispatch(editTransaction(payload,callback)),
    
    }
}
export default connect(null, mapDispatchToProps)(OpenTrans);

