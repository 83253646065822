import React from "react";
import { Row, Col, Modal, Button, Form } from 'react-bootstrap';
import '../styles.scss'
import { connect } from "react-redux";
import {
    editAdmin
} from '../../js/actions'
import axios from "axios";
import { api_url, api_callback } from "../../globals";
import ConfirmDeleteButton from "../../Common/ConfirmDeleteButton/ConfirmDeleteButton";


const initialState = {
   
    email:'',
    password:'',
    edit:false,
    new_password:'',
    old_password:'',
    c_password:'',
    change_pass_validate:false,
};


class OpenAdmin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type:'',
            name:'',
            email:'',
            shipping_company_name:'',
            ship_id:null,
            warehouse_name:'',
            warehouse_id:null,
            edit:false,
            new_password:'',
            old_password:'',
            c_password:'',
            change_pass_validate:false,
           
        };
          
    }

   
    editAdminById = (event) => {
        const{admin}=this.props; 
        let tempData = this.state;
        
       
        tempData.id=admin._id;
        this.props.editAdmin(tempData)
        this.setState(initialState)
        this.props.onHide();
    }
    
    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value })
        
    }
    onClose = () => {
        this.setState(initialState,()=>{
            this.props.onHide();
        })
       
    }

    changePassword = async(event)=>{

        const{admin}=this.props; 
        const {old_password,new_password,c_password}=this.state
        let BASE_URL;
        if(process.env.NODE_ENV=='development'){
           
            BASE_URL = "http://localhost:8080/api/";
        }else{
            BASE_URL = window.location.protocol + "//" + window.location.host+"/api/";
        }
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          };
        await axios.
        post(BASE_URL+"admin/change_password/"+admin._id,{
            old_password,
            new_password,
            c_password
        },config).then(res=>{
            console.log(res)
            api_callback(true,'Success',res.data.message)
            this.onClose()
        }).
        catch(function (error) {
            if (error.response) {
              // Request made and server responded
              console.log(error.response.data);
              if(error.response.data.message){
              api_callback(false,'Error',error.response.data.message)
              }
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
        
          });
    }
   
    editClicked=()=>{
        const admin=this.props.admin
        this.setState({
                email:admin.email,
                password:'',
                fullname:admin.fullname,
                edit:true
            });
    }
    render() {
        const { admin,show,deleteAdmin} = this.props;
        const{ email,password,fullname, edit,old_password,new_password,c_password}=this.state;
        
        if(admin!=undefined && edit == false){
        return (
            <Modal
            show={show}
            onHide={this.onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <React.Fragment>

                    <Row>
                        <Col>
                            <h3>Description</h3>
                            
                          
                        </Col>
                        <Col>

                           
                     <ConfirmDeleteButton yesFunction={()=>deleteAdmin(admin._id)}  hideModal={this.onClose}/>
                            <Button className="edit-trans" onClick={this.editClicked}>
                                Edit
                    </Button>
                        </Col>

                    </Row>
                    <br></br>

                    <Row>
                        <Col md='5'>
                            <p className='gray_text  bold text-uppercase'>Full Name</p>
                            <p>{admin.fullname}</p>
                        </Col>
                        <Col md='5'>
                            <p className='gray_text  bold text-uppercase'>EMAIL</p>
                            <p>{admin.email}</p>
                        </Col>
                       
                    </Row>
                </React.Fragment>
            </Modal.Body>


        </Modal>

    
        );}
        else if(admin!=undefined && edit == true){
            return (
                <Modal
                show={show}
                onHide={this.onClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <React.Fragment>
    
                        <Row>
                            <Col>
                                <h3>Admin</h3>
                              
                            </Col>
                            <Col>  
                                <Button className="edit-trans"onClick={ this.editAdminById}>Save  </Button>
                            </Col>
    
                        </Row>
                        <br></br>
                       <Form>

                        <Form.Row>

                        <Col md='6'>                          
                        <p className='gray_text  bold text-uppercase'>Full Name</p>
                        <Form.Group >
                              
                                <Form.Control
                                    id="fullname" type ="text" value={fullname} onChange={this.handleChange} placeholder="Enter Full Name" required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide email.
                            </Form.Control.Feedback>
                            </Form.Group>
                            </Col>  

                            <Col md='6'>                          
                            <p className='gray_text  bold text-uppercase'>EMAIL</p>
                            <Form.Group md='6' >
                                <Form.Control
                                    id="email" type ="email" value={email} onChange={this.handleChange} placeholder="Enter email" required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide email.
                            </Form.Control.Feedback>
                            </Form.Group>
                            </Col>  

                        </Form.Row>
  
                    </Form>
                    
                <hr></hr>                
                <h4>Change Password</h4>
                   <Form  onSubmit={this.changePassword}>

                    <Form.Row>
                    <Col md='6'>                          
                    <p className='gray_text  bold text-uppercase'>Old Password</p>
                    <Form.Group >
                        
                            <Form.Control
                                id="old_password" type ="password" value={old_password} onChange={this.handleChange} placeholder="Enter Old Password" required />
                            <Form.Control.Feedback type="invalid">
                                Please provide password.
                        </Form.Control.Feedback>
                        </Form.Group>
                        </Col>  

                        <Col md='6'>                          
                        <p className='gray_text  bold text-uppercase'>New Password</p>
                        <Form.Group md='6' >
                        <Form.Control
                                id="new_password" type ="password" value={new_password} onChange={this.handleChange} placeholder="Enter New Password" required />
                            <Form.Control.Feedback type="invalid">
                                Please provide password.
                        </Form.Control.Feedback>
                        </Form.Group>
                        </Col>  


                        <Col md='6'>                          
                        <p className='gray_text  bold text-uppercase'>Confirmation Password</p>
                        <Form.Group md='6' >
                        <Form.Control
                                id="c_password" type ="password" value={c_password} onChange={this.handleChange} placeholder="Enter Confirmation Password" required />
                            <Form.Control.Feedback type="invalid">
                                Please provide password.
                        </Form.Control.Feedback>
                        </Form.Group>
                        </Col>  
                    </Form.Row>

                        <Button className="btn btn-primary mt-2" type='submit'>Change Password</Button>
                    </Form>
    
                    </React.Fragment>
                </Modal.Body>
    
    
            </Modal>
    
        
            );}
        else return null;
    }
}

const mapDispatchToProps = dispatch => {
    return {
        editAdmin: payload => dispatch(editAdmin(payload)),
    
    }
}
export default connect(null, mapDispatchToProps)(OpenAdmin);


