import React from 'react'

export default function MyInput({error,label,onChange,id,name,placeholder,value,defaultValue}) {
    return (
        <div>
           <label className='gray_text'>{label}</label> 
           <input
           id={id}
           name={name}
           value={value==null?"":value}
           onChange={onChange}
           placeholder={placeholder}
           defaultValue={defaultValue}
           className='form-control'
           style={{borderColor:error&&"red",backgroundColor:error&&"rgba(255,0,0,.1)"}}
           />
        {error && <p style={{color:'red'}}>{error}</p>}
        </div>
    )
}
