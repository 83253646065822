import React from "react";
import { Card, Row, Col } from 'react-bootstrap';
import '../styles.scss';



export default function WhCard(props) {


    const { warehouse, openWarehouse } = props;
    return (
        <React.Fragment>
            <Card className="part-detail" onClick={()=>{openWarehouse(warehouse._id)}}>

                    <Row>
                    <Col>
                    <p className='black_text'>{warehouse.forwarding_company}</p>
                    </Col>
                    <Col>
                    <p className='black_text'>{warehouse.country}</p>
                    </Col>
                    <Col>
                    <p className='black_text'>{warehouse.city}</p>
                    </Col>
                    
                    </Row>

            </Card>
        </React.Fragment>

    );
}
