import React from "react";
import { Card, Row, Col } from 'react-bootstrap';
import '../styles.scss';



export default function AdminCard(props) {

        const { admin, openAdmin } = props;
    return (
        <React.Fragment>
            <Card className="part-detail" onClick={()=>{openAdmin(admin._id)}}>
                    <Row>
                    <Col>
                    <p className='black_text'>{admin.fullname}</p>
                    </Col>
                    <Col>
                    <p className='black_text'>{admin.email}</p>
                    </Col>
                    <Col>
                    </Col>
                    </Row>
            </Card>
        </React.Fragment>

    );
}
