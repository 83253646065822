import React from "react";
import { Card, Row, Col } from 'react-bootstrap';
import '../styles.scss';



export default function SupplierCard(props) {


    const { supplier, openSupplier } = props;
    return (
        <React.Fragment>
            <Card className="part-detail" onClick={()=>{openSupplier(supplier._id)}}>
                    <Row>
                    <Col>
                    <p className='black_text'>{supplier.supplier_name}</p>
                    </Col>
                    <Col>
                    <p className='black_text'>{supplier.city}</p>
                    </Col>
                    <Col>
                    <p className='black_text'>{supplier.country}</p>
                    </Col>
                    </Row>
            </Card>
        </React.Fragment>

    );
}
