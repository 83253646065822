import React,{useEffect} from 'react'
import {useQuery,useQueryClient} from 'react-query'
import { getData } from '../../globals'
import dateFormat from 'dateformat'
import icon from '../../Pics/pickupicon.png'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

export default function TimelinePart({_id}) {
//Fetching Data
const queryClient = useQueryClient()
const { isLoading, data, error, isFetching, isPreviousData } = useQuery(
    ['parttimeline',_id],
    () => getData(`transaction?reference_no=${_id}`),
    {enabled:!!_id}
  )

  useEffect(() => {
      if(data){
      console.log("TRANS",data)
      }
  }, [data])
    return (
        <div className='col-4'>
           <p className="gray_text bold">Timeline</p>
            {isLoading?
            <SkeletonTheme color='#F2F2F2' highlightColor='#FAFEFF'> <Skeleton style={{marginBottom:5}} height='20px' count={2}/></SkeletonTheme> 
            :
            data &&   data.transactions.map(trans => {
                    return (
                        <React.Fragment>
                            <img width={20} src={icon}></img>
                            <span>{dateFormat(trans.date.toLocaleString(),'d-m-yyyy')} {trans.type}  {trans.type=='In Stock'? trans.warehouse[0] ? trans.warehouse[0].forwarding_company : '': trans.type=='Send'?trans.partner?trans.partner:'' :''}</span>
                            <br></br><br></br>
                        </React.Fragment>
                    )
                })
            }
        </div>
    )
}
