import React from "react";
import { connect } from "react-redux";
import TransCard from './TransCard';
import AddTrans from './AddTrans';
import Crud from '../../Common/Crud/Crud'
import ReactLoading from 'react-loading';
import { Row, Col, Button, Form, Modal, Spinner } from 'react-bootstrap';
import ExportTransactions from './ExportTransactions'
import "react-datepicker/dist/react-datepicker.css";
import MultiSelect from "react-multi-select-component";
import Select from 'react-select';
import {
    getAllAdminVessels, getAllAdminWarehouses, getAllAdminShips,getPartners,
    filterTransactions, getAllTransactions, addTransaction, delTransaction,
    getAllAdminParts,
    getTransactionById, closeOpenedPart, closeError
} from '../../js/actions'
import OpenTrans from "./openTrans";
import Navbar from '../Common/Navbar'
import ExportFortotikes from "./ExportFortotikes";
import { CSVLink, CSVDownload } from "react-csv";

import Filters from "../../Common/Filters/Filters";
import { partSchema, partValidation } from "../Parts/config";
var dateFormat = require('dateformat')
//ffecee



class Transactions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reference_no: [],data:[], shipping_co: '', vessel: '', warehouse: '', status: '', fromDate: null, toDate:null,addPartModal: false,exportModal:false,
            is_filtered:false,
            invalidateTransactions:null
           

        };
        this.filterInput = this.filterInput.bind(this);
    }

    setinvalidateTransactions=(invalidateTransactions)=> this.setState({invalidateTransactions})

    componentDidMount = () => {
        
        /*
        this.props.getAllTransactions();
        this.props.getAllAdminWarehouses();
        this.props.getAllAdminVessels();
        this.props.getAllAdminShips();
        this.props.getAllAdminParts();
        this.props.getPartners();
       */
    }

    componentWillReceiveProps=(nextProps)=>{
        if(nextProps.ships_success){
            let all_ships=[]
           all_ships= nextProps.all_ships.map(({shipping_company_name,_id }) => ({ value: shipping_company_name, label:shipping_company_name , _id }))
           all_ships.unshift({value:'',label:'All'})
           this.setState({select_ships:all_ships})
           }
        if(nextProps.warehouses_success){
         let all_warehouses= nextProps.all_warehouses.map(({_id,forwarding_company }) => ({ value: forwarding_company, label:forwarding_company  }))
         all_warehouses.unshift({value:'',label:'All'})
         this.setState({select_warehouses:all_warehouses})
         }
        if(nextProps.vessels_success){
        
        if(this.state.select_vessels==undefined){
         let all_vessels= nextProps.all_vessels.map(({vessel,ship_id}) => ({ value: vessel, label:vessel,ship_id}))
         all_vessels.unshift({value:'',label:'All'})
         this.setState({select_vessels:all_vessels})
        }
         }
    }


    search = (e) => {
        e.preventDefault();
        const { reference_no, shipping_co, vessel, warehouse, status, fromDate, toDate } = this.state;
        let from_date = fromDate;
        let to_date = toDate;
        if(fromDate!=null && toDate!=null){
        from_date = dateFormat(fromDate,'yyyy-m-d');
        to_date = dateFormat(toDate,'yyyy-m-d');
        }
        this.setState({is_filtered:true})
        this.props.filterTransactions({
          reference_no, 
          shipping_co , 
          vessel , 
          warehouse ,
          type: status, 
          from_date,
          to_date
        })
    }

    filterInput = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    
    }
    
    renderTransactions = () => {

        const { all_transactions, filtered_items, filter_applied, transactions_success} = this.props;
        console.log("FILTERED APPLIES",filter_applied)
        if (filter_applied) {
            console.log("Filtered",filtered_items)
            if (filtered_items.length > 0) {
                return (filtered_items.map((p, i) => {
                    return (
                            <TransCard transaction={p} key={i} openTrans={this.openTrans} />
                    )
                }))
            } 
        }
        else {
            if (transactions_success) {
                console.log("All Tran",all_transactions)
                return (
                    all_transactions.map((p, i) => {
                        return (
                         <TransCard transaction={p} key={i} openTrans={this.openTrans} />
                        )
                    })
                )
            }
            else {
                return ( <ReactLoading type={'spin'} height='2em' width='2em' color={'#2E5BFF'}  />)
            }
        }
    }

    openTrans = (id) => {
        this.props.getTransactionById({ id: `${id}` });
    }
  
        
    deleteTrans = (id) => {
        this.props.delTransaction({ id: `${id}` },this.deleteTransCallback);
       
    }

    deleteTransCallback =(err,response)=>{
        if(err)return console.log(err)
        this.state.invalidateTransactions()
        this.props.closeOpenedPart();
    }
    
  changeHandler =(e)=>{
    this.setState({[e.target.id]:e.target.value})
  } 
  
  selectChangeHandler =(name,value)=>{
    this.setState({[name]:value})
   }
    
handleShippingCompanySelect=(e) =>{
        if(e.label=='All'){
        let all_vessels= this.props.all_vessels.map(({vessel,ship_id}) => ({ value: vessel, label:vessel,ship_id}))
        all_vessels.unshift({value:'',label:'All'})
         this.setState({ shipping_co: `${e.value}`,select_vessels:all_vessels})    
        }else{
         let all_vessels= this.props.all_vessels.map(({vessel,ship_id}) => ({ value: vessel, label:vessel,ship_id}))
         let vessels=all_vessels.filter(vessel=>{
            if(vessel.ship_id===e._id){return 1;}
           })
           vessels.unshift({value:'',label:'All'})
        this.setState({ shipping_co: `${e.value}`,select_vessels:vessels }) 
        }
 }
    render() {
        const { all_transactions, transaction, parts, open_item_modal, closeOpenedPart } = this.props;
        const { all_warehouses, all_vessels, all_ships,partners } = this.props;
        const { reference_no, shipping_co, vessel, warehouse, type, fromDate,toDate, addPartModal,edit } = this.state;
        const{openConfirm, error,err_msg,success_msg,closeError}=this.props;

        // error handling
        const {ships_success, vessels_success, warehouses_success, parts_success}=this.props;
        let options=[];
        if(parts.length>0){
            options= parts.map(p => {
                return { label: p.reference_no, value: p._id }
            })
        
        }

        if(localStorage.getItem("loginState")=="false" && localStorage.getItem("admin_login")=="false"){
            this.props.history.push("/admin/");
            return null;
          }
        else
        return (
            <Row>
                <Navbar active="transactions" />
                  
                        <Col>
                        
                           <Crud 
                            title='Transactions'
                            queryString='admintransactions'
                            fetchUrl='transaction'
                            dbResponse='transactions'
                            model='Transaction'
                            labels={<div id='labels'>
                            <Col><p className='gray_text text-uppercase'>Ref No./Status</p></Col>
                            <Col><p className='gray_text text-uppercase'>Ship company/vessel</p></Col>
                            <Col><p className='gray_text text-uppercase'>Supplier/Warehouse</p></Col>
                            <Col><p className='gray_text text-uppercase'>Specs/Date In</p></Col>
                            </div>}
                          
                            exportButton={<ExportTransactions /> }
                            dataCard={ <TransCard transaction={{}}  openTrans={this.openTrans}/>}
                            addRecordValidation={partValidation}
                            addRecordSchema={partSchema}
                            openRecordTitle='Open Transaction'
                            dbResponseSingular='transaction'
                            hasDates={true}
                            shouldAdd={false}
                            setinvalidateTransactions={this.setinvalidateTransactions}

                    />


                      </Col>
                     

                        <OpenTrans 
                        transaction={transaction} 
                        show={open_item_modal} 
                        onHide={closeOpenedPart} 
                        deleteTrans={this.deleteTrans}
                        invalidateTransactions={this.state.invalidateTransactions}
                        />
                      
            </Row>
        )
    }
}
const mapDispatchToProps = dispatch => {
    return {

        getAllTransactions: payload => dispatch(getAllTransactions()),
        addTransaction: payload => dispatch(addTransaction(payload)),
        getTransactionById: payload => dispatch(getTransactionById(payload)),
        delTransaction: (payload,callback) => dispatch(delTransaction(payload,callback)),

        getAllAdminShips: payload => dispatch(getAllAdminShips()),
        getAllAdminVessels: payload => dispatch(getAllAdminVessels()),
        getAllAdminWarehouses: payload => dispatch(getAllAdminWarehouses()),
        filterTransactions: payload => dispatch(filterTransactions(payload)),
        getAllAdminParts: payload => dispatch(getAllAdminParts(payload)),

        closeOpenedPart: payload => dispatch(closeOpenedPart(payload)),
        closeError: payload => dispatch(closeError()),

        getPartners:payload => dispatch(getPartners()),




    }
}
const mapStateToProps = state => {
    return {
        all_transactions: state.all_transactions, transaction: state.transaction,
        filtered_items: state.filtered_items, filter_applied: state.filter_applied,
        open_item_modal: state.open_item_modal,
        all_warehouses: state.all_warehouses, all_vessels: state.all_vessels, all_ships: state.all_ships,
        parts: state.parts,
        transaction: state.transaction,
        partners:state.partners,
        error: state.error, err_msg: state.err_msg, success_msg: state.success_msg, openConfirm: state.openConfirm,
        ships_success:state.ships_success, vessels_success:state.vessels_success,warehouses_success:state.warehouses_success,
        parts_success:state.parts_success, transactions_success:state.transactions_success


    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);


