import React from 'react';
import "./Styles/bootstrap.scss"
import "./Styles/bootstrap-grid.min.scss";
import "./Styles/style.scss"
import { Route, BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import store from "../src/js/store"
import './App.scss';


// user
import LogInForm from './User/LogInForm'
import SignUp from './User/SignUp'
import Home from './User/Home'
import Parts from './User/Parts'
import UserTransactions from './User/Transactions'
import UserSettings from './User/Settings'
import UnAuthorized from './User/UnAuthorized'

//warehouse
import WhHome from './Warehouse/Home'
import WhParts from './Warehouse/Parts'
import WhTransactions from './Warehouse/Transactions'
import WhSettings from './Warehouse/Settings'

// admin
import LoginFormAdmin from './Admin/LogInFormAdmin'
import HomeAdmin from './Admin/Home'
import PartsAdmin from './Admin/Parts'
import ShippingCompanys from './Admin/ShippingCompanys'
import Vessels from './Admin/Vessels'
import Suppliers from './Admin/Suppliers'
import Warehouses from './Admin/Warehouses'
import Transactions from './Admin/Transactions'
import Users from './Admin/Users'
import Settings from './Admin/Settings'
import UserVessels from './User/Vessels'
import './react_dates_overrides.css'

class App extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Route exact path="/" component={LogInForm} />
          <Route exact path="/SignUp" component={SignUp} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/parts" component={Parts} />
          <Route exact path="/vessels" component={UserVessels} />
          <Route exact path="/transactions" component={UserTransactions} />
          <Route exact path="/settings" component={UserSettings} />
          <Route exact path="/unauthorized" component={UnAuthorized} />

          <Route exact path="/manager/home" component={WhHome} />
          <Route exact path="/manager/parts" component={WhParts} />
          <Route exact path="/manager/transactions" component={WhTransactions} />
          <Route exact path="/manager/settings" component={WhSettings} />


          <Route exact path="/admin" component={LoginFormAdmin} />
          <Route exact path="/admin/home" component={HomeAdmin} />
          <Route exact path="/admin/parts" component={PartsAdmin} />
          <Route exact path="/admin/shipping" component={ShippingCompanys} />
          <Route exact path="/admin/vessels" component={Vessels} />
          <Route exact path="/admin/suppliers" component={Suppliers} />
          <Route exact path="/admin/warehouses" component={Warehouses} />
          <Route exact path="/admin/transactions" component={Transactions} />
          <Route exact path="/admin/users" component={Users} />
          <Route exact path="/admin/settings" component={Settings} />


        </BrowserRouter>
      </Provider>  
    );
  }
}

export default App;
