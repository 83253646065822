import React from "react";
import { Card, Row, Col } from 'react-bootstrap';
import '../styles.scss';



export default function UserCard(props) {

    const renderType=()=>{
    const{user}=props

    if(user.type==="Shipping Company"){
        if(user.ship.length==0){
            return(
                <React.Fragment>
                <p className='black_text'>{user.type}</p>
            </React.Fragment>
            )
        }
        else{
            return(
                <React.Fragment>
                <p className='black_text'>{user.type}</p>
            </React.Fragment>
            )
        }
        
    }else{
        return(
            <React.Fragment>
                <p className='black_text'>{user.type}</p>
            </React.Fragment>
        )
    }
}
    const { user, openUser } = props;
    return (
        <React.Fragment>
            <Card className="part-detail" onClick={()=>{openUser(user._id)}}>

                    <Row>
                    <Col>
                    <p className='black_text'>{user.name}</p>
                    </Col>
                    <Col>
                    {renderType()}
                    </Col>
                    <Col>
                    <p className='black_text'>{user.email}</p>
                    </Col>

                     <Col>
                    <p className='black_text'>{user.password}</p>
                    </Col>
                    </Row>

            </Card>
        </React.Fragment>

    );
}
