import React from "react";
import { connect } from "react-redux";
import VesselCard from './VesselCard';
import AddVessel from './addVessel';
//import openPart from './openVessel';
import { Row, Col, Button,Form ,Modal, Spinner} from 'react-bootstrap';
import { getAllAdminVessels,getAllAdminShips,addVessel,filterVesselsAdmin, closeError,closeOpenedPart,
getVessel, editVessel, deleteVessel, reset_filtered_items } from '../../js/actions'
import OpenVessel from "./openVessel";
import Navbar from '../Common/Navbar'
import { sort_by_field } from "../../globals";
import ReactLoading from 'react-loading';
class Vessels extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shipping_co:'', addPartModal: false, 
            vessel_id: null,
         
        };
       
    }
    componentWillMount=()=>{
        this.props.reset_filtered_item();
    }
    componentDidMount = () => {
        this.props.getAllAdminShips();
        this.props.getAllAdminVessels();
    }
    search = (e) => {
        e.preventDefault();
        const { shipping_co } = this.state;
        this.props.filterVesselsAdmin({ shipping_co: shipping_co});
    }
   
    renderVessels = () => {
        const { all_vessels, filter_applied,filtered_items , vessels_success} = this.props;
        if (filter_applied ) {
            if(filtered_items.length > 0){
                return (filtered_items.map((p, i) => {
                    if(!p.type && !p.reference_no){
                    return(
                            <VesselCard vessel={p} key={i} openVessel={this.openVessel}/>
                        )
                    }
                }))
            }
        }
        else {
            if(vessels_success){
                return (
                    all_vessels.map((p, i) => {
                        return (
                                <VesselCard vessel={p} key={i} openVessel={this.openVessel} />
                        )
                    })
                )
            }
            else{
                return ( <ReactLoading type={'spin'} height='2em' width='2em' color={'#2E5BFF'}  />)
            }
        }
    }

    openVessel = (id) => {
        this.props.getVessel({ id: `${id}` });
    }
    addVessel = (data) => {
        const { phone_no,shipping_co } = data;
        const{all_ships}=this.props;

        if (shipping_co === '')
            data.shipping_co = all_ships[0]._id

       
        this.props.addVessel(data)
        this.setState({ addPartModal: false });
        this.props.getAllAdminVessels();
    }
    deleteVessel = (id) => {
        this.props.deleteVessel({ id: `${id}` });
        this.props.closeOpenedPart();
    }


    render() {
        const { all_vessels,all_ships,vessel,ships_success } = this.props;
        const { shipping_co,addPartModal } = this.state;

        const { error, err_msg, success_msg, closeError ,openConfirm,open_item_modal,closeOpenedPart} = this.props;
        if(localStorage.getItem("loginState")=="false" && localStorage.getItem("admin_login")=="false"){
            this.props.history.push("/admin/");
            return null;
          }
        else
            return (
                <React.Fragment>
                <Row>
                    <Navbar active="vessels" />
                    <Col>
                        <Row className="top-header">

                        </Row>

                        <Row className="parts-main">
                            <Col md="10">
                                <Row>
                                    <Col><h2>Vessels</h2></Col>
                                    <Col><p className='gray_text pt-3'>{all_vessels.length} Total</p></Col>
                                    <Col> <Button variant="primary" className="add-part-btn" onClick={() => { this.setState({ addPartModal: true }) }}>+ Vessel</Button></Col>
                                </Row>
                                <div id='labels'>
                                    <Col style={{padding:0}}><p className='gray_text text-uppercase'>Vessel</p></Col>
                                    <Col style={{padding:0}}><p className='gray_text text-uppercase'>Company</p></Col>
                                    <Col style={{padding:0}}><p className='gray_text text-uppercase'>Flag</p></Col>
                                </div>
                                <div style={{overflow:'scroll',height:'82vh',padding:10}}>
                                {
                                    this.renderVessels()
                                }
                                </div>

                            </Col>

                            <Col md="2" className="parts-filter">
                                <p>FILTER</p>
                                <Form onSubmit={this.search}>
                                    {
                                        ships_success &&
                                        <Form.Group >
                                        <p className='gray_text  bold text-uppercase'>Company Name</p>
                                        <Form.Control as="select" onChange={(e) => { this.setState({ shipping_co: `${e.target.value}` }) }}>
                                        <option key="0" value=""  >Choose...</option>
                                            {
                                               sort_by_field(all_ships,'shipping_company_name').map((s, i) => {
                                                    return <option name="ship" key={s._id} value={s.shipping_company_name} >{s.shipping_company_name}</option>
                                                })
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                    }
                                
                                    <button className="btn btn-primary search-btn">Search</button>
                                    </Form>
                               
                            </Col>

                            <AddVessel show={addPartModal} onHide={() => { this.setState({ addPartModal: false }) }}
                               ships={all_ships} addVessel={this.addVessel} />

                            <OpenVessel vessel={vessel} all_ships={all_ships} show={open_item_modal} onHide={closeOpenedPart}
                            deleteVessel={this.deleteVessel} />

                        </Row>
                    </Col>

                </Row>

                
                </React.Fragment>





            )


    }
}
const mapDispatchToProps = dispatch => {
    return {
        getAllAdminVessels: payload => dispatch(getAllAdminVessels()),
        getAllAdminShips:payload => dispatch(getAllAdminShips()),
        addVessel:payload => dispatch(addVessel(payload)),
        filterVesselsAdmin:payload => dispatch(filterVesselsAdmin(payload)),

        closeError: payload => dispatch(closeError()),
        closeOpenedPart:payload => dispatch(closeOpenedPart()),
        getVessel: payload => dispatch(getVessel(payload)),
        editVessel: payload => dispatch(editVessel(payload)),
        deleteVessel: payload => dispatch(deleteVessel(payload)),
        reset_filtered_item: () => dispatch(reset_filtered_items())
    }
}
const mapStateToProps = state => {
    return { all_vessels: state.all_vessels, all_ships:state.all_ships, vessel: state.vessel, 
        filtered_items: state.filtered_items, filter_applied: state.filter_applied,
        error: state.error, err_msg: state.err_msg, success_msg: state.success_msg,openConfirm:state.openConfirm,
        open_item_modal: state.open_item_modal,
        ships_success:state.ships_success, vessels_success:state.vessels_success
      }
}

export default connect(mapStateToProps, mapDispatchToProps)(Vessels);
