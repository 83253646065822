import React from 'react'
import {Modal} from 'react-bootstrap'
import MyInput from '../Forms/MyInput'
import MySelect from '../Forms/MySelect'
import Row from '../Layout/Row'
import { Formik,useFormik } from 'formik'
import Button from '../Forms/Button'
import { api_callback, postData } from '../../globals'
import {useMutation,useQueryClient} from 'react-query'
import MyModal from '../Modal/MyModal'
import MyDatePicker from '../Forms/MyDatePicker'
export default function AddRecord({validationSchema,formSchema=[],show,setShow}) {
    const queryClient =  useQueryClient()
    
    const formik = useFormik({
        onSubmit: body => {
            let newbody = {...body}
            if(body["first_date"]!=null){
                let isoDate = new Date(body["first_date"])
                //isoDate.setUTCHours(0,0,0,0)
                
                newbody["first_date"]= isoDate.toISOString()
            }
            addRecordMutation.mutate(newbody)
            //alert(JSON.stringify(newbody))
        },
        validateOnBlur:false,
        validateOnChange:false,
        initialValues: {status:'Pending'},
        validationSchema:validationSchema
    }
      );
       // Add Record Mutation
    const addRecordMutation = useMutation((body)=>postData(body,'part'), {
      onSuccess: () => {
      formik.resetForm({status:'Pending'})
      api_callback(true,"Success","Part Added")
      queryClient.invalidateQueries(['adminparts',0])
      setShow(false)
      },
      onError:(err)=>{
        console.log("Error",err.response.data)
        if(err.response.data){
            if(err.response.status===400){
                if(err.response.data?.message){
                    api_callback(false,"Fail",err.response.data?.message||"")
                }
            }
        }
      }
    })

    return (
      <MyModal
      show={show}
      setShow={setShow}
      title='Add a Part'
      >
          <form onSubmit={formik.handleSubmit}>
            <Row style={{flexWrap:'wrap'}}>
                {formSchema.map(element=>{
                    
                    if(element.type=='select'){
                    return  <div className='col-4'> 
                    <MySelect 
                    name={element.name}
                    options={element.metadata?.options}
                    label1={element?.metadata?.label1} 
                    getUrl={formik.values[element?.metadata?.filterBy]? `${element?.metadata?.getUrl}/ship/${formik.values[element?.metadata?.filterBy]}` :element?.metadata?.getUrl} 
                    dbResponse={element?.metadata?.dbResponse} 
                    queryString={element?.metadata?.queryString} 
                    label={element.label} 
                    onChange={formik.setFieldValue} 
                    error={formik.errors[element.name]}
                    hasAllOption={false}
                    defaultValue={formik.initialValues[element.name]}
                    />
                    </div>
                    }else if(element.type=='input'){
                        return<div className='col-4'>
                            <MyInput 
                            label={element.label} 
                            value={formik.values[element.name]}  
                            onChange={formik.handleChange} 
                            id={element.name} 
                            name={element.name} 
                            error={formik.errors[element.name]}
                            /> </div> 
                       }else if(element.type=='date'){
                        return<div className='col-4'>
                            <MyDatePicker
                            label={element.label}
                            onChange={formik.setFieldValue}
                            name={element.name}
                            error={formik.errors[element.name]}
                            /> 
                            </div> 
                    }
                })}
            <div className='col-12 pt-3 pb-2'>
            <Button isLoading={addRecordMutation.isLoading} text='Submit' type='submit' />
           </div>
           </Row>
           
           </form>
      </MyModal> 
    )
}
