import React from "react";
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'


const initialState = { vessel: '', shipping_co: '', flag: '', phone_no: '', email: '', validated: false };

class AddVessel extends React.Component {
    constructor(props) {
        super(props);
        this.state = { vessel: '', shipping_co: '', flag: '', phone_no: '', email: '', validated: false };
        

        this.handleChange = this.handleChange.bind(this);
    }
    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });

    }

    addVessel = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        event.preventDefault();
        this.setState({ validated: true })
        if (form.checkValidity() === true) {
            this.props.addVessel(this.state);
            this.setState(initialState)
        }
        
    }

    onHide = () => {
        this.setState(initialState)
        this.props.onHide()
    }
    render() {
        const { show, ships } = this.props;


        const { vessel, flag, phone_no, email, validated } = this.state;
        return (
            <Modal
                show={show}
                onHide={this.onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <h4>Add a Vessel</h4>
                    <Form noValidate validated={validated} onSubmit={this.addVessel}>

                        <Form.Row>

                            <Form.Group as={Col} md="8" >
                                <p className='gray_text  bold text-uppercase'>VESSEL</p>
                                <Form.Control id="vessel" value={vessel} onChange={this.handleChange} placeholder="Enter Vessel Name " required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide Vessel Name
                            </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md="4">
                                <p className='gray_text  bold text-uppercase'>Managing Company</p>
                                <Form.Control as="select" onChange={(e) => { this.setState({ shipping_co: `${e.target.value}` }) }}>
                                   
                                    {
                                        ships.map((s, i) => {
                                            return <option name="ship" key={s._id} value={s._id} >{s.shipping_company_name}</option>
                                        })
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>


                        <Form.Row>

                            <Form.Group as={Col} md="4">
                                <p className='gray_text  bold text-uppercase'>FLAG</p>
                                <Form.Control
                                    id="flag" value={flag} onChange={this.handleChange} placeholder="Enter Flag" required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide flag.
                            </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md="4">
                                <p className='gray_text  bold text-uppercase'>PHONE NUMBER</p>
                                <ReactPhoneInput
                                        country={'gr'}
                                        inputExtraProps={{
                                        id:'phone_no',
                                        name: "phone_no",
                                        }}
                                        containerStyle={{
                                        marginLeft:0,
                                        height:'2.6rem'
                                        }}
                                        buttonStyle={{
                                        background:'rgba(224,231,255,0.2)',borderColor:'#E0E7FF' 
                                        }}
                                        inputStyle={{background:'rgba(224,231,255,0.2)',borderColor:'#E0E7FF',height:'2.6rem',width:'100%'}}
                                        value={phone_no}
                                        enableSearch={true}
                                        onChange={(phone_no)=>this.setState({phone_no})}
                                        /> 
                                <Form.Control.Feedback type="invalid">
                                    Please provide phone number.
                            </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md="4">
                                <p className='gray_text  bold text-uppercase'>EMAIL</p>
                                <Form.Control type="email" id="email" value={email} onChange={this.handleChange} placeholder="Enter Email" required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide email
                            </Form.Control.Feedback>
                            </Form.Group>


                        </Form.Row>




                        <Button type="submit" className="btn btn-primary col-4 mt-3">Done</Button>
                    </Form>
                </Modal.Body>
            </Modal>
        );

    }

}


export default AddVessel;
