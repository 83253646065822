import React from "react";
import { Row, Col, Modal, Button, Form } from 'react-bootstrap';
import '../styles.scss'
import { connect } from "react-redux";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import {
    editTransaction
} from '../../js/actions'
import Files from 'react-files'


const initialState = {
    type: '', date: '',edit: false, 
};
var dateFormat = require('dateformat')
class OpenTrans extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: '', 
            date: '',
            edit: false 
        };
          
    }

    onClose = () => {
        this.setState(initialState)
        this.props.onHide();
    }

    editTrans = (event) => {
        const {transaction}=this.props;
        const {type, date}=this.state;
        const temp=this.state;
        if(date===''){
            temp.date=transaction.date;
          
        }
        if(type===''){
            temp.type=transaction.type;
        }
        temp.id=transaction._id;
        temp.part_id=transaction.part._id
        
        this.props.editTransaction(temp);
        this.setState(initialState)
        this.props.onHide();
    }
    componentDidUpdate(prevProps){
        const{type, date}=this.state;
        if(type === '' && date === '' && this.props.transaction){
            this.setState({type:this.props.transaction.type, date:this.props.transaction.date});
        }
      

    }
 
    
    render() {
        const { transaction, show, deleteTrans } = this.props;
        const {  type, date, edit} = this.state;

        console.log(this.state);

        if (transaction != undefined) {
            return (
                <Modal
                    show={show}
                    onHide={this.onClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Body>

                        <React.Fragment>

                            <Row>
                                <Col>
                                    <h4>Ref No: {transaction.reference_no}</h4>
                                </Col>
                               
                            </Row>
                            <br></br>
                            <Row>
                                <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>SHIP COMPANY</p>
                                    <p>{transaction.ship[0].shipping_company_name}</p>
                                </Col>
                                <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>VESSEL</p>
                                    <p>{transaction.vessel[0].vessel}</p>
                                </Col>
                            </Row>


                            <Row>
                                <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>PIECES</p>
                                    <p>{transaction.part.pieces}</p>
                                </Col>
                                <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>WEIGHT</p>
                                    <p>{transaction.part.weight}</p>
                                </Col>
                                <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>DIMENSIONS</p>
                                    <p>{transaction.part.dimensions}</p>
                                </Col>
                            </Row>

                            <Row>
                                <Col md="4">
                                    <p className="gray_text bold text-uppercase" >TYPE OF TRANSACTION</p>
                                    <p>{transaction.type}</p>
                                </Col>
                                <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>DATE</p>
                                    {
                                        transaction.date &&
                                        <p>{dateFormat(transaction.date.toLocaleString(),'d-m-yyyy')}</p>
                                    }
                                   
                                </Col>
                                <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>WAREHOUSE</p>
                                    {transaction.warehouse_id &&   <p>{transaction.warehouse[0].forwarding_company}</p>}
                                </Col>
                            </Row>

                            <Row>
                            <Col md="4">
                                <p className='gray_text  bold text-uppercase'>ATTACHMENTS {transaction.code?transaction.code:""}</p>
                                    {
                                        transaction.attachment &&  <a class="nobg_btn_blue" href={transaction.attachment} target="_blank">Open File</a>
                                    }
                                   
                                </Col>

                            </Row>



                        </React.Fragment>
                    </Modal.Body>


                </Modal>

            );
        }
        else{
            return null;
        }
           
    }
}
const mapDispatchToProps = dispatch => {
    return {
        editTransaction: payload => dispatch(editTransaction(payload)),
    
    }
}
export default connect(null, mapDispatchToProps)(OpenTrans);

