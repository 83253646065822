import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';

import Joi from "joi-browser";
import Form from "../../Common/Form";
import InputField from "../../Common/InputField";
import logo from "../../logo.svg";

import './styles.scss'
import { api_callback } from '../../globals';
import {userLogin} from '../../js/actions'


class LogInForm extends Form{
    constructor(props){
        super(props);
        this.state={
            data: { email: "", password: "" },
            error: {},
            loading: false,
            loginButtonSpinner: false
        };
    }

      // schema for valiation
  schema = {
    email: Joi.string().email({ minDomainAtoms: 2 }).required().label("Email"),
    password: Joi.string().required().label("Password"),
  };

  spinnerButton = () => {
    this.setState({ loginButtonSpinner: true });
  };
    //Submit the form
    login = (e) => {
      e.preventDefault();
      //this.spinnerButton();
      const{email, password}=this.state.data
      // validation of input fields
      const error = this.validate();
      this.setState({ error: error || {} });
      //if error occur don't call api
      if (error) return null;
      //call the api
      this.props.userLogin({email, password});
    };

render(){
  const {loginState,user_login}=this.props;
  if(loginState==true && user_login==true){
    let user = JSON.parse(localStorage.getItem("user"))
    if(user.ship_id){
      console.log(user.ship_id)
      this.props.history.push("/home");
      return null;
    }
    else{
        localStorage.setItem('loginState', false);
        localStorage.setItem('user_login', false);
        localStorage.setItem('user', false);
        localStorage.setItem('token', undefined);
      this.props.history.push("/unauthorized");
      return null;
    }

  }
  
  else 
  return(
        <React.Fragment>
          <div
            className="container-fluid h-100"
            style={{ background: "#ffffff" }}
          >
            <div className="row h-100">
              <div className="logo--position d-inline">
                <img src={logo} className="img-fluid" alt="logo"></img>
              </div>
              <div className="col-md-4 offset-md-1">
                <div className="top__login--margin">
                  <div className="form__padding--smallscreen">
                    <h2 className="fontchange d-block">
                      Sign in to Speedair
                    </h2>
                    <p className="text--color">
                      Please enter your credentials to proceed.
                    </p>
                    {/*--------------Invalid Credentials--------  */}
                    {this.props.inValidLogin && (
                      <div
                        className="alert alert-danger"
                        style={{ fontSize: "14px" }}
                      >
                        The email and password you entered did not match our
                        records. Please double-check and try again.
                      </div>
                    )}
                    {/*-----------------Form begins--------------*/}
                    <form onSubmit={this.login} style={{marginTop:"20%"}}>
                      <div className="form-group">
                        <InputField
                          labeltitle="EMAIL ADDRESS"
                          typename="email"
                          fieldname="email"
                          changeHandler={this.handleChange}
                          value={this.state.data.email}
                          error={this.state.error.email}
                        />
                      </div>
                      <div className="form-group">
                        <InputField
                          labeltitle="PASSWORD"
                          typename="password"
                          fieldname="password"
                          changeHandler={this.handleChange}
                          value={this.state.data.password}
                          error={this.state.error.password}
                        />
                      </div>
                      <button
                        disabled={
                          this.validate() || this.state.loginButtonSpinner
                        }
                        type="submit"
                        className="btn btncolor btn-block btn-lg"
                        
                      >
                        {this.state.loginButtonSpinner ? (
                          <span>Loading ...</span>
                        ) : (
                          <span>Sign in</span>
                        )}
                      </button>
                      <div className="text--color text-center pb-4">
                        <span>Dont't have an account</span> &nbsp;
                        <Link to="/SignUp" className="font-weight-bold">
                          Sign Up
                        </Link>
                      </div>
                    </form>
                    {/*-----------form End-----------  */}
                  </div>
                </div>
                <a href='/admin' style={{position:'absolute',bottom:50,fontWeight:'bold',left:'42%',justifySelf:'center',cursor:'pointer'}}>Admin Login</a>
              </div>
              <div className="col-md-6 offset-md-1 pr-0">
                <div className="bgpic"></div>
              </div>
            </div>
          </div>
        </React.Fragment>
    )
}
}
const mapDispatchToProps=dispatch=>{
  return {userLogin: payload => dispatch(userLogin(payload)) }   
}
const mapStateToProps = state => {
  return { token: state.token, loginState:state.loginState, inValidLogin:state.inValidLogin, user_login:state.user_login}
}
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(LogInForm))
