import React, { Component } from 'react'
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { CSVLink, CSVDownload } from "react-csv";
import { api_callback } from '../../globals';
 
const headers = [
  { label: "Αριθμός Παραγγελιών", key: "merida" },
  { label: "Ημερομηνία", key: "date" },
  { label: "Χώρα Φόρτωσης", key: "pickup_country" },
  { label: "Φορτωτική", key: "fortotiki" },
  { label: "Πόλη Φόρτωσης", key: "pickup_city" },
  { label: "Μικτό Βάρος", key: "weight" }
];
 
const data = [
  { merida: "Ahmed", date: "Tomi", fortotiki: "ah@smthing.co.com" },
];
var dateFormat = require('dateformat')
export default class ExportFortotikes extends Component {
    constructor(props){
        super(props)
        this.state={
            data:[]
        }
    }
    onHide = () => {
        //this.setState(initialState)
       // this.props.clearfilterPartForTrans();
        this.props.onHide()
    }
    
    componentWillReceiveProps(nextProps){
    if(nextProps.filtered_transcations.length>0){
        
    }
    else {

     }
 }
 handleExport=()=>{
     let codes=[]
     const {filtered_transcations,all_transactions}=this.props
        if(filtered_transcations.length>0){
            filtered_transcations.map( transaction => {
                if(transaction.code!=undefined && transaction.code !=''){
                    if(codes.length<=0){
                    codes.push({ merida: transaction.reference_no, date:dateFormat(transaction.date.toLocaleString(),'d-m-yyyy'),pickup_country:transaction.warehouse[0].country,pickup_city:transaction.warehouse[0].city, fortotiki: transaction.code,weight:transaction.part.weight })
                    }else{
                     const found = codes.find(element =>element.fortotiki===transaction.code);
                    if(found){
                        codes.map(code=>{
                            if(code.fortotiki==found.fortotiki){
                                code.weight =parseInt(code.weight) + parseInt(transaction.part.weight)
                                code.merida =code.merida+ `|${transaction.reference_no}` 
                            }
                        })
                    }else{
                        codes.push({ merida: transaction.reference_no, date:dateFormat(transaction.date.toLocaleString(),'d-m-yyyy') ,pickup_country:transaction.warehouse[0].country,pickup_city:transaction.warehouse[0].city, fortotiki: transaction.code,weight:transaction.part.weight })
                    }
                        
                }
        }
});
       this.setState( { data:codes })
       return true
        }else{
            all_transactions.map( transaction => {
                if(transaction.code!=undefined && transaction.code !=''){
                    if(codes.length<=0){
                    codes.push({ merida: transaction.reference_no, date:dateFormat(transaction.date.toLocaleString(),'d-m-yyyy'),pickup_country:transaction.warehouse[0].country,pickup_city:transaction.warehouse[0].city, fortotiki: transaction.code,weight:transaction.part.weight })
                    }else{
                     const found = codes.find(element =>element.fortotiki===transaction.code);
                    if(found){
                    
                        codes.map(code=>{
                            if(code.fortotiki==found.fortotiki){
                                code.weight =parseInt(code.weight) + parseInt(transaction.part.weight)
                                code.merida =code.merida+ `|${transaction.reference_no}` 
                            }
                        })
                     //   codes[codes.findIndex(found)]=parseInt(found.weight) + parseInt(transaction.part.weight)
                       // codes[codes.findIndex(found)] = found.merida+ `|${transaction.reference_no}`
                    }else{
                        codes.push({ merida: transaction.reference_no, date:dateFormat(transaction.date.toLocaleString(),'d-m-yyyy') ,pickup_country:transaction.warehouse[0].country,pickup_city:transaction.warehouse[0].city, fortotiki: transaction.code,weight:transaction.part.weight })
                    }
                        
                }
        }
});
       this.setState( { data:codes }) 
        }
    }
 
    
    render() {
    
        const {show} =this.props;

        return (
            <Modal
            show={show}
            onHide={this.onHide}
          
            size='lg'
            centered
        >
            <Modal.Body>
            <CSVLink data={this.state.data}  filename={"my-file.csv"} headers={headers} onClick={this.handleExport}>
             
             Download me
            </CSVLink>

            </Modal.Body>
            </Modal>
        )
    }
}
