import React from "react";
import { Row, Col, Modal, Button, Form } from 'react-bootstrap';
import '../styles.scss'
import { connect } from "react-redux";
import {
    editPart, addTransaction
} from '../../js/actions'
import timelineIcon from '../../Pics/pickupicon.png'
var dateFormat = require('dateformat');

const initialState = {
    vessel_id: '', pieces: '', weight: '', dimensions: '', supplier_id: '',
    warehouse_id: '', edit: false,
     vessel: '', supplier: '', warehouse: ''}
class OpenPart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vessel_id: '', pieces: '', weight: '', dimensions: '', supplier_id: '',
            warehouse_id: '', edit: false,
             vessel: '', supplier: '', warehouse: ''}
       
    }
    onClose = () => {
        this.setState(initialState)
        this.props.onHide();
    }
    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
       

    }
 

    editPart = (event) => {
        const { part } = this.props;
        const { supplier_id, vessel_id, warehouse_id, dimensions, pieces, weight } = this.state;
        const temp = { supplier_id, vessel_id, warehouse_id, dimensions, pieces, weight };

        temp.part_id = part._id

        this.props.editPartInfo(temp);
        this.setState(initialState)
        this.props.onHide();
    }
    editClicked=()=>{
        const { part } = this.props;
            this.setState({
              
                vessel: part.vessel[0].vessel,
                supplier: part.supplier[0].supplier_name,
                warehouse: part.warehouse[0].forwarding_company,

              
                vessel_id: part.vessel[0]._id,
                supplier_id: part.supplier[0]._id,
                warehouse_id: part.warehouse[0]._id,

                pieces: part.pieces,
                weight: part.weight,
                dimensions: part.dimensions,
                edit:true


            })

    }
   
    render() {
        const { part, show, onHide, part_transactions, deletePart, } = this.props;
        const { suppliers, warehouses, vessels, ships } = this.props;
        const {  vessel, supplier, warehouse, pieces, weight, dimensions, edit,} = this.state;

        if (part != undefined && edit === false) {

            return (
                <React.Fragment>
                    <Modal
                        show={show}
                        onHide={this.onClose}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Body>

                            <Row>
                                <Col md="3">
                                    <h4>Reference No: {part.reference_no}</h4>
                                </Col>
                                <Col md="9">
 {/* 
    <Button className="delete-trans" onClick={() => { deletePart(part._id) }} >
                                        Delete
                            </Button>

                                    <Button className="edit-trans"onClick={this.editClicked}>
                                        Edit
                            </Button>

 */}
                                 
                                    
                                </Col>

                            </Row>
                            <br></br>

                            <Row>
                                <Col>
                                <Row>
                                <Col md="6">
                                            <p className='gray_text  bold text-uppercase'>DESCRIPTION</p>
                                            <p className="black_text-slim">{part.description}</p>
                                        </Col>     
                                 </Row>
                                    <Row>
                                        <Col md="6">
                                            <p className='gray_text  bold text-uppercase'>VESSEL</p>
                                            <p className="black_text-slim">{part.vessel[0].vessel}</p>
                                        </Col>
                                        <Col md="6">
                                            <p className='gray_text  bold text-uppercase'>PIECES</p>
                                            <p className="black_text-slim">{part.pieces}</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        
                                        <Col md="6">
                                            <p className='gray_text  bold text-uppercase'>WEIGHT</p>
                                            <p className="black_text-slim">{part.weight}</p>
                                        </Col>
                                        <Col md="6">
                                            <p className='gray_text  bold text-uppercase'>DIMENSIONS</p>
                                            <p className="black_text-slim">{part.dimensions}</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        
                                        <Col md="6">
                                            <p className='gray_text  bold text-uppercase'>SUPPLIER</p>
                                           {part.supplier.length>0 && <p className="black_text-slim">{part.supplier[0].supplier_name}</p>}
                                        </Col>
                                        <Col md="6">
                                            <p className='gray_text  bold text-uppercase'>WAREHOUSE</p>
                                            {part.warehouse.length>0 && <p className="black_text-slim">{part.warehouse[0].forwarding_company}</p>}
                                        </Col>

                                    </Row>

                                   
                                </Col>
                                <Col className='timeline'>
                                    <p className="gray_text bold">TIMELINE</p>
                                    {
                                        part_transactions.reverse().map(trans => {
                                            return (
                                                <React.Fragment>
                                                    <img width={20} src={timelineIcon}></img>
                                                    <span>{dateFormat(trans.date.toLocaleString(),'d-m-yyyy')} {trans.type}  {trans.type=='In Stock'? trans.warehouse[0] ? trans.warehouse[0].forwarding_company : '': trans.type=='Send'?trans.partner?trans.partner:'' :''}</span>
                                                    <br></br><br></br>
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </Col> 
                            </Row>
                        </Modal.Body>


                    </Modal>

                  

                </React.Fragment>

            )
        }
        else if (part != undefined && edit === true) {
            return (
                <Modal
                    show={show}
                    onHide={this.onClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Body>

                        <Row>
                            <Col md="3">
                                <h3>Description</h3>
                                <p>Reference No: {part.reference_no}</p>
                            </Col>
                            <Col md="9">

                                <Button className="edit-trans" onClick={this.editPart}>
                                    Save
                        </Button>


                            </Col>

                        </Row>
                        <br></br>

                        <Row>
                            <Col>
                                <Row>
                            
                                    <Col md="6">
                                        <p className='gray_text  bold text-uppercase'>VESSEL</p>
                                        <Form.Group>

                                            <Form.Control as="select" value={vessel} onChange={(e) => { this.setState({ vessel_id: `${e.target.value}`, vessel: e.target.value }) }}>

                                                {
                                                    vessels.map((v, i) => {
                                                        return <option name="vessel" key={v._id} value={v._id} >{v.vessel}</option>
                                                    })
                                                }
                                            </Form.Control>
                                        </Form.Group>

                                    </Col>

                                    <Col md="6">
                                        <p className='gray_text  bold text-uppercase'>PIECES</p>
                                        <Form.Group >

                                            <Form.Control
                                                id="pieces" type="number" value={pieces} onChange={this.handleChange} placeholder="Enter Pieces" required />


                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                  

                                    <Col md="6">
                                        <p className='gray_text  bold text-uppercase'>WEIGHT</p>
                                        <Form.Group >
                                            <Form.Control id="weight" value={weight} onChange={this.handleChange} placeholder="Enter Weight" required />

                                        </Form.Group>
                                    </Col>

                                    <Col md="6">
                                        <p className='gray_text  bold text-uppercase'>DIMENSIONS</p>
                                        <Form.Group >

                                            <Form.Control id="dimensions" value={dimensions} onChange={this.handleChange} placeholder="Enter Dimensions" required />

                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    
                                    <Col md="6">
                                        <p className='gray_text  bold text-uppercase'>SUPPLIER</p>
                                        <Form.Group>

                                            <Form.Control as="select" value={supplier} onChange={(e) => { this.setState({ supplier_id: `${e.target.value}`, supplier: e.target.value }) }}>
                                                {
                                                    suppliers.map((s, i) => {
                                                        return <option name="ship" key={s._id} value={s._id} required>{s.supplier_name}</option>
                                                    })
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>

                                    <Col md="6">
                                        <p className='gray_text  bold text-uppercase'>WAREHOUSE</p>
                                        <Form.Group >

                                            <Form.Control as="select" value={warehouse} onChange={(e) => { this.setState({ warehouse_id: `${e.target.value}`, warehouse: e.target.value }) }}>
                                                {
                                                    warehouses.map((w, i) => {
                                                        return <option key={w._id} value={w._id} required >{w.forwarding_company}</option>
                                                    })
                                                }
                                            </Form.Control>
                                        </Form.Group>

                                    </Col>

                                </Row>

                                <Row>
                                   
                                </Row>
                            </Col>
                           
                        </Row>
                    </Modal.Body>


                </Modal>
            )
        }
        else return null;
    }

}

const mapDispatchToProps = dispatch => {
    return {
        editPartInfo: payload => dispatch(editPart(payload)),
        addTransaction: payload => dispatch(addTransaction(payload))


    }
}
const mapStateToProps = state => {
    return {
        part_transactions: state.part_transactions,
      
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(OpenPart);


