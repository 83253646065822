import React from "react";
import { Card, Row, Col } from 'react-bootstrap';
import '../styles.scss';


export default function VesselCard(props) {
    const { vessel, openVessel } = props;
    console.log(vessel)
    if(vessel!=undefined ){
    return (
        <React.Fragment>
            <Card className="part-detail">
                <Card.Body style={{padding:'.2em'}}>
                    <Row>
                    <Col>
                    <p className='black_text'>{vessel.vessel}</p>
                    </Col>
                    <Col>
                    <p className='black_text'>{vessel.flag}</p>
                    </Col>
                    <Col>
                    <p className='black_text'>{vessel.email}</p>
                    </Col>
                    <Col><a href="#" className="open-link" onClick={()=>{openVessel(vessel._id)}}><b>Open</b></a></Col>
                    </Row>
                </Card.Body>
            </Card>
        </React.Fragment>
    );
    }else{
        return null;
    }
}
