import { store } from 'react-notifications-component';
import axios from 'axios'
export const api_url =()=>{
  if(process.env.NODE_ENV=='development'){
    return 'http://localhost:8080/'
  }else{
    return window.location.protocol + "//" + window.location.host+"/";
  }
}

export const S3_BUCKET=''
export const config = {
  bucketName: "speedair",
  region: "eu-central-1",
  accessKeyId: "AKIAUZQVU72ZKB4NEBHG",
  secretAccessKey: "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",
};
export const api_callback=(success,title,message)=>{
if(success){
    store.addNotification({
        title: title,
        message: message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 4000,
          onScreen: true
        }
      });
}
else{
    store.addNotification({
        title: title,
        message: message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 4000,
          onScreen: true
        }
      });
}
}

export const shipmentTypes=[
  {label:'EAIR',value:'EAIR'},
  {label:'I',value:'I'},
  {label:'ES',value:'ES'},
  {label:'IS',value:'IS'},
  {label:'ER',value:'ER'},
  {label:'IR',value:'IR'},
  {label:'IBR',value:'IBR'},
  {label:'AY',value:'AY'},
]

export const sort_by_field=(arr,field='label')=>{
let myarr =[]
if(arr){
if(arr.length>0){
 myarr= arr.sort(function(a, b){
   if(a[field]!=undefined && b[field]!=undefined){
  if(a[field].toString().toLowerCase()=='all'){return -1}
  if(b[field].toString().toLowerCase()=='all'){return 1}
  if(a[field] && b[field]){
  if(a[field].toString().toLowerCase() < b[field].toString().toLowerCase() ) { return -1; }
  if(a[field].toString().toLowerCase() > b[field].toString().toLowerCase() ) { return 1; }
  }
}
  return 0;
})
}
}
return myarr;
}

export const  getData = async (getUrl,key)=>{
 
  const {data} = await axios.get(`${api_url()}api/${getUrl}`,api_config())
  return data
}

export const fetchPaginatedData= async (getUrl,page = 0,limit=100) =>{
  let pageUrl;
  if(getUrl.indexOf('?')!=-1){
    pageUrl=`&page=${page}&limit=${limit}`
  }else{
    pageUrl=`?page=${page}&limit=${limit}`
  }
  const { data } = await axios.get(`${api_url()}api/${getUrl}${pageUrl}`,api_config())
  console.log("fefef")
  return data
}
export const postData = async (body,url)=>{
  const {data} = await axios.post(`${api_url()}api/${url}`,body,api_config())
  return data
}
export const putData = async (body,url)=>{
  const {data} = await axios.put(`${api_url()}api/${url}`,body,api_config())
  return data
}
export const deleteData = async (body,url)=>{
  const {data} = await axios.delete(`${api_url()}api/${url}`,api_config())
  return data
}


export const api_config =()=>   {
  return {
    headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`
  }
}
};

export const  notEmptyArray= (arr,length=0)=>{
  if(Array.isArray(arr)){
    if(arr.length>length){
      return true
    }else{
      return false
    }
  }else{
    return false
  }

}