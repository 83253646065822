import React from "react";
import { connect } from "react-redux";
import VesselCard from './VesselCard';
import AddVessel from './addVessel';
//import openPart from './openVessel';
import { Row, Col, Button,Form ,Modal, Spinner} from 'react-bootstrap';
import ReactLoading from 'react-loading';
import { getAllAdminVessels,getVesselsByShip,addVessel,filterVesselsAdmin, closeError,closeOpenedPart,
getVessel, editVessel, deleteVessel } from '../../js/actions'

import OpenVessel from "./openVessel";

import Navbar from '../Common/Navbar'
import { api_callback, sort_by_field } from "../../globals";

class UserVessels extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shipping_co:'', addPartModal: false, 
            vessel_id: null
        };
       
    }
    componentDidMount = () => {
        let user = JSON.parse(localStorage.getItem("user"))
        if(user)
        this.props.getVesselsByShip({id:user.ship_id});
    }
    search = (e) => {
        e.preventDefault();
        const { shipping_co } = this.state;
        this.props.filterVesselsAdmin({ shipping_co: shipping_co});
    }

   
    renderVessels = () => {
        const {all_vessels, filter_applied,filtered_items , vessels_success} = this.props;
       
            if(vessels_success){
                return(
                   all_vessels.map((p, i) => {
                    if(!p.type || !p.reference_no){
                        return(<VesselCard vessel={p} key={i} openVessel={this.openVessel} />)
                    }
                    })
                )
            }
            else{
                return ( <ReactLoading type={'spin'} height='2em' width='2em' color={'#2E5BFF'}  />)
            }
            

    }

    openVessel = (id) => {
        this.props.getVessel({ id: `${id}` });
    }
    addVessel = (data) => {

        let user = JSON.parse(localStorage.getItem("user"))
        if(user){

        data.shipping_co=user.ship_id
       
        this.props.addVessel(data)
        this.setState({ addPartModal: false });
        this.props.getAllAdminVessels();
        }
        else{
            api_callback(false,'Error','Not Authorized')
        }
    }
    deleteVessel = (id) => {
        this.props.deleteVessel({ id: `${id}` });
        this.props.closeOpenedPart();
    }


    render() {
        const { all_vessels,all_ships,vessel,ships_success } = this.props;
        const { shipping_co,addPartModal } = this.state;

        const { error, err_msg, success_msg, closeError ,openConfirm,open_item_modal,closeOpenedPart} = this.props;
        if(localStorage.getItem("loginState")=="false" && localStorage.getItem("admin_login")=="false"){
            this.props.history.push("/admin/");
            return null;
          }
        else
            return (
                <React.Fragment>
                <Row>
                    <Navbar active="vessels" />
                    <Col>
                        <Row className="top-header">

                        </Row>

                        <Row className="parts-main">
                            <Col md="10" >
                                <Row>
                                    <Col><h2>Vessels</h2></Col>
                                    <Col><p className='gray_text pt-3'>{all_vessels.length} Total</p></Col>
                                    {/*
                                    <Col> <Button variant="primary" className="add-part-btn" onClick={() => { this.setState({ addPartModal: true }) }}>+ Vessel</Button></Col>
                                    */}
                                    <Col></Col>
                                </Row>
                                <Row>
                                    <Col><p className='gray_text text-uppercase'>Vessel</p></Col>
                                    <Col><p className='gray_text text-uppercase'>Flag</p></Col>
                                    <Col><p className='gray_text text-uppercase'>Email</p></Col>
                                    <Col></Col>
                                </Row>
                                <div style={{overflow:'scroll',height:'70vh',padding:10}}>
                                {
                                    this.renderVessels()
                                }
                                </div>

                            </Col>

                          {/*
                           <AddVessel show={addPartModal} onHide={() => { this.setState({ addPartModal: false }) }}
                               ships={all_ships} addVessel={this.addVessel} />
                          */} 



                           

                            <OpenVessel vessel={vessel} all_ships={all_ships} show={open_item_modal} onHide={closeOpenedPart}
                            deleteVessel={this.deleteVessel} />

                        </Row>
                    </Col>

                </Row>

                
                </React.Fragment>





            )


    }
}
const mapDispatchToProps = dispatch => {
    return {
        getAllAdminVessels: payload => dispatch(getAllAdminVessels()),
        //addVessel:payload => dispatch(addVessel(payload)),
        //filterVesselsAdmin:payload => dispatch(filterVesselsAdmin(payload)),
        getVesselsByShip: payload => dispatch(getVesselsByShip(payload)),
        closeError: payload => dispatch(closeError()),
        closeOpenedPart:payload => dispatch(closeOpenedPart()),
        getVessel: payload => dispatch(getVessel(payload)),
     //   editVessel: payload => dispatch(editVessel(payload)),
     // deleteVessel: payload => dispatch(deleteVessel(payload))
    }
}
const mapStateToProps = state => {
    return { all_vessels: state.vesselsInShip, all_ships:state.all_ships, vessel: state.vessel, 
        filtered_items: state.filtered_items, filter_applied: state.filter_applied,
        error: state.error, err_msg: state.err_msg, success_msg: state.success_msg,openConfirm:state.openConfirm,
        open_item_modal: state.open_item_modal,
        ships_success:state.ships_success, vessels_success:state.vessels_success
      }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserVessels);
