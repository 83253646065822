import React from "react";
import { Row, Col, Modal, Button, Form } from 'react-bootstrap';
import '../styles.scss'
import { connect } from "react-redux";
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import {
    editVessel
} from '../../js/actions'


const initialState = {
    vessel_name:'',
    ship_id:'',
    flag:'',
    phone_no:'',
    email:'',
    edit: false 
};
class OpenVessel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vessel_name:'',
            ship_id:'',
            flag:'',
            phone_no:'',
            email:'',
            edit: false 
        };
          
    }

    onClose = () => {
        this.setState(initialState)
        this.props.onHide();
    }

    editVesselById = (event) => {
        const {all_ships,vessel}=this.props;
        const {ship_id}=this.state;
        const temp=this.state;
    
        temp.id=vessel._id;
        temp.vessel=temp.vessel_name;
        this.props.editVessel(temp);
        this.setState(initialState)
        this.props.onHide();
    }
    componentDidUpdate(prevProps){
        const{ship_id}=this.state;
        if(this.state.edit && ship_id === '' && this.props.vessel){
            const vessel=this.props.vessel
            this.setState({
                vessel_name:vessel.vessel,
                ship_id:vessel.ship_id,
                flag:vessel.flag,
                phone_no:vessel.phone_no,
                email:vessel.email,
              
            });
        }
      

    }
    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value })
        console.log(this.state);
    }
    onClose = () => {
        this.setState(initialState)
        this.props.onHide();
    }
    render() {
        const { vessel,show,deleteVessel,all_ships } = this.props;
        const{ vessel_name,ship_id,flag,phone_no,email,edit}=this.state;
        
        if(vessel!=undefined && edit == false){
        return (
            <Modal
            show={show}
            onHide={this.onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <React.Fragment>

                    <Row>
                        <Col>
                            <h3>{vessel.vessel}</h3>
                            <p>{vessel.flag}</p>
                          
                        </Col>
                        <Col>
                        {/*
                            <Button className="delete-trans" onClick={() => { deleteVessel(vessel._id) }}>Delete</Button>
                            <Button className="edit-trans" onClick={() => { this.setState({ edit: true }) }}>Edit</Button>
                        */}
                        </Col>

                    </Row>
                    <br></br>
                    <Row>
                        <Col md="4">
                            <p className='gray_text  bold text-uppercase'>VESSEL</p>
                            <p>{vessel.vessel}</p>
                        </Col>
                        <Col md="4">
                            <p className='gray_text  bold text-uppercase'>MANAGING COMPANY</p>
                            <p>{vessel.ship[0].shipping_company_name}</p>
                        </Col>
                    </Row>


                    <Row>
                        <Col md="4">
                            <p className='gray_text  bold text-uppercase'>FLAG</p>
                            <p>{vessel.flag}</p>
                        </Col>
                        <Col md="4">
                            <p className='gray_text  bold text-uppercase'>PHONE NUMBER</p>
                            <p>{vessel.phone_no}</p>
                        </Col>
                        <Col md="4">
                            <p className='gray_text  bold text-uppercase'>EMAIL</p>
                            <p>{vessel.email}</p>
                        </Col>
                    </Row>

                </React.Fragment>
            </Modal.Body>


        </Modal>

    
        );}
        else if(vessel!=undefined && edit == true){
            return (
                <Modal
                show={show}
                onHide={this.onClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <React.Fragment>
    
                        <Row>
                            <Col>
                                <h3>{vessel.vessel}</h3>
                                <p>{vessel.flag}</p>
                              
                            </Col>
                            <Col>  
                                <Button className="edit-trans"onClick={ this.editVesselById}>
                                    Save
                        </Button>
                            </Col>
    
                        </Row>
                        <br></br>
                        <Row>
                            <Col md="4">
                                <p className='gray_text  bold text-uppercase'>VESSEL</p>
                               <Form.Group >
                                            <Form.Control
                                                id="vessel_name"  value={vessel_name} onChange={this.handleChange} placeholder="Enter Vessel" required />
                                </Form.Group>
                            </Col>
                            <Col md="4">
                                <p className='gray_text  bold text-uppercase'>MANAGING COMPANY</p>
                              <Form.Group >

                                            <Form.Control as="select" value={ship_id}onChange={(e) => { this.setState({ ship_id: `${e.target.value}`}) }}>

                                                {
                                                    all_ships.map((s, i) => {
                                                        return <option name="ship_id" key={s._id} value={s._id} >{s.shipping_company_name}</option>
                                                    })
                                                }
                                            </Form.Control>
                                        </Form.Group>
                            </Col>
                        </Row>
    
    
                        <Row>
                            <Col md="4">
                                <p className='gray_text  bold text-uppercase'>FLAG</p>
                                <Form.Group >
                                            <Form.Control
                                                id="flag"  value={flag} onChange={this.handleChange} placeholder="Enter Flag" required />
                                </Form.Group>
                            </Col>
                            <Col md="4">
                                <p className='gray_text  bold text-uppercase'>PHONE NUMBER</p>
                                <ReactPhoneInput
                                country={'gr'}
                                inputExtraProps={{
                                id:'phone_no',
                                name: "phone_no",
                                required: true,
                                }}
                                containerStyle={{
                                marginLeft:0,
                                height:'2.6rem'
                                }}
                                buttonStyle={{
                                background:'rgba(224,231,255,0.2)',borderColor:'#E0E7FF' 
                                }}
                                inputStyle={{background:'rgba(224,231,255,0.2)',borderColor:'#E0E7FF',height:'2.6rem',width:'100%'}}
                                value={phone_no}
                                enableSearch={true}
                                onChange={(phone_no)=>this.setState({phone_no})}
                                /> 
                                <Form.Group >
                              
                                </Form.Group>
                            </Col>
                            <Col md="4">
                                <p className='gray_text  bold text-uppercase'>EMAIL</p>
                                <Form.Group >
                                            <Form.Control
                                                id="email"  value={email} onChange={this.handleChange} placeholder="Enter Email" required />
                                </Form.Group>
                            </Col>
                        </Row>
    
                
    
    
                    </React.Fragment>
                </Modal.Body>
    
    
            </Modal>
    
        
            );}
        else return null;
    }
}

const mapDispatchToProps = dispatch => {
    return {
        editVessel: payload => dispatch(editVessel(payload)),
    
    }
}
export default connect(null, mapDispatchToProps)(OpenVessel);


