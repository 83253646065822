import React from "react";
import { connect } from "react-redux";
import ShipCard from './ShipCard';
import AddShip from './addShip';
import OpenShip from './openShip';
import { Row, Col, Button, Form, Modal, Spinner } from 'react-bootstrap';
import ReactLoading from 'react-loading';
import { getAllAdminShips, filterShipsAdmin, addShipAdmin, closeError,closeOpenedPart,
    getShip,editShip,deleteShip } from '../../js/actions'

import Navbar from '../Common/Navbar'
import Filters from "../../Common/Filters/Filters";

class ShippingCompanys extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shipping_co: '', country: '', addPartModal: false, ship_id: null
        };
        this.filterInput = this.filterInput.bind(this);
    }
    componentDidMount = () => {
        this.props.getAllAdminShips();
    }
    componentWillReceiveProps=(nextProps)=>{
        if(nextProps.ships_success){
            let all_ships=[]
           all_ships= nextProps.all_ships.map(({shipping_company_name,_id }) => ({ value: shipping_company_name, label:shipping_company_name , _id }))
           all_ships.unshift({value:'',label:'All'})
           this.setState({select_ships:all_ships})
           }
        
    }
    search = (e) => {
        e.preventDefault();
        const { shipping_co, country } = this.state;
        this.props.filterShipsAdmin({ shipping_co , country });
    }

    filterInput = (e) => {
        this.setState({ [e.target.id]: e.target.value });
        console.log(this.state)
    }
    renderShips = () => {
        const { all_ships, filter_applied, filtered_items, ships_success } = this.props;
        if (filter_applied) {
            if (filtered_items.length > 0) {
                return (filtered_items.map((p, i) => {
                    return (
                            <ShipCard ship={p} key={i} openShip={this.openShip}/>

                    )
                })
                )
            }
           


        }
        else {
            if (ships_success) {
                return (
                    all_ships.map((p, i) => {
                        return (
                                <ShipCard ship={p} key={i} openShip={this.openShip}/>

                        )
                    })
                )
            } else {
                return ( <ReactLoading type={'spin'} height='2em' width='2em' color={'#2E5BFF'}  />)
            }

        }
    }
    changeHandler =(e)=>{
        this.setState({[e.target.id]:e.target.value})
      } 
      
      selectChangeHandler =(name,value)=>{
        this.setState({[name]:value})
       }

    openShip = (id) => {
        this.props.getShip({ id: `${id}` });
    }
    addShip = (data) => {
       
        this.props.addShipAdmin(data)
        this.setState({ addPartModal: false });
        //this.props.getAllAdminShips();
    }
    deleteShipById = (id) => {
        this.props.deleteShip({ id: `${id}` });
        this.props.closeOpenedPart();
    }
    render() {
        const { all_ships, ship, open_item_modal,closeOpenedPart } = this.props;

        const { shipping_co, country, addPartModal } = this.state;

        const { error, err_msg, success_msg, closeError ,openConfirm, ships_success} = this.props;
        
        if(localStorage.getItem("loginState")=="false" && localStorage.getItem("admin_login")=="false"){
            this.props.history.push("/admin/");
            return null;
          }
        else
        return (
            <React.Fragment>
                <Row>
                    <Navbar active="shipping" />
                    <Col>
                        <Row className="top-header">

                        </Row>

                        <Row className="parts-main">
                            <Col md="10">
                                <Row>
                                    <Col><h2>Shipping Companys</h2></Col>
                                    <Col><p className='gray_text pt-3'>{all_ships?all_ships.length:''} Total</p></Col>
                                    <Col> <Button variant="primary" className="add-part-btn" onClick={() => { this.setState({ addPartModal: true }) }}>+ Company</Button></Col>
                                </Row>
                                <div id='labels'>
                                    <Col style={{padding:0}}><p className='gray_text text-uppercase'>Name</p></Col>
                                    <Col style={{padding:0}}><p className='gray_text text-uppercase'>Person in charge</p></Col>
                                    <Col style={{padding:0}}><p className='gray_text text-uppercase'>City</p></Col>
                                    <Col style={{padding:0}}><p className='gray_text text-uppercase'>Country</p></Col>
                                </div>
                                <div style={{overflow:'scroll',height:'80vh',padding:10}}>
                                {this.renderShips() }
                                </div>

                            </Col>
                            <Filters 
                            search={this.search}
                            ships_success={true}
                            ships={this.state.select_ships}
                            changeHandler={this.changeHandler}
                            selectChangeHandler={this.selectChangeHandler}
                            hasCountry={true}
                            country={country}
                            selectParts={true}
                            />
                            

                            

                            <AddShip show={addPartModal} onHide={() => {this.setState({ addPartModal: false }) }}
                                addShip={this.addShip} />

                            <OpenShip ship={ship} show={open_item_modal} onHide={closeOpenedPart} deleteShip={this.deleteShipById}/>

                        </Row>
                    </Col>

                </Row>
               
            </React.Fragment>

        )


    }
}
const mapDispatchToProps = dispatch => {
    return {
        getAllAdminShips: payload => dispatch(getAllAdminShips()),
        filterShipsAdmin: payload => dispatch(filterShipsAdmin(payload)),
        addShipAdmin: payload => dispatch(addShipAdmin(payload)),
        closeError: payload => dispatch(closeError()),
        closeOpenedPart:payload => dispatch(closeOpenedPart()),
        
        getShip: payload => dispatch(getShip(payload)),
        editShip: payload => dispatch(editShip(payload)),
        deleteShip: payload => dispatch(deleteShip(payload))

    }
}
const mapStateToProps = state => {
    return {
        all_ships: state.all_ships, ship: state.ship, filter_applied: state.filter_applied, filtered_items: state.filtered_items,
        error: state.error, err_msg: state.err_msg, success_msg: state.success_msg,openConfirm:state.openConfirm,
        open_item_modal: state.open_item_modal,
        ships_success:state.ships_success
       
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShippingCompanys);
