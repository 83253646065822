import React from "react";
import { connect } from "react-redux";
import axios from 'axios'
import { Row, Col, Button,Form ,Modal} from 'react-bootstrap';
import { getUser,editUser} from '../../js/actions'

import Navbar from '../Common/Navbar'
import { api_callback } from "../../globals";

class AllUsers extends React.Component {
    constructor(props) {
        super(props);
       
        this.state = {
            name:'',email:'',old_password:'', new_password:'',c_password:''
        };
      
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
      

    }
    componentDidMount = () => {
        let user = JSON.parse(localStorage.getItem("user"))
        this.props.getUser({ id: user._id });
       
      
       
    }
    componentDidUpdate=(prevProps,props)=>{
        if(prevProps.user == undefined && this.props.user){
            const {user}=this.props
            this.setState({
                name:user.name,
                email:user.email,
            })
        }
    }
    changePassworddd = async(event)=>{
        event.preventDefault();
        const {user}=this.props; 
        const {old_password,new_password,c_password}=this.state
        let BASE_URL;
        if(new_password===c_password){
        if(process.env.NODE_ENV=='development'){
           
            BASE_URL = "http://localhost:8080/api/";
        }else{
            BASE_URL = window.location.protocol + "//" + window.location.host+"/api/";
        }
        await axios.
        post(BASE_URL+"user/change_password/"+user._id,{
            old_password,
            new_password,
            c_password
        }).then(res=>{
            console.log(res)
            this.setState({old_password:'', new_password:'',c_password:''})
            if(res.data.message){
            api_callback(true,'Success',res.data.message)
            }
        }).
        catch(function (error) {
            if (error.response) {
              // Request made and server responded
              console.log(error.response.data);
              if(error.response.data.message){
              api_callback(false,'Error',error.response.data.message)
              }
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
        
          });
        }else{
            api_callback(false,'Error','Passwords doesnt match')
        }
    }

    editUserById = (event) => {
        event.preventDefault();
        const {user}=this.props;
        let tempData = this.state;
        let ls=this.state;
        if(this.state.new_pass!=""){
        tempData.password=this.state.new_pass;
        ls.password=this.state.new_pass;
    }
        tempData.id=user._id;
        ls._id=user._id;
        this.props.editUser(tempData)
        this.setState({new_pass:""})
        
    }
  

 
    render() {
       
     
        const {user}=this.props;
        const {name, email, old_password,new_password,c_password}=this.state;
      
            return (
                <React.Fragment>
            <Row>
               
                <Navbar active="settings" />
                <Col>
                    <Row className="top-header">

                    </Row>

                    <Row className="parts-main ">
                  
                    <Col className='col-lg-10  bg-white p-4 rounded col-md-12 col-sm-12 ml-3 mr-3'>
                        <Row>
                            <h2>My Account</h2>
                        </Row>
                        <Row>
                        </Row>
                            <Form onSubmit={this.editUserById}>
                            <Row>
                            <Col md="8">
                            <Form.Group>
                                <p className='gray_text  bold text-uppercase'>FULL NAME</p>
                                <Form.Control
                                    id="name" value={name} onChange={this.handleChange}  required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide Name
                             </Form.Control.Feedback>
                            </Form.Group>
                            </Col>


                            <Col md="4">
                            <Form.Group>
                                <p className='gray_text  bold text-uppercase'>EMAIL</p>
                                <Form.Control
                                    id="email" type="email" value={email} onChange={this.handleChange}  required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide email
                             </Form.Control.Feedback>
                            </Form.Group>
                            </Col>
                            </Row>
                            <Row>
                                <Col md="2">
                                <Button  className="btn btn-primary mt-2 search-btn" type='submit'>Done</Button>
                                </Col>
                          
                           
                            </Row>
                            </Form>
                            <br></br>
                            <hr></hr>                
                <h4>Change Password</h4>
                   <Form onSubmit={this.changePassworddd}  >

                    <Form.Row>
                    <Col md='6'>                          
                    <p className='gray_text  bold text-uppercase'>Old Password</p>
                    <Form.Group >
                        
                            <Form.Control
                                id="old_password" type ="password" value={old_password} onChange={this.handleChange} placeholder="Enter Old Password" required />
                            <Form.Control.Feedback type="invalid">
                                Please provide password.
                        </Form.Control.Feedback>
                        </Form.Group>
                        </Col>  

                        <Col md='6'>                          
                        <p className='gray_text  bold text-uppercase'>New Password</p>
                        <Form.Group md='6' >
                        <Form.Control
                                id="new_password" type ="password" value={new_password} onChange={this.handleChange} placeholder="Enter New Password" required />
                            <Form.Control.Feedback type="invalid">
                                Please provide password.
                        </Form.Control.Feedback>
                        </Form.Group>
                        </Col>  


                        <Col md='6'>                          
                        <p className='gray_text  bold text-uppercase'>Confirmation Password</p>
                        <Form.Group md='6' >
                        <Form.Control
                                id="c_password" type ="password" value={c_password} onChange={this.handleChange} placeholder="Enter Confirmation Password" required />
                            <Form.Control.Feedback type="invalid">
                                Please provide password.
                        </Form.Control.Feedback>
                        </Form.Group>
                        </Col>  
                    </Form.Row>

                        <Button className="btn btn-primary mt-2" type='submit' >Change Password</Button>
                    </Form>

                        </Col>
                    
                       
                    </Row>

                </Col>


            </Row>
   
            </React.Fragment>





            )

            }
}
const mapDispatchToProps = dispatch => {
    return {
        getUser: payload => dispatch(getUser(payload)),
        editUser: payload => dispatch(editUser(payload)),
      
        
    }
}
const mapStateToProps = state => {
    return { 
        user:state.user
}
}

export default connect(mapStateToProps,mapDispatchToProps)(AllUsers);

