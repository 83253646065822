import * as yup from 'yup'
export const states = [
    { value: 'Pending', label: 'Pending' },
    { value: 'In Stock', label: 'In Stock' },
    { value: 'Send', label: 'Send' },
    { value: 'Delivered', label: 'Delivered' },
];
export const partValidation= yup.object().shape({
    reference_no: yup.string().required().label('Reference No.'),
    description: yup.string().required().label('Description'),
    pieces:yup.number().label('Pieces'), 
    weight:yup.number().positive().label('Weight'), 
    dimensions: yup.string().label('Dimensions'),
    ship_id: yup.string().required().label('Shipping Company'),
    warehouse_id:yup.string().required().label('Warehouse'),
    vessel_id:yup.string().required().label('Vessel'),
    status: yup.string().required().label('Status'),
    remarks: yup.string().label('Remarks'),
  })
export const partSchema=[
    {label:"Reference No.",type:'input',name:"reference_no"},
    {label:"Description",type:'input',name:"description"},
    {label:"Pieces",type:'input',name:"pieces"},
    {label:"Weight (KG)",type:'input',name:"weight"},
    {label:"DIMENSIONS (W*D*H)",type:'input',name:"dimensions"},
    {
        label:"Shipping Company",
        type:'select', 
        name:'ship_id',
        populate:"ship",
        metadata:{
        getUrl:'ship',
        queryString:'adminships',
        label1:'shipping_company_name',
    }},
    {
        label:"Vessel",
        type:'select', 
        name:'vessel_id', 
        populate:"vessel",
        metadata:{
        getUrl:'vessel',
        dbResponse:'vessels',
        queryString:'adminvessels',
        label1:'vessel',
        filterBy:'ship_id',
    }},
    {
        label:"Warehouse",
        type:'select', 
        name:'warehouse_id', 
        populate:"warehouse",
        metadata:{
        getUrl:'warehouse',
        queryString:'adminwarehouses',
        label1:'forwarding_company',
    }},
    {
        label:"Supplier",
        type:'select', 
        name:'supplier_id', 
        populate:"supplier",
        metadata:{
        getUrl:'supplier',
        queryString:'adminsuppliers',
        label1:'supplier_name',
    }},
    {label:"Status",type:'select',name:"status", metadata:{options:states}},
    {label:"Remarks",type:'input',name:"remarks"},
    {label:"Order Date Placement",type:'date',name:"first_date"},
]