
import {

    USER_LOGIN_REQUEST, USER_LOGIN_SUCCESS, USER_LOGIN_FAILURE,
    USER_SIGNUP_REQUEST, USER_SIGNUP_SUCCESS, USER_SIGNUP_FAILURE,

    //parts
    GET_ALL_PARTS_BY_SHIP_REQUEST, GET_ALL_PARTS_BY_SHIP_SUCCESS, GET_ALL_PARTS_BY_SHIP_FAILURE,
    GET_ALL_PARTS_BY_WH_REQUEST,GET_ALL_PARTS_BY_WH_SUCCESS,GET_ALL_PARTS_BY_WH_FAILURE,
    GET_PART_REQUEST, GET_PART_SUCCESS, GET_PART_FAILURE,
    GET_FILTERED_PARTS_REQUEST,

    // get all transactions of all parts of user ship and warehouse manager
    GET_ALL_TRANSACTIONS_BY_SHIP_REQUEST,
    GET_ALL_TRANSACTIONS_BY_WH_REQUEST,

    GET_FILTERED_PARTS_FOR_TRANS,
    CLEAR_FILTERED_PARTS_FOR_TRANS,


    //vessels
    GET_VESSELS_BY_SHIP_REQUEST, GET_VESSELS_BY_SHIP_SUCCESS, GET_VESSELS_BY_SHIP_FAILURE,
    ADD_VESSEL_REQUEST, ADD_VESSEL_SUCCESS, ADD_VESSEL_FAILURE,
    GET_VESSEL_REQUEST, GET_VESSEL_SUCCESS, GET_VESSEL_FAILURE,
    EDIT_VESSEL_REQUEST, EDIT_VESSEL_SUCCESS, EDIT_VESSEL_FAILURE,
    DELETE_VESSEL_REQUEST, DELETE_VESSEL_SUCCESS, DELETE_VESSEL_FAILURE,

    //suppliers
    GET_ALL_SUPPLIERS_REQUEST, GET_ALL_SUPPLIERS_SUCCESS, GET_ALL_SUPPLIERS_FAILURE,
    GET_ALL_WAREHOUSE_REQUEST, GET_ALL_WAREHOUSE_SUCCESS, GET_ALL_WAREHOUSE_FAILURE,

    CLOSE_OPENED_PART,

    // COMMON
    CLOSE_CONFIRMATION,
    GET_PARTNERS_REQUEST, GET_PARTNERS_SUCCESS, GET_PARTNERS_FAILURE,

    // parts
    GET_ALL_ADMIN_PARTS_REQUEST, GET_ALL_ADMIN_PARTS_SUCCESS, GET_ALL_ADMIN_PARTS_FAILURE,
    GET_ADMIN_FILTERED_PARTS,
    ADD_PART_REQUEST, ADD_PART_SUCCESS, ADD_PART_FAILURE,
    GET_PART_TRANSACTIONS_REQUEST, GET_PART_TRANSACTIONS_SUCCESS, GET_PART_TRANSACTIONS_FAILURE,

    GET_ALL_ADMIN_VESSELS_REQUEST, GET_ALL_ADMIN_VESSELS_SUCCESS, GET_ALL_ADMIN_VESSELS_FAILURE,
    GET_ADMIN_FILTERED_VESSELS,

    //ships
    GET_ALL_ADMIN_SHIP_REQUEST, GET_ALL_ADMIN_SHIP_SUCCESS, GET_ALL_ADMIN_SHIP_FAILURE,
    GET_ADMIN_FILTERED_SHIPS,
    ADD_SHIP_REQUEST, ADD_SHIP_SUCCESS, ADD_SHIP_FAILURE,
    GET_SHIP_REQUEST, GET_SHIP_SUCCESS, GET_SHIP_FAILURE,
    EDIT_SHIP_REQUEST, EDIT_SHIP_SUCCESS, EDIT_SHIP_FAILURE,
    DELETE_SHIP_REQUEST, DELETE_SHIP_SUCCESS, DELETE_SHIP_FAILURE,

 

    //transactions
    GET_ALL_TRANSACTIONS_REQUEST, GET_ALL_TRANSACTIONS_SUCCESS, GET_ALL_TRANSACTIONS_FAILURE,
    GET_ADMIN_FILTERED_TRANSACTIONS,
    ADD_TRANSACTION_REQUEST, ADD_TRANSACTION_SUCCESS, ADD_TRANSACTION_FAILURE,
    GET_TRANSACTION_REQUEST, GET_TRANSACTION_SUCCESS, GET_TRANSACTION_FAILURE,
    DEL_TRANSACTION_REQUEST, DEL_TRANSACTION_SUCCESS, DEL_TRANSACTION_FAILURE,
    EDIT_TRANSACTION_REQUEST, EDIT_TRANSACTION_SUCCESS, EDIT_TRANSACTION_FAILURE,

    //all users - admin section
    GET_ALL_ADMIN_USER_REQUEST,GET_ALL_ADMIN_USER_SUCCESS,GET_ALL_ADMIN_USER_FAILURE,GET_ADMIN_FILTERED_USER,
    ADD_USER_REQUEST,ADD_USER_SUCCESS,ADD_USER_FAILURE,
    GET_USER_REQUEST,GET_USER_SUCCESS,GET_USER_FAILURE,
    EDIT_USER_REQUEST,EDIT_USER_SUCCESS,EDIT_USER_FAILURE,
    DELETE_USER_REQUEST,DELETE_USER_SUCCESS,DELETE_USER_FAILURE,

    // suppliers - admin section
    GET_ALL_ADMIN_SUPPLIERS_REQUEST,GET_ALL_ADMIN_SUPPLIERS_SUCCESS,GET_ALL_ADMIN_SUPPLIERS_FAILURE,
    ADD_SUPPLIER_REQUEST,ADD_SUPPLIER_SUCCESS,ADD_SUPPLIER_FAILURE,
    GET_SUPPLIER_REQUEST,GET_SUPPLIER_SUCCESS,GET_SUPPLIER_FAILURE,
    EDIT_SUPPLIER_REQUEST,EDIT_SUPPLIER_SUCCESS,EDIT_SUPPLIER_FAILURE,
    DELETE_SUPPLIER_REQUEST,DELETE_SUPPLIER_SUCCESS,DELETE_SUPPLIER_FAILURE,
    GET_ADMIN_FILTERED_SUPPLIERS,



    // warehouses - admin section
    GET_ALL_ADMIN_WAREHOUSE_REQUEST,GET_ALL_ADMIN_WAREHOUSE_SUCCESS,GET_ALL_ADMIN_WAREHOUSE_FAILURE,
    ADD_WAREHOUSE_REQUEST,ADD_WAREHOUSE_SUCCESS,ADD_WAREHOUSE_FAILURE,
    GET_WAREHOUSE_REQUEST,GET_WAREHOUSE_SUCCESS,GET_WAREHOUSE_FAILURE,
    EDIT_WAREHOUSE_REQUEST,EDIT_WAREHOUSE_SUCCESS,EDIT_WAREHOUSE_FAILURE,
    DELETE_WAREHOUSE_REQUEST,DELETE_WAREHOUSE_SUCCESS,DELETE_WAREHOUSE_FAILURE,
    GET_ADMIN_FILTERED_WAREHOUSE,

    // admins - admin section
    GET_ALL_ADMINS_REQUEST,GET_ALL_ADMINS_SUCCESS,GET_ALL_ADMINS_FAILURE,
    ADD_ADMIN_REQUEST,ADD_ADMIN_SUCCESS,ADD_ADMIN_FAILURE,
    GET_ADMIN_REQUEST,GET_ADMIN_SUCCESS,GET_ADMIN_FAILURE,
    EDIT_ADMIN_REQUEST,EDIT_ADMIN_SUCCESS,EDIT_ADMIN_FAILURE,
    DELETE_ADMIN_REQUEST,DELETE_ADMIN_SUCCESS,DELETE_ADMIN_FAILURE,
    
    ADMIN_LOGIN_REQUEST,ADMIN_LOGIN_SUCCESS,ADMIN_LOGIN_FAILURE,
    LOG_OUT_ADMIN,LOG_OUT_USER,

    // warehouse actions
    WAREHOUSE_LOGIN_REQUEST,WAREHOUSE_LOGIN_SUCCESS,WAREHOUSE_LOGIN_FAILURE, RESET_FILTERED_ITEMS

} from '../constants/action-types';

import _ from 'lodash';
var dateFormat = require('dateformat')
const initialState = {
    //sign in
    token: undefined,
    loginState: false,
    inValidLogin: false,
    admin_login:false,
    user_login:false,
    warehouse_login:false,
    //signup
    signupState: false,
    inValidSignup: false,

    //parts - same for user and admin



    //add part section
    warehouse: [],
    suppliers: [],
    vesselsInShip: [],

    //common
    error: undefined,
    err_msg: undefined,
    success_msg: undefined,

    openConfirm: false,

    partners: [],
    partners_success:false,

    // admin states


    filtered_items: [],
    filter_applied: false,
    tempPartsTrans:[],
    trans_filter:false,
   
   



    open_item_modal: false,

    parts: [],
    part: undefined,
    parts_success:false,
    
    all_warehouses: [],
    wh:undefined,
    warehouses_success:false,

    all_suppliers:[],
    supplier:undefined,
    suppliers_success:false,
    
    all_vessels: [],
    vessel: undefined,
    vessels_success:false,

    all_ships: [],
    ship: undefined,
    ships_success:false,

    all_transactions: [],
    transaction: undefined,
    transactions_success:false,
    part_transactions: [],

    // admin all users
    all_users: [],
    users_success:false,
    user: undefined,

    // home
    pending_parts: [],
    stock_parts: [],
    send_parts: [],
    delivered_parts: [],

    all_admins:[],
    admins_success:false,
    admin:undefined
};

function rootReducer(state = initialState, action) {
    switch (action.type) {

        // common
        case CLOSE_CONFIRMATION:
            return { ...state, error: undefined, err_msg: undefined, success_msg: undefined, openConfirm: false }

        // admin reducers

        // login
        case ADMIN_LOGIN_REQUEST:
                
            return { ...state, token: undefined, loginState: false,inValidLogin: false, admin_login:false };
       
        case ADMIN_LOGIN_SUCCESS:

            
            return { ...state, token: action.payload.token, loginState: action.payload.success,admin_login:true,inValidLogin:false, parts_success:false   };
        case ADMIN_LOGIN_FAILURE:
            return { ...state, token: undefined, loginState: false,admin_login:false,inValidLogin: true }

        case LOG_OUT_ADMIN:
                return {...state, token: undefined, loginState: false,inValidLogin: false, admin_login:false };
        
        case LOG_OUT_USER:
                return {...state, token: undefined, loginState: false,inValidLogin: false, user_login:false, warehouse_login:false };
                        
        // get all admin parts - Parts Section

        case GET_ALL_ADMIN_PARTS_REQUEST:
            return { ...state, parts: [], parts_success:false };
        case GET_ALL_ADMIN_PARTS_SUCCESS:
            let pending_parts = _.filter(action.payload.parts, { 'status': 'Pending' });
            let stock_parts = _.filter(action.payload.parts, { 'status': 'In Stock' });
            let send_parts = _.filter(action.payload.parts, { 'status': 'Send' });
            let delivered_parts = _.filter(action.payload.parts, { 'status': 'Delivered' });
            return {
                ...state,parts_success:true, parts: _.reverse(action.payload.parts), pending_parts, stock_parts, send_parts, delivered_parts
                , filtered_items: [], filter_applied: false, parts_success:true
            };
        case GET_ALL_ADMIN_PARTS_FAILURE:
            return { ...state, parts: [], parts_success:false }

        case GET_ADMIN_FILTERED_PARTS:
            let filteredParts=[]
             filteredParts = state.parts
            .filter( part=> (action.payload.reference_no !== '' )? part.reference_no === action.payload.reference_no:true)
            .filter(v => action.payload.shipping_co!='' ?v.ship.length>0 ? v.ship[0].shipping_company_name === action.payload.shipping_co:false : true)
            .filter( part=> (action.payload.vessel!== '' )?part.vessel.length>0 ?  part.vessel[0].vessel=== action.payload.vessel:false:true)
            .filter( part=> (action.payload.warehouse!== '' )? part.warehouse.length>0 ? part.warehouse[0].forwarding_company === action.payload.warehouse:false :true)
            .filter( part=> (action.payload.supplier!== '' )?part.supplier.length>0 ? part.supplier[0].supplier_name=== action.payload.supplier:false:true)
            .filter( part=> (action.payload.status!== '' )? part.status=== action.payload.status:true)

            return { ...state, filtered_items: filteredParts, filter_applied: true};
           

        case GET_FILTERED_PARTS_FOR_TRANS:
            let tempPartsTrans = [];
            
            tempPartsTrans = state.parts
            .filter( part=> (action.payload.vessel!== '' )? part.vessel[0].vessel=== action.payload.vessel:true)
            .filter( part=> (action.payload.vessel!== '' )? part.vessel[0].vessel=== action.payload.vessel:true)
            .filter( part=> (action.payload.warehouse!== '' )? part.warehouse[0].forwarding_company=== action.payload.warehouse:true)
            .filter( part=> (action.payload.supplier!== '' )? part.supplier[0].supplier_name=== action.payload.supplier:true)
            .filter( part=> (action.payload.status!== '' )? part.status=== action.payload.status:true)
            .filter(v => action.payload.shipping_co!='' ?v.ship[0].shipping_company_name === action.payload.shipping_co : true)

            return { ...state, tempPartsTrans: tempPartsTrans, trans_filter: true };
            
          
        
        case CLEAR_FILTERED_PARTS_FOR_TRANS:
            return { ...state, tempPartsTrans: [], trans_filter:false };


        case GET_PART_TRANSACTIONS_REQUEST:
            return { ...state, part_transactions: [], transactions_success:false };
        case GET_PART_TRANSACTIONS_SUCCESS:
            return { ...state, part_transactions: action.payload.transactions ,transactions_success:true};
        case GET_PART_TRANSACTIONS_FAILURE:
            return { ...state, part_transactions: [] , transactions_success:false}


        case GET_PARTNERS_REQUEST:
            return { ...state, partners: [], partners_success:false };
        case GET_PARTNERS_SUCCESS:
            return { ...state, partners: action.payload ,  partners_success:true};
        case GET_PARTNERS_FAILURE:
            return { ...state, partners: [], partners_success:false }

        // get all shipping companies

        case GET_ALL_ADMIN_SHIP_REQUEST:
            return { ...state, all_ships: [], ships_success:false };
        case GET_ALL_ADMIN_SHIP_SUCCESS:
            return { ...state, ships_success:true,all_ships: _.reverse(action.payload), filtered_items: [], filter_applied: false };
        case GET_ALL_ADMIN_SHIP_FAILURE:
            return { ...state, all_ships: [], ships_success:false}

        case GET_ADMIN_FILTERED_SHIPS:
            let tempShips = [];
            if (action.payload.shipping_co !== '') {
                tempShips = _.filter(state.all_ships, function (ship) {
                    if (ship.shipping_company_name === action.payload.shipping_co) return ship;
                });
                return { ...state, filtered_items: tempShips, filter_applied: true };
            }
            if (action.payload.country !== '') {
                tempShips = _.filter(state.all_ships, function (ship) {
                    if (ship.country === action.payload.country) return ship;
                });
                return { ...state, filtered_items: tempShips, filter_applied: true };
            }

         
                return { ...state, filtered_items: [], filter_applied: false };
          

        case ADD_SHIP_REQUEST:
            return { ...state, error: undefined, success_msg: undefined, err_msg: undefined, openConfirm: false };
        case ADD_SHIP_SUCCESS:
            return { ...state, error: false, openConfirm: true, success_msg: "Ship Company added successfully", err_msg: undefined };
        case ADD_SHIP_FAILURE:
            return { ...state, error: true, openConfirm: true, success_msg: undefined, err_msg: "Error Adding Ship Company. Duplicate Email found" }


        case EDIT_SHIP_REQUEST:
            return { ...state, err_msg: undefined, success_msg: undefined, error: undefined, openConfirm: false };
        case EDIT_SHIP_SUCCESS:
            return { ...state, error: false, openConfirm: true, success_msg: "Ship Updated Successfully", err_msg: undefined };
        case EDIT_SHIP_FAILURE:
            return { ...state, error: true, openConfirm: true, err_msg: "Updating Ship failed", success_msg: undefined };

        case GET_SHIP_REQUEST:
            return { ...state, ship: undefined, open_item_modal: false };
        case GET_SHIP_SUCCESS:
            return { ...state, ship: action.payload.data.ship, open_item_modal: true };
        case GET_SHIP_FAILURE:
            return { ...state, ship: undefined, open_item_modal: false };

        case DELETE_SHIP_REQUEST:
            return { ...state, openConfirm: false, err_msg: undefined, success_msg: undefined, error: undefined };
        case DELETE_SHIP_SUCCESS:
            return { ...state, error: false, openConfirm: true, success_msg: "Ship deleted Successfully", err_msg: undefined };
        case DELETE_SHIP_FAILURE:
            return { ...state, error: true, openConfirm: true, err_msg: "Deleting Ship failed", success_msg: undefined };

        // get all vessels

        case GET_ALL_ADMIN_VESSELS_REQUEST:
            return { ...state, all_vessels: [] , vessels_success:false};
        case GET_ALL_ADMIN_VESSELS_SUCCESS:
            return { ...state, vessels_success:true,all_vessels: _.reverse(action.payload.vessels),filtered_items: [], filter_applied: false };
        case GET_ALL_ADMIN_VESSELS_FAILURE:
            return { ...state, all_vessels: [] , vessels_success:false}

        case GET_ADMIN_FILTERED_VESSELS:
            let tempVessels = [];
            if (action.payload.shipping_co !== '') {
                tempVessels = _.filter(state.all_vessels, v=> v.ship[0].shipping_company_name.toString().toLowerCase() === action.payload.shipping_co.toString().toLowerCase());
                console.log(tempVessels)
                return { ...state, filtered_items: tempVessels, filter_applied: true };
            }
                return { ...state, filtered_items: [], filter_applied: false };
      
        case ADD_VESSEL_REQUEST:
            return { ...state, error: undefined, success_msg: undefined, err_msg: undefined, openConfirm: false };
        case ADD_VESSEL_SUCCESS:
            return { ...state, error: false, openConfirm: true, success_msg: "Vessel added successfully", err_msg: undefined };
        case ADD_VESSEL_FAILURE:
            return { ...state, error: true, openConfirm: true, success_msg: undefined, err_msg: "Error Adding Vessel" }

        case EDIT_VESSEL_REQUEST:
            return { ...state, err_msg: undefined, success_msg: undefined, error: undefined, openConfirm: false };
        case EDIT_VESSEL_SUCCESS:
            return { ...state, error: false, openConfirm: true, success_msg: "Vessel Updated Successfully", err_msg: undefined };
        case EDIT_VESSEL_FAILURE:
            return { ...state, error: true, openConfirm: true, err_msg: "Updating Vessel failed", success_msg: undefined };

        case GET_VESSEL_REQUEST:
            return { ...state, vessel: undefined, open_item_modal: false };
        case GET_VESSEL_SUCCESS:
            return { ...state, vessel: action.payload.data.vessel, open_item_modal: true };
        case GET_VESSEL_FAILURE:
            return { ...state, vessel: undefined, open_item_modal: false };

        case DELETE_VESSEL_REQUEST:
            return { ...state, openConfirm: false, err_msg: undefined, success_msg: undefined, error: undefined };
        case DELETE_VESSEL_SUCCESS:
            return { ...state, error: false, openConfirm: true, success_msg: "Vessel deleted Successfully", err_msg: undefined };
        case DELETE_VESSEL_FAILURE:
            return { ...state, error: true, openConfirm: true, err_msg: "Deleting Vessel failed", success_msg: undefined };


        // users section

        case GET_ALL_ADMIN_USER_REQUEST:
            return { ...state, all_users: [], users_success:false };
        case GET_ALL_ADMIN_USER_SUCCESS:
            return { ...state, users_success:true, all_users: action.payload.users, filtered_items: [], filter_applied: false };
        case GET_ALL_ADMIN_USER_FAILURE:
            return { ...state, all_users: [], users_success:false }

        case GET_ADMIN_FILTERED_USER:
            let tempUsers = [];

            if (action.payload.type !== '') {
                tempUsers = _.filter(state.all_users, function (v) {
                        if (v.type === action.payload.type) return v;
                });
                return { ...state, filtered_items: tempUsers, filter_applied: true };
            }
            if (action.payload.shipping_co !== '') {
                tempUsers = _.filter(state.all_users, function (v) {
                    if(v.type==="Shipping Company"){
                        if (v.shipping_company_name === action.payload.shipping_co) return v;
                    }
                   
                });
                return { ...state, filtered_items: tempUsers, filter_applied: true };
            }
            
            return { ...state, filtered_items: [], filter_applied: false };
           

        case ADD_USER_REQUEST:
            return { ...state, error: undefined, success_msg: undefined, err_msg: undefined, openConfirm: false };
        case ADD_USER_SUCCESS:
            return { ...state, error: false, openConfirm: true, success_msg: "User added successfully", err_msg: undefined };
        case ADD_USER_FAILURE:
            return { ...state, error: true, openConfirm: true, success_msg: undefined, err_msg: "Error Adding User" }

        case EDIT_USER_REQUEST:
            return { ...state, err_msg: undefined, success_msg: undefined, error: undefined, openConfirm: false };
        case EDIT_USER_SUCCESS:
            return { ...state, error: false, openConfirm: true, success_msg: "User Updated Successfully", err_msg: undefined };
        case EDIT_USER_FAILURE:
            return { ...state, error: true, openConfirm: true, err_msg: "Updating User failed", success_msg: undefined };

        case GET_USER_REQUEST:
            return { ...state, user: undefined, open_item_modal: false };
        case GET_USER_SUCCESS:
            return { ...state, user: action.payload.user, open_item_modal: true };
        case GET_USER_FAILURE:
            return { ...state, user: undefined, open_item_modal: false };

        case DELETE_USER_REQUEST:
            return { ...state, openConfirm: false, err_msg: undefined, success_msg: undefined, error: undefined };
        case DELETE_USER_SUCCESS:
            return { ...state, error: false, openConfirm: true, success_msg: "User deleted Successfully", err_msg: undefined };
        case DELETE_USER_FAILURE:
            return { ...state, error: true, openConfirm: true, err_msg: "Deleting User failed", success_msg: undefined };


        // admins
        // users section

        case GET_ALL_ADMINS_REQUEST:
            return { ...state, all_admins: [], admins_success:false };
        case GET_ALL_ADMINS_SUCCESS:
            return { ...state, admins_success:true, all_admins: action.payload.admins, filtered_items: [], filter_applied: false };
        case GET_ALL_ADMINS_FAILURE:
            return { ...state, all_admins: [] , admins_success:false }

        case ADD_ADMIN_REQUEST:
            return { ...state, error: undefined, success_msg: undefined, err_msg: undefined, openConfirm: false };
        case ADD_ADMIN_SUCCESS:
            return { ...state, error: false, openConfirm: true, success_msg: "Admin added successfully", err_msg: undefined };
        case ADD_ADMIN_FAILURE:
            return { ...state, error: true, openConfirm: true, success_msg: undefined, err_msg: "Error Adding Admin" }

        case EDIT_ADMIN_REQUEST:
            return { ...state, err_msg: undefined, success_msg: undefined, error: undefined, openConfirm: false };
        case EDIT_ADMIN_SUCCESS:
            return { ...state, error: false, openConfirm: true, success_msg: "Admin Updated Successfully", err_msg: undefined };
        case EDIT_ADMIN_FAILURE:
            return { ...state, error: true, openConfirm: true, err_msg: "Updating Admin failed", success_msg: undefined };

        case GET_ADMIN_REQUEST:
            return { ...state, admin: undefined, open_item_modal: false };
        case GET_ADMIN_SUCCESS:
            return { ...state, admin: action.payload.admin, open_item_modal: true };
        case GET_ADMIN_FAILURE:
            return { ...state, admin: undefined, open_item_modal: false };

        case DELETE_ADMIN_REQUEST:
            return { ...state, openConfirm: false, err_msg: undefined, success_msg: undefined, error: undefined };
        case DELETE_ADMIN_SUCCESS:
            return { ...state, error: false, openConfirm: true, success_msg: "Admin deleted Successfully", err_msg: undefined };
        case DELETE_ADMIN_FAILURE:
            return { ...state, error: true, openConfirm: true, err_msg: "Deleting User Admin", success_msg: undefined };


        // get all transactions

        case GET_ALL_TRANSACTIONS_REQUEST:
            return { ...state, all_transactions: [], transactions_success:false };
        case GET_ALL_TRANSACTIONS_SUCCESS:
            return { ...state, transactions_success:true, all_transactions: _.reverse(action.payload.transactions), filtered_items: [], filter_applied: false };
        case GET_ALL_TRANSACTIONS_FAILURE:
            return { ...state, all_transactions: [] , transactions_success:true}

        case ADD_TRANSACTION_REQUEST:
            return { ...state, openConfirm: false, error: undefined, err_msg: undefined, success_msg: undefined };
        case ADD_TRANSACTION_SUCCESS:
            return { ...state, error: false, openConfirm: true, success_msg: "Transaction added Successfully", err_msg: undefined };
        case ADD_TRANSACTION_FAILURE:
            return { ...state, error: true, openConfirm: true, err_msg: "Adding Transaction failed", success_msg: undefined };


        case GET_TRANSACTION_REQUEST:
            return { ...state, transaction: undefined, open_item_modal: false };
        case GET_TRANSACTION_SUCCESS:
            return { ...state, transaction: action.payload.transaction, open_item_modal: true };
        case GET_TRANSACTION_FAILURE:
            return { ...state, open_item_modal: false };

        case EDIT_TRANSACTION_REQUEST:
            return { ...state, err_msg: undefined, success_msg: undefined, error: undefined, openConfirm: false };
        case EDIT_TRANSACTION_SUCCESS:
            return { ...state, error: false, openConfirm: true, success_msg: "Transaction Updated Successfully", err_msg: undefined };
        case EDIT_TRANSACTION_FAILURE:
            return { ...state, error: true, openConfirm: true, err_msg: "Updating Transaction failed", success_msg: undefined };


        case GET_ADMIN_FILTERED_TRANSACTIONS:
            let tempTrans = [];
            if (action.payload.reference_no.length>0) {
                action.payload.reference_no.map(r => {
                    let temp = _.filter(state.all_transactions, function (v) {
                        if (v.reference_no === r.label) return v;
                    });

                    tempTrans = _.concat(tempTrans, temp)
                    
                })
                tempTrans = tempTrans
                 .filter(v=>(action.payload.from_date!==null && action.payload.to_date!==null) ? new Date(dateFormat(v.date.toLocaleString(),'yyyy-m-d')) >= new Date(action.payload.from_date)&& new Date(dateFormat(v.date.toLocaleString(),'yyyy-m-d')) <= new Date(action.payload.to_date):true)
                .filter(v=>(action.payload.vessel!='') ?(v.vessel.length>0 ? v.vessel[0].vessel === action.payload.vessel:false): true) 
                .filter(v=>(action.payload.type!='') ? v.type== action.payload.type : true) 
                .filter( function (v) {
                   if(action.payload.warehouse!=''){
                   if (v.new_warehouse.length !== 0) {
                       if (v.new_warehouse[0].forwarding_company === action.payload.warehouse) return v;
                   } else {
                       if (v.warehouse[0].forwarding_company === action.payload.warehouse) return v;
                   }
               }else{
                   return true
               }
               })
               .filter(v => action.payload.shipping_co!=''?(v.ship.length>0 ?v.ship[0].shipping_company_name === action.payload.shipping_co:false) : true)
                 return { ...state, filtered_items: tempTrans, filter_applied: true,filter_length:tempTrans.length };

            }

            //if not select reference number

                 tempTrans = state.all_transactions
                 .filter(v=>(action.payload.from_date!==null && action.payload.to_date!==null) ? new Date(dateFormat(v.date.toLocaleString(),'yyyy-m-d')) >= new Date(action.payload.from_date)&& new Date(dateFormat(v.date.toLocaleString(),'yyyy-m-d')) <= new Date(action.payload.to_date):true)
                 .filter(v=>(action.payload.vessel!='') ?(v.vessel.length>0 ? v.vessel[0].vessel === action.payload.vessel:false): true) 
                 .filter(v=>(action.payload.type!='') ? v.type== action.payload.type : true) 
                 .filter( function (v) {
                    if(action.payload.warehouse!=''){
                    if (v.new_warehouse.length !== 0) {
                        if (v.new_warehouse[0].forwarding_company === action.payload.warehouse) return v;
                    } else if(v.warehouse.length>0) {
                        if (v.warehouse[0].forwarding_company === action.payload.warehouse) return v;
                    }
                }else{
                    return true
                }
                })
                .filter(v => action.payload.shipping_co!=''?(v.ship.length>0 ? v.ship[0].shipping_company_name === action.payload.shipping_co:false) : true)

            return { ...state, filtered_items:tempTrans, filter_applied:true,filter_length: tempTrans.length};

            
            


        case DEL_TRANSACTION_REQUEST:
            return { ...state, openConfirm: false, err_msg: undefined, success_msg: undefined, error: undefined };
        case DEL_TRANSACTION_SUCCESS:
            return { ...state, error: false, openConfirm: true, success_msg: "Transaction deleted Successfully", err_msg: undefined };
        case DEL_TRANSACTION_FAILURE:
            return { ...state, error: true, openConfirm: true, err_msg: "Deleting Transaction failed", success_msg: undefined };


        // get all suppliers

        case GET_ALL_ADMIN_SUPPLIERS_REQUEST:
            return { ...state, all_suppliers: [], suppliers_success:false };
        case GET_ALL_ADMIN_SUPPLIERS_SUCCESS:
            return { ...state, all_suppliers: action.payload , suppliers_success:true};
        case GET_ALL_ADMIN_SUPPLIERS_FAILURE:
            return { ...state, all_suppliers: [], suppliers_success:false}

        case GET_ADMIN_FILTERED_SUPPLIERS:
            let tempSuppliers = [];

            if (action.payload.country !== '') {
                tempSuppliers = _.filter(state.all_suppliers, function (v) {
                        if (v.country === action.payload.country) return v;
                });
                return { ...state, filtered_items: tempSuppliers, filter_applied: true };
            }
            return { ...state, filtered_items: [], filter_applied: false };
            

        case ADD_SUPPLIER_REQUEST:
            return { ...state, error: undefined, success_msg: undefined, err_msg: undefined, openConfirm: false };
        case ADD_SUPPLIER_SUCCESS:
            return { ...state, error: false, openConfirm: true, success_msg: "Supplier added successfully", err_msg: undefined };
        case ADD_SUPPLIER_FAILURE:
            return { ...state, error: true, openConfirm: true, success_msg: undefined, err_msg: "Error Adding Supplier. Duplicate Email Address" }

        case EDIT_SUPPLIER_REQUEST:
            return { ...state, err_msg: undefined, success_msg: undefined, error: undefined, openConfirm: false };
        case EDIT_SUPPLIER_SUCCESS:
            return { ...state, error: false, openConfirm: true, success_msg: "Supplier Updated Successfully", err_msg: undefined };
        case EDIT_SUPPLIER_FAILURE:
            return { ...state, error: true, openConfirm: true, err_msg: "Updating Supplier failed", success_msg: undefined };

        case GET_SUPPLIER_REQUEST:
            return { ...state, supplier: undefined, open_item_modal: false };
        case GET_SUPPLIER_SUCCESS:
            return { ...state, supplier: action.payload.data.supplier, open_item_modal: true };
        case GET_SUPPLIER_FAILURE:
            return { ...state, supplier: undefined, open_item_modal: false };

        case DELETE_SUPPLIER_REQUEST:
            return { ...state, openConfirm: false, err_msg: undefined, success_msg: undefined, error: undefined };
        case DELETE_SUPPLIER_SUCCESS:
            return { ...state, error: false, openConfirm: true, success_msg: "Warehouse Supplier Successfully", err_msg: undefined };
        case DELETE_SUPPLIER_FAILURE:
            return { ...state, error: true, openConfirm: true, err_msg: "Deleting Supplier failed", success_msg: undefined };
   

        // warehouse

        case GET_ALL_ADMIN_WAREHOUSE_REQUEST:
            return { ...state, all_warehouses: [], warehouses_success:false };
        case GET_ALL_ADMIN_WAREHOUSE_SUCCESS:
            return { ...state, all_warehouses: action.payload , warehouses_success:true};
        case GET_ALL_ADMIN_WAREHOUSE_FAILURE:
            return { ...state, all_warehouses: [] , warehouses_success:false}


        case GET_ADMIN_FILTERED_WAREHOUSE:
            let tempWarehouses = [];

            if (action.payload.country !== '') {
                tempWarehouses = _.filter(state.all_warehouses, function (v) {
                        if (v.country === action.payload.country) return v;
                });
                return { ...state, filtered_items: tempWarehouses, filter_applied: true };
            }
           
                return { ...state, filtered_items: [], filter_applied: false };
          

        case ADD_WAREHOUSE_REQUEST:
            return { ...state, error: undefined, success_msg: undefined, err_msg: undefined, openConfirm: false };
        case ADD_WAREHOUSE_SUCCESS:
            return { ...state, error: false, openConfirm: true, success_msg: "Warehouse added successfully", err_msg: undefined };
        case ADD_WAREHOUSE_FAILURE:
            return { ...state, error: true, openConfirm: true, success_msg: undefined, err_msg: "Error Adding Warehouse. Duplicate Email Address" }

        case EDIT_WAREHOUSE_REQUEST:
            return { ...state, err_msg: undefined, success_msg: undefined, error: undefined, openConfirm: false };
        case EDIT_WAREHOUSE_SUCCESS:
            return { ...state, error: false, openConfirm: true, success_msg: "Warehouse Updated Successfully", err_msg: undefined };
        case EDIT_WAREHOUSE_FAILURE:
            return { ...state, error: true, openConfirm: true, err_msg: "Updating Warehouse failed", success_msg: undefined };

        case GET_WAREHOUSE_REQUEST:
            return { ...state, Warehouse: undefined, open_item_modal: false };
        case GET_WAREHOUSE_SUCCESS:
            return { ...state, wh: action.payload.data.warehouse, open_item_modal: true };
        case GET_WAREHOUSE_FAILURE:
            return { ...state, wh: undefined, open_item_modal: false };

        case DELETE_WAREHOUSE_REQUEST:
            return { ...state, openConfirm: false, err_msg: undefined, success_msg: undefined, error: undefined };
        case DELETE_WAREHOUSE_SUCCESS:
            return { ...state, error: false, openConfirm: true, success_msg: "Warehouse deleted Successfully", err_msg: undefined };
        case DELETE_WAREHOUSE_FAILURE:
            return { ...state, error: true, openConfirm: true, err_msg: "Deleting Warehouse failed", success_msg: undefined };


        









        // warehouse reducers
       
        case WAREHOUSE_LOGIN_REQUEST:
            return { ...state, token: undefined, loginState: false, inValidLogin: false, warehouse_login:false };
        case WAREHOUSE_LOGIN_SUCCESS:
            return { ...state, token: action.payload.token, loginState: action.payload.success, inValidLogin: false, warehouse_login:true, warehouse:action.payload.user  };
        case WAREHOUSE_LOGIN_FAILURE:
                return { ...state, token: undefined, loginState: false,warehouse_login:false,inValidLogin: true }



        // client reducers
        case USER_LOGIN_REQUEST:
            return { ...state, token: undefined, loginState: false, inValidLogin: false, user_login:false };
        case USER_LOGIN_SUCCESS:
            return { ...state, token: action.payload.token, loginState: action.payload.success, inValidLogin: false, user_login:true, user:action.payload.user  };
        case USER_LOGIN_FAILURE:
                return { ...state, token: undefined, loginState: false,user_login:false,inValidLogin: true }

        case USER_SIGNUP_REQUEST:
            return { ...state, loginState: false, inValidSignup: false, signupState: false };
        case USER_SIGNUP_SUCCESS:
            return { ...state, signupState: true, inValidSignup: false };
        case USER_SIGNUP_FAILURE:
            return { ...state, signupState: false, inValidSignup: true }


        case GET_ALL_PARTS_BY_SHIP_REQUEST:
            return { ...state, parts: [], parts_success: false };
        
        case GET_ALL_PARTS_BY_WH_REQUEST:
                return { ...state, parts: [], parts_success: false };


        case GET_FILTERED_PARTS_REQUEST:
             filteredParts = state.parts
            .filter( part=> (action.payload.reference_no !== '' )? part.reference_no === action.payload.reference_no:true)
            .filter( part=> (action.payload.vessel!== '' )? part.vessel[0].vessel=== action.payload.vessel:true)
            .filter( part=> (action.payload.warehouse!== '' )? part.warehouse[0].forwarding_company=== action.payload.warehouse:true)
            .filter( part=> (action.payload.supplier!== '' )? part.supplier[0].supplier_name=== action.payload.supplier:true)
            .filter( part=> (action.payload.status!== '' )? part.status=== action.payload.status:true)

            return { ...state, filtered_items: filteredParts, filter_applied: true};
           

            case GET_ALL_TRANSACTIONS_BY_SHIP_REQUEST:
                return { ...state, all_transactions: [], transactions_success:false };
            
            case GET_ALL_TRANSACTIONS_BY_WH_REQUEST:
                    return { ...state, all_transactions: [], transactions_success:false };
                
    

        case GET_VESSELS_BY_SHIP_REQUEST:
            return { ...state, vesselsInShip: [] , vessels_success:false };
        case GET_VESSELS_BY_SHIP_SUCCESS:
            return { ...state, vesselsInShip: action.payload.data.allVessels, vessels_success:true,filter_applied:false};
        case GET_VESSELS_BY_SHIP_FAILURE:
            return { ...state, vesselsInShip: [] , vessels_success:false};

        case GET_ALL_SUPPLIERS_REQUEST:
            return { ...state, suppliers: [] };
        case GET_ALL_SUPPLIERS_SUCCESS:
            return { ...state, suppliers: action.payload };
        case GET_ALL_SUPPLIERS_FAILURE:
            return { ...state, suppliers: [] };

        case GET_ALL_WAREHOUSE_REQUEST:
            return { ...state, warehouse: [] };
        case GET_ALL_WAREHOUSE_SUCCESS:
            return { ...state, warehouse: action.payload };
        case GET_ALL_WAREHOUSE_FAILURE:
            return { ...state, warehouse: [] };


        case ADD_PART_REQUEST:
            return { ...state };
        case ADD_PART_SUCCESS:
            return { ...state };
        case ADD_PART_FAILURE:
            return { ...state };


        case GET_PART_REQUEST:
            return { ...state, part: undefined, open_item_modal: false };
       case RESET_FILTERED_ITEMS:
         return { ...state,filter_applied: false, filtered_items: [] };
        case GET_PART_SUCCESS:
            return { ...state, part: action.payload.part, open_item_modal: true };
        case GET_PART_FAILURE:
            return { ...state, part: undefined, open_item_modal: false };

        default:
            return state;


        case CLOSE_OPENED_PART:
            return { ...state, open_item_modal: false };
    }

}
export default rootReducer;