import React from 'react';
 
function StateCard({state}) {

   let mystate=String(state).toLowerCase();
   mystate = mystate.charAt(0).toUpperCase() + mystate.slice(1);
   switch (state) {
      case 'Pending':
         return <p style={{backgroundColor:'rgba(46,91,255,0.2)',width:'10ch',textAlign:'center',color:'#2E5BFF',borderRadius:20,paddingLeft:10,paddingRight:10}}>{mystate}</p>;
         break;
      case 'Send':
         return <p style={{backgroundColor:'rgba(0, 194, 212, 0.212)',width:'11ch',textAlign:'center',color:'#00C1D4',borderRadius:20,paddingLeft:10,paddingRight:10}}>{mystate}</p>;

      break;
      case 'Delivered':
         return <p style={{backgroundColor:'rgba(214, 54, 73, 0.205)',width:'11ch',textAlign:'center',color:'#D63649',borderRadius:20,paddingLeft:10,paddingRight:10}}>{mystate}</p>;;
      break;
      case 'In Stock':
         return <p style={{backgroundColor:'rgba(141, 84, 255, 0.226)',width:'12ch',textAlign:'center',color:'#8C54FF',borderRadius:20,paddingLeft:10,paddingRight:10}}>{mystate}</p>;
        break;   
      default:
         return <p></p>
         break;
   }
 
}
 
export default StateCard;