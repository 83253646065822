import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

class UnAuthorized extends React.Component {
    constructor(props) {
        super(props);
       }

  

 
    render() {
       
       return(
           <div className='row justify-content-center align-items-center'>
               <div className='col-6 bg-white rounded p-4' style={{marginTop:'10vh'}}>
               <h4>
                   You are not Linked with any Shipping company yet. Please consult the admin to access the dashboard.               
                </h4>
               
               </div>
               
           </div>
       )



    }
}


export default withRouter(UnAuthorized);

