import React from "react";
import { Row, Col, Modal, Button, Form } from 'react-bootstrap';
import '../styles.scss'
import { connect } from "react-redux";
import {
    editSupplier
} from '../../js/actions'
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import DeleteButton from "../../Common/AreYouSureDeleteButton/DeleteButton";
import ConfirmDeleteButton from "../../Common/ConfirmDeleteButton/ConfirmDeleteButton";

const initialState = { supplier_name: '', address: '', email: '', city: '', country: null, phone_no: '', edit: false };


class OpenSupplier extends React.Component {
    constructor(props) {
        super(props);
        this.state = { supplier_name: '', address: '', email: '', city: '', country: null, phone_no: '', edit: false };

    }


    editSupplierById = (event) => {
        const { supplier } = this.props;
        let tempData = this.state;


        tempData.id = supplier._id;
        this.props.editSupplier(tempData)
        this.setState(initialState)
        this.props.onHide();
    }

    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value })

    }
    onClose = () => {
        this.setState(initialState, () => {
            this.props.onHide();
        })

    }


    editClicked = () => {
        const wh = this.props.supplier
        this.setState({
            supplier_name: wh.supplier_name,
            address: wh.address,
            email: wh.email,
            city: wh.city,
            country: wh.country,
            phone_no: wh.phone_no,
            edit: true
        });
    }
    render() {
        const { supplier, show, deleteSupplier } = this.props;
        const { supplier_name, address, country, city, email, phone_no, edit } = this.state;

        if (supplier != undefined && edit == false) {
            return (
                <Modal
                    show={show}
                    onHide={this.onClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Body>
                        <React.Fragment>

                            <Row>
                                <Col>
                                <DeleteButton/>
                                    <h3>{supplier.supplier_name}</h3>
                                </Col>
                                <Col>
                            <ConfirmDeleteButton yesFunction={()=>deleteSupplier(supplier._id)}  hideModal={this.onClose}/>
                                    <Button className="edit-trans" onClick={this.editClicked}>
                                        Edit
                    </Button>
                                </Col>

                            </Row>
                            <br></br>
                            <Row>
                                <Col md="8">
                                    <p className='gray_text  bold text-uppercase'>SUPPLIER</p>
                                    <p>{supplier.supplier_name}</p>
                                </Col>
                                <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>EMAIL</p>
                                    <p>{supplier.email}</p>
                                </Col>
                            </Row>


                            <Row>

                                <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>ADDRESS</p>
                                    <p>{supplier.address}</p>
                                </Col>
                                <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>CITY</p>
                                    <p>{supplier.city}</p>
                                </Col>
                                <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>COUNTRY</p>
                                    <p>{supplier.country}</p>
                                </Col>

                            </Row>

                            <Row>

                                <Col md="4">
                                    <p className='gray_text  bold text-uppercase'>PHONE NUMBER</p>
                                    <p>{supplier.phone_no}</p>
                                </Col>
                            </Row>

                        </React.Fragment>
                    </Modal.Body>


                </Modal>


            );
        }
        else if (supplier != undefined && edit == true) {
            return (
                <Modal
                    show={show}
                    onHide={this.onClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Body>
                        <React.Fragment>

                            <Row>
                                <Col>
                                <h3>{supplier.supplier_name}</h3>

                                </Col>
                                <Col>
                                    <Button className="edit-trans" onClick={this.editSupplierById}>
                                        Save
                        </Button>
                                </Col>

                            </Row>
                            <br></br>
                            <Form >

                                <Form.Row>


                                    <Form.Group as={Col} md="8">
                                        <p className='gray_text  bold text-uppercase'>SUPPLIER</p>
                                        <Form.Control
                                            id="supplier_name" value={supplier_name} onChange={this.handleChange} required />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide Name.
                            </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        <p className='gray_text  bold text-uppercase'>EMAIL</p>
                                        <Form.Control
                                            id="email" value={email} onChange={this.handleChange} required />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide email.
                            </Form.Control.Feedback>
                                    </Form.Group>

                                </Form.Row>


                                <Form.Row>

                                    <Form.Group as={Col} md="4">
                                        <p className='gray_text  bold text-uppercase'>ADDRESS</p>
                                        <Form.Control
                                            id="address" value={address} onChange={this.handleChange} required />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide address.
                            </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="4">
                                        <p className='gray_text  bold text-uppercase'>CITY</p>
                                        <Form.Control
                                            id="city" value={city} onChange={this.handleChange} required />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide city.
                            </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="4">
                                        <p className='gray_text  bold text-uppercase'>COUNTRY</p>
                                   <Form.Control
                                            id="country" value={country} onChange={this.handleChange} required />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide country.
                            </Form.Control.Feedback>
                                    </Form.Group>

                                </Form.Row>

                                <Form.Row>
                                    <Form.Group as={Col} md="4">
                                        <p className='gray_text  bold text-uppercase'>PHONE NUMBER</p>
                                        <ReactPhoneInput
                                country={'gr'}
                                inputExtraProps={{
                                required: true,
                                }}
                                containerStyle={{
                                marginLeft:0,
                                height:'2.6rem'
                                }}
                                buttonStyle={{
                                background:'rgba(224,231,255,0.2)',borderColor:'#E0E7FF' 
                                }}
                                inputStyle={{background:'rgba(224,231,255,0.2)',borderColor:'#E0E7FF',height:'2.6rem',width:'100%'}}
                                value={phone_no}
                                enableSearch={true}
                                onChange={(phone_no)=>this.setState({phone_no})}
                                />  
                                        <Form.Control.Feedback type="invalid">
                                            Please provide phone.
                            </Form.Control.Feedback>
                                    </Form.Group>
                                   
                                </Form.Row>

                            </Form>



                        </React.Fragment>
                    </Modal.Body>


                </Modal>


            );
        }
        else return null;
    }
}

const mapDispatchToProps = dispatch => {
    return {
        editSupplier: payload => dispatch(editSupplier(payload)),
    }
}
export default connect(null, mapDispatchToProps)(OpenSupplier);


